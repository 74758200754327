@import '~jquery.scrollbar/jquery.scrollbar.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~@fancyapps/ui/dist/fancybox.css';
@import "../assets/pages/css/pages.css";
@import "../assets/css/builder.css";

:focus,
:focus-visible{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.text-full-alignment {
  text-align: left;
}

/* Font Sizes
------------------------------------
*/
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}

.Properties {
  position: relative;
}

.badges {
  position: absolute;
  z-index: 999;
  left: 10px;
  top: 10px;
}

.Properties.Properties-List .Properties__body {
  div{
    margin: auto 0;
  }
}
.Properties.Properties-List .Properties__body:has(.Properties__price){
  div{
    margin-top: auto;
    margin-bottom: 0;
  }
}

@import "../assets/css/loggia.css";
@import "../assets/css/builder-grid.css";
@import "../assets/css/skeleton.css";

.search-wrapper {
  height: 100%;
  width: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.search-container {
  position: absolute;
  left: 50%;
  top: 50%;
  border: none;
  max-width: 500px;
  width: 100%;
  box-shadow: none;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.search-wrapper .search-backdrop {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
}

.search-container > div {
  max-width: 600px;
}

.search-container > div > div {
  border: none!important;
  border-bottom: 1px solid #ccc!important;;
  border-radius: 0!important;
  box-shadow: none !important;
}

//.search-container:not(:focus-within) {
//  width: 0;
//  overflow: hidden;
//}
//
//.search-container:focus-within {
//  width: 400px;
//}

.nav-item-relative {
  position: relative;
}

.search-container .search-btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
}

/* Generic Padding Helpers
------------------------------------
*/

//.p-t-5 {
//padding-top: 5px !important;
//}
//.p-r-5 {
//  padding-right: 5px !important;
//}
//.p-l-5 {
//  padding-left: 5px !important;
//}
//.p-b-5 {
//  padding-bottom: 5px !important;
//}
//.padding-5 {
//  padding: 5px !important;
//}
//.p-t-10 {
//  padding-top: 10px !important;
//}
//.p-r-10 {
//  padding-right: 10px !important;
//}
//.p-l-10 {
//  padding-left: 10px !important;
//}
//.p-b-10 {
//  padding-bottom: 10px !important;
//}
//.padding-10 {
//  padding: 10px !important;
//}
//.p-t-15 {
//  padding-top: 15px !important;
//}
//.p-r-15 {
//  padding-right: 15px !important;
//}
//.p-l-15 {
//  padding-left: 15px !important;
//}
//.p-b-15 {
//  padding-bottom: 15px !important;
//}
//.padding-15 {
//  padding: 15px !important;
//}
//.p-t-20 {
//  padding-top: 20px !important;
//}
//.p-r-20 {
//  padding-right: 20px !important;
//}
//.p-l-20 {
//  padding-left: 20px !important;
//}
//.p-b-20 {
//  padding-bottom: 20px !important;
//}
//.padding-20 {
//  padding: 20px !important;
//}
//.p-t-25 {
//  padding-top: 25px !important;
//}
//.p-r-25 {
//  padding-right: 25px !important;
//}
//.p-l-25 {
//  padding-left: 25px !important;
//}
//.p-b-25 {
//  padding-bottom: 25px !important;
//}
//.padding-25 {
//  padding: 25px !important;
//}
//.p-t-30 {
//  padding-top: 30px !important;
//}
//.p-r-30 {
//  padding-right: 30px !important;
//}
//.p-l-30 {
//  padding-left: 30px !important;
//}
//.p-b-30 {
//  padding-bottom: 30px !important;
//}
//.padding-30 {
//  padding: 30px !important;
//}
//.p-t-35 {
//  padding-top: 35px !important;
//}
//.p-r-35 {
//  padding-right: 35px !important;
//}
//.p-l-35 {
//  padding-left: 35px !important;
//}
//.p-b-35 {
//  padding-bottom: 35px !important;
//}
//.padding-35 {
//  padding: 35px !important;
//}
//.p-t-40 {
//  padding-top: 40px !important;
//}
//.p-r-40 {
//  padding-right: 40px !important;
//}
//.p-l-40 {
//  padding-left: 40px !important;
//}
//.p-b-40 {
//  padding-bottom: 40px !important;
//}
//.padding-40 {
//  padding: 40px !important;
//}
//.p-t-45 {
//  padding-top: 45px !important;
//}
//.p-r-45 {
//  padding-right: 45px !important;
//}
//.p-l-45 {
//  padding-left: 45px !important;
//}
//.p-b-45 {
//  padding-bottom: 45px !important;
//}
//.padding-45 {
//  padding: 45px !important;
//}
//.p-t-50 {
//  padding-top: 50px !important;
//}
//.p-r-50 {
//  padding-right: 50px !important;
//}
//.p-l-50 {
//  padding-left: 50px !important;
//}
//.p-b-50 {
//  padding-bottom: 50px !important;
//}
//.padding-50 {
//  padding: 50px !important;
//}
//.p-t-55 {
//  padding-top: 55px !important;
//}
//.p-r-55 {
//  padding-right: 55px !important;
//}
//.p-l-55 {
//  padding-left: 55px !important;
//}
//.p-b-55 {
//  padding-bottom: 55px !important;
//}
//.padding-55 {
//  padding: 55px !important;
//}
//.p-t-60 {
//  padding-top: 60px !important;
//}
//.p-r-60 {
//  padding-right: 60px !important;
//}
//.p-l-60 {
//  padding-left: 60px !important;
//}
//.p-b-60 {
//  padding-bottom: 60px !important;
//}
//.padding-60 {
//  padding: 60px !important;
//}
//.p-t-65 {
//  padding-top: 65px !important;
//}
//.p-r-65 {
//  padding-right: 65px !important;
//}
//.p-l-65 {
//  padding-left: 65px !important;
//}
//.p-b-65 {
//  padding-bottom: 65px !important;
//}
//.padding-65 {
//  padding: 65px !important;
//}
//.p-t-70 {
//  padding-top: 70px !important;
//}
//.p-r-70 {
//  padding-right: 70px !important;
//}
//.p-l-70 {
//  padding-left: 70px !important;
//}
//.p-b-70 {
//  padding-bottom: 70px !important;
//}
//.padding-70 {
//  padding: 70px !important;
//}
//.p-t-75 {
//  padding-top: 75px !important;
//}
//.p-r-75 {
//  padding-right: 75px !important;
//}
//.p-l-75 {
//  padding-left: 75px !important;
//}
//.p-b-75 {
//  padding-bottom: 75px !important;
//}
//.padding-75 {
//  padding: 75px !important;
//}
//.p-t-80 {
//  padding-top: 80px !important;
//}
//.p-r-80 {
//  padding-right: 80px !important;
//}
//.p-l-80 {
//  padding-left: 80px !important;
//}
//.p-b-80 {
//  padding-bottom: 80px !important;
//}
//.padding-80 {
//  padding: 80px !important;
//}
//.p-t-85 {
//  padding-top: 85px !important;
//}
//.p-r-85 {
//  padding-right: 85px !important;
//}
//.p-l-85 {
//  padding-left: 85px !important;
//}
//.p-b-85 {
//  padding-bottom: 85px !important;
//}
//.padding-85 {
//  padding: 85px !important;
//}
//.p-t-90 {
//  padding-top: 90px !important;
//}
//.p-r-90 {
//  padding-right: 90px !important;
//}
//.p-l-90 {
//  padding-left: 90px !important;
//}
//.p-b-90 {
//  padding-bottom: 90px !important;
//}
//.padding-90 {
//  padding: 90px !important;
//}
//.p-t-95 {
//  padding-top: 95px !important;
//}
//.p-r-95 {
//  padding-right: 95px !important;
//}
//.p-l-95 {
//  padding-left: 95px !important;
//}
//.p-b-95 {
//  padding-bottom: 95px !important;
//}
//.padding-95 {
//  padding: 95px !important;
//}
//.p-t-100 {
//  padding-top: 100px !important;
//}
//.p-r-100 {
//  padding-right: 100px !important;
//}
//.p-l-100 {
//  padding-left: 100px !important;
//}
//.p-b-100 {
//  padding-bottom: 100px !important;
//}
//.padding-100 {
//  padding: 100px !important;
//}
///* Generic Margin Helpers
//------------------------------------
// */
//.m-t-0 {
//  margin-top: 0px;
//}
//.m-r-0 {
//  margin-right: 0px;
//}
//.m-l-0 {
//  margin-left: 0px;
//}
//.m-b-0 {
//  margin-bottom: 0px;
//}
//.m-t-5 {
//  margin-top: 5px;
//}
//.m-r-5 {
//  margin-right: 5px;
//}
//.m-l-5 {
//  margin-left: 5px;
//}
//.m-b-5 {
//  margin-bottom: 5px;
//}
//.m-t-10 {
//  margin-top: 10px;
//}
//.m-r-10 {
//  margin-right: 10px;
//}
//.m-l-10 {
//  margin-left: 10px;
//}
//.m-b-10 {
//  margin-bottom: 10px;
//}
//.m-t-15 {
//  margin-top: 15px;
//}
//.m-r-15 {
//  margin-right: 15px;
//}
//.m-l-15 {
//  margin-left: 15px;
//}
//.m-b-15 {
//  margin-bottom: 15px;
//}
//.m-t-20 {
//  margin-top: 20px;
//}
//.m-r-20 {
//  margin-right: 20px;
//}
//.m-l-20 {
//  margin-left: 20px;
//}
//.m-b-20 {
//  margin-bottom: 20px;
//}
//.m-t-25 {
//  margin-top: 25px;
//}
//.m-r-25 {
//  margin-right: 25px;
//}
//.m-l-25 {
//  margin-left: 25px;
//}
//.m-b-25 {
//  margin-bottom: 25px;
//}
//.m-t-30 {
//  margin-top: 30px;
//}
//.m-r-30 {
//  margin-right: 30px;
//}
//.m-l-30 {
//  margin-left: 30px;
//}
//.m-b-30 {
//  margin-bottom: 30px;
//}
//.m-t-35 {
//  margin-top: 35px;
//}
//.m-r-35 {
//  margin-right: 35px;
//}
//.m-l-35 {
//  margin-left: 35px;
//}
//.m-b-35 {
//  margin-bottom: 35px;
//}
//.m-t-40 {
//  margin-top: 40px;
//}
//.m-r-40 {
//  margin-right: 40px;
//}
//.m-l-40 {
//  margin-left: 40px;
//}
//.m-b-40 {
//  margin-bottom: 40px;
//}
//.m-t-45 {
//  margin-top: 45px;
//}
//.m-r-45 {
//  margin-right: 45px;
//}
//.m-l-45 {
//  margin-left: 45px;
//}
//.m-b-45 {
//  margin-bottom: 45px;
//}
//.m-t-50 {
//  margin-top: 50px;
//}
//.m-r-50 {
//  margin-right: 50px;
//}
//.m-l-50 {
//  margin-left: 50px;
//}
//.m-b-50 {
//  margin-bottom: 50px;
//}
//.m-t-55 {
//  margin-top: 55px;
//}
//.m-r-55 {
//  margin-right: 55px;
//}
//.m-l-55 {
//  margin-left: 55px;
//}
//.m-b-55 {
//  margin-bottom: 55px;
//}
//.m-t-60 {
//  margin-top: 60px;
//}
//.m-r-60 {
//  margin-right: 60px;
//}
//.m-l-60 {
//  margin-left: 60px;
//}
//.m-b-60 {
//  margin-bottom: 60px;
//}
//.m-t-65 {
//  margin-top: 65px;
//}
//.m-r-65 {
//  margin-right: 65px;
//}
//.m-l-65 {
//  margin-left: 65px;
//}
//.m-b-65 {
//  margin-bottom: 65px;
//}
//.m-t-70 {
//  margin-top: 70px;
//}
//.m-r-70 {
//  margin-right: 70px;
//}
//.m-l-70 {
//  margin-left: 70px;
//}
//.m-b-70 {
//  margin-bottom: 70px;
//}
//.m-t-75 {
//  margin-top: 75px;
//}
//.m-r-75 {
//  margin-right: 75px;
//}
//.m-l-75 {
//  margin-left: 75px;
//}
//.m-b-75 {
//  margin-bottom: 75px;
//}
//.m-t-80 {
//  margin-top: 80px;
//}
//.m-r-80 {
//  margin-right: 80px;
//}
//.m-l-80 {
//  margin-left: 80px;
//}
//.m-b-80 {
//  margin-bottom: 80px;
//}
//.m-t-85 {
//  margin-top: 85px;
//}
//.m-r-85 {
//  margin-right: 85px;
//}
//.m-l-85 {
//  margin-left: 85px;
//}
//.m-b-85 {
//  margin-bottom: 85px;
//}
//.m-t-90 {
//  margin-top: 90px;
//}
//.m-r-90 {
//  margin-right: 90px;
//}
//.m-l-90 {
//  margin-left: 90px;
//}
//.m-b-90 {
//  margin-bottom: 90px;
//}
//.m-t-95 {
//  margin-top: 95px;
//}
//.m-r-95 {
//  margin-right: 95px;
//}
//.m-l-95 {
//  margin-left: 95px;
//}
//.m-b-95 {
//  margin-bottom: 95px;
//}
//.m-t-100 {
//  margin-top: 100px;
//}
//.m-r-100 {
//  margin-right: 100px;
//}
//.m-l-100 {
//  margin-left: 100px;
//}
//.m-b-100 {
//  margin-bottom: 100px;
//}
//.full-height {
//  height: 100% !important;
//}
//.full-width {
//  width: 100%;
//}
//.full-vh {
//  height: 100vh;
//}
//.hide {
//  display: none;
//}
//.inline {
//  display: inline-block !important;
//}
//.block {
//  display: block;
//}
//.b-blank {
//  border-color: #000;
//}

.panel .panel-heading .panel-controls {
  float: right;
  margin-top: -3px;
}

.panel .panel-heading .panel-controls ul li {
  display: inline-block;
  list-style: none;
  line-height: 0;
}

@media (max-width: 576px) {
  .xs-p-b-85 {
    padding-bottom: 95px !important;
  }

  .xs-p-b-95 {
    padding-bottom: 95px !important;
  }
}