@charset "UTF-8";
/*------------------------------------------------------------------
Pages v5.0.3
Theme : Default
Generated From SASS
[Table of contents]

1. Layouts
2. View Ports
3. Chat
4. Cards
5. Typography
6. Buttons
7. Alerts
8. Notifications
9. Progress Indicators
10. Modals
11. Tabs & Accordians
12. Sliders
13. Treeview
14. Nestables
15. Form Elements
16. Tables & Datatables
17. Charts
18. Lists
19. Social App
20. Email App
21. Calendar App
22. Login
23. Lockscreen
24. Timeline
25. Gallery
26. Vector Map
27. Widgets
28. Misc
29. Print
30. Demo
-------------------------------------------------------------------*/
/*!
* direction.scss
*/
/* Page Loader
------------------------------------
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600");
@import url("https://keplerapis.com/fonts/css?family=Inter+UI:300,400,500,600");
/*------------------------------------------------------------------
[1. Core Layout]
*/
html {
  height: 100%;
}

/* Body Triggers for layout options
------------------------------------------------------------------
*/
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

body.fixed-header .header {
  position: fixed;
  left: 0;
  top: 0;
  user-select: none;
}

body.no-header .page-container .page-content-wrapper .content {
  padding-top: 0px;
}

body.no-header .header {
  border-bottom-color: transparent;
}

body.dashboard {
  background: #f4f4f4;
}

body.dashboard .page-container {
  background: #f4f4f4;
}

body.rtl [class^="col-"],
body.rtl [class*="col-"] {
  float: left;
}

body.menu-pin .overlay {
  padding-left: 0;
}

body.menu-pin .overlay .overlay-brand {
  margin-left: 0;
}

/* Page Container
------------------------------------
*/
.page-container {
  width: 100%;
  height: 100%;
  padding-left: 70px;
}

.page-container .page-content-wrapper {
  min-height: 100%;
  position: relative;
}

.page-container .page-content-wrapper .content {
  /* Content holder */
  z-index: 10;
  padding-top: 60px;
  padding-bottom: 69px;
  min-height: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-container .page-content-wrapper .content.full-width {
  width: 100%;
}

.page-container .page-content-wrapper .content .content-inner {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding-left: 30px;
  position: relative;
}

.page-container .page-content-wrapper .content:only-child {
  padding-bottom: 0px;
}

.page-container .page-content-wrapper .content.overlay-footer {
  padding-bottom: 0px;
}

.page-container .page-content-wrapper .footer {
  /* Footer */
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
}

.page-container .page-content-wrapper .footer.fixed {
  position: fixed;
}

.page-title {
  margin-top: 0px;
}

.group-container {
  white-space: nowrap !important;
}

.group-container .single-group {
  vertical-align: top;
  display: inline-block;
  white-space: normal;
}

.jumbotron {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0;
  background-color: #fff;
}

.jumbotron .inner {
  position: relative;
  z-index: 2;
}

.jumbotron.lg {
  height: 495px;
}

.jumbotron .cover-photo {
  width: 100%;
  height: inherit;
  overflow: hidden;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  background-size: cover;
  background-color: #19AD79;
}

.jumbotron .cover-photo img {
  display: none;
}

.jumbotron h1 {
  font-size: 44px;
}

/* Other overides */
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.copyright {
  padding: 25px 0;
  border-top: 1px solid #e0e0e0;
}

.pager {
  margin: 0;
}

/* SPLIT VIEW FOR EMAIL and APPS
------------------------------------
*/
.split-view {
  position: relative;
  height: 100%;
}

.split-view .split-list {
  float: left;
  width: 372px;
  background: #fff;
  height: 100%;
  overflow-y: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.split-view .split-list .list-view-fake-header {
  font-size: 12px !important;
  line-height: normal !important;
}

.split-view .split-list .list-refresh {
  position: absolute;
  right: 9px;
  top: 4px;
  color: rgba(33, 33, 33, 0.62);
  z-index: 101;
}

.split-view .split-list .list-refresh i {
  font-size: 18px;
}

.split-view .split-list .list-view-fake-header,
.split-view .split-list .list-view-group-header {
  background: #fff;
  height: 28px;
  color: #757575;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  letter-spacing: normal;
  font-size: 12px;
  padding-left: 12px;
  padding-top: 6px;
  width: 100%;
}

.split-view .split-list .item {
  height: 80px;
  list-style: none;
  position: relative;
  border-bottom: 1px solid rgba(33, 33, 33, 0.14);
  width: 347px;
  margin-left: 11px;
  cursor: pointer;
}

.split-view .split-list .item .inline {
  width: 256px;
  margin-left: 13px;
}

.split-view .split-list .item .inline > * {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.split-view .split-list .item .recipients {
  padding-bottom: 2px;
  letter-spacing: -0.006em;
  color: #212121;
}

.split-view .split-list .item .form-check {
  float: left;
  clear: left;
  display: none;
  max-width: 32px;
}

.split-view .split-list .item .subject {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #212121;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 19px;
  display: -webkit-box;
  white-space: normal;
  line-height: 18px;
  letter-spacing: -0.006em;
}

.split-view .split-list .item .body {
  font-size: 14px;
  height: 22px;
  color: rgba(33, 33, 33, 0.62);
  letter-spacing: -0.006em;
  font-weight: 400;
}

.split-view .split-list .item .datetime {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  position: absolute;
  right: 12px;
  top: 7px;
  color: rgba(33, 33, 33, 0.62);
}

.split-view .split-list .item:last-child {
  border-bottom: none;
}

.split-view .split-details {
  position: relative;
  overflow: auto;
  height: 100%;
}

.split-view .split-details .no-result {
  bottom: 0;
  left: 0;
  margin-top: -34px;
  opacity: 0.32;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}

.split-view .split-details .actions {
  height: 50px;
  float: left;
}

.split-view .split-details .actions li {
  list-style: none;
  position: relative;
}

.split-view .split-details .actions li:last-child:after {
  display: none;
}

.split-view .split-details .actions li:after {
  content: "";
  height: 14px;
  position: absolute;
  right: -4px;
  top: 18px;
  width: 1px;
  background: rgba(0, 0, 0, 0.07);
}

.split-view .split-details .actions li a {
  font-size: 13.1px;
  color: #4b4b4b;
  font-weight: 600;
  padding: 0 13px;
  line-height: 50px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .split-view .split-list.slideLeft {
    -webkit-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

/* Horizontal Menu
------------------------------------
*/
.horizontal-app-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.horizontal-app-menu.fixed-header {
  overflow: hidden;
}

.horizontal-app-menu.fixed-header .header {
  position: relative;
}

.horizontal-app-menu.fixed-header .page-container {
  overflow: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.horizontal-app-menu .header {
  display: block;
  height: auto;
  border-bottom: none;
  background-color: #2b303b;
  color: #929aac;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.horizontal-app-menu .header .header-inner {
  height: 60px;
  background-color: transparent;
  position: relative;
  color: #929aac;
}

.horizontal-app-menu .header .bubble, .horizontal-app-menu .header .profile-dropdown-toggle:after {
  border-color: #2b303b;
}

.horizontal-app-menu .header .header-lg-height {
  height: 75px;
}

.horizontal-app-menu .header .header-md-height {
  height: 51px;
}

.horizontal-app-menu .header .header-sm-height {
  height: 50px;
}

.horizontal-app-menu .header .search-link {
  color: #929aac;
}

.horizontal-app-menu .header .header-inner .brand.inline {
  position: relative;
  border-right: 1px solid #40444d;
  padding-right: 15px;
  margin-right: 0px;
  width: auto;
}

.horizontal-app-menu .header .header-inner .brand.inline > img {
  width: auto;
}

.horizontal-app-menu .header .notification-list {
  border: none;
}

.horizontal-app-menu .header .title-bar {
  padding-bottom: 18px;
}

.horizontal-app-menu .header .page-title {
  color: #929aac;
  font-size: 21px;
  line-height: 21px;
  font-weight: normal;
  margin-bottom: 0;
  margin-left: 15px;
}

.horizontal-app-menu .header .header-icon {
  color: #929aac;
}

.horizontal-app-menu .menu-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
  color: #929aac;
}

.horizontal-app-menu .menu-bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.horizontal-app-menu .menu-bar ul li a .arrow {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  width: 23px;
}

.horizontal-app-menu .menu-bar ul li a .arrow:before {
  font-size: 16px;
  font-family: "pages-icon";
  height: auto;
  content: "\e989";
  text-shadow: none;
  position: relative;
  top: 2px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}

.horizontal-app-menu .menu-bar ul li {
  line-height: 24px;
}

.horizontal-app-menu .menu-bar ul li.more .title {
  display: flex;
}

.horizontal-app-menu .menu-bar ul li .sub-menu {
  padding: 0;
  left: 50%;
  top: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  min-width: calc(100% + 4px);
  padding: 18px 25px;
  background: #fff;
  z-index: 1;
  position: absolute;
  box-shadow: rgba(15, 15, 15, 0.08) 0px 4px 12px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
  -ms-transform: translate(15px, 0);
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}

.horizontal-app-menu .menu-bar ul li .sub-menu li a {
  min-width: 50px;
  width: 100%;
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-weight: 400;
  opacity: 0.8;
}

.horizontal-app-menu .menu-bar ul li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(calc(34% + 0.5px), 0, 0);
  transform: translate3d(calc(34% + 0.5px), 0, 0);
  -ms-transform: translate(calc(34% + 0.5px), 0);
}

.horizontal-app-menu .menu-bar > ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.horizontal-app-menu .menu-bar > ul > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  position: relative;
}

.horizontal-app-menu .menu-bar > ul > li:hover:not(.open) > a {
  color: #fff;
}

.horizontal-app-menu .menu-bar > ul > li:first-child > a {
  padding-left: 0;
}

.horizontal-app-menu .menu-bar > ul > li:last-child > a {
  padding-right: 0;
}

.horizontal-app-menu .menu-bar > ul > li > a {
  padding: 0 20px;
}

.horizontal-app-menu .menu-bar > ul > li > ul {
  display: none;
  opacity: 0;
  list-style: none;
  border: 0;
  top: -7px;
  left: -4px;
  min-width: calc(100% + 4px);
  padding: 0 25px;
  padding-top: 60px;
  padding-bottom: 18px;
  background: #fff;
  color: #4b4b4b;
  z-index: 2;
  position: absolute;
  box-shadow: rgba(15, 15, 15, 0.08) 0px 4px 12px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
}

.horizontal-app-menu .menu-bar > ul > li > ul li .arrow {
  display: flex;
  justify-content: center;
}

.horizontal-app-menu .menu-bar > ul > li.open > ul {
  display: block;
}

.horizontal-app-menu .menu-bar > ul > li.open > ul:before {
  content: "";
  position: absolute;
  height: 1px;
  top: 52px;
  width: calc(100% - 25px * 2);
  background-color: rgba(33, 33, 33, 0.14);
}

.horizontal-app-menu .menu-bar > ul > li.open > ul > li {
  padding-left: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -ms-transform: translate(0, -10px);
  opacity: 0;
  transition: all 0.18s ease;
  border-bottom: none;
}

.horizontal-app-menu .menu-bar > ul > li.open > ul > li > a {
  min-width: 50px;
  width: 100%;
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 400;
  opacity: 0.8;
}

.horizontal-app-menu .menu-bar > ul > li.open > ul > li > a > .arrow:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.horizontal-app-menu .menu-bar > ul > li.open > ul > li:last-child > a {
  padding-bottom: 0;
}

.horizontal-app-menu .menu-bar > ul > li.open > a {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -ms-transform: translate(0, -10px);
  opacity: 0;
  transition: all 0.18s ease !important;
  color: #4b4b4b;
}

.horizontal-app-menu .menu-bar > ul > li.opening > ul {
  opacity: 1;
}

.horizontal-app-menu .menu-bar > ul > li.opening > ul > li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
  opacity: 1;
}

.horizontal-app-menu .menu-bar > ul > li.opening > a {
  position: relative;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
  opacity: 1;
}

.horizontal-app-menu .menu-bar > ul > li.closing > ul {
  opacity: 0;
}

.horizontal-app-menu .menu-bar > ul > li.closing > ul > li {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -ms-transform: translate(0, -10px);
  opacity: 0;
}

.horizontal-app-menu .menu-bar > ul > li.closing > a {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -ms-transform: translate(0, -10px);
  opacity: 0;
}

.horizontal-app-menu .menu-bar > ul li > a {
  color: inherit;
}

.horizontal-app-menu .secondary-sidebar {
  margin-left: -25px;
  border: 1px solid rgba(33, 33, 33, 0.14);
}

.horizontal-app-menu .secondary-sidebar .main-menu > li.active {
  background: transparent;
}

.horizontal-app-menu .inner-content {
  margin-left: 225px;
}

.horizontal-app-menu .content {
  -ms-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
}

.horizontal-app-menu .page-container {
  padding-left: 0;
}

.horizontal-app-menu .page-container .page-content-wrapper .content {
  padding-top: 0;
}

.horizontal-app-menu .ghost-nav-dropdown {
  top: -7px;
  left: -4px;
  background: #fff;
  z-index: 1000;
  position: absolute;
  box-shadow: rgba(15, 15, 15, 0.08) 0px 4px 12px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  transition: all 0.18s ease-in;
}

.horizontal-app-menu .calendar .calendar-header {
  background-color: transparent;
  padding-left: 0;
  position: relative;
  left: -20px;
}

.horizontal-app-menu .calendar .options {
  padding-left: 0;
}

.horizontal-app-menu .calendar .months .month:first-child {
  padding-left: 0;
}

.horizontal-app-menu .calendar .weeks-wrapper {
  margin-left: 0;
  padding-left: 0;
}

.horizontal-app-menu .calendar .week-dragger {
  margin-left: 0;
}

.horizontal-app-menu .calendar .calendar-container .grid {
  overflow: hidden;
}

@media (max-width: 991px) {
  body.horizontal-menu-open .header {
    z-index: 1000;
  }
  .horizontal-menu-backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    transition: opacity 0.3s ease;
    display: none;
  }
  .horizontal-app-menu .menu-bar {
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: #2b303b;
    width: 270px;
    left: -270px;
    overflow-y: auto;
    -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-perspective: 1000;
    flex-direction: column;
    z-index: 1;
  }
  .horizontal-app-menu .menu-bar.open {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -ms-transform: translate(100%, 0);
  }
  .horizontal-app-menu .menu-bar > ul {
    flex-flow: column nowrap;
    overflow-x: hidden;
  }
  .horizontal-app-menu .menu-bar > ul > li:first-child a,
  .horizontal-app-menu .menu-bar > ul > li:last-child a {
    padding-right: 32px !important;
    padding-left: 20px !important;
  }
  .horizontal-app-menu .menu-bar ul {
    width: 100%;
  }
  .horizontal-app-menu .menu-bar ul li {
    min-height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .horizontal-app-menu .menu-bar ul li > ul {
    padding: 0;
    overflow: hidden;
    margin-top: 6px;
    position: static;
    background: #21252d;
    opacity: 1;
    padding: 12px 0 10px 10px;
    min-width: 0;
    left: 0;
    box-shadow: none;
  }
  .horizontal-app-menu .menu-bar ul li > ul:before {
    display: none;
  }
  .horizontal-app-menu .menu-bar ul li > ul > li {
    padding-left: 0;
    min-height: 40px;
  }
  .horizontal-app-menu .menu-bar ul li > ul > li > a {
    font-size: 13px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 40px;
  }
  .horizontal-app-menu .menu-bar ul li a {
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif;
    display: inline-block;
    padding-left: 20px !important;
    padding-right: 32px !important;
    min-height: 40px;
    line-height: 40px;
    font-size: 14px;
    clear: both;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .horizontal-app-menu .menu-bar ul li a > .arrow {
    float: right;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: all 0.12s ease;
    transform-origin: 90% 50%;
    padding-left: 17px;
  }
  .horizontal-app-menu .menu-bar ul li a > .arrow.open {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  .horizontal-app-menu .menu-bar ul li.active {
    border: none;
  }
  .horizontal-app-menu .menu-bar ul li.open ul li {
    opacity: 1;
    transform: none;
  }
  .horizontal-app-menu .menu-bar ul li.open > a {
    opacity: 1;
    transform: none;
    color: #fff;
    padding-left: 20px !important;
  }
  .horizontal-app-menu .menu-bar .search-link {
    background: rgba(146, 154, 172, 0.1);
    display: block;
    height: 54px;
    width: 100%;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    color: #929aac;
    flex-shrink: 0;
    margin-top: auto;
  }
  .horizontal-app-menu .menu-bar .search-link i {
    margin: 0;
  }
  .horizontal-app-menu .menu-bar .toggle-sidebar {
    width: 35px;
    text-align: center;
    margin: 7px 10px 10px 8px;
    padding: 10px;
  }
  .horizontal-app-menu[data-pages-direction="slideRight"] {
    right: 0;
  }
  .horizontal-app-menu .header .header-sm-height {
    height: 100%;
  }
  .horizontal-app-menu .ghost-nav-dropdown {
    display: none;
  }
  .horizontal-app-menu .options {
    padding-left: 10px !important;
  }
}

@media (max-width: 1400px) {
  .header .brand.inline::after {
    top: -7px !important;
  }
}

@media (min-width: 980px) {
  .horizontal-menu .page-content {
    margin-left: 0;
  }
  .horizontal-menu .page-content .content {
    padding-top: 123px;
  }
}

.inner-content {
  margin-top: 0px;
  padding: 0px;
  overflow: auto;
  min-height: 100%;
  margin-left: 250px;
}

.toggle-secondary-sidebar {
  display: none;
}

.secondary-sidebar {
  background: #fff;
  width: 250px;
  float: left;
  padding-left: 47px;
  height: 100%;
  position: fixed;
  padding: 20px 0;
  padding-right: 20px;
}

.secondary-sidebar.not-fixed {
  position: inherit;
}

.secondary-sidebar.open {
  position: fixed;
  visibility: hidden;
  display: block;
}

.secondary-sidebar .btn-compose {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.secondary-sidebar .menu-title {
  color: #757575;
  font-size: 10.8px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  letter-spacing: 0.03em;
}

.secondary-sidebar .menu-title {
  padding-left: 30px;
}

.secondary-sidebar > ul > li {
  padding-left: 0;
}

.secondary-sidebar ul li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 30px;
  padding-right: 10px;
}

.secondary-sidebar ul li a > .icon-thumbnail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  width: auto;
  line-height: auto;
}

.secondary-sidebar ul li a > .icon-thumbnail > svg {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.secondary-sidebar ul li a.active {
  color: #212121;
}

.secondary-sidebar ul li a > .title {
  width: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  display: flex;
  align-items: center;
}

.secondary-sidebar ul li a > .title:after {
  display: none;
}

.secondary-sidebar ul li a > .badge {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.secondary-sidebar ul li a > .arrow:before {
  float: right;
  display: inline;
  font-size: 16px;
  font-family: "pages-icon";
  height: auto;
  content: "\e989";
  font-weight: 300;
  text-shadow: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}

.secondary-sidebar ul li ul {
  margin: 0;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 7px;
}

.secondary-sidebar ul li ul li {
  padding: 0;
  margin-bottom: 9px;
  margin-top: 5px;
}

.secondary-sidebar ul li.open > a {
  color: #212121;
}

.secondary-sidebar ul li.open > a .arrow:before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.secondary-sidebar ul li.open > ul {
  display: block;
}

.secondary-sidebar ul li > ul {
  display: none;
}

.secondary-sidebar .main-menu {
  padding-left: 0;
}

.secondary-sidebar .main-menu > li {
  list-style: none;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.secondary-sidebar .main-menu > li.active {
  background: #f4f4f4;
}

.secondary-sidebar .main-menu > li.active > a {
  color: #0072EC;
}

.secondary-sidebar .main-menu > li.active > a:hover {
  color: #0072EC;
}

.secondary-sidebar .main-menu > li.active > a > .title:after {
  background: #0072EC;
  border-radius: 50%;
  content: "";
  height: 7px;
  position: absolute;
  right: -14px;
  top: 6.5px;
  width: 7px;
}

.secondary-sidebar .main-menu > li a {
  font-size: 14px;
  color: #4b4b4b;
  line-height: 32px;
  font-weight: 400;
}

.secondary-sidebar .main-menu > li a:hover {
  color: #212121;
}

.secondary-sidebar .main-menu > li a > .title i {
  margin-right: 6px;
  opacity: 0.9;
}

.secondary-sidebar .sub-menu {
  margin-left: 23px;
}

.secondary-sidebar .sub-menu li {
  list-style: none;
  padding: 0;
}

.secondary-sidebar .sub-menu li.active a {
  color: #212121 !important;
}

.secondary-sidebar .sub-menu li a {
  color: #4b4b4b;
  line-height: 25px;
  font-weight: 400;
}

.secondary-sidebar .sub-menu li a:hover {
  color: #212121;
}

.secondary-sidebar .sub-menu li a .badge,
.secondary-sidebar .main-menu li a .badge {
  background: transparent;
  font-size: 13px;
  color: #929aac;
  line-height: 25px;
}

.secondary-sidebar.light {
  background-color: #fff;
  border-right: 1px solid rgba(33, 33, 33, 0.14);
}

.secondary-sidebar.light .menu-title {
  color: rgba(146, 154, 172, 0.6);
  opacity: 1;
}

.secondary-sidebar.light .icon-thumbnail {
  background-color: transparent;
}

.secondary-sidebar.light .main-menu li a:hover {
  color: #212121;
}

.secondary-sidebar.light .main-menu li.active > a {
  color: #212121;
}

.secondary-sidebar.light .sub-menu {
  margin-top: 5px;
}

.secondary-sidebar.light .sub-menu li.active a {
  color: #212121 !important;
}

.secondary-sidebar.light .sub-menu li a {
  color: rgba(146, 154, 172, 0.6);
}

.secondary-sidebar.light .sub-menu li a:hover {
  color: #212121;
}

/* Header
------------------------------------
*/
.header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  width: 100%;
  padding: 0 20px 0 0;
  z-index: 800;
  background-color: #fff;
  border-bottom: 1px solid rgba(224, 224, 224, 0.7);
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header a {
  color: #4b4b4b;
}

.header a:hover, .header a:active, .header a:focus {
  color: #4b4b4b;
}

.header .header-inner {
  height: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header.transparent {
  background-color: transparent !important;
}

.header .brand {
  vertical-align: middle;
  width: 280px;
  text-align: center;
}

.header .bubble {
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background-color: #D83C31;
  color: #fff;
  position: absolute;
  top: -3px;
  right: -5px;
  border: 2px solid #fff;
  opacity: 0.98;
}

.header .profile-dropdown-toggle {
  padding: 0;
  margin-right: 5px;
  border-radius: 50%;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 180ms;
}

.header .profile-dropdown-toggle:after {
  content: "";
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background-color: #19AD79;
  position: absolute;
  bottom: -1px;
  right: 1px;
  border: 2px solid #fff;
  opacity: 0.98;
}

.header .profile-dropdown-toggle:hover {
  box-shadow: 0px 0px 0px 4px rgba(33, 33, 33, 0.14);
}

.header .profile-dropdown-toggle.active, .header .profile-dropdown-toggle:active {
  box-shadow: 0px 0px 0px 4px rgba(117, 117, 117, 0.45);
}

.header .notification-list {
  display: inline-block;
  line-height: 23px;
  user-select: none;
}

.header .search-link {
  display: inline-block;
  margin-left: 15px;
  color: #4b4b4b;
  opacity: 0.77;
  font-size: 14px;
  vertical-align: middle;
  line-height: 12px;
  font-weight: 400;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.006em;
}

.header .search-link i {
  margin-right: 4px;
  vertical-align: middle;
}

.header .search-link:hover {
  opacity: 1;
}

.header ul li {
  line-height: 12px;
  vertical-align: middle;
}

.header .dropdown {
  line-height: 12px;
}

.header .dropdown-menu {
  margin-top: 10px;
}

/* Bootstrap navbar
------------------------------------
*/
.navbar {
  min-height: 50px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid transparent;
  top: -1px;
}

.navbar-nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav > li > a {
  padding: 10px 15px;
}

.navbar-default {
  background-color: #fff;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}

.navbar-toggle {
  border-radius: 0;
  background-color: transparent !important;
}

.navbar-default .navbar-nav > li > a {
  color: #4b4b4b;
}

.nav-collapse.collapse {
  height: 100% !important;
}

.navbar-center,
.navbar-center > li {
  float: none;
  display: inline-block;
  *display: inline;
  /* ie7 fix */
  *zoom: 1;
  /* hasLayout ie7 trigger */
  vertical-align: top;
}

.navbar-nav li a {
  min-width: 50px;
}

/* Header Responsive Classes
------------------------------------
*/
@media only screen and (min-width: 980px) {
  body.menu-pin .header .brand {
    width: 245px;
  }
  body.menu-behind .header .brand {
    width: 200px;
    text-align: left;
    padding-left: 20px;
  }
  body.box-layout {
    background-color: #fff;
  }
  body.box-layout > .container,
  body.box-layout > .full-height > .container {
    height: 100%;
    padding: 0;
    background-color: #fff;
  }
  body.box-layout .header {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  body.box-layout .header > .container {
    background-color: #fff;
    border-bottom: 1px solid rgba(230, 230, 230, 0.7);
    padding: 0 20px 0 0;
    height: 100%;
  }
  .header .brand {
    position: relative;
  }
  .header .user-info-wrapper .user-details .user-name {
    font-size: 16px;
  }
  .header .user-info-wrapper .user-details .user-other {
    font-size: 10px;
  }
  .header .user-info-wrapper .user-pic {
    position: relative;
    top: -6px;
  }
  .notification-panel {
    width: 400px;
  }
}

@media (max-width: 991px) {
  body.box-layout .header > .container {
    padding: 0;
  }
  body.box-layout .header > .container .pull-right .sm-action-bar {
    right: 0;
  }
  body.horizontal-app-menu .header > .container {
    padding: 0;
  }
  body.horizontal-app-menu .header .header-icon {
    padding: 0;
  }
  .header {
    padding: 0 15px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    height: 48px;
  }
  .header .header-inner {
    text-align: center;
  }
  .header .header-inner .mark-email {
    left: 45px;
    position: absolute;
    top: 23px;
  }
  .header .header-inner .quickview-link {
    position: absolute;
    right: 0;
    top: 12px;
  }
  .header .brand {
    width: auto;
  }
  .header > .pull-left,
  .header > .pull-right {
    position: relative;
  }
  .header > .pull-right .sm-action-bar {
    right: 0;
  }
}

.windows .header .search-link {
  font-size: 15px;
}

/* Breadcrumbs
------------------------------------
*/
.breadcrumb {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  padding: 15px 0;
  margin: 0;
  border: none;
  text-transform: uppercase;
}

.breadcrumb.breadcrumb-alt {
  padding: 8px 0;
  text-transform: none;
}

.breadcrumb.breadcrumb-alt a {
  letter-spacing: normal;
  color: #757575;
  font-size: 12px !important;
}

.breadcrumb.breadcrumb-alt > .breadcrumb-item.active {
  letter-spacing: normal;
  color: #4b4b4b;
  font-size: 12px !important;
}

.breadcrumb a {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 10.5px !important;
  letter-spacing: 0.06em;
  font-weight: 500;
  color: #4b4b4b;
}

.breadcrumb .breadcrumb-item {
  display: flex;
  padding-left: 0px;
}

.breadcrumb > .breadcrumb-item + .breadcrumb-item:before {
  color: #4b4b4b;
  font-family: "pages-icon";
  content: "\e988";
  font-size: 16px;
}

.breadcrumb > .breadcrumb-item.active {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #4e37b6;
  margin-left: 4px;
  margin-right: 5px;
  font-size: 10.5px !important;
  letter-spacing: 0.06em;
}

.breadcrumb > .breadcrumb-item.active:before {
  padding-right: 8px;
}

/* Main Menu Sidebar
------------------------------------
*/
body.mobile .sidebar-menu {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

body.mobile .sidebar-menu > ul {
  height: auto !important;
  overflow: visible !important;
  -webkit-overflow-scrolling: touch !important;
}

body.mobile .page-sidebar .sidebar-menu .menu-items li:hover a {
  color: #929aac;
}

body.mobile .page-sidebar .sidebar-menu .menu-items li:hover .icon-thumbnail {
  color: #929aac !important;
}

body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a, body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a {
  color: #fff;
}

body.mobile .page-sidebar .sidebar-menu .menu-items li.active > .icon-thumbnail, body.mobile .page-sidebar .sidebar-menu .menu-items li.open > .icon-thumbnail {
  color: #fff;
}

body.mobile .drager {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

body.sidebar-visible .page-sidebar .scroll-element {
  visibility: visible;
}

body.sidebar-visible .page-sidebar .menu-items .icon-thumbnail {
  -webkit-transform: translate3d(-14px, 0, 0);
  transform: translate3d(-14px, 0, 0);
  -ms-transform: translate(-14px, 0);
}

body.sidebar-visible .page-sidebar .sidebar-header .sidebar-header-controls {
  -webkit-transform: translate3d(58px, 0, 0);
  transform: translate3d(58px, 0, 0);
  -ms-transform: translate(58px, 0);
}

.page-sidebar {
  width: 280px;
  background-color: #2b303b;
  z-index: 1000;
  left: -210px;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -moz-transition: -moz-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -o-transition: -o-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.page-sidebar a,
.page-sidebar button {
  color: #929aac;
}

.page-sidebar a:hover, .page-sidebar a:active,
.page-sidebar button:hover,
.page-sidebar button:active {
  color: #fff;
  background-color: transparent !important;
}

.page-sidebar a:hover:focus, .page-sidebar a:active:focus,
.page-sidebar button:hover:focus,
.page-sidebar button:active:focus {
  color: #fff;
}

.page-sidebar a:visited, .page-sidebar a:focus,
.page-sidebar button:visited,
.page-sidebar button:focus {
  color: #929aac;
}

.page-sidebar button.sidebar-slide-toggle.active {
  color: #929aac;
}

.page-sidebar button.sidebar-slide-toggle.active:focus {
  background: transparent;
}

.page-sidebar .scroll-element {
  visibility: hidden;
}

.page-sidebar .sidebar-header {
  /* Side-bar header */
  display: block;
  height: 60px;
  line-height: 60px;
  background-color: #272b35;
  border-bottom: 1px solid #232730;
  color: #fff;
  width: 100%;
  padding: 0 20px;
  padding-left: 30px;
  clear: both;
  z-index: 10;
  position: relative;
  user-select: none;
}

.page-sidebar .sidebar-header .sidebar-header-controls {
  display: inline-block;
  -webkit-transition: -webkit-transform 0 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 0 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
}

.page-sidebar .sidebar-header .sidebar-header-controls .btn i {
  width: 18px;
  height: 18px;
  margin: 0;
}

.page-sidebar .sidebar-header .sidebar-slide-toggle i {
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}

.page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.page-sidebar .close-sidebar {
  position: absolute;
  right: 19px;
  top: 14px;
  padding: 9px;
  z-index: 1;
}

.page-sidebar .close-sidebar > i {
  color: rgba(255, 255, 255, 0.57);
}

.page-sidebar .sidebar-overlay-slide {
  /* Side-bar Top Slider */
  width: 100%;
  height: 100%;
  background-color: #272b35;
  display: block;
  z-index: 9;
  padding: 80px 20px 20px 20px;
}

.page-sidebar .sidebar-overlay-slide.from-top {
  top: -100%;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.page-sidebar .sidebar-overlay-slide.from-top.show {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
}

.page-sidebar .sidebar-menu {
  /* Side-bar Menu */
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
}

.page-sidebar .sidebar-menu .outer-tab-nav-section {
  display: inline-block;
  width: 45px;
  position: absolute;
  height: 100%;
  background-color: #0aa699 !important;
}

.page-sidebar .sidebar-menu .menu-items {
  /* Side-bar Menut Items */
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 10px);
  width: 100%;
}

.page-sidebar .sidebar-menu .menu-items li:hover > .icon-thumbnail, .page-sidebar .sidebar-menu .menu-items li.open > .icon-thumbnail, .page-sidebar .sidebar-menu .menu-items li.active > .icon-thumbnail {
  color: #fff;
}

.page-sidebar .sidebar-menu .menu-items li:hover > a, .page-sidebar .sidebar-menu .menu-items li.open > a, .page-sidebar .sidebar-menu .menu-items li.active > a {
  color: #fff;
}

.page-sidebar .sidebar-menu .menu-items li > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65%;
}

.page-sidebar .sidebar-menu .menu-items > li {
  display: block;
  padding: 0;
  clear: right;
}

.page-sidebar .sidebar-menu .menu-items > li:after, .page-sidebar .sidebar-menu .menu-items > li:before {
  display: table;
  content: " ";
  clear: both;
}

.page-sidebar .sidebar-menu .menu-items > li > a {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif;
  display: inline-block;
  padding-left: 32px;
  min-height: 40px;
  line-height: 40px;
  font-size: 14px;
  clear: both;
}

.page-sidebar .sidebar-menu .menu-items > li > a.open {
  background: #313131;
}

.page-sidebar .sidebar-menu .menu-items > li > a > .arrow {
  float: right;
  padding-right: 25px;
}

.page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before {
  float: right;
  display: inline;
  font-size: 16px;
  font-family: "pages-icon";
  height: auto;
  content: "\e989";
  font-weight: 300;
  text-shadow: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}

.page-sidebar .sidebar-menu .menu-items > li > a > .arrow.open:before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.page-sidebar .sidebar-menu .menu-items > li > a > .badge {
  margin-top: 12px;
}

.page-sidebar .sidebar-menu .menu-items > li > a > .title {
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 65%;
}

.page-sidebar .sidebar-menu .menu-items > li > a > .details {
  font-size: 12px;
  opacity: 0.4;
  display: block;
  clear: both;
}

.page-sidebar .sidebar-menu .menu-items > li > a.detailed > .title {
  line-height: 28px;
}

.page-sidebar .sidebar-menu .menu-items > li > a.detailed > .details {
  line-height: 16px;
}

.page-sidebar .sidebar-menu .menu-items > li.active > ul.sub-menu {
  display: block;
}

.page-sidebar .sidebar-menu .menu-items > li > .icon-thumbnail:first-letter {
  text-transform: uppercase;
}

.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu li {
  padding-left: 10px;
  padding-right: 3px;
}

.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu .icon-thumbnail {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
}

.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow:before {
  float: right;
  margin-top: 1px;
  margin-right: 20px;
  display: inline;
  font-size: 16px;
  font-family: "pages-icon";
  content: "\e989";
  font-weight: 300;
  text-shadow: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}

.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow.open:before {
  float: right;
  margin-top: 1px;
  margin-right: 18px;
  display: inline;
  font-size: 16px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-weight: 300;
  text-shadow: none;
}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 0 0 10px 0;
  background-color: #21252d;
  padding: 18px 0 10px 0;
}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
  background: none;
  padding: 0px 20px 0 40px;
  margin-top: 1px;
}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li:hover > .icon-thumbnail {
  color: #fff;
}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a {
  display: inline-block;
  padding: 5px 0px;
  font-size: 13px;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif;
  white-space: normal;
}

.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li .icon-thumbnail {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  background-color: #2b303b;
  font-size: 14px;
}

.page-sidebar .sidebar-menu .muted {
  color: rgba(33, 33, 33, 0.62);
}

[data-toggle-pin="sidebar"] > i:before {
  content: "\e98c";
}

/* Sidebar icon holder
------------------------------------
*/
.icon-thumbnail {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  left: 0;
  float: right;
  margin-right: 14px;
  color: #929aac;
  -webkit-transition: -webkit-transform 0 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 0 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: bold;
}

.icon-thumbnail > i {
  font-size: 20px;
}

.icon-thumbnail > svg {
  width: auto;
  height: auto;
  display: inline;
}

/* Sidebar Responsive Classes
------------------------------------
*/
@media only screen and (max-width: 1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none;
  }
}

@media only screen and (min-width: 980px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
  }
  body.menu-pin [data-toggle-pin="sidebar"] > i:before {
    content: "\e9a2";
  }
  body.menu-pin .page-sidebar {
    transform: translate(210px, 0) !important;
    -webkit-transform: translate(210px, 0) !important;
    -ms-transform: translate(210px, 0) !important;
    width: 250px;
  }
  body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  body.menu-pin .page-sidebar .menu-items .icon-thumbnail {
    -webkit-transform: translate3d(-14px, 0, 0);
    transform: translate3d(-14px, 0, 0);
    -ms-transform: translate(-14px, 0);
  }
  body.menu-behind .page-sidebar {
    z-index: 799;
  }
  body.box-layout .page-sidebar {
    left: auto;
    transform: none !important;
    -webkit-transform: none !important;
  }
}

@media (max-width: 991px) {
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0px) !important;
    transform: translate3d(0, 0px, 0px) !important;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 30px 0 36px;
  }
  .icon-thumbnail {
    margin-right: 24px;
  }
  .page-sidebar {
    display: none;
    z-index: auto;
  }
  .page-sidebar.visible {
    display: block;
  }
  .page-sidebar .page-sidebar-inner {
    z-index: 1;
    left: 0 !important;
    width: 260px;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    top: 40px;
    bottom: 0;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul > li > a > .badge {
    display: inline-block;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 27px;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 27px 0 31px;
  }
}

@media (max-width: 991px) {
  .sidebar-menu .icon-thumbnail {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .page-sidebar .sidebar-header {
    padding: 0 12px;
  }
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 20px;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 25px 0 28px;
  }
}

@media (max-width: 480px) {
  body .header .header-inner .toggle-secondary-sidebar {
    font-size: 16px;
    top: 12px;
  }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px;
  }
}

/* END Sidebar
------------------------------------
*/
/* Overlay Search
------------------------------------
*/
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1010;
  padding-left: 64px;
  overflow: auto;
}

.overlay .inline-block {
  display: inline-block;
}

.overlay .overlay-brand {
  margin-left: 7px;
  position: relative;
  top: -1px;
}

.overlay > div {
  width: 100%;
  height: 260px;
  display: block;
  overflow: hidden;
}

.overlay .has-results {
  overflow: visible;
}

.overlay .overlay-search {
  font-weight: 700;
  font-size: 77px;
  height: 100px;
  letter-spacing: -1.925px;
  line-height: 100px;
  width: calc(100% - 75px);
  padding-left: 0 !important;
}

.overlay .overlay-close {
  position: absolute;
  line-height: 0px;
  padding: 0;
  right: 20px;
}

.overlay .overlay-close i {
  font-size: 36px;
}

/* Quick View
------------------------------------
*/
.quickview-wrapper {
  position: fixed;
  right: -285px;
  top: 0;
  width: 285px;
  background: #fff;
  bottom: 0;
  z-index: 1000;
  border: none;
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.quickview-wrapper.open {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
}

.quickview-wrapper .quickview-toggle {
  position: absolute;
  right: 8px;
  top: 7px;
  color: #929aac;
  padding: 0;
  line-height: 0;
}

.quickview-wrapper .quickview-toggle:hover:not(.active) {
  color: #dee1e6;
  background: none;
  cursor: pointer;
}

.quickview-wrapper .quickview-toggle i {
  font-size: 24px;
}

.quickview-wrapper .btn-icon-link:before {
  z-index: 1;
}

.quickview-wrapper .nav-tabs {
  /* Quickview Tabs */
  background-color: #2b303b;
  position: relative;
  padding: 0 43px;
  border-color: transparent;
}

.quickview-wrapper .nav-tabs > li > a {
  padding: 11px;
  color: #929aac;
  border: 0;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  min-width: 62px;
  line-height: 1.4;
}

.quickview-wrapper .nav-tabs > li > a:hover {
  color: #cbcfd7;
}

.quickview-wrapper .nav-tabs > li > a.active {
  color: #fff;
}

.quickview-wrapper .nav-tabs > li > a.active:hover, .quickview-wrapper .nav-tabs > li > a.active:focus {
  background: transparent;
  color: #fff;
}

.quickview-wrapper .nav-tabs > li.active > a,
.quickview-wrapper .nav-tabs > li.active > a:hover,
.quickview-wrapper .nav-tabs > li.active > a:focus {
  background-color: transparent;
  border: 0;
}

.quickview-wrapper .nav-tabs ~ .tab-content {
  position: absolute;
  padding: 0;
  left: 0;
  right: 0;
  top: 38px;
  bottom: 0;
  height: auto;
}

.quickview-wrapper .nav-tabs ~ .tab-content > div {
  height: 100%;
}

.quickview-wrapper .nav-tabs ~ .tab-content ul {
  margin: 0;
  padding: 0;
}

.quickview-wrapper .nav-tabs ~ .tab-content ul li {
  list-style: none;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes {
  /* Quickview Notes */
  background: #fbf9e3;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes a,
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes button {
  color: #968974;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list {
  position: relative;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar {
  height: 50px;
  padding: 0 25px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul {
  margin-top: 10px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li {
  display: inline-block;
  height: auto;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a {
  height: 22px;
  line-height: 22px;
  display: block;
  padding: 0 5px;
  font-size: 14px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a:hover, .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a.selected {
  background: #968974;
  color: #fbf9e3;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar .btn-remove-notes {
  background: transparent;
  bottom: 8px;
  display: block;
  left: 50%;
  margin-left: -40%;
  position: absolute;
  width: 83%;
  border: 1px solid #968974;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul {
  padding: 0;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li {
  display: flex;
  cursor: pointer;
  height: 42px;
  padding: 0 25px;
  display: block;
  clear: both;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left {
  float: left;
  width: 65%;
  height: 100%;
  padding-top: 9px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left .form-check {
  display: none;
  float: left;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left .form-check label {
  margin-right: 0;
  vertical-align: text-top;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left p {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  display: inline-block;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .right {
  font-size: 10.5px;
  text-align: right;
  width: 35%;
  line-height: 41px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .right .date {
  margin-right: 10px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li:hover {
  background: #f4ecd1;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note {
  /* Quickview Note */
  background-image: url("../img/notes_lines.png");
  background-repeat: repeat-y;
  background-position: 27px top;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note > div {
  display: table;
  height: 100%;
  width: 100%;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar {
  background: #fcfcfa;
  height: 35px;
  width: 100%;
  display: table-row;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.33);
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li {
  display: inline-block;
  border-right: 1px solid #edecec;
  float: left;
  line-height: 37px;
  padding: 0;
  text-align: center;
  width: 40px;
  height: inherit;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a {
  color: #a5a5a5;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a:hover {
  background: #fffaf6;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a.active {
  color: #333;
  background: #f9f1ea;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar:after {
  position: absolute;
  content: "";
  width: 100%;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body {
  display: table-row;
  height: 100%;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body > div {
  display: table;
  height: 100%;
  width: 100%;
  padding: 0 20px 0 45px;
  white-space: normal;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top {
  height: 50px;
  display: table-row;
  clear: both;
  line-height: 50px;
  text-align: center;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top > a {
  float: left;
  color: #b0b0a8;
  margin-left: 10px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top > span {
  font-style: italic;
  color: #b0b0a8;
  font-size: 11px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content {
  display: table-row;
  height: 100%;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content form {
  height: 100%;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor {
  font-size: 12px;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor:focus {
  outline: none;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor::-moz-selection {
  background: #fef8ae;
}

.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor::selection {
  background: #fef8ae;
}

/* Pages Scroll bar
------------------------------------
*/
.scroll {
  position: relative;
  overflow: auto;
}

.scroll-wrapper > .scroll-element,
.scroll-wrapper > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scroll-wrapper > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scroll-wrapper > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  left: 0;
  min-width: 100%;
  width: 100%;
}

.scroll-wrapper > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 2px;
  top: 0;
  width: 4px;
}

.scroll-wrapper > .scroll-element .scroll-element_outer {
  opacity: 0.3;
}

.scroll-wrapper > .scroll-element .scroll-element_size {
  background-color: rgba(0, 0, 0, 0.07);
  opacity: 0;
}

.scroll-wrapper > .scroll-element .scroll-bar {
  background-color: #697686;
}

.scroll-wrapper > .scroll-element.scroll-x .scroll-bar {
  bottom: 0;
  height: 4px;
  min-width: 24px;
  top: auto;
}

.scroll-wrapper > .scroll-element.scroll-x .scroll-element_outer {
  bottom: 0;
  top: auto;
  left: 2px;
  -webkit-transition: height 0.2s;
  transition: height 0.2s;
}

.scroll-wrapper > .scroll-element.scroll-x .scroll-element_size {
  left: -4px;
}

.scroll-wrapper > .scroll-element.scroll-y .scroll-bar {
  left: auto;
  min-height: 24px;
  right: 0;
  width: 4px;
}

.scroll-wrapper > .scroll-element.scroll-y .scroll-element_outer {
  left: auto;
  right: 0;
  top: 2px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.scroll-wrapper > .scroll-element.scroll-y .scroll-element_size {
  top: -4px;
}

.scroll-wrapper > .scroll-content {
  box-sizing: inherit !important;
}

.scroll-wrapper.auto-hide > .scroll-element .scroll-element_track {
  display: none;
}

.scroll-wrapper
> .scroll-element.scroll-x.scroll-scrolly_visible
.scroll-element_size {
  left: -11px;
}

.scroll-wrapper
> .scroll-element.scroll-y.scroll-scrollx_visible
.scroll-element_size {
  top: -11px;
}

/* hover & drag */
.scroll-wrapper > .scroll-element:hover .scroll-element_outer,
.scroll-wrapper > .scroll-element.scroll-draggable .scroll-element_outer {
  overflow: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7;
}

.scroll-wrapper
> .scroll-element:hover
.scroll-element_outer
.scroll-element_size,
.scroll-wrapper
> .scroll-element.scroll-draggable
.scroll-element_outer
.scroll-element_size {
  opacity: 1;
}

.scroll-wrapper > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scroll-wrapper
> .scroll-element.scroll-draggable
.scroll-element_outer
.scroll-bar {
  height: 100%;
  width: 100%;
}

.scroll-wrapper > .scroll-element.scroll-x:hover .scroll-element_outer,
.scroll-wrapper
> .scroll-element.scroll-x.scroll-draggable
.scroll-element_outer {
  height: 10px;
  min-height: 7px;
}

.scroll-wrapper > .scroll-element.scroll-y:hover .scroll-element_outer,
.scroll-wrapper
> .scroll-element.scroll-y.scroll-draggable
.scroll-element_outer {
  min-width: 7px;
  width: 7px;
}

/*------------------------------------------------------------------
[5@mixin  Typography]
*/
/* Standard elements
--------------------------------------------------
*/
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #4b4b4b;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
}

/* Headings 
------------------------------------
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 500;
  color: inherit;
}

h1 {
  font-size: 44px;
  line-height: 50px;
  letter-spacing: -0.0141279em;
}

h2 {
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.021em;
}

h3 {
  font-size: 27px;
  line-height: 35px;
  letter-spacing: -0.0114923em;
}

h4 {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.00865734em;
}

h5 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.00630069em;
}

h3 small,
h4 small,
h5 small {
  font-weight: 300;
}

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-bottom: 10px;
}

/* Lins and Others
------------------------------------
*/
a {
  text-shadow: none !important;
  color: #4e37b6;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
  font-weight: 500;
}

a:focus,
a:hover,
a:active {
  color: #7252D3;
}

a,
a:focus,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none;
}

a[ng-click] {
  cursor: pointer;
}

br {
  line-height: normal;
  clear: both;
}

p {
  display: block;
  color: inherit;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.00177646em;
  line-height: 21px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}

small,
.small {
  line-height: 18px;
  font-size: 85.714%;
}

label.inline {
  display: inline-block;
  position: relative;
  top: 0px;
  font-size: 13px;
}

ul,
ol {
  margin-bottom: 10px;
}

ul > li,
ol > li {
  padding-left: 3px;
  line-height: 24px;
}

ul.lg-icon > li,
ol.lg-icon > li {
  font-size: 21px;
}

ul.lg-icon > li span,
ol.lg-icon > li span {
  font-size: 14px;
}

ul.no-style,
ol.no-style {
  list-style: none;
  padding-left: 5px;
}

address {
  margin-bottom: 0px;
}

address a {
  color: #4b4b4b;
}

blockquote {
  padding: 4px 0 0 18px;
  border-left: 0;
}

blockquote:before {
  content: "\e95d";
  font-family: "pages-icon";
  font-size: 20px;
  margin-right: 6px;
  float: left;
  position: relative;
  top: -12px;
}

blockquote p {
  font-size: 16px;
  margin-bottom: 4px;
}

blockquote small {
  line-height: 29px;
  color: #4b4b4b;
}

blockquote small:before {
  content: "—";
  margin-right: 6px;
}

blockquote.pull-right {
  border-right: 0;
}

blockquote.pull-right:before {
  float: right;
  font-family: "pages-icon";
  content: "\e95d";
  margin-left: 6px;
  margin-right: 0;
}

blockquote.pull-right small {
  padding-right: 30px;
}

blockquote.pull-right small:after {
  content: "";
}

hr {
  border-color: #e0e0e0;
}

hr.double {
  border-width: 2px;
}

hr.dotted {
  border-style: dotted none none;
}

.ff-sup {
  font-feature-settings: "kern" 1, "sups" 1;
  -webkit-font-feature-settings: "kern" 1, "sups" 1;
  -ms-font-feature-settings: "kern" 1, "sups" 1;
  -moz-font-feature-settings: "kern" 1, "sups" 1;
}

.ff-sub {
  font-feature-settings: "kern" 1, "subs" 1;
  -webkit-font-feature-settings: "kern" 1, "subs" 1;
  -ms-font-feature-settings: "kern" 1, "subs" 1;
  -moz-font-feature-settings: "kern" 1, "subs" 1;
}

/* Standard HTML Typography tags
------------------------------------
*/
code {
  color: #757575;
  background-color: rgba(33, 33, 33, 0.05);
  font-size: 97%;
  position: relative;
  line-height: inherit;
  border-radius: 3px;
  padding: 5px 7px;
  margin: 0;
}

code:hover {
  color: #0072EC;
}

code.code-sm {
  padding: 3px 6px;
}

figcaption {
  font-size: 13px;
  margin-top: 10px;
  font-weight: 400;
  display: block;
  letter-spacing: 0.008em;
  text-align: center;
  color: #757575;
  line-height: 1.46;
}

em {
  font-style: italic !important;
  font-family: inherit;
  font-weight: inherit;
}

ins {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  border-bottom: 1px solid #d0d0d0;
  text-decoration: none;
  color: #4b4b4b;
  font-weight: normal;
  font-size: 94%;
}

cite {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  color: #757575;
  text-decoration: none;
  font-style: normal;
  hanging-punctuation: first;
}

sup {
  top: -0.28em;
  font-size: 70%;
}

sub {
  bottom: 0.03em;
}

var {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-feature-settings: "calt" 1, "tnum" 1, "frac" 1, "case" 1, "ss01" 1, "cv11" 1;
}

abbr {
  text-decoration: none;
  letter-spacing: 0.01em;
  background-color: transparent;
  border-bottom: 2px solid #ffba5a;
  transition-property: color, background, border;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  text-decoration: none;
  color: #4b4b4b;
  border-bottom-color: #ffba5a;
  -webkit-text-stroke: 0.18px;
}

q {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 20px;
  hanging-punctuation: first;
}

q:before {
  content: "\201C";
  color: rgba(0, 0, 0, 0.44);
  font-size: 35px;
}

q:after {
  content: "\201D";
  font-size: 35px;
  color: rgba(0, 0, 0, 0.44);
}

audio {
  margin-top: 42px;
}

hr {
  clear: both;
  margin-bottom: 42px;
  margin-top: 42px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

address {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  margin: 0 0 1.75em;
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}

abbr {
  text-decoration: none;
  letter-spacing: 0.01em;
  background-color: transparent;
  border-bottom: 2px solid #ffba5a;
  transition-property: color, background, border;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  text-decoration: none;
  color: #4b4b4b;
  border-bottom-color: #ffba5a;
  -webkit-text-stroke: 0.18px;
}

acronym {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  background-color: transparent;
  border-bottom: 2px solid #ffba5a;
  transition-property: color, background, border;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  text-decoration: none;
  color: #4b4b4b;
  border-bottom-color: #ffba5a;
  -webkit-text-stroke: 0.2px;
}

pre {
  tab-size: 4;
  font-size: 85.714%;
  overflow-x: auto;
  font-family: monospace, monospace;
  line-height: 1.7;
  counter-reset: line;
  background-color: #f4f4f4;
  color: #4b4b4b;
  margin: 0px;
  counter-reset: line;
  padding: 17px 20px;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}

dt {
  font-weight: 700;
  margin-bottom: 5px;
}

dd {
  margin: 0 0 1.75em;
}

kbd {
  font-size: 85.714%;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position: relative;
  line-height: 34px;
  top: -3px;
  letter-spacing: 0.01em;
  padding: 5px 7px;
  margin: 0;
  color: rgba(0, 0, 0, 0.53);
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 0px 1px #c7c7c7, 0px 1px 0px 1px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px #ececec;
}

/* Types 
------------------------------------
*/
.overline {
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.06em;
  font-size: 11px;
}

/* Font Sizes 
------------------------------------
*/
.small-text {
  font-size: 12px !important;
  letter-spacing: 0.00849077em;
  line-height: 18px;
}

.small-text a {
  text-decoration: underline;
}

.normal-text {
  font-size: 13px !important;
}

.large-text {
  font-size: 15px !important;
}

/* Font Weights
------------------------------------
 */
.normal {
  font-weight: normal !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.bold {
  font-weight: 600 !important;
}

.light {
  font-weight: 300 !important;
}

/* Misc 
------------------------------------
*/
.logo {
  margin: 18px 0px;
}

.all-caps {
  text-transform: uppercase;
  letter-spacing: 0.07em !important;
}

.text-uppercase {
  text-transform: uppercase !important;
  letter-spacing: 0.07em !important;
}

.muted {
  color: #757575;
}

.hint-text {
  opacity: 0.76 !important;
}

.no-decoration {
  text-decoration: none !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Monochrome Colors
------------------------------------
 */
.bg-contrast-higher {
  background-color: #212121 !important;
  color: #fff;
}

.bg-contrast-high {
  background-color: #4b4b4b !important;
  color: #fff;
}

.bg-contrast-medium {
  background-color: #757575 !important;
  color: #fff;
}

.bg-contrast-low {
  background-color: #e0e0e0 !important;
  color: #4b4b4b;
}

.bg-contrast-lower {
  background-color: #f4f4f4 !important;
  color: #4b4b4b;
}

.bg-contrast-lowest {
  background-color: #fff !important;
  color: #4b4b4b;
}

/* Primary
------------------------------------
*/
.bg-primary {
  background-color: #7252D3 !important;
  color: #fff;
}

.bg-primary-dark {
  background-color: #4e37b6 !important;
  color: #fff;
}

.bg-primary-darker {
  background-color: #2d218f !important;
  color: #fff;
}

.bg-primary-light {
  background-color: #845ae0 !important;
  color: #fff;
}

.bg-primary-lighter {
  background-color: #eae0fb !important;
  color: #4b4b4b;
}

/* Complete 
------------------------------------
*/
.bg-complete {
  background-color: #0072EC !important;
  color: #fff;
}

.bg-complete-dark {
  background-color: #004fbf !important;
  color: #fff;
}

.bg-complete-darker {
  background-color: #00318e !important;
  color: #fff;
}

.bg-complete-light {
  background-color: #0f8ff9 !important;
  color: #fff;
}

.bg-complete-lighter {
  background-color: #d3eeff !important;
  color: #4b4b4b;
}

/* Success 
------------------------------------
*/
.bg-success {
  background-color: #19AD79 !important;
  color: #fff;
}

.bg-success-dark {
  background-color: #0d935b !important;
  color: #fff;
}

.bg-success-darker {
  background-color: #04733e !important;
  color: #fff;
}

.bg-success-light {
  background-color: #26bf93 !important;
  color: #4b4b4b;
}

.bg-success-lighter {
  background-color: #d6f7f0 !important;
  color: #4b4b4b;
}

/* Info
------------------------------------
*/
.bg-info {
  background-color: #3B4752 !important;
  color: #fff;
}

.bg-info-dark {
  background-color: #2b3947;
  color: #fff;
}

.bg-info-darker {
  background-color: #1b2839;
  color: #fff;
}

.bg-info-light {
  background-color: #475b6b;
  color: #fff;
}

.bg-info-lighter {
  background-color: #dbe6e8;
  color: #4b4b4b;
}

/* Danger 
------------------------------------
*/
.bg-danger {
  background-color: #D83C31 !important;
  color: #fff;
}

.bg-danger-dark {
  background-color: #b91e1e;
  color: #fff;
}

.bg-danger-darker {
  background-color: #900f17;
  color: #fff;
}

.bg-danger-light {
  background-color: #e6533c;
  color: #4b4b4b;
}

.bg-danger-lighter {
  background-color: #fde2da;
  color: #4b4b4b;
}

/* Warning
------------------------------------
 */
.bg-warning {
  background-color: #FFd945 !important;
  color: #4b4b4b;
}

.bg-warning-dark {
  background-color: #daab2d;
  color: #4b4b4b;
}

.bg-warning-darker {
  background-color: #aa7918;
  color: #4b4b4b;
}

.bg-warning-light {
  background-color: #ffe858;
  color: #4b4b4b;
}

.bg-warning-lighter {
  background-color: #fffde1;
  color: #4b4b4b;
}

/* Menu 
------------------------------------
*/
.bg-menu-dark {
  background-color: #21252d;
  color: #fff;
}

.bg-menu {
  background-color: #2b303b;
  color: #fff;
}

.bg-menu-light {
  background-color: #929aac;
  color: #4b4b4b;
}

/* Gradients
------------------------------------
*/
.gradient-grey {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
}

.gradient-black {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
}

/* Other Colors
------------------------------------
*/
.bg-black {
  background-color: #000 !important;
  color: #fff;
}

.bg-white {
  background-color: #fff !important;
  color: #4b4b4b;
}

.bg-transparent {
  background-color: transparent !important;
}

/* Text Colors */
.link {
  opacity: 0.7;
}

.link:hover {
  opacity: 1;
}

.text-dark {
  color: #212121 !important;
}

.text-color {
  color: #4b4b4b !important;
}

.text-primary {
  color: #45289c !important;
}

.text-complete {
  color: #0054af !important;
}

.text-success {
  color: #148e63 !important;
}

.text-info {
  color: #3B4752 !important;
}

.text-warning {
  color: #927400 !important;
}

.text-danger {
  color: #b82d23 !important;
}

.text-black {
  color: #000 !important;
}

.text-white {
  color: #fff !important;
}

/* Text Aligngments
------------------------------------
*/
.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

/* Labels
------------------------------------
*/
.label {
  padding: 3px 5px;
  font-size: 11px;
  line-height: 1;
  text-shadow: none;
  background-color: #e0e0e0;
  font-weight: 600;
  color: #4b4b4b;
  border-radius: 3px;
}

.label-success {
  background-color: #19AD79;
  color: #fff;
}

.label-warning {
  background-color: #FFd945;
  color: #4b4b4b;
}

.label-important,
.label-danger {
  background-color: #D83C31;
  color: #fff;
}

.label-info {
  background-color: #0072EC;
  color: #fff;
}

.label-inverse {
  background-color: #004fbf;
  color: #fff;
}

.label-white {
  background-color: #fff;
  color: #4b4b4b;
}

/* Font Sizes
------------------------------------
tracking =a+b×e(c × z)

a, b and c are constants
a = -0.0223
b = 0.185
c = -0.1745
z = font size

*/
.fs-10 {
  font-size: 10px !important;
  letter-spacing: 0.0180093em;
  line-height: 15px;
}

.fs-11 {
  font-size: 10.5px !important;
  letter-spacing: 0.0128359em;
  line-height: 16px;
}

.fs-12 {
  font-size: 12px !important;
  letter-spacing: 0.00849077em;
  line-height: 18px;
}

.fs-13 {
  font-size: 13px !important;
  letter-spacing: 0.00484144em;
  line-height: 19px;
}

.fs-14 {
  font-size: 14px !important;
  letter-spacing: 0.00177646em;
  line-height: 22px;
}

.fs-15 {
  font-size: 15px !important;
  letter-spacing: -0.000797757em;
  line-height: 23px;
}

.fs-16 {
  font-size: 16px !important;
  letter-spacing: -0.00295978em;
  line-height: 24px;
}

/* Line-heights
------------------------------------
*/
.lh-normal {
  line-height: normal;
}

.lh-10 {
  line-height: 10px;
}

.lh-11 {
  line-height: 11px;
}

.lh-12 {
  line-height: 12px;
}

.lh-13 {
  line-height: 13px;
}

.lh-14 {
  line-height: 14px;
}

.lh-15 {
  line-height: 15px;
}

.lh-16 {
  line-height: 16px;
}

/* Font Faces
------------------------------------
*/
.font-arial {
  font-family: Arial, sans-serif !important;
}

.font-montserrat {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.font-georgia {
  font-family: Georgia !important;
}

.font-heading {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.font-secondary {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

/* Wells
------------------------------------
*/
.well {
  background-color: #e0e0e0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  background-image: none;
}

.well.well-large {
  padding: 24px;
  width: auto;
}

.well.well-small {
  padding: 13px;
  width: auto;
}

.well.green {
  background-color: #0072EC;
  color: #fff;
  border: none;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.jumbotron {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.jumbotron p {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: inherit;
}

.jumbotron p.small {
  font-size: 85.714%;
}

/* Responsive Handlers : Typo
------------------------------------
*/
@media (max-width: 1400px) {
  body,
  p {
    font-size: 13px;
    line-height: 20px;
  }
  h1 {
    font-size: 33px;
    line-height: 44px;
    letter-spacing: -0.08px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h3 {
    font-size: 24px;
    line-height: 35.88px;
  }
  h4 {
    font-size: 18px;
    line-height: 33.88px;
  }
  h5 {
    font-size: 16px;
    line-height: 25.88px;
  }
  small,
  .small {
    font-size: 89%;
    line-height: 17px;
  }
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.table > tbody > tr > td,
.table > tbody > tr > th {
  line-height: 1.42857143;
}

.table > tfoot > tr > td,
.table > tfoot > tr > th {
  line-height: 1.42857143;
}

.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 1.42857143;
}

/* For Windows : Fixes 
------------------------------------
*/
.windows .small-text, .windows small, .windows .small {
  -webkit-text-stroke: 0.028px;
}

/*------------------------------------------------------------------
Pages Icons
------------------------------------------------------------------ */
@font-face {
  font-family: "pages-icon";
  src: url("../fonts/pages-icon/Pages-icon.woff") format("woff"), url("../fonts/pages-icon/Pages-icon.ttf") format("truetype"), url("../fonts/pages-icon/Pages-icon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.pg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "pages-icon" !important;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  direction: ltr;
  word-wrap: normal;
  white-space: nowrap;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/*------------------------------------------------------------------
[6. Buttons]
*/
/*
[Buttons Base Styles]
*/
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.006em;
  margin-bottom: 0;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background-image: none !important;
  color: #4b4b4b;
  background-color: #fff;
  text-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(33, 33, 33, 0.17);
  border-bottom: 1px solid rgba(33, 33, 33, 0.24);
  line-height: 14px;
  min-height: 28px;
  min-width: 28px;
  position: relative;
  transition: background 120ms ease-in 0s, box-shadow 120ms ease-in 0s, opacity 0.12s cubic-bezier(0.4, 0, 0.6, 1) 0ms, transform 0.12s cubic-bezier(0.4, 0, 0.6, 1) 0ms, -webkit-transform 0.12s cubic-bezier(0.4, 0, 0.6, 1) !important;
  padding: 0px 9px;
}

.btn.focus {
  box-shadow: none;
}

.btn:hover:not(.active) {
  background: #f4f4f4;
  color: #4b4b4b;
}

.btn.active {
  background: #e9ebeb;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-bottom: 1px solid rgba(33, 33, 33, 0.29);
  color: #4b4b4b;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: none !important;
  box-shadow: 0 0 0 2px #78c8fe;
}

.btn .caret {
  margin-left: 3px;
}

.btn .caret.single {
  margin-left: 0px;
}

.btn i {
  display: inline-flex;
  position: relative;
  left: 0px;
  top: 0px;
  align-items: center;
  justify-content: center;
}

.btn .pg-icon {
  font-size: 20px;
}

.btn .material-icons {
  font-size: 24px;
}

.btn span {
  flex: 1;
}

button:focus {
  outline: none !important;
}

/*
Alternate buttons
--------------------------------------------------
*/
.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #7252D3;
  border: 1px solid rgba(78, 55, 182, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-primary.active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary.active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled):active:hover,
.btn-primary .show .dropdown-toggle.btn-primary {
  background-color: #583fc9;
  color: #fff;
  border: 1px solid rgba(78, 55, 182, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
  box-shadow: none;
}

.btn-primary.hover:not(.active), .btn-primary:hover:not(.active),
.btn-primary .show .dropdown-toggle.btn-primary {
  background-color: #845ae0;
  color: #fff;
  border: 1px solid rgba(78, 55, 182, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #7252D3;
  border: 1px solid rgba(78, 55, 182, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-primary .badge {
  color: #7252D3;
  background-color: #fff;
}

.btn-success, .btn-success:focus {
  color: #fff;
  background-color: #19AD79;
  border: 1px solid rgba(13, 147, 91, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-success.active, .btn-success:not(:disabled):not(.disabled):active, .btn-success.active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled):active:hover,
.btn-success .show .dropdown-toggle.btn-success {
  background-color: #0fa465;
  color: #fff;
  border: 1px solid rgba(13, 147, 91, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
  box-shadow: none;
}

.btn-success.hover:not(.active), .btn-success:hover:not(.active),
.btn-success .show .dropdown-toggle.btn-success {
  background-color: #26bf93;
  color: #fff;
  border: 1px solid rgba(13, 147, 91, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #19AD79;
  border: 1px solid rgba(13, 147, 91, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-success .badge {
  color: #19AD79;
  background-color: #fff;
}

.btn-complete, .btn-complete:focus {
  color: #fff;
  background-color: #0072EC;
  border: 1px solid rgba(0, 79, 191, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-complete.active, .btn-complete:not(:disabled):not(.disabled):active, .btn-complete.active:focus, .btn-complete:not(:disabled):not(.disabled):active:focus, .btn-complete:not(:disabled):not(.disabled):active:hover,
.btn-complete .show .dropdown-toggle.btn-complete {
  background-color: #0059d4;
  color: #fff;
  border: 1px solid rgba(0, 79, 191, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
  box-shadow: none;
}

.btn-complete.hover:not(.active), .btn-complete:hover:not(.active),
.btn-complete .show .dropdown-toggle.btn-complete {
  background-color: #0f8ff9;
  color: #fff;
  border: 1px solid rgba(0, 79, 191, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-complete.disabled, .btn-complete.disabled:hover, .btn-complete.disabled:focus, .btn-complete.disabled:active, .btn-complete.disabled.active, .btn-complete[disabled], .btn-complete[disabled]:hover, .btn-complete[disabled]:focus, .btn-complete[disabled]:active, .btn-complete[disabled].active,
fieldset[disabled] .btn-complete,
fieldset[disabled] .btn-complete:hover,
fieldset[disabled] .btn-complete:focus,
fieldset[disabled] .btn-complete:active,
fieldset[disabled] .btn-complete.active {
  background-color: #0072EC;
  border: 1px solid rgba(0, 79, 191, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-complete .badge {
  color: #0072EC;
  background-color: #fff;
}

.btn-info, .btn-info:focus {
  color: #fff;
  background-color: #3B4752;
  border: 1px solid rgba(43, 57, 71, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-info.active, .btn-info:not(:disabled):not(.disabled):active, .btn-info.active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled):active:hover,
.btn-info .show .dropdown-toggle.btn-info {
  background-color: #2f3e50;
  color: #fff;
  border: 1px solid rgba(43, 57, 71, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
  box-shadow: none;
}

.btn-info.hover:not(.active), .btn-info:hover:not(.active),
.btn-info .show .dropdown-toggle.btn-info {
  background-color: #475b6b;
  color: #fff;
  border: 1px solid rgba(43, 57, 71, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #3B4752;
  border: 1px solid rgba(43, 57, 71, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-info .badge {
  color: #3B4752;
  background-color: #fff;
}

.btn-warning, .btn-warning:focus {
  color: #4b4b4b;
  background-color: #FFd945;
  border: 1px solid rgba(218, 171, 45, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-warning.active, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning.active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled):active:hover,
.btn-warning .show .dropdown-toggle.btn-warning {
  background-color: #f0bc34;
  color: #4b4b4b;
  border: 1px solid rgba(218, 171, 45, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
  box-shadow: none;
}

.btn-warning.hover:not(.active), .btn-warning:hover:not(.active),
.btn-warning .show .dropdown-toggle.btn-warning {
  background-color: #ffe858;
  color: #4b4b4b;
  border: 1px solid rgba(218, 171, 45, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #FFd945;
  border: 1px solid rgba(218, 171, 45, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-warning .badge {
  color: #FFd945;
  background-color: #4b4b4b;
}

.btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #D83C31;
  border: 1px solid rgba(185, 30, 30, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-danger.active, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger.active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled):active:hover,
.btn-danger .show .dropdown-toggle.btn-danger {
  background-color: #cd2121;
  color: #fff;
  border: 1px solid rgba(185, 30, 30, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
  box-shadow: none;
}

.btn-danger.hover:not(.active), .btn-danger:hover:not(.active),
.btn-danger .show .dropdown-toggle.btn-danger {
  background-color: #e6533c;
  color: #fff;
  border: 1px solid rgba(185, 30, 30, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #D83C31;
  border: 1px solid rgba(185, 30, 30, 0.5);
  border-bottom: 1px solid rgba(33, 33, 33, 0.22);
}

.btn-danger .badge {
  color: #D83C31;
  background-color: #fff;
}

.btn-default {
  background: #fff;
  color: #4b4b4b;
}

.btn-default:not(:disabled):not(.disabled):active:hover, .btn-default.active, .btn-default:active, .btn-default.active:focus, .btn-default:active:focus {
  background: #e0e0e0;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-bottom: 1px solid rgba(33, 33, 33, 0.29);
  color: #4b4b4b;
}

.btn-default.active {
  background: #e0e0e0;
  animation-name: toggle;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: #212121;
}

.btn-default.hover:not(.active), .btn-default:hover:not(.active) {
  background: #f4f4f4;
  color: #212121;
}

.btn-icon-link {
  position: relative;
  min-width: 24px;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  line-height: 0;
}

.btn-icon-link:before {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 240ms;
  bottom: -2px;
  left: -2px;
  right: -2px;
  top: -2px;
  background: none;
  border-radius: 50%;
  box-sizing: border-box;
  transform: scale(0.89);
}

.btn-icon-link:hover:not(.active) {
  background: transparent;
}

.btn-icon-link:hover:not(.active):before {
  background-color: rgba(33, 33, 33, 0.1);
  opacity: 1;
  transform: scale(1);
}

.btn-icon-link:hover:not(.active):active:before, .btn-icon-link:hover:not(.active).active:before {
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 44ms;
  background-color: rgba(117, 117, 117, 0.26);
  opacity: 1;
  transform: scale(1);
}

.btn-icon-link.active {
  background: none;
}

.btn-icon-link.active:before {
  opacity: 1;
  transform: scale(1);
  background-color: rgba(117, 117, 117, 0.26);
}

.btn-icon-link.invert:hover:not(.active) {
  color: rgba(255, 255, 255, 0.72);
}

.btn-icon-link.invert:hover:not(.active):before {
  background-color: rgba(255, 255, 255, 0.12);
}

.btn-icon-link.invert:hover:not(.active):active {
  color: #fff;
}

.btn-icon-link.invert:hover:not(.active):active:before {
  background-color: rgba(255, 255, 255, 0.18);
}

/*
Outline Buttons
--------------------------------------------------
*/
.btn-outline-primary, .btn-outline-primary:focus {
  color: #7252D3;
  border: 1px solid #7252D3;
  background: transparent;
}

.btn-outline-primary.active, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary.active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:hover,
.btn-outline-primary .show .dropdown-toggle.btn-outline-primary {
  background-color: rgba(114, 82, 211, 0.4);
  border: 1px solid #7252D3;
  color: #7252D3;
  box-shadow: none;
}

.btn-outline-primary.hover:not(.active), .btn-outline-primary:hover:not(.active),
.btn-outline-primary .show .dropdown-toggle.btn-outline-primary {
  background-color: rgba(114, 82, 211, 0.1);
  border: 1px solid #7252D3;
  color: #7252D3;
}

.btn-outline-primary.disabled, .btn-outline-primary.disabled:hover, .btn-outline-primary.disabled:focus, .btn-outline-primary.disabled:active, .btn-outline-primary.disabled.active, .btn-outline-primary[disabled], .btn-outline-primary[disabled]:hover, .btn-outline-primary[disabled]:focus, .btn-outline-primary[disabled]:active, .btn-outline-primary[disabled].active,
fieldset[disabled] .btn-outline-primary,
fieldset[disabled] .btn-outline-primary:hover,
fieldset[disabled] .btn-outline-primary:focus,
fieldset[disabled] .btn-outline-primary:active,
fieldset[disabled] .btn-outline-primary.active {
  border: 1px solid #7252D3;
  background: transparent;
  color: rgba(114, 82, 211, 0.8);
  cursor: not-allowed;
}

.btn-outline-success, .btn-outline-success:focus {
  color: #19AD79;
  border: 1px solid #19AD79;
  background: transparent;
}

.btn-outline-success.active, .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success.active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:hover,
.btn-outline-success .show .dropdown-toggle.btn-outline-success {
  background-color: rgba(25, 173, 121, 0.4);
  border: 1px solid #19AD79;
  color: #19AD79;
  box-shadow: none;
}

.btn-outline-success.hover:not(.active), .btn-outline-success:hover:not(.active),
.btn-outline-success .show .dropdown-toggle.btn-outline-success {
  background-color: rgba(25, 173, 121, 0.1);
  border: 1px solid #19AD79;
  color: #19AD79;
}

.btn-outline-success.disabled, .btn-outline-success.disabled:hover, .btn-outline-success.disabled:focus, .btn-outline-success.disabled:active, .btn-outline-success.disabled.active, .btn-outline-success[disabled], .btn-outline-success[disabled]:hover, .btn-outline-success[disabled]:focus, .btn-outline-success[disabled]:active, .btn-outline-success[disabled].active,
fieldset[disabled] .btn-outline-success,
fieldset[disabled] .btn-outline-success:hover,
fieldset[disabled] .btn-outline-success:focus,
fieldset[disabled] .btn-outline-success:active,
fieldset[disabled] .btn-outline-success.active {
  border: 1px solid #19AD79;
  background: transparent;
  color: rgba(25, 173, 121, 0.8);
  cursor: not-allowed;
}

.btn-outline-complete, .btn-outline-complete:focus {
  color: #0072EC;
  border: 1px solid #0072EC;
  background: transparent;
}

.btn-outline-complete.active, .btn-outline-complete:not(:disabled):not(.disabled):active, .btn-outline-complete.active:focus, .btn-outline-complete:not(:disabled):not(.disabled):active:focus, .btn-outline-complete:not(:disabled):not(.disabled):active:hover,
.btn-outline-complete .show .dropdown-toggle.btn-outline-complete {
  background-color: rgba(0, 114, 236, 0.4);
  border: 1px solid #0072EC;
  color: #0072EC;
  box-shadow: none;
}

.btn-outline-complete.hover:not(.active), .btn-outline-complete:hover:not(.active),
.btn-outline-complete .show .dropdown-toggle.btn-outline-complete {
  background-color: rgba(0, 114, 236, 0.1);
  border: 1px solid #0072EC;
  color: #0072EC;
}

.btn-outline-complete.disabled, .btn-outline-complete.disabled:hover, .btn-outline-complete.disabled:focus, .btn-outline-complete.disabled:active, .btn-outline-complete.disabled.active, .btn-outline-complete[disabled], .btn-outline-complete[disabled]:hover, .btn-outline-complete[disabled]:focus, .btn-outline-complete[disabled]:active, .btn-outline-complete[disabled].active,
fieldset[disabled] .btn-outline-complete,
fieldset[disabled] .btn-outline-complete:hover,
fieldset[disabled] .btn-outline-complete:focus,
fieldset[disabled] .btn-outline-complete:active,
fieldset[disabled] .btn-outline-complete.active {
  border: 1px solid #0072EC;
  background: transparent;
  color: rgba(0, 114, 236, 0.8);
  cursor: not-allowed;
}

.btn-outline-info, .btn-outline-info:focus {
  color: #3B4752;
  border: 1px solid #3B4752;
  background: transparent;
}

.btn-outline-info.active, .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info.active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:hover,
.btn-outline-info .show .dropdown-toggle.btn-outline-info {
  background-color: rgba(59, 71, 82, 0.4);
  border: 1px solid #3B4752;
  color: #3B4752;
  box-shadow: none;
}

.btn-outline-info.hover:not(.active), .btn-outline-info:hover:not(.active),
.btn-outline-info .show .dropdown-toggle.btn-outline-info {
  background-color: rgba(59, 71, 82, 0.1);
  border: 1px solid #3B4752;
  color: #3B4752;
}

.btn-outline-info.disabled, .btn-outline-info.disabled:hover, .btn-outline-info.disabled:focus, .btn-outline-info.disabled:active, .btn-outline-info.disabled.active, .btn-outline-info[disabled], .btn-outline-info[disabled]:hover, .btn-outline-info[disabled]:focus, .btn-outline-info[disabled]:active, .btn-outline-info[disabled].active,
fieldset[disabled] .btn-outline-info,
fieldset[disabled] .btn-outline-info:hover,
fieldset[disabled] .btn-outline-info:focus,
fieldset[disabled] .btn-outline-info:active,
fieldset[disabled] .btn-outline-info.active {
  border: 1px solid #3B4752;
  background: transparent;
  color: rgba(59, 71, 82, 0.8);
  cursor: not-allowed;
}

.btn-outline-warning, .btn-outline-warning:focus {
  color: #FFd945;
  border: 1px solid #FFd945;
  background: transparent;
}

.btn-outline-warning.active, .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning.active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:hover,
.btn-outline-warning .show .dropdown-toggle.btn-outline-warning {
  background-color: rgba(255, 217, 69, 0.4);
  border: 1px solid #FFd945;
  color: #FFd945;
  box-shadow: none;
}

.btn-outline-warning.hover:not(.active), .btn-outline-warning:hover:not(.active),
.btn-outline-warning .show .dropdown-toggle.btn-outline-warning {
  background-color: rgba(255, 217, 69, 0.1);
  border: 1px solid #FFd945;
  color: #FFd945;
}

.btn-outline-warning.disabled, .btn-outline-warning.disabled:hover, .btn-outline-warning.disabled:focus, .btn-outline-warning.disabled:active, .btn-outline-warning.disabled.active, .btn-outline-warning[disabled], .btn-outline-warning[disabled]:hover, .btn-outline-warning[disabled]:focus, .btn-outline-warning[disabled]:active, .btn-outline-warning[disabled].active,
fieldset[disabled] .btn-outline-warning,
fieldset[disabled] .btn-outline-warning:hover,
fieldset[disabled] .btn-outline-warning:focus,
fieldset[disabled] .btn-outline-warning:active,
fieldset[disabled] .btn-outline-warning.active {
  border: 1px solid #FFd945;
  background: transparent;
  color: rgba(255, 217, 69, 0.8);
  cursor: not-allowed;
}

.btn-outline-danger, .btn-outline-danger:focus {
  color: #D83C31;
  border: 1px solid #D83C31;
  background: transparent;
}

.btn-outline-danger.active, .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger.active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:hover,
.btn-outline-danger .show .dropdown-toggle.btn-outline-danger {
  background-color: rgba(216, 60, 49, 0.4);
  border: 1px solid #D83C31;
  color: #D83C31;
  box-shadow: none;
}

.btn-outline-danger.hover:not(.active), .btn-outline-danger:hover:not(.active),
.btn-outline-danger .show .dropdown-toggle.btn-outline-danger {
  background-color: rgba(216, 60, 49, 0.1);
  border: 1px solid #D83C31;
  color: #D83C31;
}

.btn-outline-danger.disabled, .btn-outline-danger.disabled:hover, .btn-outline-danger.disabled:focus, .btn-outline-danger.disabled:active, .btn-outline-danger.disabled.active, .btn-outline-danger[disabled], .btn-outline-danger[disabled]:hover, .btn-outline-danger[disabled]:focus, .btn-outline-danger[disabled]:active, .btn-outline-danger[disabled].active,
fieldset[disabled] .btn-outline-danger,
fieldset[disabled] .btn-outline-danger:hover,
fieldset[disabled] .btn-outline-danger:focus,
fieldset[disabled] .btn-outline-danger:active,
fieldset[disabled] .btn-outline-danger.active {
  border: 1px solid #D83C31;
  background: transparent;
  color: rgba(216, 60, 49, 0.8);
  cursor: not-allowed;
}

@keyframes toggle {
  0% {
    background: #e0e0e0;
  }
  50% {
    background: #adadad;
  }
  100% {
    background: #e0e0e0;
  }
}

.btn-link {
  padding-left: 6px;
  padding-right: 6px;
}

.btn-link, .btn-link:focus {
  color: #4b4b4b;
  background: transparent;
  border: 0;
  text-decoration: none;
}

.btn-link.active, .btn-link:not(:disabled):not(.disabled):active, .btn-link.active:focus, .btn-link:not(:disabled):not(.disabled):active:focus, .btn-link:not(:disabled):not(.disabled):active:hover {
  color: #4b4b4b;
  background: rgba(128, 128, 128, 0.33);
  border: 0;
}

.btn-link.hover:not(.active), .btn-link:hover:not(.active) {
  color: #4b4b4b;
  background: rgba(128, 128, 128, 0.2);
  border: 0;
  text-decoration: none;
}

.btn-link.disabled, .btn-link.disabled:hover, .btn-link.disabled:focus, .btn-link.disabled:active, .btn-link.disabled.active, .btn-link[disabled], .btn-link[disabled]:hover, .btn-link[disabled]:focus, .btn-link[disabled]:active, .btn-link[disabled].active,
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #757575;
  background: transparent;
  border: 0;
  pointer-events: none;
  cursor: not-allowed;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}

/* hide focus style if not from keyboard navigation */
.js-focus-visible .btn:focus:not(.focus-visible) {
  box-shadow: none;
}

/*
Button with icons
--------------------------------------------------
*/
.btn-icon-left {
  padding-left: 0px !important;
}

.btn-icon-left i {
  margin: 0 3px;
}

.btn-icon-right {
  padding-right: 0px !important;
}

.btn-icon-right i {
  margin: 3px 0;
}

/*
Button Groups
--------------------------------------------------
*/
.btn-group
.btn-group .btn {
  display: flex;
  align-items: center;
  position: relative;
}

.btn-group
.btn-group .btn i {
  font-size: 20px;
}

.btn-group
.btn-group:not(.btn-group-vertical) .btn:not(.dropdown-toggle):not(:last-child):not(.active):after {
  content: "";
  position: absolute;
  width: 1px;
  top: 20%;
  height: calc(100% - 40%);
  bottom: 10px;
  background: rgba(0, 0, 0, 0.2);
  right: -1px;
  z-index: 2;
}

.btn-group
.btn-group:not(.btn-group-vertical) .btn:not(.active):not(:first-child) {
  border-left: 1px solid transparent;
}

.btn-group
.btn-group:not(.btn-group-vertical) .btn.active {
  z-index: 2;
}

.btn-group
.btn-group.btn-group-vertical > .btn:not(:first-child) {
  margin-left: 0;
}

/*
Button with content aligned to column 
--------------------------------------------------
*/
.btn-flex-column {
  flex-direction: column;
}

/*
Button Sizes
--------------------------------------------------
*/
.btn-lg,
.btn-group-lg > .btn {
  padding: 0px 12px;
  font-size: 14px;
  line-height: 14px;
  min-height: 35px;
  min-width: 35px;
  font-weight: 500;
  letter-spacing: -0.011em;
}

.btn-lg.btn-link,
.btn-group-lg > .btn.btn-link {
  padding: 0px 5px;
}

.btn-lg.btn-icon-left i {
  margin-left: 5px;
  margin-right: 4px;
}

.btn-lg.btn-icon-right i {
  margin-right: 5px;
  margin-left: 4px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0 8px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0em;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 0 5px;
  font-size: 14px;
  line-height: 12px;
  min-width: 22px;
  min-height: 22px;
  letter-spacing: 0em;
}

.btn-cons {
  margin-right: 5px;
  min-width: 120px;
}

/*
Rounded buttons
--------------------------------------------------
*/
.btn-rounded {
  border-radius: 100px !important;
  padding-left: 11px;
  padding-right: 11px;
}

.btn-rounded.btn-lg {
  padding-left: 14px;
  padding-right: 14px;
}

.btn-rounded.btn-sm {
  padding-left: 9px;
  padding-right: 9px;
}

.btn-rounded.btn-xs {
  padding-left: 8px;
  padding-right: 8px;
}

/*
Dropdown menus
--------------------------------------------------
*/
.show .btn-group.show .dropdown-toggle,
.show .dropdown-toggle,
.show.dropdown-default .dropdown-toggle {
  box-shadow: none;
}

/* Pages default dropdown */
.dropdown-default {
  display: inline-block;
}

.dropdown-default.toggle-only {
  padding-left: 9px;
  padding-right: 9px;
}

.dropdown-default.toggle-only:after {
  position: initial;
}

.dropdown-default.show > .btn.dropdown-toggle {
  border-color: transparent !important;
  background: transparent !important;
  z-index: 791 !important;
  color: #4b4b4b;
}

.dropdown-default.show .dropdown-menu {
  opacity: 1;
  transform: scale(1, 1) !important;
  z-index: 700;
  pointer-events: all;
}

.dropdown-default.show .dropdown-menu .dropdown-item {
  visibility: visible;
}

.dropdown-default.show.dropup > .btn-primary + .dropdown-menu:after,
.dropdown-default.show.dropup > .btn-success + .dropdown-menu:after,
.dropdown-default.show.dropup > .btn-complete + .dropdown-menu:after,
.dropdown-default.show.dropup > .btn-warning + .dropdown-menu:after,
.dropdown-default.show.dropup > .btn-danger + .dropdown-menu:after,
.dropdown-default.show.dropup > .btn-info + .dropdown-menu:after {
  top: auto;
  bottom: 0;
}

.dropdown-default.show > .btn-primary + .dropdown-menu:after,
.dropdown-default.show > .btn-success + .dropdown-menu:after,
.dropdown-default.show > .btn-complete + .dropdown-menu:after,
.dropdown-default.show > .btn-warning + .dropdown-menu:after,
.dropdown-default.show > .btn-danger + .dropdown-menu:after,
.dropdown-default.show > .btn-info + .dropdown-menu:after {
  top: 0;
  height: 42px;
}

.dropdown-default.show > .btn-primary + .dropdown-menu:after {
  background-color: #7252D3;
}

.dropdown-default.show > .btn-success + .dropdown-menu:after {
  background-color: #19AD79;
}

.dropdown-default.show > .btn-complete + .dropdown-menu:after {
  background-color: #0072EC;
}

.dropdown-default.show > .btn-warning + .dropdown-menu:after {
  background-color: #FFd945;
}

.dropdown-default.show > .btn-danger + .dropdown-menu:after {
  background-color: #D83C31;
}

.dropdown-default.show > .btn-info + .dropdown-menu:after {
  background-color: #3B4752;
}

.dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu {
  margin-bottom: -47px;
  padding-bottom: 49px;
}

.dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
  bottom: 49px;
}

.dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu {
  margin-bottom: -34px;
  padding-bottom: 36px;
}

.dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
  bottom: 36px;
}

.dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu {
  margin-bottom: -29px;
  padding-bottom: 31px;
}

.dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
  bottom: 31px;
}

.dropdown-default.dropup .dropdown-menu {
  margin-bottom: -35px;
  padding: 0 3px 44px 0 !important;
  transform-origin: center bottom 0;
  top: auto !important;
  bottom: 100% !important;
}

.dropdown-default.dropup .dropdown-menu:after {
  bottom: 40px;
  top: auto !important;
}

.dropdown-default .btn.dropdown-toggle {
  text-align: left;
  padding-right: 27px;
}

.dropdown-default .btn.dropdown-toggle.btn-lg {
  padding-right: 26px;
}

.dropdown-default .btn.dropdown-toggle.btn-lg:after {
  right: 13px;
}

.dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu {
  padding-top: 39px;
  width: 100% !important;
}

.dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
  top: 39px;
}

.dropdown-default .btn.dropdown-toggle.btn-sm {
  padding-right: 18px;
}

.dropdown-default .btn.dropdown-toggle.btn-sm:after {
  right: 16px;
}

.dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu {
  padding-top: 36px;
}

.dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
  top: 36px;
}

.dropdown-default .btn.dropdown-toggle.btn-xs {
  padding-right: 17px;
}

.dropdown-default .btn.dropdown-toggle.btn-xs:after {
  right: 8px;
}

.dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu {
  padding-top: 31px;
}

.dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
  top: 31px;
}

.dropdown-default .btn.dropdown-toggle:after {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -2px;
}

.dropdown-default .btn-rounded {
  padding-left: 17px;
  padding-right: 17px;
}

.dropdown-default .btn-rounded + .dropdown-menu {
  border-radius: 17px;
}

.dropdown-default .dropdown-menu {
  margin-top: -4px;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 4px;
  overflow: hidden;
  backface-visibility: hidden;
  display: block;
  opacity: 0;
  transform: scale(1, 0) !important;
  transform-origin: center top 0;
  -webkit-transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
  transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
  z-index: -1;
}

.dropdown-default .dropdown-menu:after {
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  top: 35px;
  background: #e0e0e0;
  width: 100%;
}

.dropdown-default .dropdown-menu li,
.dropdown-default .dropdown-menu .dropdown-item {
  visibility: hidden;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.dropdown-menu {
  position: absolute;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  max-width: calc(100vw - 24px);
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  border: none;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 3px;
  font-size: 13px;
  margin: 0;
  background: #fff;
  min-width: initial;
  z-index: 700 !important;
}

.dropdown-menu .divider {
  background-color: #e0e0e0;
  height: 1px;
  margin: 3px 0;
  border-bottom: 0px;
}

.dropdown-menu > li,
.dropdown-menu > .dropdown-item {
  line-height: 20px;
  color: #4b4b4b;
  padding: 4px 8px 4px 16px;
  border-radius: 3px;
  display: block;
  clear: both;
  white-space: nowrap;
  text-align: left;
}

.dropdown-menu > li:hover, .dropdown-menu > li:focus, .dropdown-menu > li:active,
.dropdown-menu > .dropdown-item:hover,
.dropdown-menu > .dropdown-item:focus,
.dropdown-menu > .dropdown-item:active {
  background-color: transparent;
}

.dropdown-menu > li.dropdown-header,
.dropdown-menu > .dropdown-item.dropdown-header {
  padding: 3px 20px;
}

.dropdown-menu > li.active > a, .dropdown-menu > li.active > a:hover, .dropdown-menu > li.active > a:focus, .dropdown-menu > li.active, .dropdown-menu > li.active:hover, .dropdown-menu > li.active:focus,
.dropdown-menu > .dropdown-item.active > a,
.dropdown-menu > .dropdown-item.active > a:hover,
.dropdown-menu > .dropdown-item.active > a:focus,
.dropdown-menu > .dropdown-item.active,
.dropdown-menu > .dropdown-item.active:hover,
.dropdown-menu > .dropdown-item.active:focus {
  color: #212121;
  text-decoration: none;
  background-color: #f4f4f4;
  border-radius: 0;
}

.dropdown-menu > li.disabled > a, .dropdown-menu > li.disabled > a:hover, .dropdown-menu > li.disabled > a:focus, .dropdown-menu > li.disabled, .dropdown-menu > li.disabled:hover, .dropdown-menu > li.disabled:focus,
.dropdown-menu > .dropdown-item.disabled > a,
.dropdown-menu > .dropdown-item.disabled > a:hover,
.dropdown-menu > .dropdown-item.disabled > a:focus,
.dropdown-menu > .dropdown-item.disabled,
.dropdown-menu > .dropdown-item.disabled:hover,
.dropdown-menu > .dropdown-item.disabled:focus {
  color: #7e7e7e;
}

.dropdown-menu > li.disabled > a:hover, .dropdown-menu > li.disabled > a:focus, .dropdown-menu > li.disabled:hover, .dropdown-menu > li.disabled:focus,
.dropdown-menu > .dropdown-item.disabled > a:hover,
.dropdown-menu > .dropdown-item.disabled > a:focus,
.dropdown-menu > .dropdown-item.disabled:hover,
.dropdown-menu > .dropdown-item.disabled:focus {
  text-decoration: none;
  cursor: default;
}

.dropdown-menu > li > a,
.dropdown-menu > .dropdown-item > a {
  line-height: 35px;
  color: #4b4b4b;
  padding: 0 20px;
  border-radius: 3px;
  display: block;
  clear: both;
  white-space: nowrap;
  text-align: left;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus,
.dropdown-menu > .dropdown-item > a:hover,
.dropdown-menu > .dropdown-item > a:focus {
  color: #212121;
  text-decoration: none;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu > a.dropdown-item:hover, .dropdown-menu > a.dropdown-item:focus, .dropdown-menu > a.dropdown-item:active {
  color: #212121;
  text-decoration: none;
  background-color: #d3eeff;
}

.dropdown-menu > li {
  padding: 0;
}

.dropdown-menu > a {
  line-height: 35px;
  color: #4b4b4b;
  padding: 0 20px;
  border-radius: 3px;
  display: block;
  clear: both;
  white-space: nowrap;
  text-align: left;
}

.dropdown-menu > a:hover, .dropdown-menu > a:focus {
  color: #212121;
  text-decoration: none;
  background-color: transparent;
  background-image: none;
}

.dropdown-menu > li {
  padding: 0;
}

.dropdown-backdrop {
  z-index: 600;
}

/*
Animated buttons
--------------------------------------------------
*/
.btn-animated {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.btn-animated > span:first-child {
  display: inline-block;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.btn-animated > .hidden-block {
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1.9;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 220ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: all 220ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.btn-animated:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 380ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: all 380ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.btn-animated.from-top > .hidden-block {
  left: 0;
  top: -100%;
}

.btn-animated.from-top:hover > .hidden-block, .btn-animated.from-top.show-icon > .hidden-block {
  top: 0;
}

.btn-animated.from-top:hover > span:first-child, .btn-animated.from-top.show-icon > span:first-child {
  -webkit-transform: translateY(300%);
  -ms-transform: translateY(300%);
  transform: translateY(300%);
}

.btn-animated.from-left > .hidden-block {
  left: -100%;
  top: 0;
}

.btn-animated.from-left:hover > .hidden-block, .btn-animated.from-left.show-icon > .hidden-block {
  left: 0;
}

.btn-animated.from-left:hover > span:first-child, .btn-animated.from-left.show-icon > span:first-child {
  -webkit-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
}

/*
Tag buttons
--------------------------------------------------
*/
.btn-tag {
  background: transparent;
  color: #2d218f;
  border-radius: 99px;
  border: 1px solid rgba(33, 33, 33, 0.14);
}

.btn-tag:hover {
  border: 1px solid rgba(33, 33, 33, 0.14);
}

.btn-tag:active {
  background: rgba(33, 33, 33, 0.14);
}

.btn-tag:before {
  content: "#";
  font-size: 12px;
  display: inline-flex;
  position: relative;
  margin-right: 3px;
  color: inherit;
}

.btn-chip {
  border-radius: 99px;
  background: #e0e0e0;
  border-color: transparent;
  transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-chip.active {
  background: #e0e0e0;
  border-color: transparent;
  padding-left: 4px;
}

.btn-chip.active i {
  width: 20px;
}

.btn-chip:hover:not(.active) {
  background: #c7c6c6;
}

.btn-chip i {
  width: 0px;
  overflow: hidden;
  transition: width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

/*
Misc buttons
--------------------------------------------------
*/
.btn-icon {
  padding: 0;
}

.btn-toolbar .btn-group {
  margin-right: 14px;
}

.btn-toolbar .btn {
  padding-left: 5px;
  padding-right: 5px;
}

.pager .disabled > button,
.pager .disabled > button:hover,
.pager .disabled > button:focus,
.pager .disabled > span {
  cursor: not-allowed;
  opacity: 0.5;
}

.modal-content a.btn-primary:not([href]):not([tabindex]) {
  color: #fff;
}

.modal-content a.btn-primary:not([href]):not([tabindex]):hover, .modal-content a.btn-primary:not([href]):not([tabindex]):focus {
  color: #fff;
}

/*------------------------------------------------------------------
[7. Alerts]
*/
.alert {
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  padding: 9px 19px 9px 15px;
  border-radius: 3px;
  font-size: 13px;
  border-width: 0;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

.alert.bordered {
  border-width: 1px;
}

.alert .link {
  color: #ce8f22;
  font-weight: bold;
}

.alert .alert-heading {
  color: #ce8f22 !important;
  margin-bottom: 5px;
  font-weight: 600;
}

.alert .btn-small {
  position: relative;
  top: -3.5px;
}

.alert .button-set .btn {
  position: relative;
  top: 8px;
}

.alert .close {
  background: url("../img/icons/noti-cross.png") no-repeat scroll 0 0 transparent;
  background-position: -9px -10px;
  width: 10px;
  height: 9px;
  position: relative;
  opacity: 0.8;
  top: 4.5px;
  float: right;
  margin-left: 20px;
  font-size: 0;
  padding: 0;
}

.alert .close:hover {
  opacity: 1;
}

/* Alert : Color Options
------------------------------------
*/
.alert-danger,
.alert-error {
  background-color: #fde2da;
  color: #900f17;
  border-color: rgba(144, 15, 23, 0.24);
}

.alert-danger .close,
.alert-error .close {
  background-position: -95px -10px !important;
}

.alert-warning {
  background-color: #fffde1;
  color: #aa7918;
  border-color: rgba(170, 121, 24, 0.24);
}

.alert-info {
  background-color: #d3eeff;
  color: #00318e;
  border-color: rgba(0, 49, 142, 0.24);
}

.alert-info .close {
  background-position: -67px -10px !important;
}

.alert-success {
  background-color: #d6f7f0;
  color: #04733e;
  border-color: rgba(4, 115, 62, 0.24);
}

.alert-success .close {
  background-position: -38px -10px !important;
}

.alert-default {
  background-color: #fff;
  color: #4b4b4b;
  border-color: #e0e0e0;
}

.alert-default .close {
  background-position: -67px -10px !important;
}

/*------------------------------------------------------------------
[8. Notifications]
*/
/* Badges
--------------------------------------------------
*/
.badge {
  text-shadow: none;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  background-color: #e0e0e0;
  font-size: 11px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 4px;
  color: #4b4b4b;
  border-radius: 10px;
}

.badge-success {
  background-color: #19AD79;
  color: #fff;
}

.badge-warning {
  background-color: #FFd945;
  color: #fff;
}

.badge-important {
  background-color: #D83C31;
  color: #fff;
}

.badge-danger {
  background-color: #D83C31;
  color: #fff;
}

.badge-info {
  background-color: #3B4752;
  color: #fff;
}

.badge-inverse {
  background-color: #00318e;
  color: #fff;
}

.badge-white {
  background-color: #fff;
  color: #4b4b4b;
}

.badge-disable {
  background-color: #212121;
  color: #4b4b4b;
}

/* Tool Tip
--------------------------------------------------
*/
.tooltip-inner {
  font-size: 12px;
}

/* Notification popup
--------------------------------------------------
*/
.popover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  z-index: 790;
}

.notification-toggle {
  top: 35px;
  left: -26px;
  padding: 0;
}

.notification-toggle:before {
  border-bottom: 0px !important;
}

.notification-toggle:after {
  border-bottom: 0px !important;
}

.notification-panel {
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.notification-panel .notification-body {
  height: auto;
  max-height: 350px;
  position: relative;
  overflow: hidden;
}

.notification-panel .notification-body .notification-item {
  position: relative;
  margin-left: 25px;
  background-color: #fff;
  padding-right: 26px;
}

.notification-panel .notification-body .notification-item.unread .heading {
  opacity: 1;
}

.notification-panel .notification-body .notification-item.unread .option {
  background-color: #d3eeff;
}

.notification-panel .notification-body .notification-item.unread .option .mark {
  color: #0f8ff9;
}

.notification-panel .notification-body .notification-item .heading {
  line-height: 43px;
  margin-right: 5px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  float: left;
  width: 100%;
  font-size: 14px;
  opacity: 0.7;
}

.notification-panel .notification-body .notification-item .heading:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #fff;
  right: 0;
  top: 1px;
}

.notification-panel .notification-body .notification-item .heading .thumbnail-wrapper {
  cursor: pointer;
}

.notification-panel .notification-body .notification-item .heading .thumbnail-wrapper i {
  -webkit-transition: all 0.12s linear;
  transition: all 0.12s linear;
}

.notification-panel .notification-body .notification-item .heading .time {
  font-size: 11px;
  margin-right: 8px;
}

.notification-panel .notification-body .notification-item .heading.open {
  display: block;
}

.notification-panel .notification-body .notification-item .heading.open .more-details {
  display: block;
}

.notification-panel .notification-body .notification-item .heading.open .thumbnail-wrapper i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.notification-panel .notification-body .notification-item .heading .thumbnail-wrapper.d24 {
  line-height: 20px;
}

.notification-panel .notification-body .notification-item .more-details {
  display: none;
  background-color: #fff;
  width: 100%;
  height: 100%;
  clear: both;
  position: relative;
}

.notification-panel .notification-body .notification-item .more-details .more-details-inner {
  margin-left: 10px;
  padding-left: 28px;
  padding-top: 15px;
  margin-bottom: 20px;
  position: relative;
  border-left: 1px solid rgba(224, 224, 224, 0.7);
  text-align: left;
}

.notification-panel .notification-body .notification-item .more-details .more-details-inner:after {
  width: 14px;
  height: 14px;
  position: absolute;
  content: "";
  background-color: #fff;
  left: -7px;
  top: 25px;
  border: 1px solid rgba(224, 224, 224, 0.9);
  border-radius: 99px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
}

.notification-panel .notification-body .notification-item .more-details .more-details-inner:before {
  color: #b7b7b7;
  position: absolute;
  bottom: 0;
  left: -5px;
  content: " \25CF";
  font-size: 13px;
  line-height: 5px;
  background-color: #fff;
  height: 9px;
}

.notification-panel .notification-body .notification-item .more-details .more-details-inner .hint-text {
  color: rgba(33, 33, 33, 0.62);
}

.notification-panel .notification-body .notification-item .option {
  font-size: 10px;
  position: absolute;
  height: 44px;
  width: 26px;
  right: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 44px;
  background-color: #fff;
  height: 100%;
}

.notification-panel .notification-body .notification-item .option .mark {
  background-color: transparent;
  color: #b7b7b7;
}

.notification-panel .notification-body .notification-item .option .mark:before {
  content: " \25CF";
  font-size: 12px;
}

.notification-panel .notification-body .notification-item:last-child .heading {
  border-bottom: 0px;
}

.notification-panel .notification-footer {
  padding: 10px;
  display: block;
  border-top: 1px solid #e0e0e0;
}

.notification-panel .notification-footer a {
  color: #4b4b4b;
  opacity: 0.54;
}

.notification-panel .notification-footer a:hover {
  opacity: 0.74;
}

/* Simple alerts
--------------------------------------------------
*/
.alerts-container {
  position: fixed;
  width: 350px;
  right: 20px;
  z-index: 999;
  top: 80px;
}

.alerts-container[data-placement$="-left"] {
  left: 100px;
  right: auto;
}

.alerts-container[data-placement$="-right"] {
  right: 20px;
  left: auto;
}

.alerts-container[data-placement^="top-"] {
  top: 80px;
  bottom: auto;
}

.alerts-container[data-placement^="bottom-"] {
  top: auto;
  bottom: 20px;
}

.alerts-container[data-placement="bottom-right"] .alert:last-child, .alerts-container[data-placement="bottom-left"] .alert:last-child {
  margin-bottom: 0;
}

.alerts-container .alert {
  position: relative;
}

.alerts-container .alert .close {
  position: absolute;
  right: 9px;
  top: 15px;
}

/* Pages Notifications plugin
--------------------------------------------------
*/
/* Pages Notification customizations */
body:not(.horizontal-app-menu) .pgn-wrapper[data-position="top"] {
  top: 60px;
  left: 70px;
}

body:not(.horizontal-app-menu) .pgn-wrapper[data-position="bottom"] {
  left: 70px;
}

body:not(.horizontal-app-menu) .pgn-wrapper[data-position$="-left"] {
  left: 90px;
  right: auto;
}

body:not(.horizontal-app-menu) .pgn-wrapper[data-position^="top-"] {
  top: 80px;
  bottom: auto;
}

body.menu-pin .pgn-wrapper[data-position$="-left"],
body.menu-pin .pgn-wrapper[data-position="top"],
body.menu-pin .pgn-wrapper[data-position="bottom"] {
  left: 250px;
}

body.horizontal-app-menu .pgn-wrapper {
  z-index: 799;
}

.pgn-wrapper {
  position: fixed;
  z-index: 999;
}

.pgn-wrapper[data-position$="-left"] {
  left: 30px;
}

.pgn-wrapper[data-position$="-right"] {
  right: 20px;
}

.pgn-wrapper[data-position^="top-"] {
  top: 20px;
}

.pgn-wrapper[data-position^="bottom-"] {
  bottom: 20px;
}

.pgn-wrapper[data-position="top"] {
  top: 0;
  left: 0;
  right: 0;
}

.pgn-wrapper[data-position="bottom"] {
  bottom: 0;
  left: 0;
  right: 0;
}

.pgn {
  position: relative;
  margin: 10px;
}

.pgn.pgn-flip {
  margin-top: 0px;
}

.pgn .alert {
  margin: 0;
  margin-bottom: 4px;
}

/* Simple 
------------------------------------
*/
.pgn-simple .alert {
  padding-top: 13px;
  padding-bottom: 13px;
  max-width: 500px;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  max-height: 250px;
  overflow: hidden;
}

/* Bar 
------------------------------------
*/
.pgn-bar {
  overflow: hidden;
  margin: 0;
}

.pgn-bar .alert {
  border-radius: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  max-height: 91px;
}

.pgn-wrapper[data-position="top"] .pgn-bar .alert {
  animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: top left;
  -webkit-transform-origin: top left;
}

.pgn-wrapper[data-position="bottom"] .pgn-bar .alert {
  animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: bottom left;
  -webkit-transform-origin: bottom left;
}

.pgn-bar .alert span {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

/* Circle 
------------------------------------
*/
.pgn-circle .alert {
  margin-bottom: 10px;
  border-radius: 300px;
  animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  -webkit-animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  height: 60px;
  overflow: hidden;
  padding: 6px 55px 6px 6px;
  -webkit-transform: translateZ(0);
  position: relative;
}

.pgn-wrapper[data-position$="-right"] .pgn-circle .alert {
  float: right;
}

.pgn-wrapper[data-position$="-left"] .pgn-circle .alert {
  float: left;
}

.pgn-wrapper[data-position^="bottom-"] .pgn-circle .alert {
  margin-bottom: 20px;
}

.pgn-circle .alert > div > div.pgn-thumbnail > div {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
}

.pgn-circle .alert > div > div.pgn-thumbnail > div > img {
  width: 100%;
  height: 100%;
}

.pgn-circle .alert > div > div.pgn-message > div {
  opacity: 0;
  height: 47px;
  padding-left: 9px;
  animation: fadeIn 0.3s 0.5s ease forwards;
  -webkit-animation: fadeIn 0.3s 0.5s ease forwards;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word;
}

.pgn-circle .alert > div > div.pgn-message > div p:only-child {
  padding: 12px 0;
}

.pgn-circle .alert .close {
  margin-top: -12px;
  position: absolute;
  right: 18px;
  top: 50%;
  opacity: 0;
  animation: fadeIn 0.3s 0.5s ease forwards;
  -webkit-animation: fadeIn 0.3s 0.5s ease forwards;
}

.pgn-circle .alert p {
  margin-bottom: 0;
}

.pgn-circle .alert > div {
  display: table;
  height: 100%;
}

.pgn-circle .alert > div > div {
  display: table-cell;
  vertical-align: middle;
}

@keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px;
  }
  100% {
    opacity: 1;
    width: 60px;
  }
}

@-webkit-keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px;
  }
  100% {
    opacity: 1;
    width: 60px;
  }
}

@keyframes resizeCircle {
  0% {
    width: 60px;
  }
  100% {
    width: 300px;
  }
}

@-webkit-keyframes resizeCircle {
  0% {
    width: 60px;
  }
  100% {
    width: 300px;
  }
}

/* Flip 
------------------------------------
*/
.pgn-wrapper[data-position^="bottom-"] .pgn-flip .alert {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.pgn-flip .alert {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  border-radius: 0;
  padding: 25px 35px;
  max-width: 500px;
  max-height: 250px;
  overflow: hidden;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out;
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Pages Notification customizations */
body > .pgn-wrapper[data-position="top"] {
  top: 60px;
  left: 70px;
}

body > .pgn-wrapper[data-position="bottom"] {
  left: 70px;
}

body > .pgn-wrapper[data-position$="-left"] {
  left: 90px;
  right: auto;
}

body > .pgn-wrapper[data-position^="top-"] {
  top: 80px;
  bottom: auto;
}

.pgn-circle .alert .close {
  margin-top: -4px;
}

body > .pgn-wrapper {
  z-index: 900;
}

@media (max-width: 979px) {
  body .pgn-wrapper[data-position="top"] {
    left: 0 !important;
  }
  body .pgn-wrapper[data-position="bottom"] {
    left: 0 !important;
  }
}

@media (max-width: 767px) {
  body .pgn-wrapper[data-position$="-left"],
  body .pgn-wrapper[data-position$="-right"] {
    left: 10px !important;
    right: 10px !important;
  }
  body .pgn-wrapper[data-position$="-right"] .alert,
  body .pgn-wrapper[data-position$="-left"] .alert {
    max-width: 100%;
    width: 100%;
  }
}

/* Notifications demo
--------------------------------------------------
*/
.notification-positions {
  border-radius: 4px;
  border: 3px dashed #e0e0e0;
  height: 370px;
  position: relative;
}

.notification-positions > div > .position:not(:only-child) {
  max-width: 50%;
}

.notification-positions .position {
  padding: 15px;
}

.notification-positions .position img {
  border: 3px solid transparent;
}

.notification-positions .position:hover {
  cursor: pointer;
}

.notification-positions .position.active img {
  border-color: #0090d9;
}

.notification-positions .position.pull-top, .notification-positions .position.pull-bottom {
  left: 0;
  right: 0;
}

.notification-positions .position img {
  width: 100%;
}

.notification-tabs {
  flex-wrap: wrap;
}

/*------------------------------------------------------------------
[9. Progress Indicators]
*/
.progress {
  height: 4px;
  background-color: rgba(75, 75, 75, 0.2);
  background-image: none;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 20px;
}

.progress.transparent {
  background-color: rgba(0, 0, 0, 0.28);
}

.progress.progress-small {
  height: 3px;
}

/* Circular Progress : indeterminate color
------------------------------------
*/
.progress-bar-indeterminate {
  background: url("../img/progress/progress-bar-master.svg") no-repeat top left;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.progress-bar-indeterminate.progress-bar-primary {
  background-image: url("../img/progress/progress-bar-primary.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-complete {
  background-image: url("../img/progress/progress-bar-complete.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-success {
  background-image: url("../img/progress/progress-bar-success.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-info {
  background-image: url("../img/progress/progress-bar-info.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-warning {
  background-image: url("../img/progress/progress-bar-warning.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-danger {
  background-image: url("../img/progress/progress-bar-danger.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-master {
  background-image: url("../img/progress/progress-bar-master.svg");
  background-color: transparent;
}

/* Progress Bar
------------------------------------
*/
.progress-bar {
  box-shadow: none;
  border-radius: 0;
  background-color: #4b4b4b;
  background-image: none;
  -webkit-transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

/* Progress Bar : Color Options
------------------------------------
*/
.progress-bar-primary {
  background-color: #7252D3;
  background-image: none;
}

.progress-bar-complete {
  background-color: #0072EC;
  background-image: none;
}

.progress-bar-success {
  background-color: #19AD79;
  background-image: none;
}

.progress-bar-info {
  background-color: #3B4752;
  background-image: none;
}

.progress-bar-warning {
  background-color: #FFd945;
  background-image: none;
}

.progress-bar-danger {
  background-color: #D83C31;
  background-image: none;
}

.progress-bar-white {
  background-color: #fff;
  background-image: none;
}

.progress-bar-black {
  background-color: #212121;
  background-image: none;
}

.progress-info .bar,
.progress .bar-info {
  background-color: #1b2839;
  background-image: none;
}

.progress-warning .bar,
.progress .bar-warning {
  background-color: #aa7918;
  background-image: none;
}

.progress-danger .bar,
.progress .bar-danger {
  background-color: #900f17;
  background-image: none;
}

.progress-white .bar,
.progress .bar-white {
  background-color: #fff;
  background-image: none;
}

.progress-success.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #19AD79;
}

.progress-info.progress-striped .bar,
.progress-striped .bar-info {
  background-color: #3B4752;
}

/* Circular Progress : indeterminate 
------------------------------------
*/
.progress-circle-indeterminate {
  background: url("../img/progress/progress-circle-master.svg") no-repeat top left;
  width: 50px;
  height: 50px;
  background-size: 100% auto;
  margin: 0 auto;
}

.progress-circle-indeterminate.progress-circle-warning {
  background-image: url("../img/progress/progress-circle-warning.svg");
}

.progress-circle-indeterminate.progress-circle-danger {
  background-image: url("../img/progress/progress-circle-danger.svg");
}

.progress-circle-indeterminate.progress-circle-info {
  background-image: url("../img/progress/progress-circle-info.svg");
}

.progress-circle-indeterminate.progress-circle-primary {
  background-image: url("../img/progress/progress-circle-primary.svg");
}

.progress-circle-indeterminate.progress-circle-success {
  background-image: url("../img/progress/progress-circle-success.svg");
}

.progress-circle-indeterminate.progress-circle-complete {
  background-image: url("../img/progress/progress-circle-complete.svg");
}

/* Circular Progress 
------------------------------------
*/
.progress-circle {
  display: block;
  height: 45px;
  margin: 0 auto;
  position: relative;
  width: 45px;
  -webkit-backface-visibility: hidden;
}

.progress-circle.progress-circle-warning .pie .half-circle {
  border-color: #FFd945;
}

.progress-circle.progress-circle-danger .pie .half-circle {
  border-color: #D83C31;
}

.progress-circle.progress-circle-info .pie .half-circle {
  border-color: #3B4752;
}

.progress-circle.progress-circle-primary .pie .half-circle {
  border-color: #7252D3;
}

.progress-circle.progress-circle-success .pie .half-circle {
  border-color: #19AD79;
}

.progress-circle.progress-circle-complete .pie .half-circle {
  border-color: #0072EC;
}

.progress-circle.progress-circle-thick .pie .half-circle,
.progress-circle.progress-circle-thick .shadow {
  border-width: 5px;
}

.progress-circle .pie {
  clip: rect(0, 45px, 45px, 22.5px);
  height: 45px;
  position: absolute;
  width: 45px;
}

.progress-circle .pie .half-circle {
  border: 3px solid #4b4b4b;
  border-radius: 50%;
  clip: rect(0, 22.5px, 45px, 0);
  height: 45px;
  position: absolute;
  width: 45px;
}

.progress-circle .shadow {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 100%;
  width: 100%;
  box-shadow: none !important;
}

/*------------------------------------------------------------------
[11. Tabs & Accordians]
*/
/* Tabs
------------------------------------
*/
.nav-tabs {
  position: relative;
}

.nav-tabs > li {
  padding-left: 0;
  padding-right: 0;
}

.nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
  border-color: #e0e0e0;
  color: #4b4b4b;
  background-color: #e0e0e0;
}

.nav-tabs > li > a {
  display: block;
  border-radius: 0;
  padding: 13px 17px;
  margin-right: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #757575;
  font-size: 10.5px;
  min-width: 70px;
  text-transform: uppercase;
  border-color: transparent;
  position: relative;
  line-height: 1.7em;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  background: transparent;
  border-color: transparent;
  color: #4b4b4b;
}

.nav-tabs > li > a .tab-icon {
  margin-right: 6px;
}

.nav-tabs > li > a.active {
  color: #212121;
}

.nav-tabs ~ .tab-content {
  overflow: hidden;
  padding: 15px;
}

.nav-tabs ~ .tab-content > .tab-pane.slide-left, .nav-tabs ~ .tab-content > .tab-pane.slide-right {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding, .nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
  opacity: 0 !important;
}

.nav-tabs ~ .tab-content > .tab-pane.slide-left.active, .nav-tabs ~ .tab-content > .tab-pane.slide-right.active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
  opacity: 1;
}

.nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding {
  -webkit-transform: translate3d(10%, 0, 0);
  transform: translate3d(10%, 0, 0);
  -ms-transform: translate(10%, 0);
}

.nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
  -webkit-transform: translate3d(-10%, 0, 0);
  transform: translate3d(-10%, 0, 0);
  -ms-transform: translate(-10%, 0);
}

.nav-tabs.nav-tabs-left, .nav-tabs.nav-tabs-right {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.nav-tabs.nav-tabs-left:after, .nav-tabs.nav-tabs-right:after {
  border-bottom: 0px;
}

.nav-tabs.nav-tabs-left > li, .nav-tabs.nav-tabs-right > li {
  float: none;
}

.nav-tabs.nav-tabs-left {
  float: left;
  padding-right: 0;
}

.nav-tabs.nav-tabs-left ~ .tab-content {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  -ms-flex: 1;
  -ms-flex-negative: 0;
}

.nav-tabs.nav-tabs-right {
  float: right;
  padding-right: 0;
}

.nav-tabs.nav-tabs-right ~ .tab-content {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  -ms-flex: 1;
  -ms-flex-negative: 0;
}

/* Tabs : Simple 
------------------------------------
*/
.nav-tabs-simple {
  border-bottom: 0px;
}

.nav-tabs-simple:after {
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  position: absolute;
  bottom: -2px;
  z-index: 120;
  left: 0;
}

.nav-tabs-simple > li {
  margin-bottom: 0;
}

.nav-tabs-simple > li > a:after {
  position: absolute;
  -webkit-transition: all 0.1s linear 0s;
  transition: all 0.1s linear 0s;
  -webkit-backface-visibility: hidden;
  width: 100%;
  display: block;
  background-color: #D83C31;
  height: 0px;
  content: "";
  left: 0;
  z-index: 125;
  top: 100%;
}

.nav-tabs-simple > li > a:after.nav-tabs-primary {
  background-color: #7252D3;
}

.nav-tabs-simple > li > a:after.nav-tabs-success {
  background-color: #19AD79;
}

.nav-tabs-simple > li > a:after.nav-tabs-complete {
  background-color: #0072EC;
}

.nav-tabs-simple > li > a:after.nav-tabs-danger {
  background-color: #D83C31;
}

.nav-tabs-simple > li > a:after.nav-tabs-warning {
  background-color: #FFd945;
}

.nav-tabs-simple > li > a:after.nav-tabs-info {
  background-color: #3B4752;
}

.nav-tabs-simple > li > a.active:hover, .nav-tabs-simple > li > a.active:focus {
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs-simple > li > a.active:after, .nav-tabs-simple > li > a:hover:after {
  height: 3px;
}

.nav-tabs-simple.nav-tabs-left:after, .nav-tabs-simple.nav-tabs-right:after {
  border-bottom: 0px;
}

.nav-tabs-simple.nav-tabs-left > li > a:after, .nav-tabs-simple.nav-tabs-right > li > a:after {
  width: 0px;
  height: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
}

.nav-tabs-simple.nav-tabs-left > li > a.active:after, .nav-tabs-simple.nav-tabs-left > li > a:hover:after, .nav-tabs-simple.nav-tabs-right > li > a.active:after, .nav-tabs-simple.nav-tabs-right > li > a:hover:after {
  width: 3px;
}

.nav-tabs-simple.nav-tabs-left > li > a:after {
  right: 0;
  left: auto;
}

.nav-tabs-simple.nav-tabs-right > li > a:after {
  left: 0;
}

/* Tabs : Line Triangles
------------------------------------
*/
.nav-tabs-linetriangle {
  border-bottom: 0;
}

.nav-tabs-linetriangle:after {
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  position: absolute;
  bottom: -1px;
  z-index: 100;
  left: 0;
}

.nav-tabs-linetriangle > li > a.active {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}

.nav-tabs-linetriangle > li > a.active:hover, .nav-tabs-linetriangle > li > a.active:focus, .nav-tabs-linetriangle > li > a.active:active {
  border-color: transparent;
  background-color: transparent;
}

.nav-tabs-linetriangle > li > a.active:after, .nav-tabs-linetriangle > li > a.active:before {
  border: medium solid transparent;
  content: "";
  height: 0;
  left: 50%;
  pointer-events: none;
  position: absolute;
  width: 0;
  z-index: 120;
  top: 98%;
}

.nav-tabs-linetriangle > li > a.active:after {
  border-top-color: #f4f4f4;
  border-width: 10px;
  margin-left: -10px;
}

.nav-tabs-linetriangle > li > a.active:before {
  border-top-color: rgba(0, 0, 0, 0.2);
  border-width: 11px;
  margin-left: -11px;
}

.nav-tabs-linetriangle > li > a span {
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-tabs-linetriangle > li > a:hover, .nav-tabs-linetriangle > li > a:focus {
  overflow: visible;
  -webkit-transition: color 0.2s ease 0s;
  transition: color 0.2s ease 0s;
}

.nav-tabs-linetriangle ~ .tab-content {
  background: #fff;
}

.nav-tabs-linetriangle.nav-tabs-left.nav-tabs:after {
  border-bottom: none;
}

.nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:before {
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid rgba(0, 0, 0, 0.2);
}

.nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:after {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #f4f4f4;
  margin-top: 1px;
  margin-right: 2px;
}

.nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:after, .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li > a.active:before {
  top: auto;
  right: -22px;
  left: auto;
}

.nav-tabs-linetriangle.nav-tabs-right.nav-tabs:after {
  border-bottom: none;
}

.nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:before {
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid rgba(0, 0, 0, 0.2);
}

.nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:after {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f4f4f4;
  margin-top: 1px;
  margin-left: -9px;
}

.nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:after, .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li > a.active:before {
  top: auto;
  left: -11px;
  right: auto;
}

@media screen and (max-width: 58em) {
  .nav-tabs-linetriangle {
    font-size: 0.6em;
  }
}

/* Tabs : Fill-up 
------------------------------------
*/
.nav-tabs-fillup {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
}

.nav-tabs-fillup > li {
  overflow: hidden;
}

.nav-tabs-fillup > li > a {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
  background: transparent;
}

.nav-tabs-fillup > li > a:after {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background: none repeat scroll 0 0 #19AD79;
  border: 1px solid #19AD79;
  content: "";
  height: calc(100% + 1px);
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 100%, 0px);
  transform: translate3d(0, 100%, 0px);
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  width: 100%;
  z-index: -1;
}

.nav-tabs-fillup > li > a:after.nav-tabs-primary {
  background: none repeat scroll 0 0 #7252D3;
  border: 1px solid #7252D3;
}

.nav-tabs-fillup > li > a:after.nav-tabs-success {
  background: none repeat scroll 0 0 #19AD79;
  border: 1px solid #19AD79;
}

.nav-tabs-fillup > li > a:after.nav-tabs-complete {
  background: none repeat scroll 0 0 #0072EC;
  border: 1px solid #0072EC;
}

.nav-tabs-fillup > li > a:after.nav-tabs-warning {
  background: none repeat scroll 0 0 #FFd945;
  border: 1px solid #FFd945;
}

.nav-tabs-fillup > li > a:after.nav-tabs-danger {
  background: none repeat scroll 0 0 #D83C31;
  border: 1px solid #D83C31;
}

.nav-tabs-fillup > li > a:after.nav-tabs-info {
  background: none repeat scroll 0 0 #3B4752;
  border: 1px solid #3B4752;
}

.nav-tabs-fillup > li > a span {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0px, 5px, 0px);
  transform: translate3d(0px, 5px, 0px);
  -webkit-transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  display: block;
  font-weight: 700;
  line-height: 1.5;
}

.nav-tabs-fillup > li > a.active {
  z-index: 100;
  color: #fff;
}

.nav-tabs-fillup > li > a.active:hover, .nav-tabs-fillup > li > a.active:focus {
  border-color: transparent;
  background: transparent;
  color: #fff;
}

.nav-tabs-fillup > li > a.active:after {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate(0px, 0px);
}

.nav-tabs-fillup > li > a.active span {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
  -ms-transform: translate(0px, -5px);
}

.nav-tabs-fillup .icon:before {
  -webkit-transform: translate3d(0px, 5px, 0px);
  transform: translate3d(0px, 5px, 0px);
  -ms-transform: translate(0px, 5px);
  -webkit-transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  display: block;
  margin: 0;
}

.nav-tabs-fillup ~ .tab-content {
  background: #fff;
}

.nav-tabs-fillup.nav-tabs-left, .nav-tabs-fillup.nav-tabs-right {
  border-bottom: none;
}

.nav-tabs-fillup.nav-tabs-left:after, .nav-tabs-fillup.nav-tabs-right:after {
  border-bottom: none;
}

.nav-tabs-fillup.nav-tabs-left > li > a:after, .nav-tabs-fillup.nav-tabs-right > li > a:after {
  width: calc(100% + 1px);
  height: 100%;
}

.nav-tabs-fillup.nav-tabs-left > li.active a:after, .nav-tabs-fillup.nav-tabs-right > li.active a:after {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate(0px, 0px);
}

.nav-tabs-fillup.nav-tabs-left > li > a:after {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
}

.nav-tabs-fillup.nav-tabs-left > li > a span {
  -webkit-transform: translate3d(5px, 0, 0px);
  transform: translate3d(5px, 0, 0px);
  -ms-transform: translate(5px, 0);
}

.nav-tabs-fillup.nav-tabs-left > li.active a span {
  -webkit-transform: translate3d(-5px, 0, 0px);
  transform: translate3d(-5px, 0, 0px);
  -ms-transform: translate(-5px, 0);
}

.nav-tabs-fillup.nav-tabs-left > li.active .icon:before {
  -webkit-transform: translate3d(-5px, 0, 0px);
  transform: translate3d(-5px, 0, 0px);
  -ms-transform: translate(-5px, 0);
}

.nav-tabs-fillup.nav-tabs-right > li > a:after {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
  left: -1px;
}

.nav-tabs-fillup.nav-tabs-right > li > a span {
  -webkit-transform: translate3d(-5px, 0, 0px);
  transform: translate3d(-5px, 0, 0px);
  -ms-transform: translate(-5px, 0);
}

.nav-tabs-fillup.nav-tabs-right > li.active a span {
  -webkit-transform: translate3d(5px, 0, 0px);
  transform: translate3d(5px, 0, 0px);
  -ms-transform: translate(5px, 0);
}

.nav-tabs-fillup.nav-tabs-right > li.active .icon:before {
  -webkit-transform: translate3d(5px, 0, 0px);
  transform: translate3d(5px, 0, 0px);
  -ms-transform: translate(5px, 0);
}

.nav-tabs-header {
  overflow: scroll;
  width: 100%;
}

.nav-tabs-header .nav-tabs {
  width: auto;
  white-space: nowrap;
}

.nav-tabs-header .nav-tabs > li {
  display: inline-block;
  float: inherit;
}

.nav-tabs-header.nav-tabs-linetriangle {
  height: 54px;
  overflow-y: hidden;
}

.nav-tabs-header.nav-tabs-linetriangle:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  bottom: 3px;
  z-index: 120;
  content: "";
  clear: both;
}

.nav-tabs-header.nav-tabs-linetriangle ~ .tab-content {
  position: relative;
  top: -12px;
}

.nav-tabs-header ~ .tab-content {
  overflow: hidden;
  padding: 15px;
}

.nav-tab-dropdown .cs-select {
  width: 100% !important;
  max-width: 100%;
}

.nav-tab-dropdown .cs-select .cs-placeholder {
  height: 48px;
  padding: 12px 34px 15px 15px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  letter-spacing: 0.06em;
  font-size: 10.5px;
  text-transform: uppercase;
}

.nav-tab-dropdown .cs-select .cs-backdrop {
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(33, 33, 33, 0.14);
}

/* Panel Groups
------------------------------------
*/
.card-group {
  margin-bottom: 18px;
}

.card-group.horizontal {
  display: block;
}

.card-group .card-header {
  padding: 13px 18px 10px 22px;
}

.card-group .card-header.collapsed {
  background-color: #fff;
}

.card-group .card-header + .card-collapse .card-body {
  border: 0;
}

.card-group .card-header .card-title {
  width: 100%;
}

.card-group .card-header .card-title > a {
  color: #4b4b4b;
  font-size: inherit;
  padding: inherit;
  margin: inherit;
  display: block;
  opacity: 1;
}

.card-group .card-header .card-title > a:hover {
  color: #4b4b4b !important;
}

.card-group .card-header .card-title > a:hover:after {
  color: #4b4b4b !important;
}

.card-group .card-header .card-title > a:after {
  font-family: "pages-icon";
  content: "\e987";
  font-size: 17px;
  position: absolute;
  right: 13px;
  top: 36%;
  color: #4b4b4b;
}

.card-group .card-header .card-title > a.collapsed {
  color: rgba(75, 75, 75, 0.7);
  opacity: 1;
}

.card-group .card-header .card-title > a.collapsed:after {
  content: "\e986";
  color: rgba(75, 75, 75, 0.7);
}

.card-group .card {
  box-shadow: none;
  border: 1px solid rgba(33, 33, 33, 0.14);
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}

.card-group .card + .card {
  margin-top: 2px;
}

.card-group .card .card-body {
  height: auto;
}

.card-group .card.card-default {
  box-shadow: none;
  border: 1px solid rgba(33, 33, 33, 0.1);
}

.nav-pills > li:first-child {
  padding-left: 0;
}

.nav-pills > li > a {
  color: #4b4b4b;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #4b4b4b;
  background-color: #e0e0e0;
}

@media (max-width: 767px) {
  .nav.nav-tabs.nav-stack-sm li {
    float: none;
  }
  .nav.nav-tabs.nav-stack-sm.nav-tabs-linetriangle > li.active > a:after,
  .nav.nav-tabs.nav-stack-sm.nav-tabs-linetriangle > li.active > a:before {
    display: none;
  }
}

/*------------------------------------------------------------------
[10. Modals]
*/
.modal .close {
  position: absolute;
  right: 25px;
  top: 15px;
}

.modal .close:focus {
  outline: 0;
}

.modal .modal-dialog {
  transition: all 0.2s !important;
}

.modal .modal-content {
  border: 1px solid #f2f6f7;
  border-radius: 3px;
  box-shadow: none;
}

.modal .modal-header {
  text-align: center;
  border-bottom: 0;
  padding: 25px 25px 0 25px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.modal .modal-header p {
  color: #8b91a0;
}

.modal .modal-body {
  box-shadow: none;
  padding: 25px;
  padding-top: 0;
  white-space: normal;
}

.modal .modal-footer {
  border-top: none;
  box-shadow: none;
  margin-top: 0;
  padding: 25px;
  padding-top: 0;
}

.modal .drop-shadow {
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36) !important;
}

.modal.fade {
  opacity: 1 !important;
}

.modal.fade.stick-up {
  /*&.in{
				.modal-dialog{
					@include translate3d(0,0,0);
				}	
			}*/
}

.modal.fade.stick-up .modal-dialog {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -ms-transform: translate(0, -100%);
  margin-top: -5px;
}

.modal.fade.stick-up.show .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
}

.modal.fade.slide-up {
  height: 100%;
  top: 0;
}

.modal.fade.slide-up.show .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
}

.modal.fade.slide-up.disable-scroll {
  overflow: hidden;
}

.modal.fade.slide-up .modal-dialog {
  display: table;
  height: 100%;
  -webkit-transform: translate3d(0, 75%, 0);
  transform: translate3d(0, 75%, 0);
  -ms-transform: translate(0, 75%);
}

.modal.fade.slide-up .modal-content-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal.fade.center-scale {
  /*&.in{
	  			.modal-dialog{
	  				opacity: 1;
					@include scale(1);
				}
			}*/
}

.modal.fade.center-scale .modal-dialog {
  display: table;
  height: 100%;
}

.modal.fade.center-scale .modal-content {
  display: table-cell;
  vertical-align: middle;
  border: 0;
  background: transparent;
}

.modal.fade.center-scale .modal-content .modal-header {
  border-bottom: 0px;
}

.modal.fade.center-scale.show .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.modal.fade.center-scale .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}

.modal.fade.fill-in {
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  /*&.in{
				background-color: rgba(255, 255, 255, 0.85);
	  			.modal-dialog{
	  				opacity: 1;
					@include scale(1);
				}
			}*/
}

.modal.fade.fill-in > .close {
  opacity: 0.6;
  z-index: 9999;
}

.modal.fade.fill-in > .close > i {
  font-size: 16px;
}

.modal.fade.fill-in .modal-dialog {
  display: table;
  height: 100%;
}

.modal.fade.fill-in .modal-content {
  display: table-cell;
  vertical-align: middle;
  border: 0;
  background: transparent;
}

.modal.fade.fill-in .modal-content .modal-header {
  border-bottom: 0px;
}

.modal.fade.fill-in.show {
  background-color: rgba(255, 255, 255, 0.85);
}

.modal.fade.fill-in.show .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.modal.fade.fill-in .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}

.modal.fade.slide-right {
  /*&.in{
	  			.modal-dialog{
					@include translate3d(0,0,0);
				}
			}*/
}

.modal.fade.slide-right .close {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  z-index: 10;
}

.modal.fade.slide-right.show .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
}

.modal.fade.slide-right .modal-dialog {
  position: absolute;
  right: 0;
  margin: 0;
  height: 100%;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
}

.modal.fade.slide-right .modal-dialog .modal-content-wrapper {
  height: 100%;
}

.modal.fade.slide-right .modal-dialog .modal-content {
  height: 100%;
}

.modal.fade.slide-right .modal-dialog .modal-body {
  background-color: #fff;
}

.modal.fade.slide-right .modal-content {
  border-radius: 0px;
}

.fill-in-modal .modal-backdrop {
  background-color: transparent;
}

.modal-backdrop {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  /*&.in{
		opacity: 0.30;
	}*/
}

.modal-backdrop.show {
  opacity: 0.3;
}

/* Responsive Handlers: Modals
------------------------------------
*/
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

@media (max-width: 768px) {
  .modal.fill-in .modal-dialog {
    width: calc(100% - 20px);
  }
  .modal.slide-up .modal-dialog {
    width: calc(100% - 20px);
  }
}

@media (min-width: 768px) {
  .modal.stick-up .modal-dialog {
    margin: -5px auto;
  }
  .modal.slide-up .modal-dialog {
    margin: 0 auto;
  }
  .modal.fill-in .modal-dialog {
    margin: 0 auto;
  }
  .modal .modal-content {
    box-shadow: none;
  }
  .modal .modal-dialog {
    width: 600px;
  }
  .modal .modal-sm {
    width: 300px;
  }
  .modal .modal-sm {
    min-width: auto;
  }
}

/*------------------------------------------------------------------
[12. Sliders]
*/
/* Ion Range Slider
https://github.com/IonDen/ion.rangeSlider
--------------------------------------------------
*/
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
  background-image: none;
}

.irs-bar {
  background: #D83C31;
}

.irs-wrapper .irs-line {
  background-color: #e0e0e0;
}

.irs-wrapper .irs-line-mid,
.irs-wrapper .irs-line-left,
.irs-wrapper .irs-line-right,
.irs-wrapper .irs-diapason,
.irs-wrapper .irs-slider {
  background: none;
}

.irs-wrapper .irs-diapason {
  background-color: #D83C31;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.irs-wrapper .irs-from,
.irs-wrapper .irs-to,
.irs-wrapper .irs-single {
  background: #D83C31;
}

.irs-wrapper .irs-slider.from:before {
  top: -2%;
  left: 37%;
  width: 16%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  background-color: #D83C31;
}

.irs-wrapper .irs-slider.to:before {
  top: -2%;
  left: 37%;
  width: 16%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  background-color: #D83C31;
}

.irs-wrapper .irs-slider.single:before {
  top: -2%;
  left: 37%;
  width: 16%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  background-color: #D83C31;
}

.irs-wrapper .irs-from:after,
.irs-wrapper .irs-to:after,
.irs-wrapper .irs-single:after {
  border-top-color: #D83C31;
}

.irs-wrapper.success .irs-diapason {
  background-color: #19AD79;
}

.irs-wrapper.success .irs-from,
.irs-wrapper.success .irs-to,
.irs-wrapper.success .irs-single {
  background: #19AD79;
}

.irs-wrapper.success .irs-slider.from:before {
  background-color: #19AD79;
}

.irs-wrapper.success .irs-slider.to:before {
  background-color: #19AD79;
}

.irs-wrapper.success .irs-slider.single:before {
  background-color: #19AD79;
}

.irs-wrapper.success .irs-from:after,
.irs-wrapper.success .irs-to:after,
.irs-wrapper.success .irs-single:after {
  border-top-color: #19AD79;
}

.irs-wrapper.success .irs-bar {
  background-color: #19AD79;
}

.irs-wrapper.primary .irs-diapason {
  background-color: #7252D3;
}

.irs-wrapper.primary .irs-from,
.irs-wrapper.primary .irs-to,
.irs-wrapper.primary .irs-single {
  background: #7252D3;
}

.irs-wrapper.primary .irs-slider.from:before {
  background-color: #7252D3;
}

.irs-wrapper.primary .irs-slider.to:before {
  background-color: #7252D3;
}

.irs-wrapper.primary .irs-slider.single:before {
  background-color: #7252D3;
}

.irs-wrapper.primary .irs-from:after,
.irs-wrapper.primary .irs-to:after,
.irs-wrapper.primary .irs-single:after {
  border-top-color: #7252D3;
}

.irs-wrapper.primary .irs-bar {
  background-color: #7252D3;
}

.irs-wrapper.warning .irs-diapason {
  background-color: #FFd945;
}

.irs-wrapper.warning .irs-from,
.irs-wrapper.warning .irs-to,
.irs-wrapper.warning .irs-single {
  background: #FFd945;
}

.irs-wrapper.warning .irs-slider.from:before {
  background-color: #FFd945;
}

.irs-wrapper.warning .irs-slider.to:before {
  background-color: #FFd945;
}

.irs-wrapper.warning .irs-slider.single:before {
  background-color: #FFd945;
}

.irs-wrapper.warning .irs-from:after,
.irs-wrapper.warning .irs-to:after,
.irs-wrapper.warning .irs-single:after {
  border-top-color: #FFd945;
}

.irs-wrapper.warning .irs-bar {
  background-color: #FFd945;
}

.irs-wrapper.complete .irs-diapason {
  background-color: #0072EC;
}

.irs-wrapper.complete .irs-from,
.irs-wrapper.complete .irs-to,
.irs-wrapper.complete .irs-single {
  background: #0072EC;
}

.irs-wrapper.complete .irs-slider.from:before {
  background-color: #0072EC;
}

.irs-wrapper.complete .irs-slider.to:before {
  background-color: #0072EC;
}

.irs-wrapper.complete .irs-slider.single:before {
  background-color: #0072EC;
}

.irs-wrapper.complete .irs-from:after,
.irs-wrapper.complete .irs-to:after,
.irs-wrapper.complete .irs-single:after {
  border-top-color: #0072EC;
}

.irs-wrapper.complete .irs-bar {
  background-color: #0072EC;
}

.irs-wrapper.danger .irs-diapason {
  background-color: #D83C31;
}

.irs-wrapper.danger .irs-from,
.irs-wrapper.danger .irs-to,
.irs-wrapper.danger .irs-single {
  background: #D83C31;
}

.irs-wrapper.danger .irs-slider.from:before {
  background-color: #D83C31;
}

.irs-wrapper.danger .irs-slider.to:before {
  background-color: #D83C31;
}

.irs-wrapper.danger .irs-slider.single:before {
  background-color: #D83C31;
}

.irs-wrapper.danger .irs-from:after,
.irs-wrapper.danger .irs-to:after,
.irs-wrapper.danger .irs-single:after {
  border-top-color: #D83C31;
}

.irs-wrapper.danger .irs-bar {
  background-color: #D83C31;
}

/* noUiSlider
http://refreshless.com/nouislider/
--------------------------------------------------
*/
.noUi-target {
  border-radius: 0px;
  border: 0;
  box-shadow: none;
  direction: ltr;
}

.noUi-target.bg-complete .noUi-connect {
  background-color: #0072EC;
}

.noUi-target.bg-success .noUi-connect {
  background-color: #19AD79;
}

.noUi-target.bg-warning .noUi-connect {
  background-color: #FFd945;
}

.noUi-target.bg-danger .noUi-connect {
  background-color: #D83C31;
}

.noUi-target.bg-info .noUi-connect {
  background-color: #3B4752;
}

.noUi-target.bg-primary .noUi-connect {
  background-color: #7252D3;
}

.noUi-target.noUi-connect {
  box-shadow: none;
}

.noUi-handle {
  border-radius: 999px;
  box-shadow: none;
}

.noUi-handle:before, .noUi-handle:after {
  display: none;
}

.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  left: -15px;
  border: 1px solid #d6d6d6;
  top: -7px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.noUi-horizontal .noUi-handle:hover {
  border: 1px solid #b7b7b7;
}

.noUi-horizontal .noUi-handle:active {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 1px solid #d6d6d6;
}

.noUi-horizontal .noUi-handle:focus {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 1px solid #d6d6d6;
}

.disable-hover-scale .noUi-handle:active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.disable-hover-scale .noUi-handle:focus {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.vertical-slider {
  height: 150px;
}

.noUi-vertical {
  width: 4px;
}

.noUi-vertical .noUi-handle {
  width: 18px;
  height: 18px;
  border: 1px solid #d6d6d6;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.noUi-vertical .noUi-handle:hover {
  border: 1px solid #b7b7b7;
}

.noUi-vertical .noUi-handle:active {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 1px solid #d6d6d6;
}

.noUi-vertical .noUi-handle:focus {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  border: 1px solid #d6d6d6;
}

.noUi-base {
  padding: 5px 0;
}

.noUi-origin {
  border-radius: 0px;
  bottom: 5px;
}

.noUi-connect {
  box-shadow: none;
}

.noUi-background {
  background: #e9e9e9 !important;
  box-shadow: none;
}

/*------------------------------------------------------------------
[14. Nesstables]
*/
.dd-handle {
  border-color: rgba(224, 224, 224, 0.7);
  color: #4b4b4b;
  transition: backgroud 0.2s ease-in;
}

.dd-handle:hover {
  background-color: #fff;
}

.dark .dd-handle {
  color: #4b4b4b;
  background: #f4f4f4;
}

.dark .dd-handle:hover {
  background-color: rgba(244, 244, 244, 0.6);
}

.dark .dd-placeholder {
  background-color: #f4f4f4;
}

.dd3-content {
  background: #fff;
  border-color: rgba(224, 224, 224, 0.7);
  color: #4b4b4b;
}

.dd3-content:hover {
  background-color: #fff;
}

.dd3-handle {
  background: transparent;
  text-indent: 9999px;
}

.dd3-handle:before {
  font-family: "pages-icon";
  color: #4b4b4b;
  content: "\e912";
  top: 4px;
}

.dd-placeholder {
  background: #f4f4f4;
  border-color: rgba(75, 75, 75, 0.35);
}

.dd-empty {
  background-image: none;
  background: #f4f4f4;
  border-color: rgba(75, 75, 75, 0.5);
}

.dd-item > button {
  font-size: 11px;
}

.dd-item > button:before {
  font-family: "pages-icon";
  content: "\e988";
}

.dd-item > button[data-action="collapse"]:before {
  font-family: "pages-icon";
  content: "\e986";
}

/* Responsive Handlers : Nestables
------------------------------------
*/
@media only screen and (min-width: 700px) {
  .dd {
    width: 100%;
  }
  .nestable-lists .dd {
    float: left;
  }
}

/*------------------------------------------------------------------
[13. Treeview]
*/
span.dynatree-active a {
  color: #212121 !important;
  background-color: transparent !important;
}

span.dynatree-selected a {
  color: #212121 !important;
  font-style: normal;
}

ul.dynatree-container a:focus,
span.dynatree-focused a:link {
  background-color: transparent;
}

ul.dynatree-container {
  background-color: transparent;
}

ul.dynatree-container a:hover {
  color: #4b4b4b;
  opacity: 0.7;
  background-color: transparent;
}

ul.dynatree-container a {
  color: #4b4b4b;
}

span.dynatree-empty,
span.dynatree-vline,
span.dynatree-connector,
span.dynatree-expander,
span.dynatree-icon,
span.dynatree-checkbox,
span.dynatree-radio,
span.dynatree-drag-helper-img,
#dynatree-drop-marker {
  height: 17px;
  position: relative;
  top: 3px;
}

/*------------------------------------------------------------------
[2. View Ports]
*/
.view-port {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  white-space: nowrap;
  word-spacing: 0;
  font-size: 0;
  overflow: hidden;
}

.view-port > * {
  font-size: initial;
}

.view-port .navbar {
  border-radius: 0;
  padding-left: 0;
  margin-bottom: 0;
  border-left: 0;
  display: table;
  width: 100%;
  top: 0;
  border-top: 0;
  padding: 0;
  border-bottom: 1px solid #e0e0e0;
}

.view-port .navbar .navbar-inner {
  display: table-cell;
  height: 50px;
  vertical-align: middle;
}

.view-port .navbar .action {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.view-port .navbar .action.pull-right {
  right: 0;
}

.view-port .navbar .view-heading {
  font-size: 15px;
  text-align: center;
}

.view-port .navbar > p {
  line-height: 12px;
  font-size: 12px;
  margin: 0;
}

.view-port .navbar.navbar-sm {
  min-height: 35px;
}

.view-port .navbar.navbar-sm .navbar-inner {
  height: 35px;
}

.view-port .navbar.navbar-sm .action {
  line-height: 35px;
}

.view-port .view {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  vertical-align: top;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.view-port .view:first-child:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  z-index: -1;
}

.view-port .view:only-child {
  margin: 0;
}

.view-port.from-top > .view:last-child {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
}

.view-port.push > .view:first-child {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
}

.view-port.push > .view:last-child {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
}

.view-port.push-parrallax > .view:first-child {
  -webkit-transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  -webkit-transform: translate3d(-25%, 0, 0);
  transform: translate3d(-25%, 0, 0);
  -ms-transform: translate(-25%, 0);
}

.view-port.push-parrallax > .view:first-child:before {
  opacity: 0;
  z-index: 100;
}

.view-port.push-parrallax > .view:last-child {
  -webkit-transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
}

/*------------------------------------------------------------------
[4. Cards]
*/
.card {
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.14), 0 0 1px 0 rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 0px solid transparent;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  word-wrap: none;
  background: #fff;
}

.card .card-header {
  background: transparent;
  border-radius: 0px;
  border-bottom: 0px;
  padding: 16px 16px 7px 20px;
  position: relative;
  z-index: 3;
  min-height: 42px;
}

.card .card-header.separator:after {
  content: "";
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  left: 16px;
  right: 16px;
  position: absolute;
  bottom: 0;
}

.card .card-header + .card-body {
  padding-top: 0;
}

.card .card-header .card-title {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.06em;
  font-size: 10.5px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-stroke: 0px;
  filter: alpha(opacity=40);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.card .card-header .card-controls {
  float: right;
  margin-top: -3px;
}

.card .card-header .card-controls ul {
  margin: 0;
  padding: 0;
}

.card .card-header .card-controls ul li {
  padding-left: 0px;
  display: inline-block;
  list-style: none;
  line-height: 0;
}

.card .card-header .card-controls:after {
  content: "";
  display: table;
  clear: both;
}

.card .card-header.card-header-hover .card-title {
  opacity: 0.4;
}

.card .card-header.card-header-hover:hover .card-title {
  opacity: 1;
}

.card.card-collapsed .card-header .card-controls .card-icon-collapse:before {
  content: "\e986";
}

.card.card-maximized .card-header .card-controls .card-icon-maximize:before {
  content: "\e98d";
}

.card.card-default {
  border: 0px solid transparent;
  box-shadow: 0 1px 2px 0 transparent, 0 0 1px 0 rgba(0, 0, 0, 0);
  border-radius: 4px;
}

.card.card-default > .card-header {
  color: inherit;
}

.card.card-bordered {
  border: 1px solid rgba(224, 224, 224, 0.7);
}

.card.card-borderless {
  border: none;
  box-shadow: none;
}

.card.card-condensed .card-header {
  padding: 9px 13px 0 13px;
  min-height: 32px;
}

.card.card-condensed .card-header .card-title {
  opacity: 0.4;
}

.card.card-condensed .card-body {
  padding: 13px;
}

.card.card-hover .card-header .card-title {
  opacity: 0.4;
}

.card.card-hover:hover .card-header .card-title {
  opacity: 1;
}

.card.card-transparent {
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card.card-transparent .card-body {
  background: transparent;
}

.card.full-height {
  height: 100%;
}

.card.full-height .card-body {
  height: auto;
  width: 100%;
  height: 100%;
}

.card.card-featured {
  -webkit-box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
  box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
  width: calc(100% - 50px);
  float: right;
}

.card.card-featured .card-title h4 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: inherit;
}

.card.card-featured .card-body h3 {
  line-height: 34px;
  font-size: 26px;
}

.card.card-featured .footer .username {
  line-height: 8px;
  padding-top: 10px;
  font-size: 16px;
}

.card.card-featured .footer .buttons li {
  display: inline;
  list-style: none;
  font-weight: bold;
  margin-left: 20px;
}

.card.card-featured .footer .buttons li:first-child {
  margin-left: 0;
}

.card.card-featured .footer .buttons .heart {
  color: #D83C31;
}

.card.card-featured .footer .buttons .comment {
  color: #4b4b4b;
}

.card.card-featured .ribbon {
  width: 38px;
  height: 38px;
  margin-left: -39px;
  float: left;
  -webkit-box-shadow: inset -3px 0px 3px 0px rgba(0, 0, 0, 0.14);
  box-shadow: inset -3px 0px 3px 0px rgba(0, 0, 0, 0.14);
}

.card.card-featured .ribbon.green {
  background: #0072EC;
}

.card.card-featured .ribbon.blue {
  background: #19AD79;
}

.card.hover-fill:hover {
  background: #f4f4f4;
}

.card.hover-stroke:hover {
  border: 1px solid #e0e0e0;
}

.card .card-body.no-padding .row {
  margin-left: 0;
  margin-right: 0;
}

.card .card-body.no-bottom-padding {
  padding-bottom: 0;
}

.card .card-body.no-top-padding {
  padding-top: 0;
}

.card .card-body .title {
  margin-top: 0px;
}

.card .card-body.scrollable {
  margin-bottom: 20px;
}

/* Portlets
------------------------------------
*/
.card-progress {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: absolute !important;
  right: 0;
  top: 0;
  display: none;
  z-index: 2;
}

.card-progress > .progress,
.card-progress > .progress.progress-small {
  height: 3px;
}

.card-progress > .progress-circle-indeterminate,
.card-progress > .card-bar-indeterminate {
  display: block;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
  position: absolute;
  top: 50%;
}

.card-progress > .progress-circle-indeterminate {
  width: 35px;
  height: 35px;
}

.card-maximized {
  position: fixed !important;
  left: 280px;
  top: 59px;
  bottom: 0;
  right: 0;
  z-index: 600;
  margin: 0;
  width: auto;
}

/* Pages notification holder */
.card .pgn-wrapper {
  position: absolute;
  z-index: 602;
  left: 0 !important;
  top: 0 !important;
}

.card-header a:not(.btn) {
  color: inherit;
  opacity: 0.4;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-header a:not(.btn).card-refresh {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.card-header a:not(.btn).card-refresh.refreshing {
  opacity: 1;
}

.card-header .dropdown-menu .dropdown-item {
  padding: 0 20px !important;
}

.card-header .dropdown-menu .dropdown-item:hover {
  opacity: 1;
  background-color: transparent;
}

.card-header a[data-toggle]:hover {
  opacity: 1;
}

.card-icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  font-family: "pages-icon";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  color: inherit;
}

.card-icon-close:before {
  content: "\e966";
}

.card-icon-maximize:before {
  content: "\e98e";
}

.card-icon-refresh:before {
  content: "\e98c";
}

.card-icon-collapse:before {
  content: "\e987";
}

.card-icon-settings:before {
  content: "\e97f";
}

.card-icon-refresh-lg-master,
.card-icon-refresh-lg-white {
  width: 15px;
  height: 15px;
  display: block;
  background-size: cover;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.card-icon-refresh-lg-master.fade,
.card-icon-refresh-lg-white.fade {
  opacity: 0.1;
}

.card-icon-refresh-lg-master {
  background-image: url("../img/progress/progress-circle-lg-master-static.svg");
}

.card-icon-refresh-lg-white {
  background-image: url("../img/progress/progress-circle-lg-white-static.svg");
}

.card-icon-refresh-lg-master-animated,
.card-icon-refresh-lg-white-animated {
  width: 15px;
  height: 15px;
  display: block;
  background-size: cover;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.card-icon-refresh-lg-master-animated.active,
.card-icon-refresh-lg-white-animated.active {
  opacity: 1;
}

.card-icon-refresh-lg-master-animated {
  background-image: url("../img/progress/progress-circle-lg-master.svg");
}

.card-icon-refresh-lg-white-animated {
  background-image: url("../img/progress/progress-circle-lg-white.svg");
}

/* For demo purpose only */
.card-scroll {
  height: 100px;
}

.sortable .card-header {
  cursor: move;
}

/* To prevent lag while dragging */
.ui-sortable-handle {
  transition: max-height 0.3s ease 0s;
}

.sortable .grid .grid-title {
  cursor: move;
}

.ui-sortable {
  min-height: 0px !important;
}

.ui-sortable-placeholder {
  border: 1px dotted black;
  visibility: visible !important;
  height: 100% !important;
}

.ui-sortable-placeholder * {
  visibility: hidden;
}

.sortable-box-placeholder {
  background-color: #f4f4f4;
  border: 1px dashed #e0e0e0;
  display: block;
  margin-top: 0px !important;
  margin-bottom: 24px !important;
}

.sortable-box-placeholder * {
  visibility: hidden;
}

.sortable .card {
  transition: none;
}

.sortable-column {
  padding-bottom: 100px;
}

.demo-card-scrollable {
  height: 158px;
}

/*------------------------------------------------------------------
Checkbox
*/
.form-check {
  margin-bottom: 10px;
  padding-left: 0px;
}

.form-check label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  min-width: 18px;
  min-height: 18px;
  margin-bottom: 0;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.form-check label:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0px;
  -webkit-box-sizing: inherit;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #c9c9c9;
}

.form-check label {
  transition: border 140ms linear 0s, color 140ms linear 0s, background-color 140ms linear 0s;
}

.form-check label:hover {
  color: #212121;
}

.form-check label:before {
  top: 2px;
  border-radius: 3px;
  transition: border 140ms linear 0s, color 140ms linear 0s, background-color 140ms linear 0s;
}

.form-check input[type="checkbox"] {
  position: absolute;
  margin: 0;
  top: 3px;
  z-index: -1;
  width: 16px;
  height: 16px;
  opacity: 0;
}

.form-check input[type="checkbox"] + label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0px;
  border-right: 0 solid transparent;
  border-bottom: 0 solid transparent;
  width: 16px;
  height: 16px;
  overflow: hidden;
}

.form-check.checkbox-circle label:after {
  border-radius: 99px;
}

.form-check.checkbox-circle label:before {
  border-radius: 99px;
}

.form-check input[type="checkbox"]:checked + label::after {
  content: "";
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAAQAgMAAADsa5zLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTHBwcG9vb3BwcBFjhIYAAAAEdFJOUwBG9tQE3MceAAAAVUlEQVQoz2NgGLKA0QGIDwDxBSCeAMEYgAWI2YCK2CagYgwgDcRSDhgYbAkKzsSKGdgakCyY6ADES7BiiCkgJ4PYyybgxAhQAsRZDrgxCpDEg4cAAAAp2ibhZRGLHgAAAABJRU5ErkJggg==") left center;
  background-size: 160px 16px;
  background-repeat: no-repeat;
  animation-name: checkbox-check;
  animation-duration: 320ms;
  animation-timing-function: steps(9);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.form-check input[type="checkbox"]:hover:active:not(:checked) + label:before {
  background-color: rgba(0, 0, 0, 0.08);
}

.form-check input[type="checkbox"]:focus + label {
  color: #212121;
}

.form-check input[type="checkbox"]:focus + label:before {
  outline: none !important;
  box-shadow: 0 0 0 2px #78c8fe;
}

.form-check input[type="checkbox"][disabled] + label {
  cursor: not-allowed !important;
  color: #4b4b4b;
  opacity: 0.58;
}

.form-check input[type="checkbox"][disabled] + label:before {
  cursor: not-allowed !important;
  background: #ececec;
}

.form-check input[type="checkbox"]:indeterminate + label:after {
  background: none;
  background-color: #4b4b4b;
  width: 10px;
  height: 2px;
  top: 6px;
  margin: 3px;
  border-radius: 2px;
}

.form-check.right label {
  padding-left: 0px;
  padding-right: 26px;
}

.form-check.right label:before {
  right: 0px;
  left: auto;
}

.form-check.right input[type="checkbox"]:checked + label {
  position: relative;
}

.form-check.right input[type="checkbox"]:checked + label::after {
  position: absolute;
  right: 0px;
  left: auto;
}

.success input[type="checkbox"]:checked + label:before, .success input[type="checkbox"]:indeterminate + label:before {
  border-color: #19AD79;
  background-color: #19AD79;
}

.primary input[type="checkbox"]:checked + label:before, .primary input[type="checkbox"]:indeterminate + label:before {
  border-color: #7252D3;
  background-color: #7252D3;
}

.complete input[type="checkbox"]:checked + label:before, .complete input[type="checkbox"]:indeterminate + label:before {
  border-color: #0072EC;
  background-color: #0072EC;
}

.warning input[type="checkbox"]:checked + label:before, .warning input[type="checkbox"]:indeterminate + label:before {
  border-color: #FFd945;
  background-color: #FFd945;
}

.danger input[type="checkbox"]:checked + label:before, .danger input[type="checkbox"]:indeterminate + label:before {
  border-color: #D83C31;
  background-color: #D83C31;
}

.info input[type="checkbox"]:checked + label:before, .info input[type="checkbox"]:indeterminate + label:before {
  border-color: #3B4752;
  background-color: #3B4752;
}

.info input[type="checkbox"]:checked + label::after,
.danger input[type="checkbox"]:checked + label::after,
.complete input[type="checkbox"]:checked + label::after,
.primary input[type="checkbox"]:checked + label::after,
.success input[type="checkbox"]:checked + label::after {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAAQAgMAAADsa5zLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTP///////////waf0AoAAAAEdFJOUwBG9tQE3MceAAAAVUlEQVQoz2NgGLKA0QGIDwDxBSCeAMEYgAWI2YCK2CagYgwgDcRSDhgYbAkKzsSKGdgakCyY6ADES7BiiCkgJ4PYyybgxAhQAsRZDrgxCpDEg4cAAAAp2ibhZRGLHgAAAABJRU5ErkJggg==") left center;
}

.info input[type="checkbox"]:indeterminate + label::after,
.danger input[type="checkbox"]:indeterminate + label::after,
.complete input[type="checkbox"]:indeterminate + label::after,
.primary input[type="checkbox"]:indeterminate + label::after,
.success input[type="checkbox"]:indeterminate + label::after {
  background-color: #fff;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checkbox-check {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: -144px;
  }
}

/* hide focus style if not from keyboard navigation */
.js-focus-visible .form-check input[type="checkbox"]:focus:not(.focus-visible) + label:before {
  box-shadow: none;
}

.form-check input[type="radio"] + label:before {
  bottom: 2.5px;
  border-radius: 99px;
  -webkit-transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
}

.form-check input[type="radio"]:checked + label:before {
  border-color: #757575;
  border-width: 5px;
}

.form-check input[type="radio"]:hover:active:not(:checked) + label:before {
  background-color: rgba(0, 0, 0, 0.08);
}

.form-check input[type="radio"]:focus + label {
  color: #212121;
}

.form-check input[type="radio"]:focus + label:before {
  outline: none !important;
  box-shadow: 0 0 0 2px #78c8fe;
}

.form-check input[type="radio"] {
  opacity: 0;
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
}

.form-check input[type="radio"][disabled] + label {
  cursor: not-allowed !important;
  color: #4b4b4b;
  opacity: 0.58;
}

.form-check input[type="radio"][disabled] + label:before {
  cursor: not-allowed !important;
  background: #ececec;
}

.success input[type="radio"]:checked + label:before {
  border-color: #19AD79;
}

.primary input[type="radio"]:checked + label:before {
  border-color: #7252D3;
}

.info input[type="radio"]:checked + label:before {
  border-color: #3B4752;
}

.warning input[type="radio"]:checked + label:before {
  border-color: #FFd945;
}

.danger input[type="radio"]:checked + label:before {
  border-color: #D83C31;
}

.complete input[type="radio"]:checked + label:before {
  border-color: #0072EC;
}

/* hide focus style if not from keyboard navigation */
.js-focus-visible .form-check input[type="radio"]:focus:not(.focus-visible) + label:before {
  box-shadow: none;
}

/*------------------------------------------------------------------
Switch
*/
.form-check.switch input[type="checkbox"] + label {
  padding-left: 34px;
}

.form-check.switch input[type="checkbox"] + label:before {
  cursor: pointer;
  width: 26px;
  height: 18px;
  top: 2px;
  background-image: -webkit-linear-gradient(to right, #7252D3 0%, #845ae0 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
  background-image: -moz-linear-gradient(to right, #7252D3 0%, #845ae0 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
  background-image: linear-gradient(to right, #7252D3 0%, #845ae0 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
  background-size: 300%;
  background-position: right;
  border-radius: 50px;
  border: none;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  transition: background 310ms, box-shadow 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.form-check.switch input[type="checkbox"] + label:after {
  transform: translateX(0%);
  background: #fff;
  top: 4px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 9px;
  box-shadow: 1px 0px 1px 0.5px rgba(0, 0, 0, 0.12), 2px 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform, box-shadow;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.form-check.switch input[type="checkbox"]:checked + label:before {
  background-position: left;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
}

.form-check.switch input[type="checkbox"]:checked + label:after {
  transform: translateX(calc(100% - 6px));
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.08), -3px 3px 6px rgba(0, 0, 0, 0.3);
}

.form-check.switch input[type="checkbox"]:focus + label:before {
  outline: none !important;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12), 0 0 0 2px #78c8fe;
}

.form-check.switch input[type="checkbox"][disabled]:active + label:after {
  transform: scaleX(1.1);
  transform-origin: center left;
  transition: transform step-start;
}

.form-check.switch input[type="checkbox"]:checked[disabled]:active + label:after {
  transform: translateX(calc(100% - 6px)) scaleX(1.1);
  transform-origin: center right;
}

.form-check.switch input[type="checkbox"]:hover:active + label:before {
  background-color: transparent;
}

.form-check.switch.right input[type="checkbox"] + label {
  padding-left: 0px;
  padding-right: 36px;
}

.form-check.switch.right input[type="checkbox"] + label:before {
  right: 0px;
  left: auto;
}

.form-check.switch.right input[type="checkbox"] + label:after {
  right: 10px;
  left: auto;
}

.form-check.switch.switch-lg input[type="checkbox"] + label {
  padding-left: 42px;
}

.form-check.switch.switch-lg input[type="checkbox"] + label:before {
  width: 34px;
  height: 22px;
  top: 0px;
}

.form-check.switch.switch-lg input[type="checkbox"] + label:after {
  width: 18px;
  height: 18px;
  top: 2px;
}

.form-check.switch.switch-lg.right input[type="checkbox"] + label {
  padding-left: 0px;
  padding-right: 44px;
}

.form-check.switch.switch-lg.right input[type="checkbox"] + label:after {
  right: 14px;
}

.form-check.switch.success input[type="checkbox"] + label:before {
  background-image: linear-gradient(to right, #19AD79 0%, #26bf93 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
}

.form-check.switch.complete input[type="checkbox"] + label:before {
  background-image: linear-gradient(to right, #0072EC 0%, #0f8ff9 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
}

.form-check.switch.warning input[type="checkbox"] + label:before {
  background-image: linear-gradient(to right, #FFd945 0%, #ffe858 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
}

.form-check.switch.danger input[type="checkbox"] + label:before {
  background-image: linear-gradient(to right, #D83C31 0%, #e6533c 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
}

.form-check.switch.info input[type="checkbox"] + label:before {
  background-image: linear-gradient(to right, #3B4752 0%, #475b6b 44%, rgba(0, 0, 0, 0.14) 62%, rgba(0, 0, 0, 0.04) 97%);
}

/* hide focus style if not from keyboard navigation */
.js-focus-visible .form-check.switch input[type="checkbox"]:focus:not(.focus-visible) + label:before {
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
}

/* Accessibility focus for screen readers*/
.form-check.switch input[type="checkbox"] {
  top: 1px;
  width: 28px;
  height: 18px;
}

.form-check.switch.switch-lg input[type="checkbox"] {
  top: 0px;
  width: 34px;
  height: 22px;
}

/*------------------------------------------------------------------
[15. Form Elements]
*/
label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

label {
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif;
  letter-spacing: -0.006em;
}

select[multiple],
select[size] {
  height: auto !important;
}

input:focus,
select:focus,
textarea:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input:-webkit-autofill, input.error:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

input:-webkit-autofill:focus, input.error:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

form legend {
  margin: 15px 0px 10px 0px;
}

.form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(33, 33, 33, 0.14);
  border-top-color: rgba(33, 33, 33, 0.21);
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif;
  -webkit-appearance: none;
  color: #212121;
  outline: 0;
  padding: 6px 8px;
  line-height: 16px;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  min-height: 32px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.47;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.47;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.47;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control::placeholder {
  color: inherit;
  opacity: 0.47;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control:focus {
  border-color: unset;
  background-color: #fff;
  border: 1px solid #7252D3;
  box-shadow: none;
  outline: 0 !important;
}

.form-control:focus::-moz-placeholder {
  color: inherit;
  opacity: 0.57;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control:focus:-ms-input-placeholder {
  color: inherit;
  opacity: 0.57;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control:focus::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.57;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control:focus::placeholder {
  color: inherit;
  opacity: 0.57;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-control[disabled], .form-control[readonly],
fieldset[disabled] .form-control {
  background: #fafafa;
  color: rgba(75, 75, 75, 0.33);
  cursor: not-allowed;
}

.form-control.transparent {
  background-color: transparent;
  border-color: transparent;
}

.form-control.transparent:focus {
  background-color: transparent;
  border-color: transparent;
}

/* Input Sizes
--------------------------------------------------
*/
.input-sm,
.form-horizontal .form-group-sm .form-control {
  font-size: 13px;
  min-height: 32px;
  padding: 7px 9px;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
  border-radius: 3px;
  font-size: 18px;
  min-height: 45px;
  padding: 11px 13px;
}

.input-xlg {
  min-height: 51px;
  font-size: 18px;
  line-height: 22px;
}

.input-group-lg > .form-control {
  padding: 6px 1rem;
}

.input-group-sm > .form-control {
  padding: 0 0.5rem;
  min-height: 32px;
}

.select2-lg .select2-container .select2-selection.select2-selection--single {
  height: 45px;
  padding: 11px 13px;
}

.select2-lg .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  padding-top: 0px;
  line-height: 22px;
  font-size: 18px;
}

.select2-lg .select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
  bottom: 9px;
}

.select2-sm .select2-container .select2-selection.select2-selection--single {
  height: 32px;
  padding: 8px 9px;
}

.select2-sm .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  padding-top: 0px;
  line-height: 16px;
  font-size: 13px;
}

.select2-sm .select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
  bottom: 4px;
}

.input-group-text {
  background-color: #fff;
  color: rgba(75, 75, 75, 0.67);
  font-size: 14px;
  line-height: 14px;
  border: 1px solid rgba(33, 33, 33, 0.14);
  border-top-color: rgba(33, 33, 33, 0.21);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
}

.input-group-text.primary {
  background-color: #7252D3;
  color: #fff;
}

.input-group-text.success {
  background-color: #19AD79;
  color: #fff;
}

.input-group-text.complete {
  background-color: #0072EC;
  color: #fff;
}

.input-group-text.info {
  background-color: #3B4752;
  color: #fff;
}

.input-group-text.warning {
  background-color: #FFd945;
  color: #4b4b4b;
}

.input-group-text.danger {
  background-color: #D83C31;
  color: #fff;
}

.input-group-text.transparent {
  background-color: transparent;
}

.input-group-append .input-group-text {
  border-left: 0;
}

.input-group-prepend .input-group-text {
  border-right: 0;
}

/* Form layouts
--------------------------------------------------
*/
@media only screen and (min-width: 540px) {
  form .row {
    margin-left: 0;
    margin-right: 0;
  }
  form .row [class*="col-sm-"]:not(:first-child), form .row [class*="col-sm-"]:not(:last-child) {
    padding-right: 7px;
    padding-left: 7px;
  }
  form .row [class*="col-sm-"]:first-child {
    padding-left: 0;
  }
  form .row [class*="col-sm-"]:last-child {
    padding-right: 0;
  }
}

@media only screen and (min-width: 720px) {
  form .row {
    margin-left: 0;
    margin-right: 0;
  }
  form .row [class*="col-"]:not(:first-child), form .row [class*="col-"]:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
  }
  form .row [class*="col-"]:first-child {
    padding-left: 0;
  }
  form .row [class*="col-"]:last-child {
    padding-right: 0;
  }
  form .row {
    margin-left: 0;
    margin-right: 0;
  }
  form .row [class*="col-md-"]:not(:first-child), form .row [class*="col-md-"]:not(:last-child) {
    padding-right: 7px;
    padding-left: 7px;
  }
  form .row [class*="col-md-"]:first-child {
    padding-left: 0;
  }
  form .row [class*="col-md-"]:last-child {
    padding-right: 0;
  }
}

@media only screen and (min-width: 960px) {
  form .row {
    margin-left: 0;
    margin-right: 0;
  }
  form .row [class*="col-lg-"]:not(:first-child), form .row [class*="col-lg-"]:not(:last-child) {
    padding-right: 7px;
    padding-left: 7px;
  }
  form .row [class*="col-lg-"]:first-child {
    padding-left: 0;
  }
  form .row [class*="col-lg-"]:last-child {
    padding-right: 0;
  }
}

@media only screen and (min-width: 1200px) {
  form .row {
    margin-left: 0;
    margin-right: 0;
  }
  form .row [class*="col-xl-"]:not(:first-child), form .row [class*="col-xl-"]:not(:last-child) {
    padding-right: 7px;
    padding-left: 7px;
  }
  form .row [class*="col-xl-"]:first-child {
    padding-left: 0;
  }
  form .row [class*="col-xl-"]:last-child {
    padding-right: 0;
  }
}

/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal .form-group {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 0;
}

.form-horizontal .form-group:last-child {
  border-bottom: none;
}

.form-horizontal .form-group:hover .control-label {
  opacity: 1;
  color: #212121;
}

.form-horizontal .form-group.focused .control-label {
  opacity: 1;
  color: #4b4b4b;
}

.form-horizontal .form-group .control-label {
  text-align: left;
  color: #4b4b4b;
  -webkit-transition: opacity ease 0.3s;
  transition: opacity ease 0.3s;
}

.form-horizontal .form-group .control-label.required:after {
  content: "Required";
  position: absolute;
  right: 0px;
  font-size: 12px;
  margin-right: 4px;
  color: rgba(117, 117, 117, 0.87);
}

.form-horizontal .radio {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.form-horizontal .control-label {
  padding-top: 7px;
  margin-bottom: 0;
}

/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached .form-group.form-group-default {
  border-radius: 0;
  margin-bottom: 0;
}

.form-group-attached > div {
  margin: 0;
}

.form-group-attached > div:first-child.row > [class*="col-"]:first-child .form-group-default {
  border-top-left-radius: 2px;
}

.form-group-attached > div:first-child.row > [class*="col-"]:last-child .form-group-default {
  border-top-right-radius: 2px;
}

.form-group-attached > div:first-child.form-group-default {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.form-group-attached > div:last-child.row > [class*="col-"]:first-child .form-group-default {
  border-bottom-left-radius: 2px;
}

.form-group-attached > div:last-child.row > [class*="col-"]:last-child .form-group-default {
  border-bottom-right-radius: 2px;
}

.form-group-attached > div:last-child.form-group-default {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.form-group-attached > div.row > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.form-group-attached > div.row > [class*="col-"]:not(:only-child):not(:last-child)
> .form-group-default {
  border-right-color: transparent;
}

.form-group-attached > div:not(:last-child) .form-group-default, .form-group-attached > div:not(:last-child).form-group-default {
  border-bottom-color: transparent;
}

/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
  .form-group-attached .form-group-default {
    border-right-color: rgba(33, 33, 33, 0.14) !important;
  }
}

@media only screen and (min-width: 768px) {
  .form-group-attached > div.row > [class*="col-"] .form-group {
    height: 100%;
    width: 100%;
  }
}

/* Form Groups
--------------------------------------------------
*/
.form-group {
  margin-bottom: 10px;
}

.form-group label:not(.error) {
  color: #4b4b4b;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.form-group label .help {
  margin-left: 8px;
}

.form-group .help {
  font-size: 12px;
  color: rgba(33, 33, 33, 0.67);
  letter-spacing: normal;
  line-height: 18px;
  display: block;
  margin-top: 6px;
  margin-left: 3px;
}

@keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-4px);
  }
  25%,
  58% {
    -webkit-transform: translateX(4px);
  }
  75% {
    -webkit-transform: translateX(-2px);
  }
  92% {
    -webkit-transform: translateX(2px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

.form-group-default {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position: relative;
  border: 1px solid rgba(33, 33, 33, 0.14);
  border-top-color: rgba(8, 7, 7, 0.14);
  border-radius: 2px;
  padding-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 4px;
  overflow: hidden;
  width: 100%;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  cursor: text;
}

.form-group-default.form-check-group {
  cursor: default;
}

.form-group-default.form-check-group label {
  cursor: pointer;
}

.form-group-default.required:after {
  color: #D83C31;
  content: "*";
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 2px;
}

.form-group-default.disabled {
  background: #fafafa;
  color: rgba(75, 75, 75, 0.23);
  cursor: not-allowed;
}

.form-group-default.disabled.focused {
  border: 1px solid #7252D3;
}

.form-group-default.disabled.focused label {
  opacity: 1;
}

.form-group-default.disabled label {
  opacity: 0.57;
  cursor: not-allowed;
}

.form-group-default.focused {
  border: 1px solid #7252D3 !important;
}

.form-group-default.focused label {
  opacity: 0.67;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 12px;
  letter-spacing: normal;
}

.form-group-default.has-error label {
  color: #b91e1e;
  animation-name: shake;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
}

.form-group-default.has-success .form-control-feedback, .form-group-default.has-error .form-control-feedback {
  display: none !important;
}

.form-group-default.has-success .form-control,
.form-group-default.has-success .form-control:focus,
.form-group-default.has-error .form-control,
.form-group-default.has-error .form-control:focus {
  border: none;
  box-shadow: none;
}

.form-group-default + .error {
  margin-top: -10px;
  padding: 3px 7px 2px 4px;
  display: flex;
  letter-spacing: normal;
  animation-name: show-msg;
  animation-duration: 450ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.form-group-default + .error:after {
  content: "";
  width: 0%;
  position: absolute;
  height: 1px;
  background-color: #D83C31;
  top: -1px;
  right: 0px;
  animation-name: show-line;
  animation-delay: 300ms;
  animation-duration: 350ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@keyframes show-msg {
  0% {
    height: 0px;
    opacity: 0;
  }
  100% {
    height: 25px;
    opacity: 1;
  }
}

@keyframes show-line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.form-group-default.input-group {
  padding: 0;
}

.form-group-default.input-group > label {
  margin-top: 6px;
  padding-left: 12px;
}

.form-group-default.input-group > label.inline {
  margin-top: 6px;
  float: left;
}

.form-group-default.input-group > .form-control {
  margin-top: -2px;
  margin-bottom: 3px;
  padding-left: 12px;
}

.form-group-default.input-group label {
  margin-top: 6px;
  padding-left: 12px;
}

.form-group-default.input-group .form-input-group {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.form-group-default.input-group .form-control {
  margin-top: -2px;
  margin-bottom: 3px;
  padding-left: 12px;
  width: 100%;
}

.form-group-default.input-group .input-group-text {
  border: 0;
  border-radius: 0;
  min-width: 50px;
}

.form-group-default.input-group .input-group-text i {
  width: 100%;
}

.form-group-default.input-group .btn {
  border-top: 0;
  border-bottom: 0;
}

.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group-default .form-control.error {
  color: #212121;
}

.form-group-default .form-control:focus {
  background: none;
}

.form-group-default .form-control:focus::-moz-placeholder {
  color: inherit;
  opacity: 0.77;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-group-default .form-control:focus:-ms-input-placeholder {
  color: inherit;
  opacity: 0.77;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-group-default .form-control:focus::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.77;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-group-default .form-control:focus::placeholder {
  color: inherit;
  opacity: 0.77;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.form-group-default .form-control:-webkit-autofill, .form-group-default .form-control.error:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.form-group-default .form-control:-webkit-autofill:focus, .form-group-default .form-control.error:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

.form-group-default textarea.form-control {
  padding-top: 5px;
}

.form-group-default label {
  margin: 0;
  display: block;
  opacity: 1;
  cursor: text;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.form-group-default label.label-lg {
  font-size: 13px;
  left: 13px;
  top: 9px;
}

.form-group-default label.label-sm {
  font-size: 11px;
  left: 11px;
  top: 6px;
}

.form-group-default label.highlight {
  opacity: 1;
}

.form-group-default label.fade {
  opacity: 0.5;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 12px;
  letter-spacing: normal;
}

.form-group-default > .input-lg {
  height: 29px;
  min-height: 29px;
  padding-left: 1px;
}

.form-group-default > .input-sm {
  min-height: 18px;
  height: 18px;
}

.form-group-default.form-group-default-select {
  overflow: visible;
}

.form-group-default.form-group-default-select .ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
  padding-top: 6px;
  height: 23px;
}

.form-group-default.form-group-default-select .ui-select-container .select2-choices {
  border: 0px;
}

.form-group-default.form-group-default-select2 {
  padding: 0;
}

.form-group-default.form-group-default-select2 > label {
  position: absolute;
  z-index: 10;
  padding: 5px 12px 0 9px;
  color: #757575;
}

.form-group-default.form-group-default-select2 > label.label-lg {
  left: 0;
  top: 0;
}

.form-group-default.form-group-default-select2 > label.label-sm {
  left: 0;
  top: 0;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single {
  padding-top: 20px;
  height: 52px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-arrow b:before {
  top: 20px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--single .select2-chosen {
  padding-left: 3px;
  padding-top: 1px;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--multiple {
  padding-top: 19px;
  padding-bottom: 0;
  min-height: 52px;
  border: 0px !important;
}

.form-group-default.form-group-default-select2 .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  margin-top: 5px;
}

.form-group-default.form-group-default-select2.input-lg {
  height: auto;
  padding: 0;
}

.form-group-default.form-group-default-select2.input-lg .select2-container .select2-selection--single {
  padding-top: 26px;
  height: 58px;
}

.form-group-default.form-group-default-select2.input-lg .select2-container .select2-selection--single .select2-selection__rendered {
  font-size: 18px;
}

.form-group-default.form-group-default-select2.input-sm {
  height: auto;
  padding: 0;
}

.form-group-default.form-group-default-select2.input-sm .select2-container .select2-selection--single {
  padding-top: 21px;
  height: 49px;
}

.form-group-default.form-group-default-select2.input-sm .select2-container .select2-selection--single .select2-selection__rendered {
  font-size: 13px;
}

.form-group-default.form-group-default-selectFx {
  padding: 0;
}

.form-group-default.form-group-default-selectFx > label {
  position: absolute;
  z-index: 10;
  padding: 7px 12px 0 12px;
}

.form-group-default.form-group-default-selectFx > label.label-lg {
  left: 0;
  top: 0;
}

.form-group-default.form-group-default-selectFx > label.label-sm {
  left: 0;
  top: 0;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-placeholder {
  padding-top: 28px;
  height: 52px;
  padding-left: 12px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select {
  height: auto;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select > span:after,
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select .cs-selected span:after {
  top: 39px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-lg .cs-placeholder {
  height: 60px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-sm .cs-placeholder {
  height: 50px;
}

.form-group-default.form-group-default-selectFx .cs-wrapper .dropdown-placeholder {
  vertical-align: top;
}

.form-group-default .input-group-append .input-group-text:before {
  content: "";
  width: 1px;
  height: 80%;
  top: 0px;
  left: -10px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.16);
}

.form-group-default .input-group-prepend .input-group-text:before {
  content: "";
  width: 1px;
  height: 80%;
  top: 0px;
  right: -10px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.16);
}

/* Form validation
--------------------------------------------------
*/
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #0d935b;
}

.has-success .form-control {
  border-color: #0d935b;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-success .form-control:focus {
  border-color: #09643e;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-success .form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-success .input-group-addon {
  background: #f4f4f4;
  border: 1px solid rgba(33, 33, 33, 0.14);
  color: rgba(75, 75, 75, 0.47);
}

.has-success .form-control-feedback {
  color: #0d935b;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #daab2d;
}

.has-warning .form-control {
  border-color: #daab2d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .form-control:focus {
  border-color: #b48c20;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-warning .input-group-addon {
  background: #f4f4f4;
  border: 1px solid rgba(33, 33, 33, 0.14);
  color: rgba(75, 75, 75, 0.47);
}

.has-warning .form-control-feedback {
  color: #daab2d;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #D83C31;
}

.has-error .form-control {
  border-color: #D83C31;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .form-control:focus {
  border-color: #b42c22;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset, 0 0 0 1px rgba(0, 0, 0, 0.1), 0 -1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.has-error .input-group-addon {
  background: #f4f4f4;
  border: 1px solid rgba(33, 33, 33, 0.14);
  color: rgba(75, 75, 75, 0.47);
}

.has-error .form-control-feedback {
  color: #D83C31;
}

label.error {
  font-size: 12px;
  color: #b91e1e;
  display: block;
}

.validation-popover {
  background: #000;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
}

.validation-popover .popover-body {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.4;
  color: #fff;
}

.validation-popover > .arrow::after {
  border-top-color: #000;
}

/* Plugins
--------------------------------------------------
Datepicker
https://github.com/eternicode/bootstrap-datepicker
*/
.datepicker {
  padding: 9px;
  border-radius: 2px;
  font-size: 12px;
}

.datepicker.dropdown-menu {
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
}

.datepicker:after {
  border-bottom-color: #fff;
}

.datepicker thead tr .datepicker-switch {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.006em;
}

.datepicker thead tr .next,
.datepicker thead tr .prev {
  color: #7252D3;
  content: "";
  font-size: 0px;
}

.datepicker thead tr .next:before,
.datepicker thead tr .prev:before {
  color: #7252D3;
  font-family: "pages-icon";
  font-size: 18px;
}

.datepicker thead tr .prev:before {
  content: "\e989";
}

.datepicker thead tr .next:before {
  content: "\e988";
}

.datepicker thead tr .dow {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #212121;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  border-bottom: 1px solid #d0d0d0;
}

.datepicker thead tr th {
  width: 34px;
  height: 29px;
}

.datepicker table {
  height: 250px;
}

.datepicker table tr td {
  padding-bottom: 14px;
  width: 31px;
  height: 29px;
}

.datepicker table tr td.day {
  border-top: 1px solid rgba(33, 33, 33, 0.14);
  position: relative;
  color: #212121;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.datepicker table tr td.day:hover:not(.active) {
  background-color: transparent;
  z-index: 1;
}

.datepicker table tr td.day:hover:not(.active):before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 0px 1px #757575;
  -moz-box-shadow: 0px 0px 0px 1px #757575;
  box-shadow: 0px 0px 0px 1px #757575;
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  z-index: -1;
}

.datepicker table tr td.active {
  background-color: transparent !important;
  z-index: 1;
  cursor: default !important;
  border-color: rgba(33, 33, 33, 0.14) !important;
}

.datepicker table tr td.active:before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: #7252D3;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  z-index: -1;
}

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
  background-image: none;
  text-shadow: none;
  font-weight: 600;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background-color: #f4f4f4;
  background-image: none;
  color: #fff;
}

.datepicker table tr td.disabled {
  color: rgba(33, 33, 33, 0.62);
  font-weight: 500;
  position: relative;
  opacity: 0.78;
}

.datepicker table tr td.disabled:before {
  display: none !important;
}

.datepicker table tr td.disabled:after {
  content: "";
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 2px solid #fff;
  position: absolute;
  top: 1px;
  left: 1px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAYUExURQAAAEdwTAAAAAAAAAAAAAAAAAAAAAAAAKkycPQAAAAIdFJOU0AACuuWFpQXdpvuhwAAAFRJREFUCNc1zLEJgEAAQ9GIC9y3sVUQa0ewsdcVBFdwfsNd7D4PEnXwzlA2wXgDvfRTUahzNaIo5Aq5QkwKDbtCy6nQ4ZdGl6vS422l9XKFXCFXCH14sRAYdHQ86wAAAABJRU5ErkJggg==);
  background-repeat: repeat;
  background-size: 17px 15px;
  opacity: 0.47;
}

.datepicker table tr td.disabled:hover {
  background-color: transparent;
  cursor: default;
  color: #616161;
}

.datepicker table tr td.old, .datepicker table tr td.new {
  color: #757575;
  opacity: 0.67;
  font-weight: 400;
  border-top: 1px solid transparent;
  pointer-events: none;
}

.datepicker table tr td.old:after, .datepicker table tr td.new:after {
  opacity: 0;
}

.datepicker table tr td.old:before, .datepicker table tr td.new:before {
  display: none;
}

.datepicker table tr td span {
  border-radius: 1px;
  width: 48px;
  height: 42px;
  line-height: 40px;
  font-weight: 500;
  font-size: 12px;
  border-top: 1px solid rgba(33, 33, 33, 0.14);
  margin: 0;
}

.datepicker table tr td span.active {
  background-color: #7252D3 !important;
}

.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
  background-image: none;
  border: none;
  text-shadow: none;
}

.datepicker table tbody tr td {
  padding-bottom: 6px;
}

.datepicker table tbody tr .odd {
  color: #d0d3d8;
}

.datepicker table tbody tr:first-child td.day {
  padding-top: 14px;
  padding-bottom: 6px;
}

.datepicker table tbody tr:first-child td.day:hover:before {
  top: 12px;
  left: 4px;
}

.datepicker.dropdown-menu {
  border-color: #e0e0e0;
  color: #4b4b4b;
}

.datepicker.datepicker-dropdown.datepicker-orient-bottom:before {
  border-color: #e0e0e0;
}

.datepicker-inline {
  width: auto;
}

.input-daterange .input-group-addon {
  text-shadow: none;
  border: 0;
  margin: 0;
}

/* Timepicker
https://github.com/m3wolf/bootstrap3-timepicker
*/
.bootstrap-timepicker-widget table td a i {
  font-size: 12px;
}

.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget .bootstrap-timepicker-widget input {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.bootstrap-timepicker-widget.dropdown-menu {
  background: #fff;
}

/* Daterangepicker
http://www.dangrossman.info/
*/
.daterangepicker {
  background: #fff;
}

.daterangepicker .calendar .calendar-date {
  border: 1px solid rgba(33, 33, 33, 0.14);
}

.daterangepicker .calendar .prev,
.daterangepicker .calendar .next,
.daterangepicker .calendar th {
  color: #7252D3;
  text-transform: uppercase;
  font-size: 11px;
}

.daterangepicker .calendar .month {
  color: #6f7b8a;
  font-size: 13px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #7252D3;
  border-color: #7252D3;
}

/* Select2
http://ivaynberg.github.io/select2/
*/
.form-group-default .select2-container .select2-selection,
.select2-container-multi .select2-choices {
  border-color: transparent;
}

select.full-width + .select2-container {
  width: 100% !important;
}

.select2-container *:focus {
  outline: none;
}

.select2-container .select2-selection {
  background-image: none;
  border-radius: 2px;
  border: 1px solid rgba(33, 33, 33, 0.14);
  padding: 2px 9px;
  transition: border 0.2s linear 0s;
  min-height: 35px;
  display: flex;
  align-items: center;
}

.select2-container .select2-selection .select2-selection__rendered {
  padding: 0;
  display: flex;
  align-items: center;
}

.select2-container .select2-selection .select2-selection__rendered .select2-selection__clear {
  font-weight: normal;
  right: 16px;
  width: 20px;
  text-align: center;
  color: #888;
}

.select2-container .select2-selection .select2-selection__arrow {
  top: auto;
  bottom: 5px;
  right: 10px !important;
  left: auto;
  background: transparent;
  border-left: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.select2-container .select2-selection.select2-selection--single {
  height: 35px;
}

.select2-container .select2-selection > .select2-chosen {
  margin-right: 26px;
  margin-left: 0;
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: transparent;
}

.select2-container--default .select2-results__group {
  color: #212121;
}

.select2-search input,
.select2-search-choice-close,
.select2-container .select2-choice abbr,
.select2-container .select2-choice .select2-arrow b {
  background-image: none !important;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
  border: 1px solid rgba(33, 33, 33, 0.14);
  background: #f4f4f4;
}

.select2-dropdown-open .select2-choice .select2-arrow {
  -webkit-transform: scale(scale(1, -1));
  -ms-transform: scale(scale(1, -1));
  transform: scale(scale(1, -1));
}

.select2-dropdown {
  border: 1px solid rgba(33, 33, 33, 0.14);
  border-bottom: none;
  border-radius: 2px;
  padding-top: 0px;
}

.select2-dropdown.select2-drop-above {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: none;
}

.select2-container--open .select2-selection {
  box-shadow: none;
  border: 1px solid #7252D3 !important;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.select2-container--open .select2-dropdown {
  top: 1px;
}

.select2-search--dropdown {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
}

.select2-search--dropdown input {
  background: #fff !important;
  vertical-align: baseline;
  line-height: 28px;
  border-radius: 2px;
  border: none;
  font-size: 12px;
  border: 1px solid rgba(33, 33, 33, 0.14) !important;
}

.select2-results {
  margin: 3px 8px 10px 8px;
}

.select2-results li {
  color: #4b4b4b;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  color: #212121;
}

.select2-results li.select2-result-with-children > .select2-result-label:first-child {
  padding-top: 8px;
}

.select2-results .select2-results__option--highlighted {
  background-color: #f4f4f4 !important;
  border-radius: 3px;
  color: #4b4b4b !important;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: none;
  color: #4b4b4b;
  font-size: 12px;
  padding-left: 8px;
  padding-top: 0px;
  position: relative;
  top: -5px;
}

.select2-dropdown {
  border: 1px solid rgba(33, 33, 33, 0.14);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-top: 5px;
  z-index: 790;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-container--open
.select2-selection--multiple
.select2-selection__choice {
  background-color: #fff !important;
}

.select2-selection--multiple .select2-selection__rendered {
  background-image: none;
}

.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  background-color: #e0e0e0;
  background-image: none;
  border: none;
  box-shadow: none;
  color: inherit;
  border-radius: 99px;
  margin: 0px 6px 0px 0;
  padding: 0 8px 0 7px;
  line-height: 21px;
}

.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  color: #626262;
  font-weight: 900;
  margin-right: 4px;
  position: relative;
  top: -1px;
}

.select2-selection--multiple.select2-container-active .select2-choices {
  border: 1px solid rgba(33, 33, 33, 0.14);
  box-shadow: none;
}

.select2-selection--multiple .select2-search-choice-close {
  left: 6px;
}

.select2-search-choice-close {
  background: none;
  top: 4px;
  right: 0;
}

.select2-search-choice-close:hover {
  text-decoration: none;
}

.select2-drop-multi .select2-results .select2-no-results,
.select2-drop-multi .select2-results .select2-searching,
.select2-drop-multi .select2-results .select2-selection-limit {
  top: 0px;
}

.select2.form-control {
  padding: 0;
  box-shadow: none;
  border: 0;
}

.select2-drop-mask {
  z-index: 700;
}

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background: #f4f4f4;
  border-radius: 3px;
  color: #4b4b4b;
}

.ui-select-bootstrap > .ui-select-choices {
  background-color: #fff;
}

.ui-select-choices-group-label {
  color: #212121;
  font-weight: bold;
}

.modal-open .select2-drop-active {
  z-index: 1051;
}

.modal-open .select2-container {
  z-index: 1051;
}

.modal-open .datepicker.dropdown-menu {
  z-index: 1051 !important;
}

.modal-open .select2-drop-mask {
  z-index: 1050;
}

.modal-open .cs-skin-slide.cs-active {
  z-index: 1050;
}

.dropdown-placeholder {
  display: inline-block;
  vertical-align: middle;
}

.dropdown-mask {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0 none;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 600;
}

.ui-select-bootstrap *:focus {
  outline: none;
}

.ui-select-bootstrap .ui-select-choices-row > span {
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 24px;
  font-size: 14px;
}

.ui-select-bootstrap .ui-select-match {
  box-shadow: none;
}

.ui-select-bootstrap .ui-select-choices-group-label {
  padding-left: 15px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  color: #626262;
}

.ui-select-bootstrap .ui-select-match.btn-default-focus {
  background-color: transparent;
}

.form-group-default-select {
  padding-bottom: 2px;
}

.form-group-default-select .ui-select-bootstrap .ui-select-dropdown {
  width: calc(100% + 24px) !important;
  margin-left: -12px;
  padding: 10px;
}

.form-group-default-select .ui-select-bootstrap .ui-select-match-text {
  width: calc(100% + 24px);
  margin-top: -17px;
  margin-left: -16px;
  padding: 16px 16px 0;
}

.form-group-default-select .ui-select-bootstrap .ui-select-match {
  padding-top: 2px !important;
}

.form-group-default-select .ui-select-bootstrap .ui-select-match i.caret {
  margin-top: 10px;
}

.form-group-default-select .ui-select-bootstrap .ui-select-match.btn-default-focus {
  outline: none;
}

.form-group-default-select .ui-select-bootstrap .ui-select-match > .ui-select-toggle {
  width: calc(100% + 27px);
  height: 50px;
  margin-top: -34px;
  margin-left: -16px;
  padding: 32px 16px 16px;
}

.form-group-default-select .ui-select-bootstrap .dropdown-menu .divider {
  display: none;
}

.form-group-default-select .ui-select-bootstrap.ui-select-container {
  margin-top: -3px;
  padding-bottom: 2px;
}

.form-group-default-select .ui-select-multiple {
  margin-top: -7px;
}

.form-group-default-select .ui-select-multiple .select2-search-field input {
  height: 23px !important;
}

.form-group-default-select .ui-select-multiple .select2-search-choice {
  margin-top: 2px;
  margin-bottom: 0;
}

.form-group-default-select .ui-select-multiple .select2-search-choice:first-child {
  margin-left: 0;
}

.form-group-default-select .select2-container-multi .select2-choices {
  background-color: transparent;
}

/* Bootstrap Tags input
https://github.com/timschlechter/bootstrap-tagsinput
*/
.form-group-default .bootstrap-tagsinput {
  border: 0px;
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 0;
  vertical-align: top;
  padding-bottom: 0px;
  padding-left: 0;
  margin-top: -3px;
}

.form-group-default .bootstrap-tagsinput .tag {
  padding: 2px 9px;
  line-height: 18px;
  margin-bottom: 2px;
  margin-left: 0;
  margin-right: 2px;
}

.form-group-default .bootstrap-tagsinput input {
  min-height: 20px;
}

.form-group-default .bootstrap-tagsinput input:first-child {
  margin-left: 0;
}

.bootstrap-tagsinput {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  background-color: transparent;
  border: 1px solid rgba(33, 33, 33, 0.14);
  border-radius: 4px;
  padding: 0;
  padding-top: 5px;
  padding-right: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.bootstrap-tagsinput.active-element {
  background-color: #e0e0e0;
}

.bootstrap-tagsinput input {
  border: none;
  margin: 5px;
  margin-top: 0;
  margin-right: 0;
  min-height: 25px;
  min-width: 10em !important;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex-shrink: 0;
  -webkit-flex-grow: 1;
  -webkit-flex-basis: auto;
}

.bootstrap-tagsinput .tag {
  padding: 3px 9px;
  padding-right: 6px;
  border-radius: 3px;
  line-height: 18px;
  display: flex;
  margin: 5px;
  margin-top: 0;
  margin-right: 0;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.bootstrap-tagsinput .tag[data-role="remove"]:hover:active,
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: none;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  font-family: "pages-icon";
  content: "\e966";
  padding: 0;
  font-size: 14px;
}

/* Quill Editor v1.3.6
https://github.com/HackerWins/summernote
*/
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
  pointer-events: none;
}

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li {
  list-style-type: none;
}

.ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li::before {
  content: "\2022";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *,
.ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li::before,
.ql-editor ul[data-checked="false"] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li::before {
  content: "\2611";
}

.ql-editor ul[data-checked="false"] > li::before {
  content: "\2610";
}

.ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}

.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: 0.75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.ql-snow {
  box-sizing: border-box;
}

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover,
.ql-snow .ql-toolbar button:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type="file"],
.ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

.ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
  stroke-width: 1;
}

.ql-snow .ql-transparent {
  opacity: 0.4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: 0.83em;
}

.ql-snow .ql-editor h6 {
  font-size: 0.67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  font-size: 85%;
  padding: 2px 4px;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}

.ql-snow .ql-picker-label::before {
  display: inline-block;
  line-height: 22px;
}

.ql-snow .ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
}

.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0px;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0px;
  width: 16px;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  position: absolute;
  margin-top: -9px;
  right: 0;
  top: 50%;
  width: 18px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""])::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""])::before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "Heading 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "Heading 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "Heading 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "Heading 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "Heading 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  font-size: 0.83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  font-size: 0.67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active),
  .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
}

.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}

.ql-snow .ql-tooltip::before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px;
}

.ql-snow .ql-tooltip input[type="text"] {
  display: none;
  border: 1px solid #ccc;
  font-size: 13px;
  height: 26px;
  margin: 0px;
  padding: 3px 5px;
  width: 170px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.ql-snow .ql-tooltip a.ql-action::after {
  border-right: 1px solid #ccc;
  content: "Edit";
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "Save";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]::before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "Enter video:";
}

.ql-snow a {
  color: #06c;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}

#quill {
  height: 200px;
}

/* Pages SelectFx */
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}

.cs-select select {
  display: none;
}

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Placeholder and selected option */
.cs-select > span {
  padding-right: 3em;
}

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
  content: "\25BE";
  right: 1em;
}

.cs-select .cs-selected span::after {
  content: "\2713";
  margin-left: 1em;
}

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cs-select ul span {
  padding: 1em;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

div.cs-skin-slide {
  color: #fff;
  /*font-size: 1.5em;*/
  width: 300px;
}

@media screen and (max-width: 30em) {
  div.cs-skin-slide {
    font-size: 1em;
    width: 250px;
  }
}

div.cs-skin-slide::before {
  content: "";
  background: #282b30;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1.1, 3.5, 1);
  transform: scale3d(1.1, 3.5, 1);
}

.cs-skin-slide > span {
  height: 80px;
  line-height: 32px;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s;
}

@media screen and (max-width: 30em) {
  .cs-skin-slide > span {
    height: 60px;
    line-height: 28px;
  }
}

.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0;
}

.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.cs-skin-slide .cs-options {
  background: transparent;
  width: 70%;
  height: 400%;
  padding: 1.9em 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate(-50%, -50%);
}

@media screen and (max-width: 30em) {
  .cs-skin-slide .cs-options {
    padding-top: 3em;
  }
}

.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

/* more options need more delay declaration */
.cs-skin-slide .cs-options li span {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 65%;
  padding: 0.8em 1em 0.8em 2.5em;
}

.cs-skin-slide .cs-options li span:hover,
.cs-skin-slide .cs-options li.cs-focus span,
.cs-skin-slide .cs-options li.cs-selected span {
  color: #eb7e7f;
  background: transparent;
}

.cs-skin-slide .cs-selected span::after {
  content: "";
}

/* Pages Select  overriding */
.form-group-default .cs-skin-slide > span {
  padding: 0 30px 0 0;
  height: 22px;
  line-height: 21px;
}

.form-group-default .cs-wrapper {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-wrapper {
  display: inline-block;
}

.form-control.cs-select:not(.cs-active) {
  width: 100% !important;
}

.cs-select {
  background-color: transparent;
}

.cs-select span {
  text-overflow: initial;
}

.cs-select .cs-placeholder {
  width: 100%;
}

div.cs-skin-slide {
  width: auto;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, sans-serif;
  color: #5e5e5e;
}

div.cs-skin-slide:before {
  background-color: transparent;
}

div.cs-skin-slide.cs-transparent {
  background: none;
}

div.cs-skin-slide.cs-transparent .cs-backdrop {
  border-color: transparent;
  background: none;
}

div.cs-skin-slide.cs-transparent.cs-active .cs-backdrop {
  background: #fff;
}

div.cs-skin-slide > span {
  height: 35px;
  padding: 6px 33px 6px 17px;
  line-height: 23px;
  z-index: 1;
}

div.cs-skin-slide.cs-active {
  z-index: 790;
}

div.cs-skin-slide.cs-active:before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

div.cs-skin-slide.cs-active .cs-backdrop {
  border: transparent;
  background: #fff;
  box-shadow: -1px 0 1px #ccc, 1px 0 1px #ccc;
}

div.cs-skin-slide > span:after,
div.cs-skin-slide.cs-active > span:after {
  content: "\e98b";
  font-family: "pages-icon";
  color: #5e5e5e;
  font-size: 23px;
  position: absolute;
  right: 7px;
}

div.cs-skin-slide .cs-options {
  height: auto;
  padding: 9px 0;
  width: auto;
  padding: 10px;
  max-height: 350px;
  transform: translate3d(1, 1, 1);
  overflow: hidden;
  z-index: 1;
}

div.cs-skin-slide .cs-options ul {
  width: 100%;
  display: table;
}

div.cs-skin-slide .cs-options ul li {
  display: table-row;
}

div.cs-skin-slide .cs-options ul li span {
  display: table-cell;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  padding: 5px 0;
  text-transform: none;
  max-height: 350px;
  overflow-y: auto;
}

div.cs-skin-slide .cs-options ul li span:hover,
div.cs-skin-slide .cs-options ul li.cs-focus span,
div.cs-skin-slide .cs-options ul li.cs-selected span {
  color: #212121;
}

.cs-backdrop {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid rgba(33, 33, 33, 0.14);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  transition-delay: 0.3s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  transition-delay: 0.35s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(8) {
  transition-delay: 0.4s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(9) {
  transition-delay: 0.45s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(10) {
  transition-delay: 0.5s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(11) {
  transition-delay: 0.55s;
}

.cs-input-group-addon {
  background: #f4f4f4;
  border: 1px solid rgba(33, 33, 33, 0.14);
  padding: 6px 10px;
}

.twitter-typeahead {
  width: 100%;
}

.tt-menu {
  width: 100%;
  padding: 3px 10px 3px 3px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-top: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.tt-suggestion {
  color: #4b4b4b;
  padding: 3px 7px 4px;
  padding-left: 26px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  background: #f4f4f4;
}

.tt-suggestion.tt-cursor {
  background: #f4f4f4;
}

.tt-suggestion p {
  margin: 0;
}

.form-group-default.typehead {
  overflow: inherit;
}

.form-group-default .tt-menu {
  width: calc(100% + 24px);
  margin: 4px -12px;
}

.dropzone {
  overflow: hidden;
}

.dropzone .dz-default.dz-message {
  width: 100%;
}

/* Dropzone for Angular */
.dropzone[dropzone="dropzone"] input[type="file"] {
  visibility: hidden;
}

/* end overrides */
/* Demo specifc classes */
.demo-form-wysiwyg {
  height: 250px;
}

.error-form .form-group-default {
  padding: 0;
  justify-content: space-between;
}

.error-form .form-group-default label {
  margin-top: 6px;
  padding-left: 12px;
}

.error-form .form-group-default .form-control {
  margin-top: -2px;
  margin-bottom: 3px;
  padding-left: 12px;
}

.error-container.bottom-container {
  margin-top: 0;
}

.h-c-50 {
  height: calc(50px);
}

.card-toolbar .note-editor .btn-toolbar {
  display: block;
}

/*------------------------------------------------------------------
[16. Tables and Datatables]
*/
/* Generic Tables 
------------------------------------
*/
.table {
  margin-bottom: 0;
  margin-top: 5px;
}

.table thead tr th {
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 10.5px;
  letter-spacing: 0.06em;
  padding-top: 14px;
  padding-bottom: 14px;
  vertical-align: middle;
  border-bottom: 1px solid rgba(224, 224, 224, 0.7);
  color: #4b4b4b;
  border-top: none;
}

.table thead tr th[class*="sorting_"]:not([class="sorting_disabled"]) {
  color: #4b4b4b;
}

.table thead tr th:first-child {
  padding-left: 18px !important;
}

.table thead tr th .btn {
  margin-top: -20px;
  margin-bottom: -20px;
}

.table tbody tr td {
  background: #fff;
  color: #4b4b4b;
  border-bottom: 1px solid rgba(224, 224, 224, 0.7);
  border-top: 0px;
  padding: 20px;
  font-size: 13.5px;
}

.table tbody tr td .btn-tag {
  margin: 5px;
}

.table tbody tr td .btn-tag:hover {
  background: rgba(33, 33, 33, 0.15);
}

.table tbody tr td[class*="sorting_"] {
  color: #4b4b4b;
}

.table tbody tr td .checkbox label::after {
  left: 0.5px;
}

.table tbody tr.selected td {
  background: #fffde1;
  color: #4b4b4b;
}

.table.table-hover tbody tr:hover td {
  background: #d3eeff !important;
  color: #4b4b4b;
}

.table.table-hover tbody tr.selected:hover td {
  background: #fffde1 !important;
  color: #4b4b4b;
}

.table.table-striped tbody tr td {
  background: #f4f4f4 !important;
}

.table.table-striped tbody tr:nth-child(2n + 1) td {
  background: #fff !important;
}

.table.table-borderless tbody tr td {
  border-top: 0;
}

.table.table-condensed {
  table-layout: fixed;
}

.table.table-condensed thead tr th {
  padding-left: 20px;
  padding-right: 20px;
}

.table.table-condensed tbody tr td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.table.table-condensed thead tr th,
.table.table-condensed tbody tr td,
.table.table-condensed tbody tr td *:not(.dropdown-default) {
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table.table-condensed thead tr th.reset-overflow *,
.table.table-condensed tbody tr td.reset-overflow *,
.table.table-condensed tbody tr td *:not(.dropdown-default).reset-overflow * {
  overflow: initial !important;
}

.table.table-condensed.table-detailed > tbody > tr.shown > td {
  background: #fffde1;
}

.table.table-condensed.table-detailed > tbody > tr.shown > td:first-child:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.table.table-condensed.table-detailed > tbody > tr.shown + tr > td {
  background: rgba(244, 244, 244, 0.4);
  padding: 0px 40px;
}

.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline {
  margin-top: 13px;
  margin-bottom: 13px;
  background: transparent;
}

.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline tr,
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline td {
  background: transparent;
  font-weight: 600;
}

.table.table-condensed.table-detailed > tbody > tr.row-details > td:first-child:before {
  content: "";
  display: none;
}

.table.table-condensed.table-detailed > tbody > tr > td:hover {
  cursor: pointer;
}

.table.table-condensed.table-detailed > tbody > tr > td:first-child:before {
  content: "\e988";
  display: inline-block;
  font-size: 16px;
  top: -1px;
  position: relative;
  margin-right: 8px;
  font-family: "pages-icon";
  -webkit-transition: all 0.12s linear;
  transition: all 0.12s linear;
}

.table.table-condensed.table-detailed .table-inline td {
  border: none;
  text-align: left;
}

.table.table-borderless > tbody > tr > td {
  border-bottom: 0px;
}

.fht-table {
  margin-bottom: 0 !important;
}

/* Data-tables 
------------------------------------
*/
.table.dataTable.no-footer {
  border: none;
}

.dataTables_scroll:hover .dataTables_scrollBody:before {
  content: "";
  top: 0;
  height: 0;
}

.dataTables_scrollBody {
  overflow-y: auto;
  border: none !important;
}

.dataTables_scrollBody:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
}

.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: 0.5;
}

.dataTables_wrapper .dataTables_paginate ul > li > a {
  padding: 5px 10px;
  color: #4b4b4b;
  opacity: 0.35;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.dataTables_wrapper .dataTables_paginate ul > li > a:hover {
  opacity: 0.65;
}

.dataTables_wrapper .dataTables_paginate ul > li.next > a,
.dataTables_wrapper .dataTables_paginate ul > li.prev > a {
  opacity: 1;
}

.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: 0.35;
}

.dataTables_wrapper .dataTables_paginate ul > li.disabled a:hover {
  opacity: 0.35;
}

.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  margin-top: 25px !important;
}

.dataTables_paginate.paging_bootstrap.pagination {
  padding-top: 0;
  padding-right: 20px;
}

.dataTables_wrapper .dataTables_info {
  clear: none;
  font-size: 12px;
  padding: 0 33px;
  color: #4b4b4b;
}

.dataTables_wrapper .dataTables_paginate ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
}

.dataTables_scrollHeadInner {
  padding-right: 0 !important;
}

.export-options-container {
  position: relative;
}

.dataTables_wrapper .dataTables_paginate ul > li.active > a {
  font-weight: bold;
  color: #4b4b4b;
  opacity: 1;
}

.export-options-container a {
  color: inherit;
  opacity: 1;
}

.exportOptions .DTTT.btn-group a {
  display: block !important;
}

table.dataTable thead .sorting_asc:after {
  background-image: url("../img/icons/sort_asc.png");
}

table.dataTable thead .sorting_desc:after {
  background-image: url("../img/icons/sort_desc.png");
}

table.dataTable thead .sorting:after {
  background-image: url("../img/icons/sort_both.png");
}

table.dataTable thead .sorting_asc_disabled:after {
  background-image: url("../img/icons/sort_asc_disabled.png");
}

table.dataTable thead .sorting_desc_disabled:after {
  background-image: url("../img/icons/sort_desc_disabled.png");
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px;
  content: "";
  width: 19px;
  height: 19px;
  background-position: center center;
  opacity: 1;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 12px;
}

/* Demo classes */
.demo-table-search thead th:nth-child(1) {
  width: 20%;
}

.demo-table-search thead th:nth-child(2) {
  width: 22%;
}

.demo-table-search thead th:nth-child(3) {
  width: 24%;
}

.demo-table-search thead th:nth-child(4) {
  width: 15%;
}

.demo-table-search thead th:nth-child(5) {
  width: 19%;
}

.demo-table-dynamic thead th:nth-child(1) {
  width: 25%;
}

.demo-table-dynamic thead th:nth-child(2) {
  width: 30%;
}

.demo-table-dynamic thead th:nth-child(3) {
  width: 20%;
}

.demo-table-dynamic thead th:nth-child(4) {
  width: 25%;
}

.table-invoice th {
  border-top: 0;
}

.dataTables_wrapper .row {
  width: 100%;
}

.dataTables_wrapper .row > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dataTables_wrapper#tableWithDynamicRows_wrapper > div {
  width: 100%;
}

/* Responsive Handlers : Tables */
@media (max-width: 991px) {
  .dataTables_wrapper .dataTables_info {
    float: left;
  }
  .dataTables_paginate.paging_bootstrap.pagination {
    float: right;
  }
}

@media (max-width: 767px) {
  .table-responsive {
    border: 1px solid #ddd;
  }
  .dataTables_wrapper .row {
    margin: 0;
  }
  .dataTables_wrapper .row > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0 !important;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
  }
  .dataTables_wrapper .dataTables_info ul,
  .dataTables_wrapper .dataTables_paginate ul {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: left;
    clear: both;
    display: block;
  }
}

/*------------------------------------------------------------------
[17. Charts]
*/
.line-chart .nvd3 line.nv-guideline {
  /* Vertical bar on hover in interactive chart */
  stroke-width: 30px;
  stroke-opacity: 0.04;
  stroke: #000;
}

.line-chart .nvd3 .nv-groups path.nv-area {
  /*  filled area */
  fill-opacity: 0.3;
}

.line-chart .nvd3 .nv-axis line {
  /*  Line */
  stroke-opacity: 0.56;
  stroke: rgba(75, 75, 75, 0.19);
}

.line-chart .nvd3 .nv-axis line {
  /*  grid lines */
  stroke-opacity: 0.5;
}

.line-chart[data-x-grid="false"] .nv-x .tick line {
  display: none;
}

.line-chart[data-y-grid="false"] .nv-y .tick line {
  display: none;
}

.line-chart .domain {
  /* domain */
  opacity: 0;
}

.line-chart[data-points="true"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  /* Toggle points */
  fill-opacity: 1;
  stroke-opacity: 0.5;
}

.line-chart .nvtooltip table td.legend-color-guide div {
  border-radius: 100px;
}

.line-chart thead .nv-pointer-events-none strong {
  color: #989797;
}

.line-chart .nv-pointer-events-none {
  font-size: 12px;
}

.line-chart .nv-pointer-events-none .value.nv-pointer-events-none {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 11px;
  color: #989797;
}

.line-chart .nvtooltip table {
  margin: 12px 10px 14px 15px;
}

.line-chart .nvtooltip.xy-tooltip.nv-pointer-events-none {
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.line-chart[data-stroke-width="1"] .nvd3 .nv-groups path.nv-line {
  /* Line widths */
  stroke-width: 1px;
}

.line-chart[data-stroke-width="1"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  /* Line widths */
  stroke-width: 2px;
}

.line-chart[data-stroke-width="2"] .nvd3 .nv-groups path.nv-line {
  stroke-width: 2px;
}

.line-chart[data-stroke-width="2"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke-width: 3px;
}

.line-chart[data-stroke-width="3"] .nvd3 .nv-groups path.nv-line {
  stroke-width: 3px;
}

.line-chart[data-stroke-width="3"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke-width: 4px;
}

.line-chart .tick text,
.line-chart .nvd3 .nv-axis .nv-axisMaxMin text {
  fill: #757575;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
}

.line-chart .nvd3.nv-scatter .nv-groups .nv-point.hover,
.line-chart .nvd3 .nv-groups .nv-point.hover {
  fill: inherit !important;
  stroke: inherit !important;
}

/* Line widths
------------------------------------
*/
.line-chart[data-line-color="master"] .nvd3 line.nv-guideline, .line-chart[data-line-color="master"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="master"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #4b4b4b;
}

.line-chart[data-line-color="success"] .nvd3 line.nv-guideline, .line-chart[data-line-color="success"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="success"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #19AD79;
}

.line-chart[data-line-color="primary"] .nvd3 line.nv-guideline, .line-chart[data-line-color="primary"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="primary"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #7252D3;
}

.line-chart[data-line-color="info"] .nvd3 line.nv-guideline, .line-chart[data-line-color="info"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="info"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #3B4752;
}

.line-chart[data-line-color="complete"] .nvd3 line.nv-guideline, .line-chart[data-line-color="complete"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="complete"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #0072EC;
}

.line-chart[data-line-color="warning"] .nvd3 line.nv-guideline, .line-chart[data-line-color="warning"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="warning"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #FFd945;
}

.line-chart[data-line-color="danger"] .nvd3 line.nv-guideline, .line-chart[data-line-color="danger"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="danger"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #D83C31;
}

.line-chart[data-line-color="white"] .nvd3 line.nv-guideline, .line-chart[data-line-color="white"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="white"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: rgba(255, 255, 255, 0.36);
}

.line-chart[data-line-color="black"] .nvd3 line.nv-guideline, .line-chart[data-line-color="black"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="black"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #000;
}

/* Area Fill colors
------------------------------------
*/
.line-chart[data-area-color="master"] .nvd3 .nv-groups path.nv-area {
  fill: #4b4b4b;
}

.line-chart[data-area-color="success"] .nvd3 .nv-groups path.nv-area {
  fill: #19AD79;
}

.line-chart[data-area-color="info"] .nvd3 .nv-groups path.nv-area {
  fill: #3B4752;
}

.line-chart[data-area-color="complete"] .nvd3 .nv-groups path.nv-area {
  fill: #0072EC;
}

.line-chart[data-area-color="primary"] .nvd3 .nv-groups path.nv-area {
  fill: #7252D3;
}

.line-chart[data-area-color="warning"] .nvd3 .nv-groups path.nv-area {
  fill: #FFd945;
}

.line-chart[data-area-color="danger"] .nvd3 .nv-groups path.nv-area {
  fill: #D83C31;
}

.line-chart[data-area-color="white"] .nvd3 .nv-groups path.nv-area {
  fill: #fff;
}

.line-chart[data-area-color="black"] .nvd3 .nv-groups path.nv-area {
  fill: #000;
}

/* Point fill colors
------------------------------------
*/
.line-chart[data-point-color="master"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #4b4b4b;
}

.line-chart[data-point-color="success"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #19AD79;
}

.line-chart[data-point-color="info"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #3B4752;
}

.line-chart[data-point-color="complete"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #0072EC;
}

.line-chart[data-point-color="primary"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #7252D3;
}

.line-chart[data-point-color="warning"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #FFd945;
}

.line-chart[data-point-color="danger"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #D83C31;
}

.line-chart[data-point-color="white"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #fff;
}

#nvd3-line svg,
#nvd3-line2 svg,
#nvd3-area svg {
  height: 500px;
}

/* Rickshaw Charts
------------------------------------
*/
.rickshaw-chart.rickshaw_graph .detail {
  padding-left: 15px;
  transform: translateX(-15px);
  width: 30px;
  background: rgba(0, 0, 0, 0.04);
  right: -15px;
}

.rickshaw-chart.rickshaw_graph .detail .item,
.rickshaw-chart.rickshaw_graph .detail .x_label {
  transform: translateX(15px);
}

.rickshaw-chart.rickshaw_graph .detail:after {
  content: "";
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  display: block;
}

.rickshaw-chart .y_grid .tick.major line {
  stroke-dasharray: 3px, 5px;
  opacity: 0.7;
}

.rickshaw-chart.rickshaw_graph .detail .x_label {
  display: none;
}

.rickshaw-chart.rickshaw_graph .detail .item {
  line-height: 1.4;
  padding: 0.5em;
}

.rickshaw-chart.rickshaw_graph .detail_swatch {
  float: right;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px 0 0;
}

.rickshaw-chart.rickshaw_graph .detail .date {
  font-size: 11px;
  color: #a0a0a0;
  opacity: 0.5;
}

#tab-rickshaw-realtime #rickshaw-realtime_y_axis {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  width: 40px;
  left: 0;
  z-index: 1;
}

#tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .y_ticks path,
#tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .x_ticks_d3 path {
  fill: none;
  stroke: none;
}

#tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .y_ticks text,
#tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .x_ticks_d3 text {
  opacity: 0.35;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
}

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .dot {
  border-radius: 50px;
}

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .x_label {
  display: none;
}

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .item {
  line-height: 1.4;
  padding: 0.5em;
}

#tab-rickshaw-bars #rickshaw-stacked-bars .detail_swatch {
  float: right;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px 0 0;
}

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .date {
  font-size: 11px;
  color: #a0a0a0;
  opacity: 0.5;
}

#tab-rickshaw-slider #rickshaw-slider {
  height: 500px;
}

#tab-rickshaw-slider .rickshaw_graph .x_grid_d3 .tick {
  stroke-opacity: 0;
}

#tab-rickshaw-slider .rickshaw_graph .y_ticks path,
#tab-rickshaw-slider .rickshaw_graph .x_ticks_d3 path {
  stroke: none;
}

#tab-rickshaw-slider .rickshaw_graph .y_ticks text,
#tab-rickshaw-slider .rickshaw_graph .x_ticks_d3 text {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
}

#tab-rickshaw-slider .rickshaw_range_slider_preview .frame {
  opacity: 0;
}

#tab-rickshaw-slider .rickshaw_range_slider_preview .left_handle,
#tab-rickshaw-slider .rickshaw_range_slider_preview .right_handle {
  fill: #000;
  fill-opacity: 0.1 !important;
}

#tab-rickshaw-slider .slider {
  position: absolute;
  top: 0;
  height: 93px;
  overflow: hidden;
}

#tab-rickshaw-slider .chart {
  position: absolute;
  bottom: 40px;
  top: 150px;
  left: 33px;
  right: 0;
  width: auto;
}

#tab-rickshaw-slider .chart .x_tick.plain .title {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
}

#tab-rickshaw-slider .y_axis {
  bottom: 0;
  position: absolute;
  top: 150px;
  width: 40px;
  left: -6px;
}

#tab-rickshaw-slider .rickshaw_graph .x_tick {
  border-color: transparent;
}

#tab-rickshaw-slider .rickshaw_graph .x_tick .title {
  bottom: -24px;
  left: -15px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/*------------------------------------------------------------------
[18. List]
*/
.list-view-wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.list-view-container {
  position: relative;
}

.list-view-container .list-quickscroll ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 120;
  padding-top: 10px;
  padding-bottom: 10px;
}

.list-view-container .list-quickscroll ul li a {
  font-size: 0.7em;
  vertical-align: baseline;
}

.list-view-group-container {
  margin: 0;
  min-height: 1px;
  overflow: hidden;
  padding: 26px 0 0 0;
  position: relative;
}

.list-view-group-container:last-child ul {
  border: 0;
}

.list-view-group-header,
.list-view-fake-header {
  background: #f4f4f4;
  color: #232830;
  font: normal 11px/14px "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  margin: 0;
  padding: 6px 0 5px 15px;
  position: absolute;
  z-index: 10;
}

.list-view-group-header {
  bottom: auto;
  min-height: 1px;
  top: 0;
  width: 100%;
  border-top: 0;
}

.list-view-fake-header {
  width: 100%;
  z-index: 100;
  font-size: 11px !important;
  line-height: 14px !important;
}

.list-view-fake-header.list-view-hidden {
  visibility: hidden;
}

.list-view-group-container.list-view-animated .list-view-group-header {
  bottom: 0;
  top: auto;
}

input.list-view-search {
  font-size: 15px;
  color: #232830;
}

.no-top-border .list-view-fake-header {
  border-top: 0;
}

.list-view ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.14);
}

.list-view li {
  padding-left: 15px;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.list-view li:hover {
  background-color: #d3eeff;
}

.list-view li.active {
  background-color: #fffde1;
}

.list-view li p {
  margin: 0;
  line-height: 19px;
}

.list-view li > a {
  margin-right: 15px;
  width: 100%;
}

.list-view li > * {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-view.boreded li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.list-view.boreded li:last-child > a {
  border-bottom: 0;
}

.list-view.boreded li + li {
  border-top: 0;
}

[data-ios="true"] .list-view-group-header,
[data-ios="true"] .list-view-fake-header {
  width: 288px;
  /*scrollbars aren't visible in iOS devices, so make the headers wider */
}

.list-group-item {
  border: 1px solid #f4f4f4;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  background-color: #26bf93;
  border-color: #26bf93;
}

.list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.list-group-item:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

/*------------------------------------------------------------------
[19. Social App]
*/
.social-wrapper,
.social {
  height: 100%;
  width: 100%;
}

/* Cover
------------------------------------
*/
.social-wrapper .social .jumbotron {
  height: 60vh;
  padding-top: 0;
}

.social-wrapper .social .cover-photo {
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
}

.social-wrapper .social .cover-photo:before {
  background-image: url("../img/linear_gradient.png");
  background-repeat: repeat-x;
  bottom: 0;
  content: " ";
  height: 270px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}

.social-wrapper .social .cover-photo .pull-bottom {
  z-index: 2;
}

.cover-img-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cover-img-container .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
}

/* Feed
------------------------------------
*/
.social-user-profile {
  width: 52px;
}

.social-user-profile > .thumbnail-wrapper {
  float: none;
}

.social-wrapper .social .feed {
  overflow-y: hidden;
  width: 100%;
}

.social-wrapper .social .feed > .day {
  white-space: normal;
  display: block;
  width: 100%;
  margin: 0 auto;
}

.social-wrapper .social .feed > .day:after {
  feed: "";
  display: block;
  clear: both;
}

.social-wrapper .social .feed > .day:hover > .timeline:after {
  background: #0072EC;
}

/* Social Cards
------------------------------------
*/
.social-card {
  padding-bottom: 0;
  margin-bottom: 0;
  background: #fff;
  float: left;
  position: relative;
  border-radius: 4px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-bottom: 20px;
  width: 300px;
  border: 1px solid #e0e0e0;
}

.social-card .circle {
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  border-radius: 50%;
  border: 2px solid #f4f4f4;
  width: 9px;
  height: 9px;
  background: #475b6b;
  z-index: 1;
}

.social-card .circle:hover {
  cursor: pointer;
}

.social-card.full-width {
  width: 100% !important;
}

.social-card.status {
  background: rgba(211, 238, 255, 0.6);
  border-radius: 4px;
  padding: 15px 25px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.social-card.status:hover, .social-card.status.hover {
  border-color: #dbe6e8 !important;
}

.social-card.status h5 {
  font-size: 12px;
  margin: 0;
  color: #4b4b4b;
}

.social-card.status h5 .time {
  color: #4b4b4b;
}

.social-card.status h2 {
  color: #4b4b4b;
  font-size: 25px;
  line-height: 32px;
  margin: 0;
  font-weight: normal;
}

.social-card.status .reactions {
  opacity: 0.45;
  font-size: 12px;
  padding: 0;
  margin: 5px 0 0;
  padding: 0;
}

.social-card.status .reactions li {
  display: inline-block;
  list-style: none;
  padding: 0;
}

.social-card.status .reactions li a {
  color: #212121;
}

.social-card.status .circle {
  width: 9px;
  height: 9px;
  border: none;
  background: #0072EC;
}

.social-card .card-header,
.social-card .card-footer {
  background-color: #fff;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.social-card.share .card-header {
  padding: 15px 15px 12px;
  border-bottom: 1px solid #e0e0e0;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.social-card.share .card-header:hover {
  background: rgba(211, 238, 255, 0.6);
  border-color: #d3eeff;
  cursor: pointer;
}

.social-card.share .card-header:hover h6 {
  opacity: 0.6;
}

.social-card.share .card-header.last {
  border-bottom: none;
}

.social-card.share .card-header .user-pic {
  float: left;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.social-card.share .card-header .user-pic > img {
  width: 30px;
  height: 30px;
}

.social-card.share .card-header h5 {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  line-height: 22.7px;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.006em;
  margin-top: -3px;
}

.social-card.share .card-header h6 {
  font-size: 12px;
  margin: 0;
  opacity: 0.45;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  opacity: 0.45;
}

.social-card.share .card-description {
  padding: 12px 16px 11px;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.social-card.share .card-description p {
  margin-bottom: 4px;
}

.social-card.share .card-description .via {
  opacity: 0.45;
  display: block;
  font-size: 12px;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.social-card.share .card-content {
  position: relative;
}

.social-card.share .card-content .buttons {
  left: 17px;
  padding: 0;
  position: absolute;
  top: 10px;
}

.social-card.share .card-content .buttons li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
}

.social-card.share .card-content .buttons li a {
  color: #fff;
}

.social-card.share .card-feed {
  overflow: hidden;
  position: relative;
  max-height: 400px;
}

.social-card.share .card-feed:hover .buttons {
  opacity: 1;
}

.social-card.share .card-feed .buttons {
  position: absolute;
  top: 5px;
  right: 0;
  opacity: 0;
}

.social-card.share .card-feed .buttons li {
  display: inline-block;
  list-style: none;
}

.social-card.share .card-feed .buttons li a {
  color: #fff;
  opacity: 0.8;
  padding: 5px;
}

.social-card.share .card-feed > * {
  max-width: 100%;
}

.social-card.share .card-footer {
  padding: 14px 16px 11px;
  font-size: 12px !important;
}

.social-card.share .card-footer:hover {
  background: rgba(211, 238, 255, 0.6);
  cursor: pointer;
}

.social-card.share .card-footer:hover .reactions,
.social-card.share .card-footer:hover .time {
  opacity: 0.8;
}

.social-card.share .card-footer .time {
  float: left;
  opacity: 0.45;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-top: 1px;
}

.social-card.share .card-footer .reactions {
  float: right;
  margin: 0;
  padding: 0;
  opacity: 0.45;
}

.social-card.share .card-footer .reactions li {
  display: inline-block;
  list-style: none;
}

.social-card.share .card-footer .reactions li a {
  color: inherit;
}

.social-card.share .card-description,
.social-card.share .card-feed,
.social-card.share .card-footer {
  border-color: transparent;
  border-style: solid;
}

.social-card.share .card-description {
  border-width: 1px;
}

.social-card.share .card-feed {
  border-width: 0 1px;
  margin-top: -2px;
}

.social-card.share .card-footer {
  border-width: 0 1px 1px 1px;
}

.social-card.share.share-other .card-description {
  padding-bottom: 0;
}

.social-card.share.share-other .card-footer {
  padding-top: 0;
  border-radius: 0;
}

.social-card.share.share-other .card-footer:hover {
  background: #fff;
}

.social-card.share.share-other .card-header {
  border-top: 1px solid rgba(33, 33, 33, 0.14);
}

.social-card.share.share-other .circle {
  background: #FFd945;
}

.social-card.col1 {
  width: 300px;
}

.social-card.col2 {
  width: 620px;
}

.social-card.col3 {
  width: 920px;
}

.social-card img {
  width: 100%;
}

/* Step Form : Status */
.simform {
  margin-left: 0;
  padding: 0;
  position: static;
  margin-bottom: 20px;
}

.simform .error-message {
  padding-top: 29px !important;
  padding-left: 22px;
}

.simform .final-message,
.simform .error-message {
  margin: 0 !important;
  font-size: 15px;
  opacity: 0.5;
  display: none;
  margin-top: 19px;
  position: static;
  text-align: left;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.simform ol:before {
  background: transparent;
}

.simform .questions li {
  overflow: hidden;
}

.simform .questions li.current {
  position: relative;
}

.simform .questions li.current input {
  font-size: 14px;
  padding: 0 !important;
  margin: 0 !important;
}

.simform .questions li > span {
  width: 100%;
}

.simform .questions li > span label {
  font-size: 12px;
  opacity: 0.55;
  font-weight: 300;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.simform .questions input {
  background: transparent !important;
  height: 30px;
}

.simform .questions .current input,
.simform .no-js .questions input {
  background: transparent;
  border: none;
}

.simform .controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.simform .controls .next,
.simform .controls .number {
  display: none !important;
}

.simform .progress:before {
  background: none;
}

/* Responsive Handlers: Social App
------------------------------------
*/
@media (min-width: 991px) and (max-width: 1070px) {
  .day .card:first-child .col-md-4:first-child {
    width: 100%;
    margin-bottom: 20px;
  }
  .day .card:first-child .col-md-4:nth-child(2),
  .day .card:first-child .col-md-4:nth-child(3) {
    width: 50%;
  }
}

@media (max-width: 667px) {
  .social-wrapper .social .feed > .day > .card {
    width: 100% !important;
  }
}

/*------------------------------------------------------------------
[20. Email App]
*/
/* Email Components
--------------------------------------------------
*/
.compose-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  background: #fff;
  border-top: 1px solid #f4f4f4;
  z-index: 101;
}

.compose-wrapper .compose-email {
  font-size: 24px;
}

.email-composer {
  position: relative;
  overflow: auto;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.17);
  max-width: 680px;
}

.email-composer .email-toolbar-wrapper .wysihtml5-toolbar {
  background: #f4f4f4;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  border-top: none;
}

.email-composer .email-body-wrapper {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 12px;
}

.email-composer .bootstrap-tagsinput {
  margin: 0;
  padding: 0;
}

.email-composer > .row {
  margin-left: -30px;
  margin-right: -30px;
}

.split-view .split-details .email-content-wrapper {
  background: #f4f4f4;
  height: 100%;
  width: auto;
  overflow: auto;
  padding: 10px;
  margin-top: 50px;
  border-radius: 6px;
}

.split-view .split-details .email-content-wrapper .actions-wrapper {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 15px;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 799;
}

.split-view .split-details .email-content-wrapper .email-content-header {
  padding: 20px;
}

.split-view .split-details .email-content-wrapper .email-content-header .subject {
  font-size: 22px;
  line-height: 1.2;
}

.split-view .split-details .email-content-wrapper .email-content {
  margin: 0 auto;
  width: 600px;
  display: block;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid rgba(33, 33, 33, 0.14);
}

.split-view .split-details .email-content-wrapper .email-content .email-content-header .sender .name {
  font-size: 15px;
  color: #3B4752;
}

.split-view .split-details .email-content-wrapper .email-content .email-content-header .sender .datetime {
  color: rgba(33, 33, 33, 0.62);
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.split-view .split-details .email-content-wrapper .email-content .email-content-header .subject {
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #4b4b4b;
  font-size: 15.2px;
  line-height: 17px;
}

.split-view .split-details .email-content-wrapper .email-content .email-content-header .fromto .btn-xs {
  border-radius: 13px;
}

.split-view .split-details .email-content-wrapper .email-content .email-content-body p {
  line-height: 23px;
  color: #4b4b4b;
  letter-spacing: 0.001em;
}

.split-view .split-details .email-content-wrapper .email-content .email-reply {
  min-height: 200px;
}

.split-view .split-details .email-content-wrapper .email-content .editor-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.split-view .split-details .email-content-wrapper .email-content .editor-wrapper .wysihtml5-sandbox {
  max-height: 150px;
  min-height: 130px !important;
  width: 100% !important;
}

/* Email App Top Header
--------------------------------------------------
*/
.header-wrapper-email {
  font-size: 14.92px;
}

.header-wrapper-email .dropdown > .btn {
  color: #0072EC;
}

.header-wrapper-email .btn:hover {
  text-decoration: none;
}

/* Responsive Handler : Email App
--------------------------------------------------
*/
@media (max-width: 768px) {
  .email-content .wysiwyg5-wrapper {
    min-height: 400px;
  }
}

.email-composer .email-toolbar-wrapper .dropdown .dropdown-toggle::after {
  display: none;
}

/*------------------------------------------------------------------
[21. Calendar App]
*/
body.overflow-hidden {
  overflow: hidden;
}

/*
Calendar
--------------------------------------------------
Header / .calendar-header
Years / .years .year
Months / .months
Weeks / .weeks-wrapper .week
Grid / .calendar-container
Events / .event-container
*/
.calendar {
  height: 100%;
}

.calendar .drager {
  overflow: hidden;
}

.calendar .drager .scroll-element {
  display: none;
}

.calendar.month {
  padding-left: 5px;
}

.calendar.month .options .date {
  margin-bottom: 22px;
}

.calendar.month .week-dragger {
  display: none;
}

.calendar.month .calendar-container {
  border-top: 1px solid #e0e0e0;
  padding-top: 0;
  height: calc(100% - 169px);
}

.calendar.month .calendar-container .view .tble {
  width: 100%;
}

.calendar.month .calendar-container .view .tble .thead .tcell .weekday {
  margin-left: 10px;
}

.calendar .calendar-header {
  height: 43px;
  background-color: #fff;
  line-height: 43px;
  padding-left: 60px;
}

.calendar .calendar-header > .drager {
  width: calc(100% - 141px);
  border-right: 1px solid rgba(33, 33, 33, 0.14);
  line-height: 35px;
}

.calendar .calendar-header .years {
  width: auto;
  list-style: none;
  white-space: nowrap;
  margin-top: 5px;
}

.calendar .calendar-header .years .year {
  display: inline-block;
  width: 69px;
  text-align: center;
  white-space: nowrap;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.calendar .calendar-header .years .year > a {
  color: #757575;
  position: relative;
}

.calendar .calendar-header .years .year > a.active {
  color: #212121;
}

.calendar .calendar-header .years .year > a.has-event:before {
  position: absolute;
  content: '\25CF';
  width: 100%;
  font-size: 8px;
  line-height: 6px;
  text-align: center;
  color: rgba(0, 0, 0, 0.44);
}

.calendar .options {
  padding-left: 60px;
  margin-top: 15px;
}

.calendar .options .months {
  width: auto;
  white-space: nowrap;
  height: 43px;
  line-height: 43px;
}

.calendar .options .months .month {
  min-width: 30px;
  max-width: 100px;
  padding: 0 10px;
  display: inline-block;
}

.calendar .options .months .month > a {
  position: relative;
  color: #757575;
}

.calendar .options .months .month > a:hover {
  color: #4b4b4b;
}

.calendar .options .months .month > a.active {
  color: #212121;
}

.calendar .options .months .month > a.has-event:before {
  position: absolute;
  content: '\25CF';
  top: -6px;
  width: 100%;
  font-size: 8px;
  line-height: 6px;
  text-align: center;
  color: rgba(0, 0, 0, 0.33);
}

.calendar .options .date {
  margin-bottom: 20px;
}

.calendar .week-dragger {
  border-bottom: 1px solid rgba(33, 33, 33, 0.14);
  margin-left: -20px;
}

.calendar .weeks-wrapper {
  width: auto;
  white-space: nowrap;
  padding-left: 10px;
  padding-bottom: 12px;
  margin-bottom: 5px;
  margin-left: 20px;
}

.calendar .weeks-wrapper .week {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}

.calendar .weeks-wrapper .week:first-child {
  padding-left: 4px;
}

.calendar .weeks-wrapper .week:last-child {
  padding-left: 0px;
}

.calendar .weeks-wrapper .week:before {
  content: '';
  position: absolute;
  right: -20px;
  bottom: 6px;
  height: 20px;
  width: 20px;
  border-left: 1px dotted rgba(33, 33, 33, 0.14);
}

.calendar .weeks-wrapper .week.active .day-wrapper .week-date .day > a {
  color: rgba(33, 33, 33, 0.9);
}

.calendar .weeks-wrapper .week .day-wrapper {
  display: inline-block;
}

.calendar .weeks-wrapper .week .day-wrapper .week-date {
  text-align: center;
  width: 21px;
  height: 21px;
  margin: 6px;
  border-radius: 99px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
}

.calendar .weeks-wrapper .week .day-wrapper .week-date.current-date {
  background-color: #e0e0e0;
}

.calendar .weeks-wrapper .week .day-wrapper .week-date.active {
  background-color: #19AD79;
}

.calendar .weeks-wrapper .week .day-wrapper .week-date.active .day > a {
  opacity: 1;
  color: #fff;
}

.calendar .weeks-wrapper .week .day-wrapper .week-date .day > a {
  letter-spacing: -0.01em;
}

.calendar .weeks-wrapper .week .day-wrapper .week-day {
  text-align: center;
}

.calendar .weeks-wrapper .week .day-wrapper .day {
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 10;
  padding: 1px 0;
  font-size: 12px;
  color: #757575;
}

.calendar .weeks-wrapper .week .day-wrapper .day > a {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  color: #757575;
  font-weight: 600;
}

.calendar .weeks-wrapper .week .day-wrapper .day > a.has-event:before {
  position: absolute;
  content: '\25CF';
  top: -10px;
  width: 100%;
  font-size: 8px;
  line-height: 6px;
  text-align: center;
  color: #19AD79;
}

.calendar .weeks-wrapper .week .day-wrapper .day.week-header {
  text-transform: uppercase;
  text-align: center;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.06em;
  font-size: 10.5px;
}

.calendar .calendar-container {
  position: relative;
  height: calc(100% - 200px);
  padding-top: 10px;
}

.calendar .calendar-container .view {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell {
  position: relative;
  height: 20%;
  clear: both;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell .cell-inner .holder {
  position: absolute;
  top: 37px;
  left: 0;
  right: 0;
  bottom: 0;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell.active {
  background: #fff;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell.active .cell-inner {
  background-color: #fef8e7;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell.not {
  background-color: #fff;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell.drop-target .cell-inner {
  background-color: #fff;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell .cell-inner:only-child, .calendar .calendar-container .view.month-view .tble .trow .tcell .cell-inner {
  height: 100%;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell .month-date {
  position: relative;
  text-align: center;
  margin-top: 10px;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  padding-top: 2px;
  margin: 6px;
  float: right;
  display: block;
  z-index: 1;
  border-radius: 99px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell .month-date.current-date {
  background-color: #e0e0e0;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell .month-date.active {
  background-color: #19AD79;
  opacity: 1;
  color: #fff;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell .event-container {
  height: 30px;
  position: relative !important;
  min-height: 30px !important;
}

.calendar .calendar-container .view.month-view .tble .trow .tcell .event-container .event-inner {
  padding: 8px;
}

.calendar .calendar-container .view.month-view .ghost-element {
  height: 30px;
  position: relative;
  width: 100%;
}

.calendar .calendar-container .view.month-view .grid .tble {
  height: 100%;
  width: 100%;
}

.calendar .calendar-container .view.month-view .event-container .event-title {
  font-size: 12px;
}

.calendar .calendar-container .view.day-view .tble {
  white-space: nowrap;
}

.calendar .calendar-container .view.day-view .tble .thead {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.calendar .calendar-container .view.day-view .tble .tcell {
  display: none;
  max-width: 100%;
  width: 100%;
}

.calendar .calendar-container .view.day-view .tble .tcell.active {
  display: inline-block;
}

.calendar .calendar-container .view.day-view .tble .trow {
  display: block;
  height: 80px;
}

.calendar .calendar-container .view.day-view .tble .trow .tcell.active {
  background-color: #fff;
}

.calendar .calendar-container .view .tble {
  display: inline-table;
  width: calc(100% - 50px);
  vertical-align: top;
  position: relative;
}

.calendar .calendar-container .view .tble .thead {
  display: table-row;
  width: 100%;
  background: #fff;
}

.calendar .calendar-container .view .tble .thead .tcell {
  padding: 10px;
  height: 40px;
  position: relative;
}

.calendar .calendar-container .view .tble .thead .tcell .weekday {
  font-size: 12px;
  display: inline-block;
  color: #757575;
}

.calendar .calendar-container .view .tble .thead .tcell .weekdate {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  color: #4b4b4b;
}

.calendar .calendar-container .view .tble .thead .tcell.active .weekdate, .calendar .calendar-container .view .tble .thead .tcell.active .weekday {
  color: #212121;
}

.calendar .calendar-container .view .tble .thead .tcell:before {
  content: '';
  border-bottom: 1px solid #e0e0e0;
  width: calc(100% - 18px);
  position: absolute;
  bottom: 0;
}

.calendar .calendar-container .view .tble .thead .tcell .event-bubble {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  float: right;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

.calendar .calendar-container .view .tble .tcell {
  display: table-cell;
  height: 80px;
  max-width: 14.2857%;
  width: 14.2857%;
}

.calendar .calendar-container .view .tble .trow {
  display: table-row;
}

.calendar .calendar-container .view .tble .trow .tcell {
  background: #fff;
  padding: 0 10px;
}

.calendar .calendar-container .view .tble .trow .tcell .cell-inner {
  height: 40px;
  position: relative;
}

.calendar .calendar-container .view .tble .trow .tcell .cell-inner:first-child {
  border-bottom: 1px dotted #e0e0e0;
}

.calendar .calendar-container .view .tble .trow .tcell .cell-inner:last-child {
  border-bottom: 1px solid #e0e0e0;
}

.calendar .calendar-container .view .tble .trow .tcell .cell-inner:only-child {
  height: 40px;
  border-bottom: 0;
}

.calendar .calendar-container .view .tble .trow .tcell.active {
  background-color: #fef8e7;
}

.calendar .calendar-container .view .tble .trow .tcell.active > * {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.calendar .calendar-container .view .tble .trow .tcell.disable {
  background-color: #fff;
}

.calendar .calendar-container .view .tble .loading {
  left: 10px;
}

.calendar .calendar-container .loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

.calendar .calendar-container .pgn-wrapper {
  position: absolute;
}

.calendar .calendar-container .even-holder {
  position: absolute;
  display: table;
  width: calc(100% - 50px);
  height: calc(100% - 25px);
}

.calendar .calendar-container .even-holder .event-placeholder {
  height: 100%;
  width: 14.2857%;
  display: table-cell;
}

.calendar .calendar-container .event-container {
  width: 100%;
  height: 40px;
  position: absolute !important;
  z-index: 10;
  overflow: hidden;
  margin-bottom: 5px;
  min-height: 40px !important;
  -webkit-transition: width 0.16s ease;
  transition: width 0.16s ease;
}

.calendar .calendar-container .event-container .event-inner {
  padding: 15px;
}

.calendar .calendar-container .event-container .event-inner:before {
  position: absolute;
  width: 8px;
  height: 8px;
  content: '';
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 999px;
  right: 14px;
  top: 11px;
}

.calendar .calendar-container .event-container .time-wrap {
  line-height: 12px;
  text-align: left;
  color: rgba(0, 0, 0, 0.77);
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar .calendar-container .event-container .event-start-time, .calendar .calendar-container .event-container .event-end-time {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.62);
}

.calendar .calendar-container .event-container .event-title {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar .calendar-container .event-container .resizable-handle {
  position: absolute;
  opacity: 1;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

.calendar .calendar-container .event-container .resizable-handle:after {
  content: "=";
  color: rgba(0, 0, 0, 0.44);
}

.calendar .calendar-container .event-container .resizable-handle:before {
  content: '';
  transition: all 120ms ease-in 0s;
  background-color: rgba(0, 0, 0, 0.07);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
}

.calendar .calendar-container .event-container .resizable-handle:hover:before {
  opacity: 1;
}

.calendar .calendar-container .event-container.dragging {
  z-index: 100;
}

.calendar .calendar-container .grid {
  display: block;
  overflow: scroll;
  height: calc(100% - 40px);
  -webkit-overflow-scrolling: touch;
}

.calendar .calendar-container .grid.slot-60 .tble .trow .tcell .cell-inner {
  height: 80px;
  border-bottom: 1px solid #e0e0e0;
}

.calendar .calendar-container .grid.slot-15 .tble .trow .tcell {
  height: 25px;
}

.calendar .calendar-container .grid.slot-15 .tble .trow .tcell .cell-inner {
  height: 25px;
  border-bottom: 1px solid #e0e0e0;
}

.calendar .calendar-container .grid.slot-15 .tble .trow .tcell .cell-inner:last-child {
  border-bottom: 1px solid #e0e0e0;
}

.calendar .calendar-container .grid.slot-15 .time-slot-wrapper .time-slot {
  height: 100px;
}

.calendar .calendar-container .allday-cell {
  height: 40px;
  display: inline-block;
  width: 50px;
  float: left;
}

.calendar .time-slot-wrapper {
  display: inline-block;
  width: 50px;
  float: left;
  height: 100%;
}

.calendar .time-slot-wrapper .time-slot {
  display: block;
  height: 80px;
  width: 100%;
  background: #fff;
}

.calendar .time-slot-wrapper .time-slot:first-child span {
  display: none;
}

.calendar .time-slot-wrapper .time-slot > span {
  float: right;
  font-size: 12px;
  position: relative;
  top: -13%;
  color: #757575;
  font-size: 11px;
  letter-spacing: 0.06em;
  font-size: 10.5px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  right: 5px;
}

.calendar-event {
  width: 330px;
  right: -330px;
}

.calendar-event .scrollable {
  height: 100%;
}

.date-selector {
  cursor: pointer;
}

.horizontal-app-menu .calendar .weeks-wrapper .week:first-child {
  padding-left: 0;
}

.horizontal-app-menu .calendar .calendar-container .allday-cell {
  width: 38px;
}

.horizontal-app-menu .calendar .time-slot-wrapper {
  width: 38px;
}

/*
Responsive Util
*/
@media (max-width: 991px) {
  .calendar {
    background-color: #fff;
  }
  .calendar .calendar-header {
    display: none;
  }
  .calendar .options {
    margin-top: 0;
    padding-left: 10px;
  }
  .calendar .options .months {
    line-height: 21px;
    padding-top: 14px;
  }
  .calendar .options .date {
    margin-bottom: 10px;
  }
  .calendar .calendar-container {
    height: 100%;
  }
  .calendar .calendar-container .view.week-view .tble {
    white-space: nowrap;
  }
  .calendar .calendar-container .view.week-view .tble .thead {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }
  .calendar .calendar-container .view.week-view .tble .tcell {
    display: none;
    max-width: 100%;
    width: 100%;
  }
  .calendar .calendar-container .view.week-view .tble .tcell.active {
    display: inline-block;
    background-color: #fff;
  }
  .calendar .calendar-container .view.week-view .tble .trow {
    display: block;
    height: 80px;
  }
  .calendar .calendar-container .view.month-view .allday-cell {
    display: none;
  }
  .calendar .calendar-container .view.month-view .grid .tble {
    padding-left: 0;
  }
  .calendar .calendar-container .view.month-view .tble .thead .tcell {
    padding: 3px;
    padding-top: 10px;
  }
  .calendar .calendar-container .view.month-view .tble .trow .tcell {
    padding: 0 6px;
  }
  .calendar .calendar-container .view.month-view .tble .trow .tcell .event-container {
    border-radius: 99px;
    -webkit-border-radius: 99px;
    -moz-border-radius: 99px;
    position: absolute !important;
    height: 16px !important;
    width: 16px !important;
    min-height: 16px !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .calendar .calendar-container .view.month-view .tble .trow .tcell .event-container .event-inner {
    display: none;
  }
  .calendar .calendar-container.month {
    height: calc(100% - 101px);
  }
  .calendar .weeks-wrapper {
    margin-bottom: 0;
  }
  .calendar.month {
    padding-left: 0;
  }
  .calendar.month .calendar-container {
    height: calc(100% - 108px);
  }
  .calendar-event {
    width: 240px;
    right: -240px;
  }
  .months-drager {
    margin-left: 16px;
    margin-right: 36px;
    border-right: 1px solid #e0e0e0;
    padding-top: 0;
  }
  .horizontal-app-menu .months-drager {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .calendar.month .calendar-container .view .tble .thead .tcell {
    text-align: center;
  }
  .calendar.month .calendar-container .view .tble .thead .tcell:before {
    width: 100%;
  }
  .calendar.month .calendar-container .view .tble .thead .tcell .weekday {
    width: 11px;
    overflow: hidden;
    letter-spacing: 4px;
    font-weight: bold;
  }
}

/*------------------------------------------------------------------
[22. Login]
*/
.login-wrapper {
  background-color: #7252D3;
  background: url(https://storage.googleapis.com/kepler-marketing/community_login_cover_c.jpg);
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.login-wrapper > * {
  height: 100%;
}

.login-wrapper .bg-pic {
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.login-wrapper .bg-pic > img {
  height: 100%;
  opacity: 0.6;
}

.login-wrapper .login-container {
  width: 496px;
  display: block;
  position: relative;
  float: right;
}

.login-wrapper .bg-caption {
  width: 500px;
}

.register-container {
  width: 550px;
  margin: auto;
  height: 100%;
}

/* Responsive handlers : Login
------------------------------------
*/
@media (max-width: 768px) {
  .login-wrapper .login-container {
    width: 100%;
  }
  .register-container {
    width: 100%;
    padding: 15px;
  }
}

@media only screen and (max-width: 321px) {
  .login-wrapper .login-container {
    width: 100%;
  }
}

/*------------------------------------------------------------------
[23. Lock Screen]
*/
.lock-container {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
}

.lock-screen-wrapper .credentials {
  margin-top: -84px;
  position: absolute;
  top: 50%;
}

.lock-screen-wrapper .credentials .thumbnail-wrapper {
  width: 53px;
  height: 53px;
}

.lock-screen-wrapper .credentials .logged {
  opacity: 0.21;
  margin-top: -5px !important;
}

.lock-screen-wrapper .credentials .name {
  opacity: 0.69;
  margin-top: -5px !important;
  font-size: 36px;
  height: 45px;
  overflow: hidden;
}

.lock-screen-wrapper .terms-wrapper > div {
  display: table;
}

.lock-screen-wrapper .terms-wrapper .terms {
  display: table-cell;
  vertical-align: middle;
}

.lock-screen-wrapper .terms-wrapper .logo-terms {
  width: 60px;
  height: 60px;
  border-radius: 15px;
  text-align: center;
  position: relative;
}

.lock-screen-wrapper .terms-wrapper .logo-terms .brand {
  left: 50%;
  margin-left: -21px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
}

/* Responsive Handlers : Lockscreen 
------------------------------------
*/
@media (max-width: 767px) {
  .lock-container {
    width: 80%;
  }
  .lock-screen-wrapper .credentials form {
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .lock-screen-wrapper .credentials {
    margin: 0;
    position: static;
    margin-top: 100px;
    width: 100%;
    float: left;
    clear: both;
  }
  .lock-screen-wrapper .credentials > div {
    text-align: center;
  }
  .lock-screen-wrapper .credentials .thumbnail-wrapper {
    float: none;
    margin: 0 auto;
  }
}

/*------------------------------------------------------------------
[24. Timeline]
*/
/*
  Adapted from Vertical Timeline by Sebastiano Guerriero
  http://codyhouse.co/gem/vertical-timeline/
*/
/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */
.timeline-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.timeline-container::after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

.timeline-container.top-circle:before {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 99px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  background-color: #fff;
  z-index: 10;
}

/* -------------------------------- 

Main components 

-------------------------------- */
.timeline {
  position: relative;
  padding: 3em 0 10em 0;
  margin-top: 0;
  margin-bottom: 0;
}

.timeline::before {
  /* this is the vertical line */
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 2px;
  background: #fff;
}

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline {
    margin-bottom: 0;
  }
  .timeline-container:not(.left) .timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.timeline-block {
  position: relative;
  margin: 2em 0;
}

.timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-block:first-child {
  margin-top: 0;
}

.timeline-block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline .timeline-block {
    margin: 4em 0;
  }
  .timeline-container:not(.left) .timeline .timeline-block:first-child {
    margin-top: 0;
  }
  .timeline-container:not(.left) .timeline .timeline-block:last-child {
    margin-bottom: 0;
  }
}

.timeline-point {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  background: #adadad;
  border: 2px solid #fff;
}

.timeline-point.small {
  height: 12px;
  margin-left: 13px;
  margin-top: 14px;
  width: 12px;
}

.timeline-point i {
  color: #fff;
}

.timeline-point.primary {
  background-color: #7252D3;
}

.timeline-point.complete {
  background-color: #0072EC;
}

.timeline-point.success {
  background-color: #19AD79;
}

.timeline-point.info {
  background-color: #3B4752;
}

.timeline-point.danger {
  background-color: #D83C31;
}

.timeline-point.warning {
  background-color: #FFd945;
}

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline .timeline-point {
    left: 50%;
    margin-left: -21px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .timeline-container:not(.left) .timeline .timeline-point.is-hidden {
    visibility: hidden;
  }
  .timeline-container:not(.left) .timeline .timeline-point.small {
    margin-left: -7px;
  }
  .timeline-container:not(.left) .timeline .timeline-point.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.timeline-content {
  position: relative;
  margin-left: 60px;
}

.timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-content .card {
  margin-bottom: 0;
}

.timeline-content .event-date {
  display: inline-block;
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
  clear: both;
}

@media (max-width: 480px) {
  .timeline-container {
    width: 94%;
  }
}

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline .timeline-content {
    margin-left: 0;
    width: 46%;
  }
  .timeline-container:not(.left) .timeline .timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .timeline-container:not(.left) .timeline .timeline-content.is-hidden {
    visibility: hidden;
  }
  .timeline-container:not(.left) .timeline .timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
  .timeline-container:not(.left) .timeline .timeline-content .event-date {
    position: absolute;
    width: 100%;
    left: 118%;
    top: -2px;
    font-size: 16px;
    font-size: 1rem;
  }
  .timeline-container:not(.left) .timeline .timeline-content .event-date small {
    margin-top: 13px;
    display: block;
  }
  .timeline-container:not(.left) .timeline .timeline-content .event-date h6 + small {
    margin-top: 0;
  }
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(odd) .timeline-content .card {
    float: right;
  }
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content {
    float: right;
  }
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content .event-date {
    left: auto;
    right: 118%;
    text-align: right;
  }
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.timeline-container.center .timeline {
  margin-top: 3em;
  margin-bottom: 3em;
}

.timeline-container.center .timeline::before {
  left: 50%;
  margin-left: -2px;
}

.timeline-container.center .timeline .timeline-point {
  left: 50%;
  margin-left: -21px;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

.timeline-container.center .timeline .timeline-point.is-hidden {
  visibility: hidden;
}

.timeline-container.center .timeline .timeline-point.small {
  margin-left: -7px;
}

.timeline-container.center .timeline .timeline-point.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  -moz-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}

.timeline-container.center .timeline .timeline-content {
  margin-left: 0;
  width: 46%;
}

.timeline-container.center .timeline .timeline-content::before {
  top: 24px;
  left: 100%;
  border-color: transparent;
  border-left-color: white;
}

.timeline-container.center .timeline .timeline-content.is-hidden {
  visibility: hidden;
}

.timeline-container.center .timeline .timeline-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}

.timeline-container.center .timeline .timeline-content .event-date {
  position: absolute;
  width: 100%;
  left: 118%;
  top: -2px;
  font-size: 16px;
  font-size: 1rem;
}

.timeline-container.center .timeline .timeline-content .event-date small {
  margin-top: 13px;
  display: block;
}

.timeline-container.center .timeline .timeline-content .event-date h6 + small {
  margin-top: 0;
}

.timeline-container.center .timeline .timeline-block:nth-child(odd) .timeline-content .card {
  float: right;
}

.timeline-container.center .timeline .timeline-block:nth-child(even) .timeline-content {
  float: right;
}

.timeline-container.center .timeline .timeline-block:nth-child(even) .timeline-content::before {
  top: 24px;
  left: auto;
  right: 100%;
  border-color: transparent;
  border-right-color: white;
}

.timeline-container.center .timeline .timeline-block:nth-child(even) .timeline-content .event-date {
  left: auto;
  right: 118%;
  text-align: right;
}

@media only screen and (min-width: 1170px) {
  .timeline-container.left {
    width: 60%;
    margin-left: 100px;
  }
}

/*------------------------------------------------------------------
[25. Gallery]
*/
.gallery {
  margin: 70px auto 0 auto;
}

.gallery-item {
  overflow: hidden;
  cursor: default;
  background-color: #000;
  margin-bottom: 10px;
  position: relative;
  width: 280px;
  height: 240px;
}

.gallery-item:hover {
  cursor: pointer;
}

.gallery-item[data-width="1"] {
  width: 280px;
}

.gallery-item[data-width="2"] {
  width: 570px;
}

.gallery-item[data-height="1"] {
  height: 240px;
}

.gallery-item[data-height="2"] {
  height: 490px;
}

.gallery-item > img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

.gallery-item > .live-tile img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

.gallery-item .rating {
  margin-top: -5px;
  color: rgba(255, 255, 255, 0.3);
}

.gallery-item .rating > .rated {
  color: white;
}

.gallery-item .item-info {
  -webkit-transform: translate3d(0, 40%, 0);
  transform: translate3d(0, 40%, 0);
  -webkit-transition: -webkit-transform 0.35s, color 0.35s;
  transition: transform 0.35s, color 0.35s;
}

.gallery-item .item-info.more-content {
  -webkit-transform: translate3d(0, 32%, 0);
  transform: translate3d(0, 32%, 0);
}

.gallery-item:hover .item-info {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.gallery-item:hover > img {
  opacity: 0.6;
}

.gallery-item:hover > .live-tile img {
  opacity: 0.6;
}

.gallery-item:active .item-info {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.gallery-item:active > img {
  opacity: 0.6;
}

.item-details {
  z-index: 1050;
}

.item-details .dialog__content {
  width: 845px;
  max-width: 845px;
  padding: 0;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.item-details .dialog__content .dialog__overview {
  height: 516px;
  position: relative;
}

.item-details .dialog__content .dialog__overview .buy-now {
  position: absolute;
  bottom: 20px;
  right: 35px;
}

.item-details .dialog__content .dialog__overview .item-slideshow .slide {
  width: 516px;
  height: 516px;
  display: block;
  overflow: hidden;
}

.item-details .dialog__content .dialog__footer {
  height: 75px;
}

.item-details .dialog__content .dialog__footer .price {
  margin: 0;
  padding: 0;
  line-height: 75px;
}

.item-details .dialog__content .dialog__footer .separator {
  position: relative;
}

.item-details .dialog__content .dialog__footer .separator:after {
  content: "";
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  height: 27px;
  right: 0;
  top: 24px;
}

.item-details .dialog__content .dialog__footer .recommended li {
  overflow: hidden;
}

.item-details .dialog__content .dialog__footer .recommended li a {
  width: 56px;
  height: 56px;
  display: block;
}

.item-details .dialog__content .dialog__footer .recommended li img {
  width: 100%;
}

.item-details .dialog__content .close {
  top: 15px;
  right: 15px;
  z-index: 100;
}

.item-details .owl-nav {
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  color: #fff;
  opacity: 0.7;
  font-size: 18px;
  padding: 0 20px;
}

.item-details .owl-nav .owl-prev {
  float: left;
}

.item-details .owl-nav .owl-next {
  float: right;
}

.item-details .owl-dots {
  bottom: 23px;
  position: absolute;
  right: 23px;
}

.item-details .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: inline-block;
  background: rgba(0, 0, 0, 0.3);
  margin-left: 6px;
}

.item-details .owl-dots .owl-dot.active {
  background: #fff;
}

.owl-carousel .owl-stage-outer {
  direction: ltr;
}

.gallery-filters {
  position: absolute;
  left: 0;
  right: 0;
  height: 70px;
  top: -70px;
}

@media (max-width: 920px) {
  .gallery-item.first {
    display: none;
  }
}

@media (max-width: 767px) {
  .item-details .dialog__content {
    height: 90%;
    overflow-y: auto;
    width: 400px;
    max-width: 400px;
  }
  .item-details .dialog__content .container-fluid {
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .item-details .dialog__content .dialog__overview {
    height: 100%;
    margin-right: -30px;
    margin-left: -30px;
  }
  .item-details .item-slideshow-wrapper {
    height: 515px !important;
  }
  .item-details .item-description {
    height: auto !important;
  }
  .item-details .item-description .buy-now {
    position: static !important;
    float: right;
    margin-bottom: 20px;
  }
  .item-details .item-slideshow .owl-stage-outer,
  .item-details .item-slideshow .owl-stage {
    height: 100%;
  }
  .item-details .item-slideshow .slide {
    width: 100% !important;
  }
}

@media (max-width: 420px) {
  .gallery {
    margin-top: 80px;
  }
  .gallery-filters {
    top: -90px;
  }
  .item-details .dialog__content {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 610px) {
  .gallery-item,
  .gallery {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .item-details .dialog__content .container-fluid > .row {
    margin-left: -30px;
    margin-right: -30px;
  }
}

/*------------------------------------------------------------------
[26. Vector Map : Mapplic Plugin]
*/
.horizontal-app-menu .map-controls {
  left: 15px;
  top: 15px;
}

.mapplic-container {
  width: 100%;
  background-color: transparent;
}

.mapplic-container .mapplic-fullscreen-button {
  left: auto;
  right: 154px;
  bottom: 0;
  top: auto;
}

.mapplic-container .mapplic-clear-button {
  visibility: hidden;
}

.mapplic-tooltip:before {
  content: "Location";
  font-size: 12px;
  margin: 0;
  line-height: normal;
  opacity: 0.7;
  color: #4b4b4b;
}

.mapplic-tooltip-close {
  opacity: 0.5;
  background: none;
}

.mapplic-tooltip-close:after {
  content: "\e966";
  font-family: "pages-icon";
  font-size: 17px;
  position: relative;
  top: -7px;
  right: 7px;
  color: #4b4b4b;
}

.mapplic-tooltip-title {
  display: none;
}

.mapplic-tooltip-content {
  margin-top: 5px;
}

.mapplic-tooltip {
  max-width: 150px;
  padding: 8px 9px;
  border-radius: 4px;
}

.mapplic-tooltip-description {
  font-weight: bold;
  color: #4b4b4b;
}

.mapplic-tooltip-description p {
  font-size: 12px;
  color: #757575;
  margin-bottom: 4px;
}

.mapplic-tooltip-description strong {
  color: #D83C31;
  margin-right: 2px;
}

.map-controls {
  position: absolute;
  left: 50px;
  top: 80px;
  z-index: 1;
}

.map-controls select {
  width: 240px;
}

.mapplic-pin {
  background-image: url("../img/maps/marker-master.svg");
  background-size: contain;
}

.mapplic-pin.pulse {
  background-image: url("../img/maps/pulse-master.svg");
}

.mapplic-pin.pulse.green {
  background-image: url("../img/maps/pulse-success.svg");
}

.mapplic-pin.pulse.blue {
  background-image: url("../img/maps/pulse-complete.svg");
}

.mapplic-pin.pulse.purple {
  background-image: url("../img/maps/pulse-primary.svg");
}

.mapplic-pin.pulse.yellow {
  background-image: url("../img/maps/pulse-warning.svg");
}

.mapplic-pin.pulse.red {
  background-image: url("../img/maps/pulse-danger.svg");
}

.mapplic-pin.pulse-alt {
  background-image: url("../img/maps/pulse-alt-master.svg");
}

.mapplic-pin.pulse-alt.green {
  background-image: url("../img/maps/pulse-alt-success.svg");
}

.mapplic-pin.pulse-alt.blue {
  background-image: url("../img/maps/pulse-alt-complete.svg");
}

.mapplic-pin.pulse-alt.purple {
  background-image: url("../img/maps/pulse-alt-primary.svg");
}

.mapplic-pin.pulse-alt.yellow {
  background-image: url("../img/maps/pulse-alt-warning.svg");
}

.mapplic-pin.pulse-alt.red {
  background-image: url("../img/maps/pulse-alt-danger.svg");
}

.mapplic-pin.marker {
  background-image: url("../img/maps/marker-master.svg");
}

.mapplic-pin.marker.green {
  background-image: url("../img/maps/marker-success.svg");
}

.mapplic-pin.marker.blue {
  background-image: url("../img/maps/marker-complete.svg");
}

.mapplic-pin.marker.purple {
  background-image: url("../img/maps/marker-primary.svg");
}

.mapplic-pin.marker.yellow {
  background-image: url("../img/maps/marker-warning.svg");
}

.mapplic-pin.marker.red {
  background-image: url("../img/maps/marker-danger.svg");
}

.mapplic-pin.marker-alt {
  background-image: url("../img/maps/marker-alt-master.svg");
}

.mapplic-pin.marker-alt.green {
  background-image: url("../img/maps/marker-alt-success.svg");
}

.mapplic-pin.marker-alt.blue {
  background-image: url("../img/maps/marker-alt-complete.svg");
}

.mapplic-pin.marker-alt.purple {
  background-image: url("../img/maps/marker-alt-primary.svg");
}

.mapplic-pin.marker-alt.yellow {
  background-image: url("../img/maps/marker-alt-warning.svg");
}

.mapplic-pin.marker-alt.red {
  background-image: url("../img/maps/marker-alt-danger.svg");
}

/*------------------------------------------------------------------
[27. Widgets]
*/
.widget {
  position: relative;
}

.widget > div {
  position: relative;
  z-index: 1;
}

.widget:after {
  background-size: cover;
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
  opacity: 0.69;
}

.widget-1:after {
  background-image: url("../img/dashboard/pages_hero.jpg");
  background-size: cover;
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
  opacity: 0.69;
}

.widget-1:before {
  background-image: url("../img/linear_gradient.png");
  background-repeat: repeat-x;
  content: " ";
  left: 0;
  right: 0;
  height: 325px;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.widget-1 > .card-body .company:nth-child(2) > div {
  display: table;
  margin: 0 auto;
  width: 127px;
}

.widget-1 > .card-body > * {
  z-index: 1;
}

.widget-1 > .card-body > *:not(.pull-bottom) {
  position: relative;
}

.widget-1 > .card-body .pull-bottom {
  padding: 0 49px 45px 42px;
}

.widget-1 span.label {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}

.widget-2:after {
  background-image: url("../img/social/person-cropped.jpg");
}

.widget-3 a {
  text-decoration: none;
}

.widget-3 .pg-map {
  font-size: 42px;
}

.widget-3 .widget-3-fav {
  background: rgba(0, 0, 0, 0.08);
  vertical-align: middle;
  padding: 9px 10px;
  display: flex;
}

.widget-4 .row-sm-height:nth-child(1) {
  height: 30px;
}

.widget-4 .row-sm-height:nth-child(2) {
  height: 30px;
}

.widget-4-chart {
  height: 100%;
  width: 100%;
  bottom: 0;
  position: absolute;
  right: 0;
}

.widget-4-chart.line-chart .tick text,
.widget-4-chart .line-chart .nvd3 .nv-axis .nv-axisMaxMin text {
  transform: translate(-10px, -32px);
}

.widget-4-chart .nvtooltip .nv-pointer-events-none thead {
  display: none;
}

.widget-4-chart .nvtooltip .nv-pointer-events-none tbody .nv-pointer-events-none .key {
  display: none;
}

.widget-5-chart-container {
  overflow: hidden;
}

.widget-5-chart {
  height: auto;
  width: auto;
  bottom: 20px;
  position: absolute;
  right: 20px;
  left: 20px;
  top: 40px;
}

.widget-6 .label {
  background: rgba(75, 75, 75, 0.3);
  color: #fff;
}

.widget-7 .slide-back .row-sm-height:nth-child(1) {
  height: 60%;
}

.widget-7 .slide-back .row-sm-height:nth-child(2) {
  height: 40%;
}

.widget-7-chart {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.widget-7-chart.line-chart[data-points="true"] .nvd3.nv-line
.nvd3.nv-scatter
.nv-groups
.nv-point {
  stroke-opacity: 1;
}

.widget-7-chart .nvd3 circle.nv-point:nth-child(4) {
  fill: #19AD79 !important;
  stroke: #fff !important;
  stroke-width: 2px !important;
}

.widget-8 {
  height: 145px;
}

.widget-8 .row-xs-height:first-child {
  height: 41px;
}

.widget-8-chart {
  height: 100px;
  width: 50%;
  bottom: 0;
  position: absolute;
  right: 0;
}

.widget-8-chart .line-chart[data-points="true"]
.nvd3.nv-line
.nvd3.nv-scatter
.nv-groups
.nv-point {
  stroke-opacity: 0.3;
}

.widget-8-chart .line-chart .nvd3 .nv-groups path.nv-line {
  stroke-opacity: 0.15;
}

.widget-8-chart .nvtooltip {
  display: none;
}

.widget-9 {
  height: 145px;
}

.widget-9 .row-xs-height:first-child {
  height: 26px;
}

.widget-9 .progress {
  background: rgba(0, 0, 0, 0.1);
}

.widget-10 {
  height: 145px;
}

.widget-11 sup {
  margin-right: -4px;
}

.widget-11 .widget-11-table {
  height: 100%;
}

.widget-11 .widget-11-table tr td:first-child {
  width: 40%;
}

.widget-11-2 sup {
  margin-right: -4px;
}

.widget-11-2 .widget-11-2-table {
  height: 273px;
}

.widget-11-3 .widget-11-3-table {
  height: 384px;
}

.widget-12 .list-inline a {
  padding: 3px 4px;
  border-radius: 3px;
  opacity: 0.7;
}

.widget-12 .list-inline .active a {
  opacity: 1;
}

.widget-12 .nvd3-line svg {
  height: 322px;
}

.widget-12 .widget-12-search {
  margin-top: -3px;
}

@media (max-width: 992px) {
  .widget-12 {
    flex: none;
  }
  .widget-12 .widget-12-footer {
    position: relative !important;
  }
}

@media (max-width: 992px) {
  .share-other .card-content {
    height: 250px;
    min-height: 250px;
  }
}

.widget-14 .row-xs-height:nth-child(1) {
  height: 30px;
}

.widget-14 .row-xs-height:nth-child(2) {
  height: 120px;
}

.widget-14 .row-xs-height:nth-child(3) {
  height: 297px;
}

.widget-14-chart_y_axis {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  width: 35px;
  left: 0;
  z-index: 2;
}

.widget-14-chart_y_axis .rickshaw_graph .y_ticks path,
.widget-14-chart_y_axis .rickshaw_graph .x_ticks_d3 path {
  fill: none;
  stroke: none;
}

.widget-14-chart_y_axis .rickshaw_graph .y_ticks text,
.widget-14-chart_y_axis .rickshaw_graph .x_ticks_d3 text {
  opacity: 0.35;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
}

.widget-14-chart_y_axis .y_ticks.plain g:first-child {
  opacity: 0 !important;
}

.widget-14-chart-legend .ui-sortable li {
  padding-right: 0;
}

.widget-14-chart-legend .ui-sortable li .action {
  display: none;
}

.widget-14-chart-legend .ui-sortable li:last-child {
  margin-top: 7px;
}

.widget-15 #widget-15-tab-1 > div {
  height: 170px;
}

.widget-15-2 #widget-15-2-tab-1 .full-width {
  height: 180px;
}

.widget-16-header .pull-left:last-child {
  width: 69%;
}

.widget-16-chart {
  height: 138px;
}

.widget-16-chart.line-chart[data-stroke-width="2"] .nvd3.nv-line
.nvd3.nv-scatter
.nv-groups
.nv-point {
  stroke-width: 2px;
}

.widget-17 {
  height: 467px;
}

.widget-17 .weather-info .row {
  margin-bottom: 12px;
}

.widget-18-post {
  height: 342px;
  background: url("../img/social/quote.jpg");
  background-position: center center;
  background-size: cover;
}

.widget-19-post {
  height: 220px;
  background: #00a79a;
}

.widget-19-post img {
  top: 50%;
  margin-top: -70px;
}

.btn-circle-arrow {
  border: 1px solid #fff;
  border-radius: 100px;
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}

.btn-circle-arrow i {
  font-size: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  margin-left: -1px;
  margin-top: -1px;
}

.live-tile {
  color: inherit;
}

/*** Large screens ***/
@media only screen and (min-width: 1824px) {
  .ar-3-2:before {
    padding-top: calc(55% - 5px) !important;
  }
  .ar-2-3:before {
    padding-top: calc(135% - 5px) !important;
  }
}

@media (max-width: 991px) {
  .card {
    height: auto !important;
  }
  .widget-8,
  .widget-9,
  .widget-10 {
    height: 180px !important;
  }
}

@media (max-width: 480px) {
  .widget-1-wrapper {
    height: 340px;
  }
}

@media (max-width: 420px) {
  .widgets-container {
    margin-top: 80px;
  }
}

@media (max-width: 610px) {
  .widget-item,
  .widgets-container {
    width: 100% !important;
  }
}

/*------------------------------------------------------------------
[3. Chat]
*/
/* Chat Components
--------------------------------------------------
*/
.chat-view .chat-inner {
  padding: 15px;
  overflow-x: hidden;
  height: calc(100% - 103px);
}

.chat-view .message {
  margin-bottom: 10px;
  line-height: 30px;
}

.chat-view .profile-img-wrapper {
  height: 28px;
  width: 28px;
}

.chat-view .chat-bubble {
  position: relative;
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 0px 0 5px;
  max-width: 60%;
  white-space: normal;
}

.chat-view .chat-bubble > p {
  margin: 0;
}

.chat-view .chat-bubble.from-me {
  background: #d3eeff;
  color: #212121;
  opacity: 0.8;
  float: right;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.chat-view .chat-bubble.from-them {
  color: #212121;
  opacity: 0.8;
  background: #f4f4f4;
  position: relative;
  opacity: 0.8;
  float: left;
  border: 1px solid #edf1f7;
}

.chat-view .chat-input {
  border: 0;
  height: 45px;
}

.chat-view .chat-input:focus, .chat-view .chat-input:active {
  background-color: #fff;
}

.chat-view .user-controls {
  padding-top: 12px;
}

/* Chat User List
--------------------------------------------------
*/
.chat-user-list > a {
  height: 60px;
}

/* Alert List
--------------------------------------------------
*/
.alert-list > a {
  height: 45px;
}

/*------------------------------------------------------------------
[28. Misc : Helper Classes]
*/
.custom {
  height: 150px;
}

.icon-list .fa-item {
  display: block;
  color: #212121;
  line-height: 32px;
  height: 32px;
  padding-left: 10px;
}

.icon-list .fa-item > i {
  width: 32px;
  font-size: 14px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.push-on-sidebar-open {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  -webkit-backface-visibility: hidden;
}

/* Thumbnail for icons and profile pics
------------------------------------
*/
.thumbnail-wrapper {
  display: inline-block;
  overflow: hidden;
  float: left;
}

.thumbnail-wrapper.circular {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.thumbnail-wrapper.bordered {
  border-width: 4px;
  border-style: solid;
}

.thumbnail-wrapper.bordered.d16 {
  width: 20px;
  height: 20px;
}

.thumbnail-wrapper.bordered.d16 > * {
  line-height: 12px;
}

.thumbnail-wrapper.bordered.d24 {
  width: 28px;
  height: 28px;
}

.thumbnail-wrapper.bordered.d24 > * {
  line-height: 24px;
}

.thumbnail-wrapper.bordered.d32 {
  width: 36px;
  height: 36px;
}

.thumbnail-wrapper.bordered.d32 > * {
  line-height: 28px;
}

.thumbnail-wrapper.bordered.d48 {
  width: 40px;
  height: 40px;
}

.thumbnail-wrapper.bordered.d48 > * {
  line-height: 36px;
}

.thumbnail-wrapper.d16 {
  width: 16px;
  height: 16px;
}

.thumbnail-wrapper.d16 > * {
  line-height: 16px;
}

.thumbnail-wrapper.d24 {
  width: 24px;
  height: 24px;
}

.thumbnail-wrapper.d24 > * {
  line-height: 24px;
}

.thumbnail-wrapper.d32 {
  width: 32px;
  height: 32px;
}

.thumbnail-wrapper.d32 > * {
  line-height: 32px;
}

.thumbnail-wrapper.d39 {
  width: 39px;
  height: 39px;
}

.thumbnail-wrapper.d39 > * {
  line-height: 39px;
}

.thumbnail-wrapper.d48 {
  width: 36px;
  height: 36px;
}

.thumbnail-wrapper.d48 > * {
  line-height: 36px;
}

.thumbnail-wrapper > * {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  text-align: center;
}

/* Profile dropdown
------------------------------------
*/
.profile-dropdown {
  background: #fff;
}

.profile-dropdown:before {
  position: absolute;
  top: -7px;
  right: 15px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.profile-dropdown:after {
  position: absolute;
  top: -6px;
  right: 16px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: "";
}

.profile-dropdown > a {
  -webkit-transition: opacity ease 0.3s;
  transition: opacity ease 0.3s;
  padding-left: 17px;
  padding-right: 37px;
  min-width: 180px;
}

.profile-dropdown > a > i {
  margin-right: 5px;
}

.profile-dropdown > a:hover {
  opacity: 1;
}

.profile-dropdown-toggle {
  background: transparent;
  border: none;
}

/* Scroll
------------------------------------
*/
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Overides
------------------------------------
*/
.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.no-overflow {
  overflow: hidden !important;
}

.auto-overflow {
  overflow: auto;
}

.reset-overflow {
  overflow: initial !important;
}

.center-margin {
  margin-left: auto;
  margin-right: auto;
}

.inherit-size {
  width: inherit;
  height: inherit;
}

.inherit-height {
  height: inherit;
}

.image-responsive-height {
  width: 100%;
}

.image-responsive-width {
  height: 100%;
}

.overlayer {
  position: absolute;
  display: block;
  z-index: 21;
}

.overlayer.fullwidth {
  width: 100%;
}

.overlayer-wrapper {
  position: relative;
  display: block;
  z-index: 10;
}

.overlay-fixed {
  position: fixed !important;
  top: auto !important;
}

.top-left {
  position: absolute !important;
  top: 0;
  left: 0;
}

.top-right {
  position: absolute !important;
  top: 1px;
  right: 0;
}

.bottom-left {
  position: absolute !important;
  bottom: 1px;
  left: 0;
}

.bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
}

.pull-bottom {
  position: absolute !important;
  bottom: 0;
}

.pull-up {
  position: absolute !important;
  top: 0;
}

.pull-center {
  left: 0;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.pull-center {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: table;
  z-index: 1;
}

.cursor {
  cursor: pointer;
}

.scroll-x-hidden {
  overflow-x: hidden !important;
}

/* Generic Padding Helpers
------------------------------------
*/
.p-t-0 {
  padding-top: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.padding-0 {
  padding: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.padding-5 {
  padding: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.padding-10 {
  padding: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.padding-15 {
  padding: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.padding-20 {
  padding: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.padding-25 {
  padding: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.padding-30 {
  padding: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.padding-35 {
  padding: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.padding-40 {
  padding: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.padding-45 {
  padding: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.padding-50 {
  padding: 50px !important;
}

/* Generic Margin Helpers
------------------------------------
 */
.m-t-0 {
  margin-top: 0px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100%;
}

.mw-80 {
  max-width: 80%;
}

.hide {
  display: none !important;
}

.inline {
  display: inline-block !important;
}

.block {
  display: block !important;
}

/* Border Helpers
------------------------------------
*/
.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0;
}

.b-r {
  border-right-width: 1px;
}

.b-l {
  border-left-width: 1px;
}

.b-t {
  border-top-width: 1px;
}

.b-b {
  border-bottom-width: 1px;
}

.b-a {
  border-width: 1px;
}

.b-dashed {
  border-style: dashed;
}

.b-thick {
  border-width: 2px;
}

.b-transparent {
  border-color: transparent;
}

.b-grey {
  border-color: rgba(33, 33, 33, 0.14);
}

.b-white {
  border-color: #fff;
}

.b-black {
  border-color: #000;
}

.b-primary {
  border-color: #7252D3;
}

.b-complete {
  border-color: #0072EC;
}

.b-success {
  border-color: #19AD79;
}

.b-info {
  border-color: #3B4752;
}

.b-danger {
  border-color: #D83C31;
}

.b-warning {
  border-color: #FFd945;
}

/* Border Radius
------------------------------------
*/
.b-rad-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.b-rad-md {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.b-rad-lg {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
}

.no-border {
  border: none !important;
}

/** Profile Wrappers **/
.profile-img-wrapper {
  display: inline-block;
  float: left;
  border-radius: 100px 100px 100px 100px;
  display: inline-block;
  height: 35px;
  overflow: hidden;
  width: 35px;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1;
}

.profile-img-wrapper.big {
  height: 68px;
  width: 68px;
}

.profile-img-wrapper.with-left-space-custom {
  margin-left: 7px;
}

.relative {
  position: relative;
}

.flex-1 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[data-pages-bg-image] {
  background-size: cover;
}

/* Demo Purposes */
.icon-set-preview {
  transition: opacity 0.1s linear;
}

#icon-list {
  transition: all 0.1s ease-in-out;
}

.visible-xlg {
  display: none;
}

.hidden-xlg {
  display: block;
}

.sm-gutter .row > [class^="col-"],
.sm-gutter .row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.sm-gutter .row {
  margin-left: -5px;
  margin-right: -5px;
}

/* Aspect ratio */
.ar-1-1 .card,
.ar-2-1 .card,
.ar-1-2 .card,
.ar-3-2 .card,
.ar-2-3 .card {
  margin: 0;
}

.ar-1-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}

.ar-1-1:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}

.ar-1-1 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ar-2-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}

.ar-2-1:before {
  content: "";
  display: block;
  padding-top: calc(50% - 5px);
  /* initial ratio of 1:1*/
}

.ar-2-1 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ar-1-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}

.ar-1-2:before {
  content: "";
  display: block;
  padding-top: calc(150% - 5px);
  /* initial ratio of 1:1*/
}

.ar-1-2 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ar-3-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}

.ar-3-2:before {
  content: "";
  display: block;
  padding-top: calc(75% - 5px);
  /* initial ratio of 1:1*/
}

.ar-3-2 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ar-2-3 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}

.ar-2-3:before {
  content: "";
  display: block;
  padding-top: calc(125% - 5px);
  /* initial ratio of 1:1*/
}

.ar-2-3 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.v-align-bottom {
  vertical-align: bottom !important;
}

.v-align-top {
  vertical-align: top !important;
}

.v-align-middle {
  vertical-align: middle !important;
}

/* vertical alignment styles */
.col-top {
  vertical-align: top !important;
}

.col-middle {
  vertical-align: middle !important;
}

.col-bottom {
  vertical-align: bottom !important;
}

/* columns of same height styles
------------------------------------
*/
.container-xs-height {
  display: table;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.row-xs-height {
  display: table-row;
}

.col-xs-height {
  display: table-cell;
  float: none;
}

@media (min-width: 768px) {
  .container-sm-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
}

@media (min-width: 992px) {
  .container-md-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .row-md-height {
    display: table-row;
  }
  .col-md-height {
    display: table-cell !important;
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .container-lg-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .row-lg-height {
    display: table-row;
  }
  .col-lg-height {
    display: table-cell !important;
    float: none !important;
  }
}

.windows .d-flex {
  -ms-flex-wrap: wrap;
}

/*------------------------------------------------------------------
[29. Print]
*/
@media print {
  .header,
  .page-sidebar,
  .quickview-wrapper,
  .overlay {
    display: none;
  }
  .page-container {
    padding-left: 0;
  }
  .page-content-wrapper .content {
    border-top: 0;
  }
  .page-content-wrapper .content .card {
    border: none;
  }
  .page-content-wrapper .content .card .card-body {
    padding: 0;
  }
  [class^="padding-"],
  [class*="padding-"],
  .table tbody tr td {
    padding: 10px;
  }
}

/*------------------------------------------------------------------
[30. Demo : Only for demo pursposes]
*/
.builder {
  width: 423px;
  right: -423px;
}

.builder > div {
  height: calc(100% - 12px);
}

.builder [data-action].active:before {
  background-image: url("../img/demo/tick.svg");
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  top: 84px;
  right: -10px;
  z-index: 10;
}

.builder a {
  position: relative;
  display: block;
  width: 100%;
  color: #939393;
}

.builder a > img {
  opacity: 0.33;
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}

.builder a.active {
  color: #4b4b4b;
}

.builder a.active > img {
  opacity: 0.87;
}

.builder a:hover {
  color: #6f6f6f;
}

.builder a:hover > img {
  opacity: 0.7;
}

.builder a.btn-toggle-layout.active:before {
  background-image: url("../img/demo/tick.svg");
  width: 23px;
  height: 23px;
  content: "";
  position: absolute;
  top: 84px;
  right: -10px;
  z-index: 10;
}

.builder .builder-footer {
  right: 0;
  left: 0;
  z-index: 10;
}

.builder .theme-selector.active:before {
  top: 54px;
  right: 10px;
}

.builder .nav-tabs {
  background-color: transparent;
  padding: 0;
}

.builder .nav-tabs > li > a {
  min-width: 99px;
  padding: 16px 0;
}

.builder .nav-tabs > li > a.active {
  color: inherit;
}

.builder .nav-tabs ~ .tab-content {
  position: relative;
  padding-right: 0px;
  height: 100%;
  top: 0;
  background-color: #fff;
}

.builder .nav-tabs ~ .tab-content .tab-pane {
  height: 100%;
  overflow-x: hidden !important;
}

.builder .nav-tabs ~ .tab-content .tab-pane .scrollable {
  padding-top: 27px;
  height: calc(100% - 47px);
  overflow-x: hidden !important;
}

.builder .builder-close {
  position: absolute;
  right: 36px;
  top: 17px;
  padding: 7px;
  width: auto;
  z-index: 10;
}

.builder .builder-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 54px;
  height: 50px;
  top: 20%;
  left: -48px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.builder:before {
  position: absolute;
  content: "";
  width: 48px;
  height: 50px;
  background-color: #fff;
  top: 20%;
  left: -47px;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  border-left: 1px solid rgba(33, 33, 33, 0.14);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.builder:after {
  position: absolute;
  content: "";
  background-color: #fff;
  width: 5px;
  height: 50px;
  left: 0;
  top: 20%;
}

.builder .btn-toggle-theme > img {
  opacity: 1 !important;
}

.builder .btn-toggle-theme p {
  line-height: 18px;
}

.rtl .builder .builder-toggle {
  left: auto;
  right: -48px;
}

.content-builder {
  display: none;
}

.content-builder.active {
  display: block;
}

.content-builder .placeholder {
  position: relative;
}

.content-builder .placeholder:after {
  content: "Content goes here";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
  right: 0;
  text-align: center;
  font-size: 26px;
  opacity: 0.16;
  color: #4b4b4b;
}

.previewer {
  height: 35px;
}

/* Demo Widths For GIF Animations 
------------------------------------
*/
.demo-bg-hinted {
  background-color: #939393;
}

.demo-fs-23 {
  font-size: 23px !important;
}

.demo-mw-50 {
  max-width: 50px;
}

.demo-mw-300 {
  max-width: 300px;
}

.demo-mw-350 {
  max-width: 350px;
}

.demo-mw-400 {
  max-width: 400px;
}

.demo-mw-500 {
  max-width: 500px;
}

.demo-mw-600 {
  max-width: 600px;
}

.demo-mw-AA {
  max-width: 485px;
}

.demo-mw-calib {
  max-width: 310px;
}

.demo-container {
  height: 2000px;
}

/* Views page */
.view-iframe-wrapper {
  width: 100%;
  height: 255px;
  background: #fff;
  position: relative;
}

.view-iframe-wrapper iframe {
  width: 100%;
  height: 100%;
  padding: 45px 45px 0 45px;
}

.panel .panel-heading .panel-title {
  font-family: 'Montserrat';
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.02em;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-stroke: 0px;
  filter: alpha(opacity=40);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

@media (max-width: 1200px) {
  .view-iframe-wrapper iframe {
    padding: 0;
  }
}

.visible-xlg {
  display: none;
}

.hidden-xlg {
  display: block;
}

/*** Large screens ***/
@media (min-width: 1824px) {
  .visible-xlg {
    display: block !important;
  }
  .hidden-xlg {
    display: none !important;
  }
  .quickview-wrapper .tab-content #quickview-notes > .inner {
    width: 570px;
  }
  .container-fluid.container-fixed-lg {
    width: 1700px;
    margin-right: auto;
    margin-left: auto;
  }
  .menu-pin .container-fluid.container-fixed-lg {
    width: 1450px;
  }
  /*.visible-xlg {
        display: block !important;
    }
    .hidden-xlg {
        display: none !important;
    }*/
  .col-xlg-1,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9,
  .col-xlg-10,
  .col-xlg-11 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }
  .col-xlg-12 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xlg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }
  .col-xlg-10 {
    width: 83.3333%;
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }
  .col-xlg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }
  .col-xlg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }
  .col-xlg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }
  .col-xlg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .col-xlg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }
  .col-xlg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.6667%;
  }
  .col-xlg-pull-10 {
    right: 83.3333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.6667%;
  }
  .col-xlg-pull-7 {
    right: 58.3333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.6667%;
  }
  .col-xlg-pull-4 {
    right: 33.3333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.6667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333%;
  }
  .col-xlg-pull-0 {
    right: 0;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.6667%;
  }
  .col-xlg-push-10 {
    left: 83.3333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.6667%;
  }
  .col-xlg-push-7 {
    left: 58.3333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.6667%;
  }
  .col-xlg-push-4 {
    left: 33.3333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.6667%;
  }
  .col-xlg-push-1 {
    left: 8.33333%;
  }
  .col-xlg-push-0 {
    left: 0;
  }
  .col-xlg-offset-12 {
    margin-left: 100%;
  }
  .col-xlg-offset-11 {
    margin-left: 91.6667%;
  }
  .col-xlg-offset-10 {
    margin-left: 83.3333%;
  }
  .col-xlg-offset-9 {
    margin-left: 75%;
  }
  .col-xlg-offset-8 {
    margin-left: 66.6667%;
  }
  .col-xlg-offset-7 {
    margin-left: 58.3333%;
  }
  .col-xlg-offset-6 {
    margin-left: 50%;
  }
  .col-xlg-offset-5 {
    margin-left: 41.6667%;
  }
  .col-xlg-offset-4 {
    margin-left: 33.3333%;
  }
  .col-xlg-offset-3 {
    margin-left: 25%;
  }
  .col-xlg-offset-2 {
    margin-left: 16.6667%;
  }
  .col-xlg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-xlg-offset-0 {
    margin-left: 0;
  }
}

/*** Desktops ***/
/*** Medium Size Screen ***/
@media only screen and (max-width: 1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none;
  }
  .footer-widget {
    padding: 11px 21px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .visible-xl-down {
    display: block !important;
  }
}

/*** Desktops & Laptops ***/
@media only screen and (min-width: 980px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
  }
  body.menu-pin .header .brand {
    width: 245px;
  }
  body.menu-pin .page-container {
    padding-left: 0;
  }
  body.menu-pin .page-container .page-content-wrapper .content {
    padding-left: 250px;
  }
  body.menu-pin .page-container .page-content-wrapper .footer {
    left: 250px;
  }
  body.menu-pin [data-toggle-pin="sidebar"] > i:before {
    content: "\e9a2";
  }
  body.menu-pin .page-sidebar {
    transform: translate(210px, 0) !important;
    -webkit-transform: translate(210px, 0) !important;
    -ms-transform: translate(210px, 0) !important;
    width: 250px;
  }
  body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
    transform: translateX(21px);
    -webkit-transform: translateX(21px);
  }
  body.menu-pin .page-sidebar .menu-items .icon-thumbnail {
    transform: translate3d(-14px, 0, 0);
    -webkit-transform: -webkit-translate3d(-14px, 0, 0);
  }
  body.menu-behind .page-sidebar {
    z-index: 799;
  }
  body.menu-behind .header .brand {
    width: 200px;
    text-align: left;
    padding-left: 20px;
  }
  body.box-layout {
    background-color: #fff;
  }
  body.box-layout > .container,
  body.box-layout > .full-height > .container {
    height: 100%;
    padding: 0;
    background-color: #fff;
  }
  body.box-layout .header {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  body.box-layout .header > .container {
    background-color: #fff;
    border-bottom: 1px solid rgba(230, 230, 230, 0.7);
    padding: 0 20px 0 0;
    height: 100%;
  }
  body.box-layout .page-sidebar {
    left: auto;
    transform: none !important;
    -webkit-transform: none !important;
  }
  body.box-layout .page-container .page-content-wrapper .footer {
    width: auto;
  }
  .header .brand {
    position: relative;
  }
  .header .user-info-wrapper .user-details .user-name {
    font-size: 16px;
  }
  .header .user-info-wrapper .user-details .user-other {
    font-size: 10px;
  }
  .header .user-info-wrapper .user-pic {
    position: relative;
    top: -6px;
  }
  .notification-panel {
    width: 400px;
  }
}

/*** General Small Screen Desktops ***/
/*** General tablets and phones ***/
@media (max-width: 991px) {
  .hidden-lg-down {
    display: none !important;
  }
  .page-container {
    padding-left: 0;
  }
  .d-flex-lg-down {
    display: flex;
  }
  .justify-between-lg-down {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  body.sidebar-open .page-container {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -ms-transform: translate(250px, 0);
    overflow: hidden;
    position: fixed;
  }
  body.sidebar-open .push-on-sidebar-open {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -ms-transform: translate(250px, 0);
    overflow: hidden;
  }
  body.box-layout > .container {
    padding: 0;
    height: 100%;
  }
  body.box-layout .header > .container {
    padding: 0;
  }
  body.box-layout .header > .container .pull-right .sm-action-bar {
    right: 0;
  }
  body.horizontal-app-menu .header > .container {
    padding: 0;
  }
  body.horizontal-app-menu .secondary-sidebar {
    margin-left: 0;
  }
  .sm-action-bar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .sm-action-bar > a {
    padding: 10px;
    display: inline-block;
  }
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0px) !important;
    transform: translate3d(0, 0px, 0px) !important;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 30px 0 36px;
  }
  .page-container {
    padding-left: 0;
    position: relative;
    transition: transform 0.25s ease;
    -webkit-transition: -webkit-transform 0.25s ease;
  }
  .page-container .page-content-wrapper .content {
    overflow-x: hidden;
    height: auto;
  }
  .icon-thumbnail {
    margin-right: 24px;
  }
  .page-sidebar {
    display: none;
    z-index: auto;
  }
  .page-sidebar.visible {
    display: block;
  }
  .page-sidebar .page-sidebar-inner {
    z-index: 1;
    left: 0 !important;
    width: 260px;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    top: 40px;
    bottom: 0;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul > li > a > .badge {
    display: inline-block;
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px;
  }
  .secondary-sidebar .btn-compose {
    display: none;
  }
  .inner-content,
  .horizontal-app-menu .inner-content {
    margin: 0;
  }
  .breadcrumb {
    padding-left: 15px;
    padding-right: 15px;
  }
  .copyright {
    padding-left: 15px;
    padding-right: 15px;
  }
  body > .pgn-wrapper[data-position="top"] {
    top: 48px;
    left: 0;
  }
  body > .pgn-wrapper[data-position="bottom"] {
    left: 0;
  }
  body > .pgn-wrapper[data-position$="-left"] {
    left: 20px;
    right: auto;
  }
  .sm-table {
    display: table;
    width: 100%;
  }
  .user-profile-wrapper {
    position: absolute;
    right: 50px;
    top: -9px;
  }
  .container {
    width: 100%;
  }
  .table-responsive-block table,
  .table-responsive-block thead,
  .table-responsive-block tbody,
  .table-responsive-block th,
  .table-responsive-block td,
  .table-responsive-block tr {
    display: block;
  }
  .table-responsive-block thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table-responsive-block tr {
    border: 1px solid rgba(230, 230, 230, 0.7);
    margin-bottom: 15px;
  }
  .table-responsive-block td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }
  .table-responsive-block td:last-child {
    border-bottom: 0;
  }
  .table-responsive-block td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  .table-responsive-block td:before {
    content: attr(data-title);
  }
  .kill-pull-md-down {
    position: relative;
    display: block;
    width: inherit;
  }
  .kill-pull-md-down .toggle-secondary-sidebar {
    display: inline-block;
    font-size: 18px;
  }
  .nav-tabs-header.nav-tabs-linetriangle {
    height: 61px;
  }
  .overlay {
    padding-left: 15px;
  }
}

/* Landscape view of all tablet devices */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
  .page-container .page-content-wrapper .content {
    padding-top: 60px;
  }
  body.box-layout > .container {
    width: 100%;
  }
  .inner-content {
    margin-left: 0;
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -155px;
    padding: 20px;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  }
  .secondary-sidebar .btn-compose {
    display: none;
  }
  .mark-email {
    margin-left: 25px;
  }
  .horizontal-app-menu .secondary-sidebar {
    display: inline-block;
    position: relative;
    top: inherit;
    left: inherit;
    right: inherit;
    padding: 20px 0;
    border-radius: 0;
    float: left;
    height: 100%;
  }
  .split-details {
    margin-left: 0;
  }
  .toggle-secondary-sidebar {
    font-size: 18px;
    display: block;
    font-size: 18px;
    left: 50%;
    margin-left: -36px;
    position: absolute;
  }
  .toggle-secondary-sidebar ~ .brand {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .sm-b-r,
  .sm-b-l,
  .sm-b-t,
  .sm-b-b {
    border-width: 0;
  }
  .sm-b-r {
    border-right-width: 1px;
  }
  .sm-b-l {
    border-left-width: 1px;
  }
  .sm-b-t {
    border-top-width: 1px;
  }
  .sm-b-b {
    border-bottom-width: 1px;
  }
  .sidebar-menu .icon-thumbnail {
    margin-right: 15px;
  }
}

@media (min-width: 1200px) {
  .row-same-height {
    overflow: hidden;
  }
  .row-same-height > [class*="col-lg"] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .visible-md {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .row-same-height {
    overflow: hidden;
  }
  .row-same-height > [class*="col-md"] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
}

@media (min-width: 768px) {
  .row-same-height {
    overflow: hidden;
  }
  .row-same-height > [class*="col-sm"] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
  .box-layout .container .jumbotron,
  .container-fluid .jumbotron {
    padding: 0;
  }
  .d-flex-md-up {
    display: flex;
  }
  .navbar-toggle {
    display: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .dataTables_wrapper.form-inline .checkbox input[type="checkbox"],
  .form-inline .radio input[type="radio"] {
    position: absolute;
  }
  .justify-end-md-up {
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-flex-end;
  }
}

@media (min-width: 700px) {
  .dd {
    float: none;
  }
}

/* Portrait view of all tablet devices */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 27px;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 27px 0 31px;
  }
  .icon-thumbnail {
    margin-right: 21px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .visible-sm {
    display: block;
  }
  .hidden-sm {
    display: none;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
  .md-p-t-0 {
    margin-top: 0px !important;
  }
  .md-p-r-0 {
    margin-right: 0px !important;
  }
  .md-p-l-0 {
    margin-left: 0px !important;
  }
  .md-p-b-0 {
    margin-bottom: 0px !important;
  }
  .md-p-t-5 {
    margin-top: 5px !important;
  }
  .md-p-r-5 {
    margin-right: 5px !important;
  }
  .md-p-l-5 {
    margin-left: 5px !important;
  }
  .md-p-b-5 {
    margin-bottom: 5px !important;
  }
  .md-p-t-10 {
    margin-top: 10px !important;
  }
  .md-p-r-10 {
    margin-right: 10px !important;
  }
  .md-p-l-10 {
    margin-left: 10px !important;
  }
  .md-p-b-10 {
    margin-bottom: 10px !important;
  }
  .md-p-t-15 {
    margin-top: 15px !important;
  }
  .md-p-r-15 {
    margin-right: 15px !important;
  }
  .md-p-l-15 {
    margin-left: 15px !important;
  }
  .md-p-b-15 {
    margin-bottom: 15px !important;
  }
  .md-p-t-0 {
    margin-top: 0px !important;
  }
  .md-p-r-0 {
    margin-right: 0px !important;
  }
  .md-p-l-0 {
    margin-left: 0px !important;
  }
  .md-p-b-0 {
    margin-bottom: 0px !important;
  }
  .md-p-t-5 {
    margin-top: 5px !important;
  }
  .md-p-r-5 {
    margin-right: 5px !important;
  }
  .md-p-l-5 {
    margin-left: 5px !important;
  }
  .md-p-b-5 {
    margin-bottom: 5px !important;
  }
  .md-p-t-10 {
    margin-top: 10px !important;
  }
  .md-p-r-10 {
    margin-right: 10px !important;
  }
  .md-p-l-10 {
    margin-left: 10px !important;
  }
  .md-p-b-10 {
    margin-bottom: 10px !important;
  }
  .md-p-t-15 {
    margin-top: 15px !important;
  }
  .md-p-r-15 {
    margin-right: 15px !important;
  }
  .md-p-l-15 {
    margin-left: 15px !important;
  }
  .md-p-b-15 {
    margin-bottom: 15px !important;
  }
}

@media (max-width: 979px) {
  .toggle-secondary-sidebar {
    font-size: 18px;
    position: static;
    display: block;
  }
  .toggle-secondary-sidebar ~ .brand {
    display: none !important;
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  }
  .secondary-sidebar .btn-compose {
    display: none;
  }
  .split-view .split-list {
    width: 100%;
    height: auto;
    bottom: 50px;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1000;
    float: none;
  }
  .split-view .split-list .item {
    padding: 8px;
  }
  .split-view .split-list .item .inline {
    width: 188px;
  }
  .split-view .split-details {
    margin-left: 0;
    width: 100%;
  }
  .split-view .split-details .email-content-wrapper {
    overflow: auto;
    padding: 0;
    height: calc(100% - 50px);
  }
  .split-view .split-details .email-content-wrapper .email-content {
    width: 90%;
  }
  .compose-wrapper {
    display: block !important;
  }
  .compose-wrapper .btn-compose {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .jumbotron,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .page-container .page-content-wrapper .content {
    padding-top: 48px;
    padding-bottom: 100px;
  }
  .page-sidebar .sidebar-header {
    padding: 0 12px;
  }
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 20px;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 25px 0 28px;
  }
  .icon-thumbnail {
    margin-right: 20px;
  }
  .secondary-sidebar {
    top: 48px;
  }
  .split-details {
    margin-left: 0;
  }
  .email-composer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sm-pull-bottom,
  .sm-pull-up {
    position: relative !important;
  }
  .sm-p-t-0 {
    margin-top: 0px !important;
  }
  .sm-p-r-0 {
    margin-right: 0px !important;
  }
  .sm-p-l-0 {
    margin-left: 0px !important;
  }
  .sm-p-b-0 {
    margin-bottom: 0px !important;
  }
  .sm-p-t-5 {
    margin-top: 5px !important;
  }
  .sm-p-r-5 {
    margin-right: 5px !important;
  }
  .sm-p-l-5 {
    margin-left: 5px !important;
  }
  .sm-p-b-5 {
    margin-bottom: 5px !important;
  }
  .sm-p-t-10 {
    margin-top: 10px !important;
  }
  .sm-p-r-10 {
    margin-right: 10px !important;
  }
  .sm-p-l-10 {
    margin-left: 10px !important;
  }
  .sm-p-b-10 {
    margin-bottom: 10px !important;
  }
  .sm-p-t-15 {
    margin-top: 15px !important;
  }
  .sm-p-r-15 {
    margin-right: 15px !important;
  }
  .sm-p-l-15 {
    margin-left: 15px !important;
  }
  .sm-p-b-15 {
    margin-bottom: 15px !important;
  }
  .sm-p-t-20 {
    margin-top: 20px !important;
  }
  .sm-p-r-20 {
    margin-right: 20px !important;
  }
  .sm-p-l-20 {
    margin-left: 20px !important;
  }
  .sm-p-b-20 {
    margin-bottom: 20px !important;
  }
  .sm-p-t-25 {
    margin-top: 25px !important;
  }
  .sm-p-r-25 {
    margin-right: 25px !important;
  }
  .sm-p-l-25 {
    margin-left: 25px !important;
  }
  .sm-p-b-25 {
    margin-bottom: 25px !important;
  }
  .sm-p-t-30 {
    margin-top: 30px !important;
  }
  .sm-p-r-30 {
    margin-right: 30px !important;
  }
  .sm-p-l-30 {
    margin-left: 30px !important;
  }
  .sm-p-b-30 {
    margin-bottom: 30px !important;
  }
  .sm-p-t-35 {
    margin-top: 35px !important;
  }
  .sm-p-r-35 {
    margin-right: 35px !important;
  }
  .sm-p-l-35 {
    margin-left: 35px !important;
  }
  .sm-p-b-35 {
    margin-bottom: 35px !important;
  }
  .sm-p-t-40 {
    margin-top: 40px !important;
  }
  .sm-p-r-40 {
    margin-right: 40px !important;
  }
  .sm-p-l-40 {
    margin-left: 40px !important;
  }
  .sm-p-b-40 {
    margin-bottom: 40px !important;
  }
  .sm-p-t-45 {
    margin-top: 45px !important;
  }
  .sm-p-r-45 {
    margin-right: 45px !important;
  }
  .sm-p-l-45 {
    margin-left: 45px !important;
  }
  .sm-p-b-45 {
    margin-bottom: 45px !important;
  }
  .sm-p-t-50 {
    margin-top: 50px !important;
  }
  .sm-p-r-50 {
    margin-right: 50px !important;
  }
  .sm-p-l-50 {
    margin-left: 50px !important;
  }
  .sm-p-b-50 {
    margin-bottom: 50px !important;
  }
  .sm-p-t-0 {
    margin-top: 0px !important;
  }
  .sm-p-r-0 {
    margin-right: 0px !important;
  }
  .sm-p-l-0 {
    margin-left: 0px !important;
  }
  .sm-p-b-0 {
    margin-bottom: 0px !important;
  }
  .sm-p-t-5 {
    margin-top: 5px !important;
  }
  .sm-p-r-5 {
    margin-right: 5px !important;
  }
  .sm-p-l-5 {
    margin-left: 5px !important;
  }
  .sm-p-b-5 {
    margin-bottom: 5px !important;
  }
  .sm-p-t-10 {
    margin-top: 10px !important;
  }
  .sm-p-r-10 {
    margin-right: 10px !important;
  }
  .sm-p-l-10 {
    margin-left: 10px !important;
  }
  .sm-p-b-10 {
    margin-bottom: 10px !important;
  }
  .sm-p-t-15 {
    margin-top: 15px !important;
  }
  .sm-p-r-15 {
    margin-right: 15px !important;
  }
  .sm-p-l-15 {
    margin-left: 15px !important;
  }
  .sm-p-b-15 {
    margin-bottom: 15px !important;
  }
  .sm-p-t-20 {
    margin-top: 20px !important;
  }
  .sm-p-r-20 {
    margin-right: 20px !important;
  }
  .sm-p-l-20 {
    margin-left: 20px !important;
  }
  .sm-p-b-20 {
    margin-bottom: 20px !important;
  }
  .sm-p-t-25 {
    margin-top: 25px !important;
  }
  .sm-p-r-25 {
    margin-right: 25px !important;
  }
  .sm-p-l-25 {
    margin-left: 25px !important;
  }
  .sm-p-b-25 {
    margin-bottom: 25px !important;
  }
  .sm-p-t-30 {
    margin-top: 30px !important;
  }
  .sm-p-r-30 {
    margin-right: 30px !important;
  }
  .sm-p-l-30 {
    margin-left: 30px !important;
  }
  .sm-p-b-30 {
    margin-bottom: 30px !important;
  }
  .sm-p-t-35 {
    margin-top: 35px !important;
  }
  .sm-p-r-35 {
    margin-right: 35px !important;
  }
  .sm-p-l-35 {
    margin-left: 35px !important;
  }
  .sm-p-b-35 {
    margin-bottom: 35px !important;
  }
  .sm-p-t-40 {
    margin-top: 40px !important;
  }
  .sm-p-r-40 {
    margin-right: 40px !important;
  }
  .sm-p-l-40 {
    margin-left: 40px !important;
  }
  .sm-p-b-40 {
    margin-bottom: 40px !important;
  }
  .sm-p-t-45 {
    margin-top: 45px !important;
  }
  .sm-p-r-45 {
    margin-right: 45px !important;
  }
  .sm-p-l-45 {
    margin-left: 45px !important;
  }
  .sm-p-b-45 {
    margin-bottom: 45px !important;
  }
  .sm-p-t-50 {
    margin-top: 50px !important;
  }
  .sm-p-r-50 {
    margin-right: 50px !important;
  }
  .sm-p-l-50 {
    margin-left: 50px !important;
  }
  .sm-p-b-50 {
    margin-bottom: 50px !important;
  }
  .sm-no-margin {
    margin: 0px;
  }
  .sm-no-padding {
    padding: 0px;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-pull-right {
    float: right !important;
  }
  .sm-pull-left {
    float: left !important;
  }
  .sm-pull-reset {
    float: none !important;
  }
  .sm-block {
    display: block;
  }
  .sm-image-responsive-height {
    width: 100%;
    height: auto;
  }
  .email-composer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sm-padding-10 {
    padding: 10px !important;
  }
  .lock-box {
    align-items: flex-start;
    height: auto;
  }
  .login-box {
    padding-right: 15px;
    padding-left: 15px;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
  .sm-padding-10 {
    padding: 10px !important;
  }
  .lock-box {
    align-items: flex-start;
    height: auto;
  }
  .login-box {
    padding-right: 15px;
    padding-left: 15px;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
  .nav-tabs-linetriangle.nav-cust {
    flex-direction: column;
  }
  .nav-tabs-linetriangle li a.active:after,
  .nav-tabs-linetriangle li a.active:before {
    display: none;
  }
  .sm-vh-25 {
    height: 25vh;
  }
  .sm-vh-50 {
    height: 50vh;
  }
  .sm-vh-75 {
    height: 75vh;
  }
  .sm-vh-100 {
    height: 100vh;
  }
  .horizontal-app-menu .page-container .page-content-wrapper .content > .container {
    padding-left: 0;
    padding-right: 0;
  }
  .summernote-wrapper .btn-group {
    -ms-flex-flow: wrap;
    flex-flow: wrap;
  }
}

/*** Phones ***/
@media (max-width: 480px) {
  body {
    width: 100%;
  }
  body #overlay-search {
    font-size: 48px;
    height: 118px;
    line-height: 46px;
  }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px;
  }
  .panel .panel-heading {
    padding-left: 15px;
  }
  .panel .panel-body {
    padding: 15px;
    padding-top: 0;
  }
  .map-controls {
    left: 10px;
  }
  .register-container {
    height: auto !important;
  }
}

/*** Retina Display Images **/
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 200 / 100), only screen and (min-device-pixel-ratio: 2) {
  .editor-icon {
    background-image: url("../img/editor_tray_2x.png");
    background-size: 480px 40px;
  }
  .alert .close {
    background: url("../img/icons/noti-cross-2x.png") no-repeat scroll 0 0 transparent;
    background-position: -9px -10px;
    width: 10px;
    height: 9px;
    position: relative;
    opacity: 0.8;
    background-size: 114px 29px;
  }
}

.hidden {
  display: none !important;
}

.visible-sm,
.visible-xs {
  display: none;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block;
  }
  .hidden-xs {
    display: none;
  }
}

.dot-navigation .swiper-pagination-bullet {
  background: #FFF !important;
  width: 12px !important;
  height: 12px !important;
  background: #333 !important;
}