/* FONTS */
.header .menu > li > a,
.header .menu > li > .classic ul > li a,
.header .menu > li > .horizontal ul li a,
.header .menu > li > .mega .container ul.sub-menu > li a,
.header .menu > li > .mega .container .sub-menu-heading,
.panel .panel-heading .panel-title,
.panel.panel-featured .panel-title h4,
.block-title,
.font-montserrat,
.nav-tabs > li > a,
.form-group label:not(.error),
.error-number,
.menu-item span.left .desc,
.menu-item span.left .notes {
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.btn {
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

b, strong{
	font-weight: 700;
}
a{
	font-weight: 300;
}

/* TYPOGRAPHY */
.text-overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.text-link {
	cursor: pointer;
}
.pointer:hover {
	cursor: pointer !important;
}
a.no-pointer:hover{
	cursor: default !important;
}
a.no-pointer img:hover{
	cursor: default !important;
}
.block-title.large {
	font-size: 18px !important;
}

/* NEW ----------------------------------------------------------------------------------------------- */

.fs-18, .fs-18 p {
	font-size: 17px !important;
}
.text-white h3 {
	color: #FFF !important;
}
/* --------------------------------------------------------------------------------------------------- */



/* IMAGES */
.img-desaturate {
	filter:         url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	-webkit-filter: grayscale(100%);
	-moz-filter:    grayscale(100%);
	-ms-filter:     grayscale(100%);
	-o-filter:      grayscale(100%);
	filter: gray;
}
a:hover .img-desaturate {
	filter: none;
	cursor: pointer;
}

/* HEADER */
.logo_header {
	max-height: 60px;
	max-width: 300px;
}
.logo_header_large {
	max-height: 71px;
}
@media (max-width: 420px) {
	.logo_header {
		max-width: 200px;
	}
}
.header.header-80 {
	height: 80px;
}
.header .menu > li > a {
	opacity: 0.9 !important;
	font-weight: bold;
}
.header .menu > li > .classic ul > li a {
	font-size: 11px;
	font-weight: bold;
	opacity: 0.5;
}
.header .menu > li > .classic.properties {
	min-width: 229px;
}
.header .menu > li > .classic ul > li.active a {
	opacity: 1;
}

/* NEW ----------------------------------------------------------------------------------------------- */
.header.light {
	background-color: rgba(256, 256, 256, 0.95);
}
.header.dark {
	background-color: rgba(0, 0, 0, 0.87);
}
/* --------------------------------------------------------------------------------------------------- */


/* BUTTONS ICONS MENU BAR */
.nav-icon-toggle {
	display: inline-block;
	color: #000;
}
.header.transparent-dark .nav-icon-toggle {
	color: #121212;
}
.header.transparent-light .nav-icon-toggle {
	color: #FFF;
}
.header.dark .nav-icon-toggle {
	color: #FFF;
}
.header.light .nav-icon-toggle {
	color: #121212;
}

.header.transparent-dark .btn-bordered {
	background-color: transparent;
	border-color: #121212;
	color: #121212;
}
.header.transparent-light .btn-bordered {
	background-color: transparent;
	border-color: #FFF;
	color: #FFF;
}
.header.dark .btn-bordered {
	background-color: transparent;
	border-color: #FFF;
	color: #FFF;
}
.header.light .btn-bordered {
	background-color: transparent;
	border-color: #121212;
	color: #121212;
}


/* LISTS - PROPERTIES */
.demo-story-block {
	height: 250px;
}
.demo-story-block-double {
	height: 480px;
}
.demo-custom-height-1 {
	height: 65%;
}
.demo-custom-height-2 {
	height: 35%;
}
.demo-custom-height-3 {
	height: calc(100% - 120px);
}
.demo-custom-height-4 {
	height: 75%;
}


/* LISTS - ARTICLES */
.hover-push-white {
	background: #FFF;
	position: relative;
	cursor: pointer;
}
.hover-push-white:hover .hover-backdrop {
	opacity: 0.2;
}
.hover-push-white:hover .hover-caption {
	-webkit-transform: translate3d(0, -20px, 0);
	transform: translate3d(0, -20px, 0);
}
.hover-push-white:hover .hover-caption a {
	opacity: 1;
}
.hover-push-white:hover .hover-caption a:after {
	-webkit-transform: translate3d(0, 40px, 0);
	transform: translate3d(0, 40px, 0);
	opacity: 1;
}
.hover-push-white .hover-backdrop {
	opacity: 0.6;
	background-size: cover !important;
	background-position: center !important;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.hover-push-white .hover-caption {
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.hover-push-white .hover-caption a {
	display: inline-block;
	margin: 0 auto;
	position: relative;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.hover-push-white .hover-caption a:after {
	position: absolute;
	bottom: 32px;
	left: 0;
	width: 100%;
	height: 2px;
	background: #fff;
	content: '';
	-webkit-transform: translate3d(0, 80px, 0);
	transform: translate3d(0, 80px, 0);
	opacity: 0;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.hover-zoom {
	cursor: pointer;
	position: relative;
}
.hover-zoom .inner {
	z-index: 1;
}
.hover-zoom:hover [data-pages-bg-image] {
	transform: scale(1.1);
}
.hover-zoom:hover [data-pages-bg-image] .bg-overlay {
	opacity: .3 !important;
}
.hover-zoom [data-pages-bg-image] {
	transition: all .3s ease;
}
.hover-zoom [data-pages-bg-image] .bg-overlay {
	transition: all .3s ease;
}
.hover-zoom-simple {
	cursor: pointer;
	position: relative;
}
.hover-zoom-simple [data-pages-bg-image] {
	background-position: center center;
	transition: background-size 0.3s ease;
	-webkit-transition: background-size 0.3s ease;
	background-size: 100%;
	background-repeat: no-repeat;
}
.hover-zoom-simple [data-pages-bg-image]:hover {
	background-size: 110%;
}
.hover-zoom-simple [data-pages-bg-image] .bg-overlay {
	transition: all .3s ease;
}
.hover-zoom-simple [data-pages-bg-image]:hover .bg-overlay {
	opacity: .6 !important;
}


/* FOOTER */
.logo_footer {
	max-height: 40px;
	max-width: 200px;
	margin-bottom: 10px;
}
.mobile_store_logo {
	height: 40px;
}
@media (max-width: 420px) {
	.mobile_store_logo {
		height: 30px;
	}
}
.w3c_validate_logo {
	height: 20px;
}
a .loggia_logo_cube {
	opacity: 1;
}
a:hover .loggia_logo_cube {
	opacity: 0.8;
}
.loggia_logo_cube {
	height: 40px;
}
.loggia_logo_cube_right {
	fill:#F2F2F2;
}
.loggia_logo_cube_left {
	fill:#999999;
}
.loggia_logo_cube_top {
	fill:#CCCCCC;
}

/* FANCYBOX */
.swiper-slide .fancybox{
	height: 100%;
}

/* GALLERY */
.item-details {
	z-index: 1050;
}
.item-details .dialog__content {
	width: 845px;
	max-width: 845px;
	padding: 0;
	text-align: left;
	border: 1px solid rgba(0, 0, 0, 0.8);
}
.item-details .dialog__content .dialog__overview {
	height: 516px;
	position: relative;
}
.item-details .dialog__content .dialog__overview .buy-now {
	position: absolute;
	bottom: 20px;
	right: 35px;
}
.item-details .dialog__content .dialog__overview .item-slideshow .slide {
	width: 516px;
	height: 516px;
	display: block;
	overflow: hidden;
}
.item-details .dialog__content .dialog__footer {
	height: 75px;
}
.item-details .dialog__content .dialog__footer .price {
	margin: 0;
	padding: 0;
	line-height: 75px;
}
.item-details .dialog__content .dialog__footer .separator {
	position: relative;
}
.item-details .dialog__content .dialog__footer .separator:after {
	content: "";
	width: 1px;
	background: rgba(255, 255, 255, 0.1);
	position: absolute;
	height: 27px;
	right: 0;
	top: 24px;
}
.item-details .dialog__content .dialog__footer .recommended li {
	overflow: hidden;
}
.item-details .dialog__content .dialog__footer .recommended li a {
	width: 56px;
	height: 56px;
	display: block;
}
.item-details .dialog__content .dialog__footer .recommended li img {
	width: 100%;
}
.item-details .dialog__content .close {
	top: 15px;
	right: 15px;
	z-index: 100;
}
.item-details .owl-nav {
	left: 0;
	position: absolute;
	top: 50%;
	width: 100%;
	color: #fff;
	opacity: .7;
	font-size: 18px;
	padding: 0 20px;
}
.item-details .owl-nav .owl-prev {
	float: left;
}
.item-details .owl-nav .owl-next {
	float: right;
}
.item-details .owl-dots {
	bottom: 23px;
	position: absolute;
	right: 23px;
}
.item-details .owl-dots .owl-dot {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	display: inline-block;
	background: rgba(0, 0, 0, 0.3);
	margin-left: 6px;
}
.item-details .owl-dots .owl-dot.active {
	background: #fff;
}
.owl-carousel .owl-stage-outer {
	direction: ltr;
}
@media (max-width: 920px) {

}
@media (max-width: 767px) {
	.item-details .dialog__content {
		height: 90%;
		overflow-y: auto;
		width: 400px;
		max-width: 400px;
	}
	.item-details .dialog__content .container-fluid {
		height: 100%;
		padding-left: 30px;
		padding-right: 30px;
	}
	.item-details .dialog__content .dialog__overview {
		height: 100%;
		margin-right: -30px;
		margin-left: -30px;
	}
	.item-details .item-slideshow-wrapper {
		height: 515px !important;
	}
	.item-details .item-description {
		height: auto !important;
	}
	.item-details .item-description .buy-now {
		position: static !important;
		float: right;
		margin-bottom: 20px;
	}
	.item-details .item-slideshow .owl-stage-outer,
	.item-details .item-slideshow .owl-stage {
		height: 100%;
	}
	.item-details .item-slideshow .slide {
		width: 100% !important;
	}
}
@media (max-width: 420px) {
	.item-details .dialog__content {
		width: 100%;
		max-width: 100%;
	}
}
@media (max-width: 610px) {

}
@media (min-width: 768px) {
	.item-details .dialog__content .container-fluid > .row {
		margin-left: -30px;
		margin-right: -30px;
	}
}


/* SERVICES */
.service-item {
	position: relative;
	-webkit-transition: color .3s ease-in-out;
	transition: color .3s ease-in-out;
	font-weight: normal;
	letter-spacing: 0.01em;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-feature-settings: "kern" 1;
	-moz-font-feature-settings: "kern" 1;
	background-image: none !important;
	color: #626262;
	text-shadow: none;
	box-shadow: none;
	line-height: 21px;
	padding-left: 10px;
	padding-right: 10px;
	transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;
	color: #505050;
	display: block;
	font-size: 16px;
	height: auto;
	background-color: #FBFBFB;
	border: none;
	cursor: auto;
	margin-bottom: 10px;
}
.service-item:hover {
	background-color: #fbf2eb;
}
@media (max-width: 991px) {
	.service-item {
		background-color: #fafafa;
	}
}
/* NEW ----------------------------------------------------------------------------------------------- */
.bg-master-dark .nav-tabs {
	border-bottom: 1px solid #666;
}
.bg-master-dark .nav-tabs > li.active > a, .bg-master-dark .nav-tabs > li.active > a:hover, .bg-master-dark .nav-tabs > li.active > a:focus {
	border-color: #666;
	background-color: #2c2c2c;
}
.bg-master-dark .service-item {
	background-color: #2c2c2c;
	color: #fafafa;
}
.bg-master-dark .service-item:hover {
	background-color: #2f2f2f;
}
@media (max-width: 991px) {
	.bg-master-dark .service-item {
		background-color: #2c2c2c;
	}
}
/* --------------------------------------------------------------------------------------------------- */

.service-item span.left {
	text-align: left;
	max-height: 100%;
	width: 250px;
	vertical-align: middle;
	display: block;
	vertical-align: middle;
	float: left;
	font-weight: bold;
}
.service-item span.left br {
	line-height: 1.5;
}
.service-item span.left .desc {
	font-weight: normal;
}
.service-item span.left .notes {
	font-weight: normal;
	font-size: 11px;
	font-style: italic;
}
.service-item span.right {
	padding: 3px 0;
	-webkit-transition: all linear .2s;
	transition: all linear .2s;
	text-align: right;
	float: right;
	width: 90px;
	display: block;
	padding-top: 10px;
	font-weight: normal;
}
.service-item.offer span.left-top {
	width: 60%;
	float: left;
	padding-top: 5px;
	font-size: 13px;
}
.service-item.offer span.right {
	padding-top: 5px
}
.service-item .item-price {
	padding-left: 20px
}
@media (min-width: 991px) and (max-width: 1200px) {
	.service-item span.left {
		width: 280px;
	}
	.service-item.no-price span.left {
		min-width: 280px;
	}
	.service-item.no-thumb span.left {
		width: 350px;
	}
	.service-item.no-thumb.no-price span.left {
		min-width: 350px;
	}
}
@media (min-width: 1201px) {
	.service-item span.left {
		width: 280px;
	}
	.service-item.no-price span.left {
		min-width: 370px;
	}
	.service-item.no-thumb span.left {
		width: 340px;
	}
	.service-item.no-thumb.no-price span.left {
		min-width: 430px;
	}
}
@media (max-width: 991px) {
	.service-item {
		padding-bottom: 15px;
	}
	.service-item.no-price span.left {
		min-width: auto;
	}
}
.service-list .card.share .card-header h5 {
	font-size: 16px;
}
.service-list .card.col1 {
}

/* MENU LIST */
.menu-item {
	height: 73px;
	cursor: pointer;
	color: #505050;
	display: table;
	position: relative;
	-webkit-transition: color .3s ease-in-out;
	transition: color .3s ease-in-out;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0.01em;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-feature-settings: "kern" 1;
	-moz-font-feature-settings: "kern" 1;
	margin-bottom: 0;
	border: 1px solid #f0f0f0;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	background-image: none !important;
	color: #626262;
	background-color: #ffffff;
	text-shadow: none;
	box-shadow: none;
	line-height: 21px;
	padding-left: 10px;
	padding-right: 10px;
	transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%
}
.menu-item:hover {
	background-color: #fbf2eb;
}
.menu-item.color {
	background-color: #fafafa;
	box-shadow: none;
}
.menu-item.color:hover {
	background-color: #fbf2eb;
}
.menu-item.offer {
	height: 123px;
	background-color: #fbf2eb;
}
.menu-item.offer:hover {
	background-color: #FFF;
}

@media (max-width: 991px) {
	.menu-item {
		background-color: #fafafa;
	}
	.menu-item.offer {
		height: auto;
	}
}
.menu-item span.left {
	text-align: left;
	max-height: 100%;
	width: 250px;
	display: table-cell;
	vertical-align: middle;
}
.menu-item span.right {
	text-align: right;
	float: right;
	width: 90px;
	display: inline-block;
	padding: 3px 0;
	-webkit-transition: all linear .2s;
	transition: all linear .2s;
	padding-top: 16px
}
.menu-item.offer span.left-top {
	width: 60%;
	float: left;
	padding-top: 5px;
	font-size: 13px;
}
.menu-item.offer span.right {
	padding-top: 5px
}

.menu-item .item-price {
	padding-left: 20px
}
/* No shop */
.no-shop .menu-item {
	color: #505050;
	display: block;
	font-size: 16px;
	height: auto;
	background-color: #FBFBFB;
	border: none;
	cursor: auto;
	margin-bottom: 10px;
}
.no-shop .item-price .fa {
	display: none;
}
.no-shop.no-price .menu-item span.right {
	display: none;
}
.no-shop.no-price .item-price {
	display: none;
}
.no-shop .menu-item.no-price span.right {
	display: none;
}
.no-shop .no-price .item-price {
	display: none;
}
.no-shop .menu-item span.left {
	max-height: 100%;
	width: 250px;
	display: block;
	vertical-align: middle;
	float: left;
	font-weight: bold;
}
.no-shop .menu-item span.left br {
	line-height: 1.5;
}
.no-shop .menu-item span.left .desc {
	font-weight: normal;
}
.no-shop .menu-item span.left .notes {
	font-weight: normal;
	font-size: 11px;
	font-style: italic;
}
.no-shop .menu-item span.right {
	text-align: right;
	float: right;
	width: 90px;
	display: block;
	padding-top: 10px;
	font-weight: normal;
}
.no-thumbs .menu-item .thumbnail-wrapper {
	display: none;
}
@media (min-width: 991px) and (max-width: 1200px) {
	.no-shop .menu-item span.left {
		width: 210px;
	}
	.no-shop.no-price .menu-item span.left {
		min-width: 300px;
	}
	.no-shop .menu-item.no-price span.left {
		min-width: 300px;
	}
}
@media (min-width: 1201px) {
	.no-shop .menu-item span.left {
		width: 290px;
	}
	.no-shop.no-price .menu-item span.left {
		min-width: 380px;
	}
	.no-shop .menu-item.no-price span.left {
		min-width: 380px;
	}
}
@media (max-width: 991px) {
	.no-shop .menu-item {
		border-bottom: solid 1px #EEE;
		padding-bottom: 15px;
	}
	.no-shop.no-price .menu-item span.left {
		min-width: auto;
	}
	.no-shop .menu-item.no-price span.left {
		min-width: auto;
	}
}
.no-shop .card.share .card-description {
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 0px;
	padding-bottom: 15px;
	font-size: 16px;
}


/* BLOG */
.card.share .card-header h5 {
	font-size: 16px;
}
.card.status:hover,
.card.status.hover {
	border-color: #d8dadc !important;
}
.card.share .card-header:hover {
	background: transparent;
	cursor: inherit;
}
.card.share .card-header:hover h6 {
	opacity: .45;
}
.card.share .card-header:hover {
	border-bottom: 1px solid #daeffd;
}

.card.share .card-footer:hover {
	background: transparent;
	cursor: inherit;
}
.card.share .card-footer:hover .reactions,
.card.share .card-footer:hover .time {
	opacity: .45;
}
a.card:hover {
	-webkit-box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
	box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
}


/* AWARDS */
.channel_badges {
	margin-left: -10px;
}
.award {
	float: left;
	margin-bottom: 20px;
	height: 125px;
}
.award_ta_excellence {
}
.award_custom_image img {
	max-height: 115px;
	margin-left: 10px;
	margin-right: 10px;
}
.award_hc_image {
	margin-left: 10px;
	margin-right: 10px;
}
.award_ta_bravo {
	margin-top: -10px;
	height: 125px;
}
.award_container .TA_certificateOfExcellence {
	margin-left: auto;
	margin-right: auto;
	display: block;
}
.award_container img {

}


/* OTAs */
.ota_logo {
	max-height: 25px;
}


/* CUSTOM BOX */
.custom-box {
	width: 280px;
	min-height: 200px;
	border: 1px solid #DDD;
	position: fixed;
	top: 200px;
	right: auto;
	bottom: auto;
	/*    left: -282px;*/
	left: 0px;
	z-index: 20;
	background-color: #ffffff;
	-webkit-border-radius: 0 3px 3px 0;
	-khtml-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	-o-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
}
.custom-box #costumizer {
	width: 40px;
	height: 46px;
	border: 1px solid #DDD;
	border-left-color: #FFF;
	display: block;
	cursor: pointer;
	font-size: 26px;
	line-height: 20px;
	padding: 9px 0 0 6px;
	position: absolute;
	top: 20px;
	right: -40px;
	bottom: auto;
	left: auto;
	z-index: 1;
	background-color: #ffffff;
	-webkit-border-radius: 0 3px 3px 0;
	-khtml-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	-o-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
}
.custom-box #costumizer span {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #999;
}
.custom-box #costumizer:hover span {
	color: #333;
}
.custom-box #costumizer:after {
	content: '';
	position: absolute;
	top: 0;
	right: auto;
	bottom: auto;
	left: -7px;
	z-index: 1;
	width: 10px;
	height: 100%;
	background-color: #ffffff;
}
.custom-box .title-cb {
	display: block;
	font-size: 16px;
	font-weight: 400;
	padding: 10px;
}
.custom-box .box-container {
	border-bottom: 1px solid #E8E8E8;
	padding-left: 10px;
	height: 100px;
}
.custom-box .box-container .color-bd {
	border: 1px solid #DDD;
}
.custom-box .box-container a {
	display: block;
	cursor: pointer;
	float: left;
	height: 40px;
	margin: 0 5px 5px 0;
	width: 82px;
}
.custom-box .box-container a.c1 {
	background-color: #06d0d8;
}
.custom-box .box-container a.c2 {
	background-color: #37c6f5;
}
.custom-box .box-container a.c3 {
	background-color: #ff2851;
}
.custom-box .box-container a.c4 {
	background-color: #a5d549;
}
.custom-box .box-container a.c5 {
	background-color: #ffdf2b;
}
.custom-box .box-container a.c6 {
	background-color: #ff871c;
}
.custom-box .text {
	font-size: 14px;
	font-style: italic;
	padding: 10px;
	line-height: 22px;
	display: block;
}
.custom-box .itemBlog:nth-child(1) {
	
	width: 284px;
}
.custom-box .box-container {
	
	height: 195px;
}
.custom-box .itemBlogPicture{
	width: 217px;
	height: 144px;
	background-size :contain;
	
}
.custom-box .itemBlog{
	
	padding-top: 20px;
}
.custom-box .box-container a {
	display: block;
	cursor: pointer;
	float: right;
	height: 40px;
	margin: 0 36px 0 0;
	width: 120px;
	/*    border: 1px solid #000;*/
	border: none;
	text-align: center;
}
.custom-box .text {
	
	padding-top: 15px;
}
.custom-box {
	background-color: #f8af1c;
	border: 1px solid #f8af1c;
}
.custom-box .box-container {
	border-bottom: none;
	padding-left: 10px;
	height: 100px;
}
.custom-box {
	left: auto;
	right: 0px;
}
.custom-box #costumizer {
	border: 1px solid #f8af1c;
	left: -86px;
	right: auto;
	background-color: #f8af1c;
	line-height: 25px;
	height: auto;
	width: 89px;
	font-size: 21px;
	text-align: center;
	padding: 6%;
}
.custom-box #costumizer:after {
	
	background-color: transparent;
}
.custom-box #costumizer span{
	color:#333;
}
.custom-box .box-container a:hover{
	color:#333;
}
.custom-box {
	width: 237px;
}
.custom-box {
	top:350px;
}
.custom-box .fa-times {
	position: absolute;
	top: 3px;
	left: 3px;
	font-size: 13px;
}
.custom-box #costumizer h4{
	font-size: 18px;
}


/* DONT KNOW */
#servicesIncluded .card-content img,#servicesOndemand .card-content img{
	object-fit: cover;
	height: 203px;
}

#stats .col-sm-1{
	width:auto;
}
@media (min-width: 1200px){
	.margin_top_contact {
		margin-top: 64px;
	}
}

@media (min-width: 992px) and  (max-width: 1199px) {
	.margin_top_contact {
		margin-top: 100px;
	}
}
.gallery-item h2.block-title,
.line-heigth-h5{
	line-height: 25.88px;
}

.like_h2{
	letter-spacing: unset;
	font-size: 31px;
	line-height: 42px;
}


@media (max-width: 767px) {
	.nav-tabs-simple > li {
		margin-bottom: 0;
		width: 50%;
	}
}

.nav-tab-dropdown .cs-select .cs-placeholder {
	background: #fafafa !important;
}