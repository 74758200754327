.hidden{
	display: none!important;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl,
.visible-xxl{
	display: none!important;
}
.visible{
	display: inline-block!important;
}
.rounded-0{
	border-radius: 0 !important;
}
.rounded-1{
	border-radius: 0.0625rem !important;
}
.rounded-2{
	border-radius: 0.125rem !important;
}
.rounded-3{
	border-radius: 0.188rem !important;
}
.rounded-4{
	border-radius: 0.25rem !important;
}
.rounded-5{
	border-radius: 0.313rem !important;
}
.rounded-6{
	border-radius: 0.375rem !important;
}
.rounded-7{
	border-radius: 0.438rem !important;
}
.rounded-8{
	border-radius: 0.5rem !important;
}
.rounded-9{
	border-radius: 0.563rem !important;
}
.rounded-10{
	border-radius: 0.625rem !important;
}

.mt-20px{
	margin-top: 20px !important;
}


.rounded-top-0{
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}
.rounded-top-1{
	border-top-left-radius: 0.0625rem !important;
	border-top-right-radius: 0.0625rem !important;
}
.rounded-top-2{
	border-top-left-radius: 0.125rem !important;
	border-top-right-radius: 0.125rem !important;
}
.rounded-top-3{
	border-top-left-radius: 0.188rem !important;
	border-top-right-radius: 0.188rem !important;
}
.rounded-top-4{
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
}
.rounded-top-5{
	border-top-left-radius: 0.313rem !important;
	border-top-right-radius: 0.313rem !important;
}
.rounded-top-6{
	border-top-left-radius: 0.375rem !important;
	border-top-right-radius: 0.375rem !important;
}
.rounded-top-7{
	border-top-left-radius: 0.438rem !important;
	border-top-right-radius: 0.438rem !important;
}
.rounded-top-8{
	border-top-left-radius: 0.5rem !important;
	border-top-right-radius: 0.5rem !important;
}
.rounded-top-9{
	border-top-left-radius: 0.563rem !important;
	border-top-right-radius: 0.563rem !important;
}
.rounded-top-10{
	border-top-left-radius: 0.625rem !important;
	border-top-right-radius: 0.625rem !important;
}


.rounded-end-0{
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.rounded-end-1{
	border-top-right-radius: 0.0625rem !important;
	border-bottom-right-radius: 0.0625rem !important;
}
.rounded-end-2{
	border-top-right-radius: 0.125rem !important;
	border-bottom-right-radius: 0.125rem !important;
}
.rounded-end-3{
	border-top-right-radius: 0.188rem !important;
	border-bottom-right-radius: 0.188rem !important;
}
.rounded-end-4{
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}
.rounded-end-5{
	border-top-right-radius: 0.313rem !important;
	border-bottom-right-radius: 0.313rem !important;
}
.rounded-end-6{
	border-top-right-radius: 0.375rem !important;
	border-bottom-right-radius: 0.375rem !important;
}
.rounded-end-7{
	border-top-right-radius: 0.438rem !important;
	border-bottom-right-radius: 0.438rem !important;
}
.rounded-end-8{
	border-top-right-radius: 0.5rem !important;
	border-bottom-right-radius: 0.5rem !important;
}
.rounded-end-9{
	border-top-right-radius: 0.563rem !important;
	border-bottom-right-radius: 0.563rem !important;
}
.rounded-end-10{
	border-top-right-radius: 0.625rem !important;
	border-bottom-right-radius: 0.625rem !important;
}

.rounded-bottom-0{
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.rounded-bottom-1{
	border-bottom-left-radius: 0.0625rem !important;
	border-bottom-right-radius: 0.0625rem !important;
}
.rounded-bottom-2{
	border-bottom-left-radius: 0.125rem !important;
	border-bottom-right-radius: 0.125rem !important;
}
.rounded-bottom-3{
	border-bottom-left-radius: 0.188rem !important;
	border-bottom-right-radius: 0.188rem !important;
}
.rounded-bottom-4{
	border-bottom-left-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom-5{
	border-bottom-left-radius: 0.313rem !important;
	border-bottom-right-radius: 0.313rem !important;
}
.rounded-bottom-6{
	border-bottom-left-radius: 0.375rem !important;
	border-bottom-right-radius: 0.375rem !important;
}
.rounded-bottom-7{
	border-bottom-left-radius: 0.438rem !important;
	border-bottom-right-radius: 0.438rem !important;
}
.rounded-bottom-8{
	border-bottom-left-radius: 0.5rem !important;
	border-bottom-right-radius: 0.5rem !important;
}
.rounded-bottom-9{
	border-bottom-left-radius: 0.563rem !important;
	border-bottom-right-radius: 0.563rem !important;
}
.rounded-bottom-10{
	border-bottom-left-radius: 0.625rem !important;
	border-bottom-right-radius: 0.625rem !important;
}

.rounded-start-0{
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.rounded-start-1{
	border-top-left-radius: 0.0625rem !important;
	border-bottom-left-radius: 0.0625rem !important;
}
.rounded-start-2{
	border-top-left-radius: 0.125rem !important;
	border-bottom-left-radius: 0.125rem !important;
}
.rounded-start-3{
	border-top-left-radius: 0.188rem !important;
	border-bottom-left-radius: 0.188rem !important;
}
.rounded-start-4{
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}
.rounded-start-5{
	border-top-left-radius: 0.313rem !important;
	border-bottom-left-radius: 0.313rem !important;
}
.rounded-start-6{
	border-top-left-radius: 0.375rem !important;
	border-bottom-left-radius: 0.375rem !important;
}
.rounded-start-7{
	border-top-left-radius: 0.438rem !important;
	border-bottom-left-radius: 0.438rem !important;
}
.rounded-start-8{
	border-top-left-radius: 0.5rem !important;
	border-bottom-left-radius: 0.5rem !important;
}
.rounded-start-9{
	border-top-left-radius: 0.563rem !important;
	border-bottom-left-radius: 0.563rem !important;
}
.rounded-start-10{
	border-top-left-radius: 0.625rem !important;
	border-bottom-left-radius: 0.625rem !important;
}

@media (max-width: 576px) {
	.hidden-xs {
		display: none!important;
	}
	.visible-xs {
		display: block!important;
	}
	.d-xs-flex{
		display: flex!important;
	}
	.pt-xs-0{
		padding-top: 0!important;
	}
	.pb-xs-0{
		padding-bottom: 0!important;
	}
	.ps-xs-0{
		padding-left: 0!important;
	}
	.pe-xs-0{
		padding-right: 0!important;
	}

	.pt-xs-1 {
		padding-bottom: 0.25rem !important;
	}

	.pt-xs-2 {
		padding-bottom: 0.5rem !important;
	}

	.pt-xs-3 {
		padding-bottom: 1rem !important;
	}

	.pt-xs-4 {
		padding-bottom: 1.5rem !important;
	}

	.pt-xs-5 {
		padding-bottom: 3rem !important;
	}

	.pb-xs-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-xs-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-xs-3 {
		padding-bottom: 1rem !important;
	}

	.pb-xs-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-xs-5 {
		padding-bottom: 3rem !important;
	}

	.mb-xs-0{
		margin-bottom: 0 !important;
	}
	.mb-xs-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-xs-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-xs-3 {
		margin-bottom: 1rem !important;
	}

	.mb-xs-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-xs-5 {
		margin-bottom: 3rem !important;
	}
}

@media (min-width: 576px) and (max-width: 768px) {
	.visible-sm {
		display: block!important;
	}
	.d-sm-flex{
		display: flex!important;
	}

	.hidden-sm {
		display: none!important;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.visible-md {
		display: block!important;
	}
	.d-md-flex{
		display: flex!important;
	}
	.hidden-md {
		display: none!important;
	}
}

@media (min-width: 992px) and (max-width: 1200px){
	.visible-lg {
		display: block!important;
	}
	.d-lg-flex{
		display: flex!important;
	}
	.hidden-lg {
		display: none!important;
	}
}

@media (min-width: 1200px) and (max-width: 1400px) {
	.visible-xl {
		display: block!important;
	}
	.d-xl-flex{
		display: flex!important;
	}
	.hidden-xl {
		display: none!important;
	}
}

@media (min-width: 1400px) {
	.visible-xxl {
		display: block!important;
	}
	.d-xxl-flex{
		display: flex!important;
	}
	.hidden-xxl {
		display: none!important;
	}
}

@media (max-width: 576px){
	.xs-flex-row-reverse {
		flex-direction: row-reverse !important;
	}
	.xs-flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	/*----------------------
	[- TOP ROUNDED]
	 */
	.xs-rounded-top-0{
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
	.xs-rounded-top-1{
		border-top-left-radius: 0.0625rem !important;
		border-top-right-radius: 0.0625rem !important;
	}
	.xs-rounded-top-2{
		border-top-left-radius: 0.125rem !important;
		border-top-right-radius: 0.125rem !important;
	}
	.xs-rounded-top-3{
		border-top-left-radius: 0.188rem !important;
		border-top-right-radius: 0.188rem !important;
	}
	.xs-rounded-top-4{
		border-top-left-radius: 0.25rem !important;
		border-top-right-radius: 0.25rem !important;
	}
	.xs-rounded-top-5{
		border-top-left-radius: 0.313rem !important;
		border-top-right-radius: 0.313rem !important;
	}
	.xs-rounded-top-6{
		border-top-left-radius: 0.375rem !important;
		border-top-right-radius: 0.375rem !important;
	}
	.xs-rounded-top-7{
		border-top-left-radius: 0.438rem !important;
		border-top-right-radius: 0.438rem !important;
	}
	.xs-rounded-top-8{
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
	.xs-rounded-top-9{
		border-top-left-radius: 0.563rem !important;
		border-top-right-radius: 0.563rem !important;
	}
	.xs-rounded-top-10{
		border-top-left-radius: 0.625rem !important;
		border-top-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- END ROUNDED]
	 */
	.xs-rounded-end-0{
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.xs-rounded-end-1{
		border-top-right-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.xs-rounded-end-2{
		border-top-right-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.xs-rounded-end-3{
		border-top-right-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.xs-rounded-end-4{
		border-top-right-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.xs-rounded-end-5{
		border-top-right-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.xs-rounded-end-6{
		border-top-right-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.xs-rounded-end-7{
		border-top-right-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.xs-rounded-end-8{
		border-top-right-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.xs-rounded-end-9{
		border-top-right-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.xs-rounded-end-10{
		border-top-right-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- BOTTOM ROUNDED]
	 */
	.xs-rounded-bottom-0{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.xs-rounded-bottom-1{
		border-bottom-left-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.xs-rounded-bottom-2{
		border-bottom-left-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.xs-rounded-bottom-3{
		border-bottom-left-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.xs-rounded-bottom-4{
		border-bottom-left-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.xs-rounded-bottom-5{
		border-bottom-left-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.xs-rounded-bottom-6{
		border-bottom-left-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.xs-rounded-bottom-7{
		border-bottom-left-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.xs-rounded-bottom-8{
		border-bottom-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.xs-rounded-bottom-9{
		border-bottom-left-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.xs-rounded-bottom-10{
		border-bottom-left-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- START ROUNDED]
	 */
	.xs-rounded-start-0{
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.xs-rounded-start-1{
		border-top-left-radius: 0.0625rem !important;
		border-bottom-left-radius: 0.0625rem !important;
	}
	.xs-rounded-start-2{
		border-top-left-radius: 0.125rem !important;
		border-bottom-left-radius: 0.125rem !important;
	}
	.xs-rounded-start-3{
		border-top-left-radius: 0.188rem !important;
		border-bottom-left-radius: 0.188rem !important;
	}
	.xs-rounded-start-4{
		border-top-left-radius: 0.25rem !important;
		border-bottom-left-radius: 0.25rem !important;
	}
	.xs-rounded-start-5{
		border-top-left-radius: 0.313rem !important;
		border-bottom-left-radius: 0.313rem !important;
	}
	.xs-rounded-start-6{
		border-top-left-radius: 0.375rem !important;
		border-bottom-left-radius: 0.375rem !important;
	}
	.xs-rounded-start-7{
		border-top-left-radius: 0.438rem !important;
		border-bottom-left-radius: 0.438rem !important;
	}
	.xs-rounded-start-8{
		border-top-left-radius: 0.5rem !important;
		border-bottom-left-radius: 0.5rem !important;
	}
	.xs-rounded-start-9{
		border-top-left-radius: 0.563rem !important;
		border-bottom-left-radius: 0.563rem !important;
	}
	.xs-rounded-start-10{
		border-top-left-radius: 0.625rem !important;
		border-bottom-left-radius: 0.625rem !important;
	}
}
@media (min-width: 576px){
	.sm-flex-row-reverse {
		flex-direction: row-reverse !important;
	}
	.sm-flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	/*----------------------
	[- TOP ROUNDED]
	 */
	.sm-rounded-top-0{
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
	.sm-rounded-top-1{
		border-top-left-radius: 0.0625rem !important;
		border-top-right-radius: 0.0625rem !important;
	}
	.sm-rounded-top-2{
		border-top-left-radius: 0.125rem !important;
		border-top-right-radius: 0.125rem !important;
	}
	.sm-rounded-top-3{
		border-top-left-radius: 0.188rem !important;
		border-top-right-radius: 0.188rem !important;
	}
	.sm-rounded-top-4{
		border-top-left-radius: 0.25rem !important;
		border-top-right-radius: 0.25rem !important;
	}
	.sm-rounded-top-5{
		border-top-left-radius: 0.313rem !important;
		border-top-right-radius: 0.313rem !important;
	}
	.sm-rounded-top-6{
		border-top-left-radius: 0.375rem !important;
		border-top-right-radius: 0.375rem !important;
	}
	.sm-rounded-top-7{
		border-top-left-radius: 0.438rem !important;
		border-top-right-radius: 0.438rem !important;
	}
	.sm-rounded-top-8{
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
	.sm-rounded-top-9{
		border-top-left-radius: 0.563rem !important;
		border-top-right-radius: 0.563rem !important;
	}
	.sm-rounded-top-10{
		border-top-left-radius: 0.625rem !important;
		border-top-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- END ROUNDED]
	 */
	.sm-rounded-end-0{
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.sm-rounded-end-1{
		border-top-right-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.sm-rounded-end-2{
		border-top-right-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.sm-rounded-end-3{
		border-top-right-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.sm-rounded-end-4{
		border-top-right-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.sm-rounded-end-5{
		border-top-right-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.sm-rounded-end-6{
		border-top-right-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.sm-rounded-end-7{
		border-top-right-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.sm-rounded-end-8{
		border-top-right-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.sm-rounded-end-9{
		border-top-right-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.sm-rounded-end-10{
		border-top-right-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- BOTTOM ROUNDED]
	 */
	.sm-rounded-bottom-0{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.sm-rounded-bottom-1{
		border-bottom-left-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.sm-rounded-bottom-2{
		border-bottom-left-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.sm-rounded-bottom-3{
		border-bottom-left-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.sm-rounded-bottom-4{
		border-bottom-left-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.sm-rounded-bottom-5{
		border-bottom-left-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.sm-rounded-bottom-6{
		border-bottom-left-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.sm-rounded-bottom-7{
		border-bottom-left-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.sm-rounded-bottom-8{
		border-bottom-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.sm-rounded-bottom-9{
		border-bottom-left-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.sm-rounded-bottom-10{
		border-bottom-left-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- START ROUNDED]
	 */
	.sm-rounded-start-0{
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.sm-rounded-start-1{
		border-top-left-radius: 0.0625rem !important;
		border-bottom-left-radius: 0.0625rem !important;
	}
	.sm-rounded-start-2{
		border-top-left-radius: 0.125rem !important;
		border-bottom-left-radius: 0.125rem !important;
	}
	.sm-rounded-start-3{
		border-top-left-radius: 0.188rem !important;
		border-bottom-left-radius: 0.188rem !important;
	}
	.sm-rounded-start-4{
		border-top-left-radius: 0.25rem !important;
		border-bottom-left-radius: 0.25rem !important;
	}
	.sm-rounded-start-5{
		border-top-left-radius: 0.313rem !important;
		border-bottom-left-radius: 0.313rem !important;
	}
	.sm-rounded-start-6{
		border-top-left-radius: 0.375rem !important;
		border-bottom-left-radius: 0.375rem !important;
	}
	.sm-rounded-start-7{
		border-top-left-radius: 0.438rem !important;
		border-bottom-left-radius: 0.438rem !important;
	}
	.sm-rounded-start-8{
		border-top-left-radius: 0.5rem !important;
		border-bottom-left-radius: 0.5rem !important;
	}
	.sm-rounded-start-9{
		border-top-left-radius: 0.563rem !important;
		border-bottom-left-radius: 0.563rem !important;
	}
	.sm-rounded-start-10{
		border-top-left-radius: 0.625rem !important;
		border-bottom-left-radius: 0.625rem !important;
	}
}
@media (min-width: 768px){
	.md-flex-row-reverse {
		flex-direction: row-reverse !important;
	}
	.md-flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	/*----------------------
	[- TOP ROUNDED]
	 */
	.md-rounded-top-0{
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
	.md-rounded-top-1{
		border-top-left-radius: 0.0625rem !important;
		border-top-right-radius: 0.0625rem !important;
	}
	.md-rounded-top-2{
		border-top-left-radius: 0.125rem !important;
		border-top-right-radius: 0.125rem !important;
	}
	.md-rounded-top-3{
		border-top-left-radius: 0.188rem !important;
		border-top-right-radius: 0.188rem !important;
	}
	.md-rounded-top-4{
		border-top-left-radius: 0.25rem !important;
		border-top-right-radius: 0.25rem !important;
	}
	.md-rounded-top-5{
		border-top-left-radius: 0.313rem !important;
		border-top-right-radius: 0.313rem !important;
	}
	.md-rounded-top-6{
		border-top-left-radius: 0.375rem !important;
		border-top-right-radius: 0.375rem !important;
	}
	.md-rounded-top-7{
		border-top-left-radius: 0.438rem !important;
		border-top-right-radius: 0.438rem !important;
	}
	.md-rounded-top-8{
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
	.md-rounded-top-9{
		border-top-left-radius: 0.563rem !important;
		border-top-right-radius: 0.563rem !important;
	}
	.md-rounded-top-10{
		border-top-left-radius: 0.625rem !important;
		border-top-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- END ROUNDED]
	 */
	.md-rounded-end-0{
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.md-rounded-end-1{
		border-top-right-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.md-rounded-end-2{
		border-top-right-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.md-rounded-end-3{
		border-top-right-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.md-rounded-end-4{
		border-top-right-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.md-rounded-end-5{
		border-top-right-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.md-rounded-end-6{
		border-top-right-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.md-rounded-end-7{
		border-top-right-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.md-rounded-end-8{
		border-top-right-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.md-rounded-end-9{
		border-top-right-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.md-rounded-end-10{
		border-top-right-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- BOTTOM ROUNDED]
	 */
	.md-rounded-bottom-0{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.md-rounded-bottom-1{
		border-bottom-left-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.md-rounded-bottom-2{
		border-bottom-left-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.md-rounded-bottom-3{
		border-bottom-left-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.md-rounded-bottom-4{
		border-bottom-left-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.md-rounded-bottom-5{
		border-bottom-left-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.md-rounded-bottom-6{
		border-bottom-left-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.md-rounded-bottom-7{
		border-bottom-left-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.md-rounded-bottom-8{
		border-bottom-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.md-rounded-bottom-9{
		border-bottom-left-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.md-rounded-bottom-10{
		border-bottom-left-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- START ROUNDED]
	 */
	.md-rounded-start-0{
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.md-rounded-start-1{
		border-top-left-radius: 0.0625rem !important;
		border-bottom-left-radius: 0.0625rem !important;
	}
	.md-rounded-start-2{
		border-top-left-radius: 0.125rem !important;
		border-bottom-left-radius: 0.125rem !important;
	}
	.md-rounded-start-3{
		border-top-left-radius: 0.188rem !important;
		border-bottom-left-radius: 0.188rem !important;
	}
	.md-rounded-start-4{
		border-top-left-radius: 0.25rem !important;
		border-bottom-left-radius: 0.25rem !important;
	}
	.md-rounded-start-5{
		border-top-left-radius: 0.313rem !important;
		border-bottom-left-radius: 0.313rem !important;
	}
	.md-rounded-start-6{
		border-top-left-radius: 0.375rem !important;
		border-bottom-left-radius: 0.375rem !important;
	}
	.md-rounded-start-7{
		border-top-left-radius: 0.438rem !important;
		border-bottom-left-radius: 0.438rem !important;
	}
	.md-rounded-start-8{
		border-top-left-radius: 0.5rem !important;
		border-bottom-left-radius: 0.5rem !important;
	}
	.md-rounded-start-9{
		border-top-left-radius: 0.563rem !important;
		border-bottom-left-radius: 0.563rem !important;
	}
	.md-rounded-start-10{
		border-top-left-radius: 0.625rem !important;
		border-bottom-left-radius: 0.625rem !important;
	}
	.md-p-r-18{
		padding-right: 18px!important;
	}
	.md-p-r-24{
		padding-right: 24px!important;
	}
	.text-md-justify,
	.text-md-justify *{
		text-align: justify;
	}
}
@media (min-width: 992px){
	.lg-flex-row-reverse {
		flex-direction: row-reverse !important;
	}
	.lg-flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	/*----------------------
	[- TOP ROUNDED]
	 */
	.lg-rounded-top-0{
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
	.lg-rounded-top-1{
		border-top-left-radius: 0.0625rem !important;
		border-top-right-radius: 0.0625rem !important;
	}
	.lg-rounded-top-2{
		border-top-left-radius: 0.125rem !important;
		border-top-right-radius: 0.125rem !important;
	}
	.lg-rounded-top-3{
		border-top-left-radius: 0.188rem !important;
		border-top-right-radius: 0.188rem !important;
	}
	.lg-rounded-top-4{
		border-top-left-radius: 0.25rem !important;
		border-top-right-radius: 0.25rem !important;
	}
	.lg-rounded-top-5{
		border-top-left-radius: 0.313rem !important;
		border-top-right-radius: 0.313rem !important;
	}
	.lg-rounded-top-6{
		border-top-left-radius: 0.375rem !important;
		border-top-right-radius: 0.375rem !important;
	}
	.lg-rounded-top-7{
		border-top-left-radius: 0.438rem !important;
		border-top-right-radius: 0.438rem !important;
	}
	.lg-rounded-top-8{
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
	.lg-rounded-top-9{
		border-top-left-radius: 0.563rem !important;
		border-top-right-radius: 0.563rem !important;
	}
	.lg-rounded-top-10{
		border-top-left-radius: 0.625rem !important;
		border-top-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- END ROUNDED]
	 */
	.lg-rounded-end-0{
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.lg-rounded-end-1{
		border-top-right-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.lg-rounded-end-2{
		border-top-right-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.lg-rounded-end-3{
		border-top-right-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.lg-rounded-end-4{
		border-top-right-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.lg-rounded-end-5{
		border-top-right-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.lg-rounded-end-6{
		border-top-right-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.lg-rounded-end-7{
		border-top-right-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.lg-rounded-end-8{
		border-top-right-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.lg-rounded-end-9{
		border-top-right-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.lg-rounded-end-10{
		border-top-right-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- BOTTOM ROUNDED]
	 */
	.lg-rounded-bottom-0{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.lg-rounded-bottom-1{
		border-bottom-left-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.lg-rounded-bottom-2{
		border-bottom-left-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.lg-rounded-bottom-3{
		border-bottom-left-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.lg-rounded-bottom-4{
		border-bottom-left-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.lg-rounded-bottom-5{
		border-bottom-left-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.lg-rounded-bottom-6{
		border-bottom-left-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.lg-rounded-bottom-7{
		border-bottom-left-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.lg-rounded-bottom-8{
		border-bottom-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.lg-rounded-bottom-9{
		border-bottom-left-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.lg-rounded-bottom-10{
		border-bottom-left-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- START ROUNDED]
	 */
	.lg-rounded-start-0{
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.lg-rounded-start-1{
		border-top-left-radius: 0.0625rem !important;
		border-bottom-left-radius: 0.0625rem !important;
	}
	.lg-rounded-start-2{
		border-top-left-radius: 0.125rem !important;
		border-bottom-left-radius: 0.125rem !important;
	}
	.lg-rounded-start-3{
		border-top-left-radius: 0.188rem !important;
		border-bottom-left-radius: 0.188rem !important;
	}
	.lg-rounded-start-4{
		border-top-left-radius: 0.25rem !important;
		border-bottom-left-radius: 0.25rem !important;
	}
	.lg-rounded-start-5{
		border-top-left-radius: 0.313rem !important;
		border-bottom-left-radius: 0.313rem !important;
	}
	.lg-rounded-start-6{
		border-top-left-radius: 0.375rem !important;
		border-bottom-left-radius: 0.375rem !important;
	}
	.lg-rounded-start-7{
		border-top-left-radius: 0.438rem !important;
		border-bottom-left-radius: 0.438rem !important;
	}
	.lg-rounded-start-8{
		border-top-left-radius: 0.5rem !important;
		border-bottom-left-radius: 0.5rem !important;
	}
	.lg-rounded-start-9{
		border-top-left-radius: 0.563rem !important;
		border-bottom-left-radius: 0.563rem !important;
	}
	.lg-rounded-start-10{
		border-top-left-radius: 0.625rem !important;
		border-bottom-left-radius: 0.625rem !important;
	}
}
@media (min-width: 1200px){
	.xl-flex-row-reverse {
		flex-direction: row-reverse !important;
	}
	.xl-flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	/*----------------------
	[- TOP ROUNDED]
	 */
	.xl-rounded-top-0{
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
	.xl-rounded-top-1{
		border-top-left-radius: 0.0625rem !important;
		border-top-right-radius: 0.0625rem !important;
	}
	.xl-rounded-top-2{
		border-top-left-radius: 0.125rem !important;
		border-top-right-radius: 0.125rem !important;
	}
	.xl-rounded-top-3{
		border-top-left-radius: 0.188rem !important;
		border-top-right-radius: 0.188rem !important;
	}
	.xl-rounded-top-4{
		border-top-left-radius: 0.25rem !important;
		border-top-right-radius: 0.25rem !important;
	}
	.xl-rounded-top-5{
		border-top-left-radius: 0.313rem !important;
		border-top-right-radius: 0.313rem !important;
	}
	.xl-rounded-top-6{
		border-top-left-radius: 0.375rem !important;
		border-top-right-radius: 0.375rem !important;
	}
	.xl-rounded-top-7{
		border-top-left-radius: 0.438rem !important;
		border-top-right-radius: 0.438rem !important;
	}
	.xl-rounded-top-8{
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
	.xl-rounded-top-9{
		border-top-left-radius: 0.563rem !important;
		border-top-right-radius: 0.563rem !important;
	}
	.xl-rounded-top-10{
		border-top-left-radius: 0.625rem !important;
		border-top-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- END ROUNDED]
	 */
	.xl-rounded-end-0{
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.xl-rounded-end-1{
		border-top-right-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.xl-rounded-end-2{
		border-top-right-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.xl-rounded-end-3{
		border-top-right-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.xl-rounded-end-4{
		border-top-right-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.xl-rounded-end-5{
		border-top-right-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.xl-rounded-end-6{
		border-top-right-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.xl-rounded-end-7{
		border-top-right-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.xl-rounded-end-8{
		border-top-right-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.xl-rounded-end-9{
		border-top-right-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.xl-rounded-end-10{
		border-top-right-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- BOTTOM ROUNDED]
	 */
	.xl-rounded-bottom-0{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.xl-rounded-bottom-1{
		border-bottom-left-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.xl-rounded-bottom-2{
		border-bottom-left-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.xl-rounded-bottom-3{
		border-bottom-left-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.xl-rounded-bottom-4{
		border-bottom-left-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.xl-rounded-bottom-5{
		border-bottom-left-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.xl-rounded-bottom-6{
		border-bottom-left-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.xl-rounded-bottom-7{
		border-bottom-left-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.xl-rounded-bottom-8{
		border-bottom-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.xl-rounded-bottom-9{
		border-bottom-left-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.xl-rounded-bottom-10{
		border-bottom-left-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- START ROUNDED]
	 */
	.xl-rounded-start-0{
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.xl-rounded-start-1{
		border-top-left-radius: 0.0625rem !important;
		border-bottom-left-radius: 0.0625rem !important;
	}
	.xl-rounded-start-2{
		border-top-left-radius: 0.125rem !important;
		border-bottom-left-radius: 0.125rem !important;
	}
	.xl-rounded-start-3{
		border-top-left-radius: 0.188rem !important;
		border-bottom-left-radius: 0.188rem !important;
	}
	.xl-rounded-start-4{
		border-top-left-radius: 0.25rem !important;
		border-bottom-left-radius: 0.25rem !important;
	}
	.xl-rounded-start-5{
		border-top-left-radius: 0.313rem !important;
		border-bottom-left-radius: 0.313rem !important;
	}
	.xl-rounded-start-6{
		border-top-left-radius: 0.375rem !important;
		border-bottom-left-radius: 0.375rem !important;
	}
	.xl-rounded-start-7{
		border-top-left-radius: 0.438rem !important;
		border-bottom-left-radius: 0.438rem !important;
	}
	.xl-rounded-start-8{
		border-top-left-radius: 0.5rem !important;
		border-bottom-left-radius: 0.5rem !important;
	}
	.xl-rounded-start-9{
		border-top-left-radius: 0.563rem !important;
		border-bottom-left-radius: 0.563rem !important;
	}
	.xl-rounded-start-10{
		border-top-left-radius: 0.625rem !important;
		border-bottom-left-radius: 0.625rem !important;
	}
}

@media (min-width: 1440px){
	.xxl-flex-row-reverse {
		flex-direction: row-reverse !important;
	}
	.xxl-flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	/*----------------------
	[- TOP ROUNDED]
	 */
	.xxl-rounded-top-0{
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
	.xxl-rounded-top-1{
		border-top-left-radius: 0.0625rem !important;
		border-top-right-radius: 0.0625rem !important;
	}
	.xxl-rounded-top-2{
		border-top-left-radius: 0.125rem !important;
		border-top-right-radius: 0.125rem !important;
	}
	.xxl-rounded-top-3{
		border-top-left-radius: 0.188rem !important;
		border-top-right-radius: 0.188rem !important;
	}
	.xxl-rounded-top-4{
		border-top-left-radius: 0.25rem !important;
		border-top-right-radius: 0.25rem !important;
	}
	.xxl-rounded-top-5{
		border-top-left-radius: 0.313rem !important;
		border-top-right-radius: 0.313rem !important;
	}
	.xxl-rounded-top-6{
		border-top-left-radius: 0.375rem !important;
		border-top-right-radius: 0.375rem !important;
	}
	.xxl-rounded-top-7{
		border-top-left-radius: 0.438rem !important;
		border-top-right-radius: 0.438rem !important;
	}
	.xxl-rounded-top-8{
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
	.xxl-rounded-top-9{
		border-top-left-radius: 0.563rem !important;
		border-top-right-radius: 0.563rem !important;
	}
	.xxl-rounded-top-10{
		border-top-left-radius: 0.625rem !important;
		border-top-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- END ROUNDED]
	 */
	.xxl-rounded-end-0{
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.xxl-rounded-end-1{
		border-top-right-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.xxl-rounded-end-2{
		border-top-right-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.xxl-rounded-end-3{
		border-top-right-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.xxl-rounded-end-4{
		border-top-right-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.xxl-rounded-end-5{
		border-top-right-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.xxl-rounded-end-6{
		border-top-right-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.xxl-rounded-end-7{
		border-top-right-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.xxl-rounded-end-8{
		border-top-right-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.xxl-rounded-end-9{
		border-top-right-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.xxl-rounded-end-10{
		border-top-right-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- BOTTOM ROUNDED]
	 */
	.xxl-rounded-bottom-0{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.xxl-rounded-bottom-1{
		border-bottom-left-radius: 0.0625rem !important;
		border-bottom-right-radius: 0.0625rem !important;
	}
	.xxl-rounded-bottom-2{
		border-bottom-left-radius: 0.125rem !important;
		border-bottom-right-radius: 0.125rem !important;
	}
	.xxl-rounded-bottom-3{
		border-bottom-left-radius: 0.188rem !important;
		border-bottom-right-radius: 0.188rem !important;
	}
	.xxl-rounded-bottom-4{
		border-bottom-left-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;
	}
	.xxl-rounded-bottom-5{
		border-bottom-left-radius: 0.313rem !important;
		border-bottom-right-radius: 0.313rem !important;
	}
	.xxl-rounded-bottom-6{
		border-bottom-left-radius: 0.375rem !important;
		border-bottom-right-radius: 0.375rem !important;
	}
	.xxl-rounded-bottom-7{
		border-bottom-left-radius: 0.438rem !important;
		border-bottom-right-radius: 0.438rem !important;
	}
	.xxl-rounded-bottom-8{
		border-bottom-left-radius: 0.5rem !important;
		border-bottom-right-radius: 0.5rem !important;
	}
	.xxl-rounded-bottom-9{
		border-bottom-left-radius: 0.563rem !important;
		border-bottom-right-radius: 0.563rem !important;
	}
	.xxl-rounded-bottom-10{
		border-bottom-left-radius: 0.625rem !important;
		border-bottom-right-radius: 0.625rem !important;
	}

	/*----------------------
	[- START ROUNDED]
	 */
	.xxl-rounded-start-0{
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.xxl-rounded-start-1{
		border-top-left-radius: 0.0625rem !important;
		border-bottom-left-radius: 0.0625rem !important;
	}
	.xxl-rounded-start-2{
		border-top-left-radius: 0.125rem !important;
		border-bottom-left-radius: 0.125rem !important;
	}
	.xxl-rounded-start-3{
		border-top-left-radius: 0.188rem !important;
		border-bottom-left-radius: 0.188rem !important;
	}
	.xxl-rounded-start-4{
		border-top-left-radius: 0.25rem !important;
		border-bottom-left-radius: 0.25rem !important;
	}
	.xxl-rounded-start-5{
		border-top-left-radius: 0.313rem !important;
		border-bottom-left-radius: 0.313rem !important;
	}
	.xxl-rounded-start-6{
		border-top-left-radius: 0.375rem !important;
		border-bottom-left-radius: 0.375rem !important;
	}
	.xxl-rounded-start-7{
		border-top-left-radius: 0.438rem !important;
		border-bottom-left-radius: 0.438rem !important;
	}
	.xxl-rounded-start-8{
		border-top-left-radius: 0.5rem !important;
		border-bottom-left-radius: 0.5rem !important;
	}
	.xxl-rounded-start-9{
		border-top-left-radius: 0.563rem !important;
		border-bottom-left-radius: 0.563rem !important;
	}
	.xxl-rounded-start-10{
		border-top-left-radius: 0.625rem !important;
		border-bottom-left-radius: 0.625rem !important;
	}
}
.pb-6{
	padding-bottom: 2px;
}
.p-t-100{
	padding-top: 100px;
}
.p-b-100{
	padding-bottom: 100px;
}
