@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
/*------------------------------------------------------------------
Builder v1.0
[Table of contents]

1. HEADER
2. OVERLAY
3. FORMS
4. SELECT 2
5. COLORS
6. FOOTER
7. TYPOGRAPHY
8. MODALS
9. ASPECT RATIO
10. CARDS
11. SWIPER
12. SWIPER-WITH-THUMBNAILS
13. BUTTONS
14. VIDEO
15. OPACITY
16. TEXT
17. BORDERS
18. SHADOWS
19. READ MORE ANIMATION
20. GOOGLE MAPS
21. STICKY
22. PROPERTIES
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[0. HTML CUSTOM ELEMENTS]
*/
jumbotron,
booking-bar,
description,
article,
articles,
blog,
experiences,
newsletter {
	display: block;
}
jumbotron .swiper .swiper-wrapper > span{
	width: 100%!important;
	height: 100%!important;
	max-width: 100%!important;
	max-height: 100%!important;
}
jumbotron img{
	width: 100%!important;
	height: 100%!important;
	max-width: 100%!important;
	max-height: 100%!important;
	object-fit: cover!important;
}
.bg-overlay{
	position: absolute;
	top: 0;
}
body{
	background-color: #fafafa;
}
@media only screen and (min-width: 991px) {
	body > div {
		/*display: flex;*/
		/*display: -webkit-flex;*/
		/*flex-direction: column;*/
		/*-ms-flex-direction: column;*/
		/*-webkit-flex-direction: column;*/
		/*min-height: 100%;*/
		/*margin: 0;*/
	}
}
/*------------------------------------------------------------------
[1. HEADER]
*/
.Header{
	top: 0;
	position: relative;
	display: flex;
	display: -webkit-flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	align-items: center;
	-webkit-align-items: center;
	padding: 0.5rem 0;
	height: 60px;
	width: 100%;
	z-index: 1022;
	-webkit-transition: background 0.4s ease, height 0.4s ease;
	transition: background 0.4s ease, height 0.4s ease;
}
.Header.Header-Double{
	display: block;
	height: auto;
	position: initial;
}
.Header .container,
.Header .container-fluid {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}
.Header.Header-Double .container,
.Header.Header-Centered .container,
.Header.Header-Double .container-fluid,
.Header.Header-Centered .container-fluid{
	display: block;
	flex-wrap: initial;
	align-items: initial;
	-webkit-align-items: initial;
	justify-content: initial;
	-webkit-justify-content: initial;
}
.Header.Header-Sticky-Top{
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}
.Header.Header-Light-Transparent,
.Header.Header-Dark-Transparent{
	background-color: transparent;
	min-height: 76px;
}
.Header.Header-Dark{
	background-color: rgba(0,0,0);
}
.Header.Header-Light{
	background-color: rgba(255,255,255);
}
.Header.Header-Light-Transparent .fa,
.Header.Header-Light-Transparent .fal,
.Header.Header-Light-Transparent .fas,
.Header.Header-Light-Transparent .far,
.Header.Header-Light-Transparent .fab,
.Header.Header-Light-Transparent svg,
.Header.Header-Light-Transparent .navbar-nav .nav-link{
	color: #000;
}
.Header.Header-Dark-Transparent .fa,
.Header.Header-Dark-Transparent .fal,
.Header.Header-Dark-Transparent .fas,
.Header.Header-Dark-Transparent .far,
.Header.Header-Dark-Transparent .fab,
.Header.Header-Dark-Transparent svg,
.Header.Header-Dark-Transparent .navbar-nav .nav-link{
	color: #fff;
}
.Header.Header-Light .fa,
.Header.Header-Light .fal,
.Header.Header-Light .fas,
.Header.Header-Light .far,
.Header.Header-Light .fab,
.Header.Header-Light svg,
.Header.Header-Light ul li a:not(.dropdown-item),
.Header.Header-Light .navbar-nav .nav-link {
	color: #000;
}
.Header.Header-Dark .fa,
.Header.Header-Dark .fal,
.Header.Header-Dark .fas,
.Header.Header-Dark .far,
.Header.Header-Dark .fab,
.Header.Header-Dark svg,
.Header.Header-Dark ul li a:not(.dropdown-item),
.Header.Header-Dark .navbar-nav .nav-link {
	color: #fff;
}
.Header.Header-Double.Header-Dark .b-r{
	border-color: rgba(255, 255, 255);
}
.Header.Header-Double.Header-Light .b-r{
	border-color: rgba(33, 33, 33, .14);
}

.Header.Header-Dark-Transparent .btn{
	color: rgba(255,255,255);
	background-color: transparent;
	border-color: rgba(255,255,255);
}
.Header.Header-Light-Transparent .btn{
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}

.Header.Header-Dark .btn {
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.Header.Header-Light .btn{
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.Header.Header-Dark .btn:hover:not(.active) {
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.Header.Header-Light .btn:hover:not(.active) {
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}

.Header.minimized.Header-Dark .navbar-nav .nav-link{
	color: rgba(255,255,255);
}
.Header.minimized.Header-Light .navbar-nav .nav-link{
	color: rgba(0,0,0);
}
.Header.minimized.Header-Dark .btn{
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.Header.minimized.Header-Light .btn{
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.Header.minimized.Header-Dark .btn:hover:not(.active){
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.Header.minimized.Header-Light .btn:hover:not(.active){
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.Modal-Header .modal-dialog,
.Modal-Header-Double .modal-dialog{
	width: auto;
	min-width: 280px;
	max-width: 280px;
}
.Modal-Header .modal-content{
	border: none!important;
}
.navbar{
	height: 60px;
	width: 100%;
	z-index: 800;
	top: 0;
}
.navbar .navbar-brand{
	margin-right: 0px;
}
/* -------------- COLOR STYLING --------------- */
.navbar-light-transparent,
.navbar-dark-transparent{
	background-color: transparent;
	min-height: 76px;
}
.navbar-dark{
	background-color: rgba(0,0,0);
}
.navbar-light{
	background-color: rgba(255,255,255);
}
.navbar-dark.border-bottom{
	border-bottom: rgba(0,0,0) !important;
}
.navbar-light.border-bottom{
	border-bottom: 1px solid rgba(255,255,255, .5) !important;
}
.navbar-light-transparent .fa,
.navbar-light-transparent .fal,
.navbar-light-transparent .fas,
.navbar-light-transparent .far,
.navbar-light-transparent .fab,
.navbar-light-transparent svg {
	color: #000;
}
.navbar-dark-transparent .fa,
.navbar-dark-transparent .fal,
.navbar-dark-transparent .fas,
.navbar-dark-transparent .far,
.navbar-dark-transparent .fab,
.navbar-dark-transparent svg {
	color: #fff;
}
.navbar-dark .fa,
.navbar-dark .fal,
.navbar-dark .fas,
.navbar-dark .far,
.navbar-dark .fab,
.navbar-dark svg {
	color: #fff;
}
.navbar-light .fa,
.navbar-light .fal,
.navbar-light .fas,
.navbar-light .far,
.navbar-light .fab,
.navbar-light svg {
	color: #000;
}
.navbar-dark-transparent .navbar-nav .nav-link{
	color: rgba(255,255,255);
}
.navbar-light-transparent .navbar-nav .nav-link{
	color: rgba(0,0,0);
}
.navbar-dark-transparent .btn{
	color: rgba(255,255,255);
	background-color: transparent;
	border-color: rgba(255,255,255);
}
.navbar-light-transparent .btn{
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.navbar-dark-transparent .btn:hover:not(.active){
	color: rgba(255,255,255);
	background-color: transparent;
	border-color: rgba(255,255,255);
}
.navbar-light-transparent .btn:hover:not(.active){
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.navbar-dark .navbar-nav .nav-link{
	color: rgba(255,255,255);
}
.navbar-light .navbar-nav .nav-link{
	color: rgba(0,0,0);
}
.navbar-dark .btn {
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.navbar-light .btn{
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.navbar-dark .btn:hover:not(.active) {
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.navbar-light .btn:hover:not(.active) {
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}

.minimized.navbar-dark .navbar-nav .nav-link{
	color: rgba(255,255,255);
}
.minimized.navbar-light .navbar-nav .nav-link{
	color: rgba(0,0,0);
}
.minimized.navbar-dark .btn{
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.minimized.navbar-light .btn{
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.minimized.navbar-dark .btn:hover:not(.active){
	color: rgba(255, 255, 255);
	background-color: transparent;
	border-color: rgba(255, 255, 255);
}
.minimized.navbar-light .btn:hover:not(.active){
	color: rgba(0,0,0);
	background-color: transparent;
	border-color: rgba(0,0,0);
}
.navbar-nav li.nav-item{
	display: inline-block;
	list-style: none;
	font-size: 13px;
	margin-right: 12px;
	vertical-align: middle;
	padding-left: 3px;
	line-height: 24px;
}
a.nav-link{
	line-height: initial;
	font-weight: bold;
	text-transform: uppercase;
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.nav-link{
	/*padding: .5rem 1rem!important;*/
}
.nav.inner{
	height: 60px;
}
.nav.inner > li > a{
	font-weight: 300;
	font-size: 14px;
	padding: 5px 10px;
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
/* -------------- END COLOR STYLING --------------- */


/* ----------------------- HEADER DROPDOWNS / LI STYLE ----------------------- */
header .dropdown-article-menu{
	margin-top: .34rem!important;
	padding: 0;
	left: 50%;
	right: 0;
	min-width: 190px;
	margin-left: -95px;
	border-radius: 0;
	background-color: rgba(255,255,255);
}
header .dropdown-lang-menu{
	margin-top: .22rem!important;
	padding: 0;
	left: 50%;
	right: 0;
	min-width: 150px;
	margin-left: -75px;
	border-radius: 0;
	background-color: rgba(255,255,255);
}
header .dropdown-menu:before{
	content: '';
	position: absolute;
	height: 8px;
	width: calc(50% - 7px);
	background-color: #fff;
	border-top: 1px solid rgba(0,0,0,.12);
	top: -7px;
}
header .dropdown-menu:after{
	content: '';
	position: absolute;
	height: 8px;
	right: 0;
	width: calc(50% - 7px);
	background-color: #fff;
	border-top: 1px solid rgba(0,0,0,.12);
	top: -7px;
}
header .dropdown-menu > li{
	opacity: 1;
	line-height: 24px;
	margin: 5px 25px;
	-webkit-transition: all .1s linear .1s;
	transition: all .1s linear .1s;
}
header ul.dropdown-menu{
	background-color: rgba(255,255,255);
	z-index: 1023 !important;
}
header ul.dropdown-menu:before{
	background-color: rgba(255,255,255);
}
header ul.dropdown-menu:after{
	background-color: rgba(255,255,255);
}
header ul.dropdown-menu > li > a{
	color: rgba(98,98,98);
}
header ul.dropdown-menu > li:first-child{
	margin-top: 20px;
}
header ul.dropdown-menu > li:last-child{
	margin-bottom: 20px;
}
header .dropdown-toggle::after{
	border: 0;
	font-size: 10px;
	content: none;
	margin-left: 0.25rem;
	vertical-align: bottom;
	font-family: "Font Awesome 5 Pro";
}
header .dropdown-menu > li > a{
	padding: 0;
	font-size: 11px;
	text-transform: uppercase;
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.dropdown-menu > li > a,
.dropdown-menu > .dropdown-item > a{
	line-height: 24px;
}
.lang-toggle:after{
	content: none!important;
}
/* ----------------------- END HEADER DROPDOWNS / LI STYLE ----------------------- */

header .dropdown-menu > li.arrow {
	background-repeat: no-repeat;
	width: 14px;
	height: 8px;
	left: calc(50% - 7px);
	position: absolute;
	top: -7px;
}
header .dropdown-menu > li.arrow {
	background-image: none;
	margin: 0!important;
}
header ul:not(.dropdown-menu) > li:last-child{
	margin: 0!important;
}

/* ------------------ SIDE MENU DROPDOWNS / LI STYLE ------------------ */
.modal-body .nav-link{
	color: rgba(0,0,0);
}
.modal-body.navbar-menu > ul > li{
	opacity: 1;
	line-height: 12px;
	margin: 5px 25px;
	-webkit-transition: all .1s linear .1s;
	transition: all .1s linear .1s;
}
.modal-body.navbar-menu > ul > li:first-child{
	margin-top: 20px;
}
.modal-body.navbar-menu > ul > li:last-child{
	margin-bottom: 20px;
}
.modal-body.navbar-menu .dropdown-menu > li{
	opacity: 1;
	line-height: 24px;
	margin: 5px 25px;
	-webkit-transition: all .1s linear .1s;
	transition: all .1s linear .1s;
}
.modal-body.navbar-menu .dropdown-menu > li.dropdown-ul-item:not(span):first-child{
	margin-top: 20px;
}
.modal-body.navbar-menu .dropdown-menu > li.dropdown-ul-item:last-child{
	margin-bottom: 20px;
}
.modal-body.navbar-menu .dropdown-menu > li > a{
	padding: 0;
	font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.modal-body.navbar-menu .dropdown-toggle::after{
	border: 0;
	font-size: 10px;
	content: '';
	margin-left: 0.25rem;
	vertical-align: bottom;
}

.no-bold a.nav-link,
.no-bold .nav.inner > li > a,
header.no-bold .dropdown-menu > li > a,
.modal-body.navbar-menu.no-bold .dropdown-menu > li > a{
	font-weight: normal;
}
header.no-bold .border-2,
.modal-body.no-bold .border-2 {
	border-width: 1.5px!important;
}
/* ------------------ END SIDE MENU DROPDOWN / LI STYLE ------------------ */

header .alt {
	display: none!important;
}
header .navbar-light-transparent .navbar-brand,
header .navbar-dark-transparent .navbar-brand{
	padding: 1.4375rem 1.875rem;
}
header.minimized{
	min-height: 60px;
}
header.minimized .navbar-brand{
	padding: 0.3125rem 0.875rem;
}
.logo{
	display: inline-block;
}
header.minimized .logo {
	display: none!important;
}
header.minimized .alt {
	display: inline-block!important;
}
.logo_header{
	max-height: 60px;
	max-width: 300px;
}
.logo-d-header{
	margin: 0 14px;
}
.collapse:not(.show){
	display: none;
}
.navbar-toggle-collapse{
	display: flex!important;
}
@media(max-width: 991px){
	.container{
		max-width: 100%;
	}
}

@media (max-width: 768px) {
	[data-builder-header="centered"] ul{
		display: none!important;
	}
	[data-builder-header="centered"] .navbar-toggle{
		display: inline-block!important;
	}
	[data-builder-header="centered"] [data-builder-center-logo]{
		width: 100%;
	}
	.navbar-toggle-collapse {
		display: none!important;
	}
}

li > ul{
	-webkit-transition: max-height 0.2s ease-in;
	transition: max-height 0.2s ease-in;
	border: 1px solid transparent;
	border-top: 0;
}

.fixed-top{
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
/*
 * [1. END HEADER]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[2. OVERLAY]
*/
.bg-overlay{
	height: 100%;
	width: 100%;
	top: 0;
}
.z-index-1{
	z-index: 1;
}
.z-index-2{
	z-index: 2;
}
.z-index-3{
	z-index: 3;
}
.z-index-4{
	z-index: 4;
}
.z-index-5{
	z-index: 5;
}
/*
 * [2. END OVERLAY]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[3. FORMS]
*/
.form-group-default.input-group-attached {
	position: relative;
}
.form-group-default.input-group-attached .input-group-btn {
	right: -1%;
}
.form-group-default.input-group-attached .input-group-btn > .btn {
	position: absolute;
	right: -1%;
	top: 0;
	height: auto;
	z-index: 2;
}
.no-border.focused{
	border: none!important;
}
/*
 * [3. END FORMS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[4. SELECT 2]
*/
.select2-selection{
	border: none!important;
}
.select2-container--open .select2-selection{
	border: none!important;
}
.no-arrow .select2-container .select2-selection .select2-selection__arrow b{
	display: none;
}
.bg-transparent .select2-container .select2-selection{
	background-color: transparent;
}
.text-white .select2-container .select2-selection .select2-selection__rendered{
	color: #fff;
}
/*.inner .form-group-default.form-group-default-select2 > label{*/
/*	z-index: 1052;*/
/*}*/
.form-group-default.form-group-default-select2 > label{
	 color: #4b4b4b;
 }
/*
 * [4. END SELECT 2]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[5. COLORS]
 */
.background-none{
	background: none;
}
.bg-master {
	background-color: #626262;
}
.bg-master-light {
	background-color: #e6e6e6;
}
.bg-master-lighter {
	background-color: #f0f0f0;
}
.bg-master-lightest {
	background-color: #fafafa;
}
.bg-master-dark {
	background-color: #2c2c2c;
}
.bg-master-darker {
	background-color: #1a1a1a;
}
.bg-master-darkest {
	background-color: #121212;
}
.bg-pages-body{
	background-color: #f4f4f4;
}

.background-none-important{
	background: none!important;
}
.bg-master-important {
	background-color: #626262!important;
}
.bg-master-light-important {
	background-color: #e6e6e6!important;
}
.bg-master-lighter-important {
	background-color: #f0f0f0!important;
}
.bg-master-lightest-important {
	background-color: #fafafa!important;
}
.bg-master-dark-important {
	background-color: #2c2c2c!important;
}
.bg-master-darker-important {
	background-color: #1a1a1a!important;
}
.bg-master-darkest-important {
	background-color: #121212!important;
}
/* Text Colors */
.text-master {
  color: #626262 !important;
}
.text-master-light {
  color: #e6e6e6 !important;
}
.text-menu {
  color: #788195 !important;
}
/*
 * [5. END COLORS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[6. FOOTER]
 */
.logo_footer {
	max-height: 40px;
	max-width: 200px;
	margin-bottom: 10px;
}
.mobile_store_logo {
	height: 40px;
}
@media (max-width: 420px) {
  .mobile_store_logo {
    height: 30px;
  }
}
.w3c_validate_logo {
	height: 20px;
}
a .loggia_logo_cube {
	opacity: 1;
}
a:hover .loggia_logo_cube {
	opacity: 0.8;
}
.loggia_logo_cube {
	height: 40px;
}
.loggia_logo_cube_right {
	fill:#F2F2F2;
}
.loggia_logo_cube_left {
	fill:#999999;
}
.loggia_logo_cube_top {
	fill:#CCCCCC;
}
footer.light-mode p,
footer.light-mode li a{
	color: rgba(0,0,0);
}
footer.dark-mode p,
footer.dark-mode li a{
	color: rgba(255,255,255);
}
footer.light-mode ul li a{
	border-right: 1px solid rgba(0,0,0,.3);
}
footer.dark-mode ul li a{
	border-right: 1px solid rgba(255,255,255,.3);
}

footer .light-mode p,
footer .light-mode li a{
	color: rgba(0,0,0);
}
footer .dark-mode p,
footer .dark-mode li a{
	color: rgba(255,255,255);
}
footer .light-mode ul li a{
	border-right: 1px solid rgba(0,0,0,.3);
}
footer .dark-mode ul li a{
	border-right: 1px solid rgba(255,255,255,.3);
}

footer ul li:last-child a{
	border: none;
}
/*
 * [6. END FOOTER]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[7. TYPOGRAPHY]
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 300;
  color: #2c2c2c;
}
h1 {
  font-size: 44px;
  line-height: 55px;
  letter-spacing: -0.009em;
}
h1.mega {
  font-size: 110px;
  line-height: 89px;
}
h2 {
  font-size: 31px;
  line-height: 42px;
}
h3 {
  font-size: 27px;
  line-height: 40px;
}
h4 {
  font-size: 22px;
  line-height: 31.88px;
  letter-spacing: -0.0045em;
  -webkit-text-stroke-width: 0.24px;
}
h5 {
  font-size: 18px;
  line-height: 25.88px;
}
h3 small,
h4 small,
h5 small {
  font-weight: 300;
}
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-bottom: 10px;
}
.block-title {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
code {
  color: #c64643;
  background-color: #f0f0f0;
}
code:hover {
  background-color: #fddddd;
}
dd,
p {
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 25px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}
/* Misc
------------------------------------
*/
.line-through {
  text-decoration: line-through;
}
/* Line-heights
------------------------------------
*/
.lh-17 {
  line-height: 17px;
}
.lh-18 {
  line-height: 18px;
}
.lh-large {
  line-height: 30px;
}
.segoe{
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

/* Responsive Handlers : Typo
------------------------------------
*/
@media (max-width: 1400px) {
  .jumbotron h1 {
    line-height: 55px;
  }
}
/*
 * [7. END TYPOGRAPHY]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[8. MODALS]
 */

.modal.fade.slide_right{
	display: none !important;
}
.modal.fade.slide_right.show{
	display: block !important;
	z-index: 99999;
}
.modal.fade.slide_right .modal-dialog {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-ms-transform: translate(0, 0);
}
.modal.fade.slide_right .modal-dialog {
	position: absolute;
	top: 0;
	left: auto;
	right: 0;
	margin: 0;
	height: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-ms-transform: translate(0, 0);
}
.modal.fade.slide_right .modal-dialog .modal-content {
	height: fit-content;
	min-height: 100%;
}

#property_photos_modal.modal-dialog,
#property_featured_photos_modal.modal-dialog{
	min-width: 100%;
}
.modal.fade.slide-left .close {
	position: absolute;
	top: 0;
	left: 0;
	margin-right: 10px;
	z-index: 10;
}

.modal.fade.slide-left.show .modal-dialog {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-ms-transform: translate(0, 0);
}

.modal.fade.slide-left .modal-dialog {
	position: absolute;
	left: 0;
	margin: 0;
	height: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-ms-transform: translate(0, 0);
}

.modal.fade.slide-left .modal-dialog .modal-content-wrapper {
	height: 100%;
}

.modal.fade.slide-left .modal-dialog .modal-content {
	height: 100%;
}

.modal.fade.slide-left .modal-dialog .modal-body {
	background-color: #fff;
}

.modal.fade.slide-left .modal-content {
	border-radius: 0px;
}
@media (max-width: 576px) {
	.modal .modal-dialog{
		width: 100%;
	}
}
/*
 * [8. END MODALS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[9. ASPECT RATIO]
 */
.hover-zoom {
	cursor: pointer;
	position: relative;
}
.hover-zoom:hover [data-pages-bg-image] {
	transform: scale(1.1);
}
/*.hover-zoom:hover [data-pages-bg-image] .bg-overlay {*/
/*	opacity: .3 !important;*/
/*}*/
.hover-zoom [data-pages-bg-image] {
	transition: all .3s ease;
}
.hover-zoom [data-pages-bg-image] .bg-overlay {
	transition: all .3s ease;
}
.ar-16-9{
	aspect-ratio: 16 / 9;
}
/*
 * [9. END ASPECT RATIO]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[10. CARDS]
 */
.blog{
	margin: 0 auto;
}
.card.blog-item{
	width: 18rem;
}
.blog-sizer{
	width: 18rem;
}
/*
 * [10. END CARDS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[11. SWIPER]
 */
.swiper {
	width: 100%;
	height: 100%;
}
.swiper-slide {
	text-align: center;
	font-size: 18px;
}
.swiper-slide img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover!important;
}
.swiper-slide > span {
	width: 100%!important;
	height: 100%!important;
	max-width: 100%!important;
	max-height: 100%!important;
}
.swiper-slide .swiper-lazy > span {
	width: 100%!important;
	height: 100%!important;
	max-width: 100%!important;
	max-height: 100%!important;
}
.swiper-button-next, .swiper-button-prev{
	color: #FFF!important;
}
.swiper-pagination-bullet{
	background: #FFF!important;
}
.custom-pagination {
    text-align: center;
    width: auto;
}
.inline-pagination {
    display: inline-block !important; 
}
.swiper-pagination-flex{
	display: flex;
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
	margin: 0 2px!important;
}
.swiper-custom-pagination .swiper-custom-pagination-bullet{
	opacity: .6;
}
.swiper-custom-pagination .swiper-custom-pagination-bullet > *{
	font-size: 8px;
}
.modal-open .modal-body .swiper .swiper-button-prev,
.modal-open .modal-body .swiper .swiper-button-next{
	color: white;
}
.modal-open .modal-body .swiper .swiper-pagination-bullet {
	background-color: white;
}
.modal-open .modal-body .swiper .swiper-pagination-bullets {
	display: none;
}

@media (max-width: 576px) {
	.modal-open .modal-body .swiper .swiper-pagination-bullets {
		display: block;
	}
	.modal-open .modal-body .swiper .swiper-button-prev,
	.modal-open .modal-body .swiper .swiper-button-next{
		display: none;
	}
}
.swiper-button-prev.swiper-custom-prev:after{
	content: none;
}
.swiper-button-next.swiper-custom-next:after{
	content: none;
}
.swiper-button-prev.swiper-custom-prev,
.swiper-button-next.swiper-custom-next{
	display: none;
	background: rgba(255, 255, 255, 1);
	border-radius: 50%;
	height: 30px;
	width: 30px;
	align-items: center;
	justify-content: center;
	transform: translate(0%, 50%);
}

.d-navigation .swiper-button-prev.swiper-custom-prev,
.d-navigation .swiper-button-next.swiper-custom-next {
	display: flex;
}

.swiper-button-close{
	display: none;
	background: rgba(0, 0, 0, .4);
	border-radius: 50%;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	transform: translate(0%, 50%);
	transition: transform 0.25s ease;
	top: 0;
	right: 10px;
	position: absolute;
	z-index: 1020;
	border-width: 1px;
	border-style: solid;
	border: none;
}
.swiper:hover .swiper-button-prev.swiper-custom-prev,
.swiper:hover .swiper-button-next.swiper-custom-next,
.swiper:hover .swiper-button-close{
	display: flex;
}
.swiper-custom-prev.swiper-button-disabled,
.swiper-custom-next.swiper-button-disabled,
.swiper-button-close.swiper-button-custom-close{
	pointer-events: initial!important;
}
.swiper-button-close.swiper-button-custom-close{
	box-shadow: 0 0 6px #CCC;
	cursor: pointer;
	z-index: 10;
}
/*
 * [11. END SWIPER]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[12. SWIPER-WITH-THUMBNAILS]
 */
.swiper {
	width: 100%;
	height: 100%;
}
.mainSwiper {
	height: 80%;
	width: 100%;
}
.thumbSwiper {
	height: 18%;
	width: 100%;
}
.swiper-slide {
	text-align: center;
	font-size: 18px;
}
.swiper-slide img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover!important;
}
.swiper-slide > span {
	width: 100%!important;
	height: 100%!important;
	max-width: 100%!important;
	max-height: 100%!important;
}
.swiper-slide .swiper-lazy {
	width: 100%!important;
	height: 100%!important;
	max-width: 100%!important;
	max-height: 100%!important;
}
.thumbSwiper .swiper-slide {
	width: 25%;
	height: 100%;
	opacity: 0.4;
}
.thumbSwiper .swiper-slide-thumb-active {
	opacity: 1;
}
/*
 * [12. END SWIPER-WITH-THUMBNAILS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[13. BUTTONS]
 */
.btn-increase, .btn-decrease{
	border: 1px solid rgba(0,0,0,.6);
	border-radius: 30px;
	width: 30px;
	height: 30px;
}
.btn-increase > svg, .btn-decrease > svg{
	color: #000;
	opacity: .6;
}
.btn-increase:hover, .btn-decrease:hover{
	border: 1px solid rgba(0,0,0,.8);
	border-radius: 30px;
	width: 30px;
	height: 30px;
}
.btn-increase:hover > svg, .btn-decrease:hover > svg{
	color: #000;
	opacity: .8;
}
.btn-decrease[disabled],
.btn-increase[disabled] {
	border: 1px solid rgba(0,0,0,.3);
	border-radius: 30px;
	width: 30px;
	height: 30px;
}
.btn-decrease[disabled] > svg,
.btn-increase[disabled] > svg{
	color: #000;
	opacity: .3;
}
input#adults::-webkit-outer-spin-button,
input#adults::-webkit-inner-spin-button,
input#kids::-webkit-outer-spin-button,
input#kids::-webkit-inner-spin-button,
input#infants::-webkit-outer-spin-button,
input#infants::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input#adults,
input#kids,
input#infants {
	-moz-appearance:textfield; /* Firefox */
}
/*
 * [13. END BUTTONS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[14. VIDEO]
 */
.video-js {
	display: block;
	vertical-align: top;
	box-sizing: border-box;
	color: #fff;
	background-color: #000;
	position: relative;
	padding: 0;
	font-size: 10px;
	line-height: 1;
	font-weight: 400;
	font-style: normal;
	font-family: Arial, Helvetica, sans-serif;
	word-break: normal;
}

.video-js *, .video-js :after, .video-js :before{
	box-sizing: inherit;
}
/*
 * [14. END VIDEO]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[15. OPACITY]
 */
.opacity-10{
	opacity: .1;
}
.opacity-15{
	opacity: .15;
}
.opacity-20{
	opacity: .2;
}
.opacity-25{
	opacity: .25;
}
.opacity-30{
	opacity: .3;
}
.opacity-35{
	opacity: .35;
}
.opacity-40{
	opacity: .4;
}
.opacity-45{
	opacity: .45;
}
.opacity-50{
	opacity: .5;
}
.opacity-55{
	opacity: .55;
}
.opacity-60{
	opacity: .6;
}
.opacity-65{
	opacity: .65;
}
.opacity-70{
	opacity: .7;
}
.opacity-75{
	opacity: .75;
}
.opacity-80{
	opacity: .8;
}
.opacity-85{
	opacity: .85;
}
.opacity-90{
	opacity: .9;
}
.opacity-95{
	opacity: .95;
}
.opacity-100{
	opacity: 1;
}
/*
 * [15. END OPACITY]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[16. TEXT]
 */
.text-justify{
	text-align: justify!important;
}

.line-clamp-0,
.child-line-clamp-0 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 0!important;
	overflow: hidden!important;
}
.line-clamp-1,
.child-line-clamp-1 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 1!important;
	overflow: hidden!important;
}
.line-clamp-2,
.child-line-clamp-2 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 2!important;
	overflow: hidden!important;
}
.line-clamp-3,
.child-line-clamp-3 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 3!important;
	overflow: hidden!important;
}
.line-clamp-4,
.child-line-clamp-4 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 4!important;
	overflow: hidden!important;
}
.line-clamp-5,
.child-line-clamp-5 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 5!important;
	overflow: hidden!important;
}
.line-clamp-6,
.child-line-clamp-6 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 6!important;
	overflow: hidden!important;
}
.line-clamp-7,
.child-line-clamp-7 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 7!important;
	overflow: hidden!important;
}
.line-clamp-8,
.child-line-clamp-8 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 8!important;
	overflow: hidden!important;
}
.line-clamp-9,
.child-line-clamp-9 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 9!important;
	overflow: hidden!important;
}
.line-clamp-10,
.child-line-clamp-10 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 10!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-1,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-1 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 1!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-2,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-2 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 2!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-3,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-3 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 3!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-4,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-4 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 4!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-5,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-5 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 5!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-6,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-6 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 6!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-7,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-7 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 7!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-8,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-8 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 8!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-9,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-9 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 9!important;
	overflow: hidden!important;
}
_::-webkit-full-page-media, _:future, :root .safari-line-clamp-10,
_::-webkit-full-page-media, _:future, :root .safari-child-line-clamp-10 > p {
	width: auto!important;
	display: -webkit-box!important;
	-webkit-box-orient: vertical!important;
	-webkit-line-clamp: 10!important;
	overflow: hidden!important;
}
/*
 * [16. END TEXT]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[17. BORDERS]
 */
.border-radius-2,
.properties.border-radius-2 > *{
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	overflow: hidden;
}
.border-radius-4,
.properties.border-radius-4 > *{
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	overflow: hidden;
}
.border-radius-6,
.properties.border-radius-6 > *{
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	overflow: hidden;
}
.border-radius-8,
.properties.border-radius-8 > * {
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	overflow: hidden;
}
.border-radius-10,
.properties.border-radius-10 > *{
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	overflow: hidden;
}

.border-radius-12,
.properties.border-radius-12 > *{
	border-radius: 12px;
	-moz-border-radius: 12px;
	-webkit-border-radius: 12px;
	overflow: hidden;
}

.border-top-radius-10,
.properties.border-top-radius-10 > *{
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	-moz-border-top-left-radius: 10px;
	-moz-border-top-right-radius: 10px;
	-webkit-border-top-left-radius: 10px;
	-webkit-border-top-right-radius: 10px;
	overflow: hidden;
}
.border-top-radius-12,
.properties.border-top-radius-12 > *{
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	-moz-border-top-left-radius: 12px;
	-moz-border-top-right-radius: 12px;
	-webkit-border-top-left-radius: 12px;
	-webkit-border-top-right-radius: 12px;
	overflow: hidden;
}

.border-radius-2 > .col-photo-list > *{
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
	-moz-border-top-left-radius: 2px;
	-moz-border-bottom-left-radius: 2px;
	-webkit-border-top-left-radius: 2px;
	-webkit-border-bottom-left-radius: 2px;
	overflow: hidden;
}
.border-radius-4 > .col-photo-list > *{
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	-moz-border-top-left-radius: 4px;
	-moz-border-bottom-left-radius: 4px;
	-webkit-border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	overflow: hidden;
}
.border-radius-6 > .col-photo-list > *{
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	-moz-border-bottom-left-radius: 6px;
	-webkit-border-top-left-radius: 6px;
	-webkit-border-bottom-left-radius: 6px;
	overflow: hidden;
}
.border-radius-8 > .col-photo-list > *{
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	-moz-border-top-left-radius: 8px;
	-moz-border-bottom-left-radius: 8px;
	-webkit-border-top-left-radius: 8px;
	-webkit-border-bottom-left-radius: 8px;
	overflow: hidden;
}
.border-radius-10 > .col-photo-list > *{
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	-moz-border-top-left-radius: 10px;
	-moz-border-bottom-left-radius: 10px;
	-webkit-border-top-left-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	overflow: hidden;
}
.border-radius-2 > .col-photo-tile > *{
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	-moz-border-top-left-radius: 2px;
	-moz-border-top-right-radius: 2px;
	-webkit-border-top-left-radius: 2px;
	-webkit-border-top-right-radius: 2px;
	border-bottom-left-radius: 0;
	-moz-borderborder-bottom-left-radius: 0;
	-webkit-borderborder-bottom-left-radius: 0;
	overflow: hidden;
}
.border-radius-4 > .col-photo-tile > *{
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	-moz-border-top-left-radius: 4px;
	-moz-border-top-right-radius: 4px;
	-webkit-border-top-left-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	border-bottom-left-radius: 0;
	-moz-borderborder-bottom-left-radius: 0;
	-webkit-borderborder-bottom-left-radius: 0;
	overflow: hidden;
}
.border-radius-6 > .col-photo-tile > *{
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	-moz-border-top-left-radius: 6px;
	-moz-border-top-right-radius: 6px;
	-webkit-border-top-left-radius: 6px;
	-webkit-border-top-right-radius: 6px;
	border-bottom-left-radius: 0;
	-moz-borderborder-bottom-left-radius: 0;
	-webkit-borderborder-bottom-left-radius: 0;
	overflow: hidden;
}
.border-radius-8 > .col-photo-tile > *{
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	-moz-border-top-left-radius: 8px;
	-moz-border-top-right-radius: 8px;
	-webkit-border-top-left-radius: 8px;
	-webkit-border-top-right-radius: 8px;
	overflow: hidden;
}
.border-radius-10 > .col-photo-tile > *{
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	-moz-border-top-left-radius: 10px;
	-moz-border-top-right-radius: 10px;
	-webkit-border-top-left-radius: 10px;
	-webkit-border-top-right-radius: 10px;
	border-bottom-left-radius: 0;
	-moz-borderborder-bottom-left-radius: 0;
	-webkit-borderborder-bottom-left-radius: 0;
	overflow: hidden;
}
@media(max-width: 576px){
	.border-radius-2 > .col-photo-list > *{
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		-moz-border-top-left-radius: 2px;
		-moz-border-top-right-radius: 2px;
		-webkit-border-top-left-radius: 2px;
		-webkit-border-top-right-radius: 2px;
		border-bottom-left-radius: 0;
		-moz-borderborder-bottom-left-radius: 0;
		-webkit-borderborder-bottom-left-radius: 0;
	}
	.border-radius-4 > .col-photo-list > *{
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		-moz-border-top-left-radius: 4px;
		-moz-border-top-right-radius: 4px;
		-webkit-border-top-left-radius: 4px;
		-webkit-border-top-right-radius: 4px;
		border-bottom-left-radius: 0;
		-moz-borderborder-bottom-left-radius: 0;
		-webkit-borderborder-bottom-left-radius: 0;
	}
	.border-radius-6 > .col-photo-list > *{
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		-moz-border-top-left-radius: 6px;
		-moz-border-top-right-radius: 6px;
		-webkit-border-top-left-radius: 6px;
		-webkit-border-top-right-radius: 6px;
		border-bottom-left-radius: 0;
		-moz-borderborder-bottom-left-radius: 0;
		-webkit-borderborder-bottom-left-radius: 0;
	}
	.border-radius-8 > .col-photo-list > *{
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		-moz-border-top-left-radius: 8px;
		-moz-border-top-right-radius: 8px;
		-webkit-border-top-left-radius: 8px;
		-webkit-border-top-right-radius: 8px;
	}
	.border-radius-10 > .col-photo-list > *{
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		-moz-border-top-left-radius: 10px;
		-moz-border-top-right-radius: 10px;
		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		border-bottom-left-radius: 0;
		-moz-borderborder-bottom-left-radius: 0;
		-webkit-borderborder-bottom-left-radius: 0;
	}
}
.card.border-radius-2 > div,
.card.border-radius-2 > div > span{
	border-top-left-radius: 2px;
	-moz-border-top-left-radius: 2px;
	-webkit-border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	-moz-border-top-right-radius: 2px;
	-webkit-border-top-right-radius: 2px;
}
.card.border-radius-4 > div,
.card.border-radius-4 > div > span{
	border-top-left-radius: 4px;
	-moz-border-top-left-radius: 4px;
	-webkit-border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	-moz-border-top-right-radius: 4px;
	-webkit-border-top-right-radius: 4px;
}
.card.border-radius-6 > div,
.card.border-radius-6 > div > span{
	border-top-left-radius: 6px;
	-moz-border-top-left-radius: 6px;
	-webkit-border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	-moz-border-top-right-radius: 6px;
	-webkit-border-top-right-radius: 6px;
}
.card.border-radius-8 > div,
.card.border-radius-8 > div > span{
	border-top-left-radius: 8px;
	-moz-border-top-left-radius: 8px;
	-webkit-border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	-moz-border-top-right-radius: 8px;
	-webkit-border-top-right-radius: 8px;
}
.card.border-radius-10 > div,
.card.border-radius-10 > div > span{
	border-top-left-radius: 10px;
	-moz-border-top-left-radius: 10px;
	-webkit-border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	-moz-border-top-right-radius: 10px;
	-webkit-border-top-right-radius: 10px;
}

.card.bg-transparent.border-radius-2 > div,
.card.bg-transparent.border-radius-2 > div > span{
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
}
.card.bg-transparent.border-radius-4 > div,
.card.bg-transparent.border-radius-4 > div > span{
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
}
.card.bg-transparent.border-radius-6 > div,
.card.bg-transparent.border-radius-6 > div > span{
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}
.card.bg-transparent.border-radius-8 > div,
.card.bg-transparent.border-radius-8 > div > span{
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
}
.card.bg-transparent.border-radius-10 > div,
.card.bg-transparent.border-radius-10 > div > span{
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
}
.border-lightest{
	border-color: #f4f4f4!important;
}
/*
 * [17. END BORDERS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[18. SHADOWS]
 */
.shadow-1{
	box-shadow: 0 0 1px #ccc;
	-moz-box-shadow: 0 0 1px #ccc;
	-webkit-box-shadow: 0 0 1px #ccc;
}
.shadow-2{
	box-shadow: 0 0 2px #ccc;
	-moz-box-shadow: 0 0 2px #ccc;
	-webkit-box-shadow: 0 0 2px #ccc;
}
.shadow-3{
	box-shadow: 0 0 3px #ccc;
	-moz-box-shadow: 0 0 3px #ccc;
	-webkit-box-shadow: 0 0 3px #ccc;
}
.shadow-4{
	box-shadow: 0 0 4px #ccc;
	-moz-box-shadow: 0 0 4px #ccc;
	-webkit-box-shadow: 0 0 4px #ccc;
}
.shadow-5{
	box-shadow: 0 0 5px #ccc;
	-moz-box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
}
/*
 * [18. END SHADOWS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[19. READ MORE ANIMATION]
 */
.box {
	transition: all 0.3s ease-in-out;
}
.text,
.text.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
}
.text:not([class*="line-clamp"]),
.box .text:not([class*="line-clamp"]) {
	transition: all 0.3s ease-in-out;
	-webkit-line-clamp: 90;
}
/*
 * [19. END READ MORE ANIMATION]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
[20. GOOGLE MAPS]
 */
div:has(> .btn-design-color){
	z-index: 1020!important;
}
.btn-map{
	width: 38px;
	height: 38px;
	transition: transform .2s;
	border: 1px solid rgba(33, 33, 33, 0.17);
	box-shadow: rgb(255 255 255 / 18%) 0px 0px 0px 1px inset, rgb(0 0 0 / 18%) 0px 2px 4px;
}
.btn-map:not([disabled]):hover{
	transform: scale(1.08);
	/*filter: brightness(.95);*/
}
.btn-map[disabled]:hover,
.btn-map[disabled]:hover > *{
	pointer-events: unset!important;
}
.btn-map:focus{
	background-color: initial;
}
.gm-style-iw-d,
.gm-style-iw.gm-style-iw-c{
	padding: 0 !important;
	overflow: unset!important;
	max-height: unset!important;
}
button.gm-ui-hover-effect{
	display: none!important;
}
div.gm-style-iw-tc:after{
	content: none!important;
}
div:has(> .gm-style-iw-a){
	z-index: 9999!important;
}
.properties-pagination > button{
	width: 32px;
	height: 32px;
	border: none;
	border-radius: 100%!important;
	background-color: transparent!important;
}
.properties-pagination > button.active{
	width: 32px;
	height: 32px;
	border: none;
	border-radius: 100%!important;
	background-color: rgb(0,0,0, 1)!important;
	color: rgb(255, 255, 255)!important;
}
.properties-pagination > button:hover{
	width: 32px;
	height: 32px;
	border: none;
	border-radius: 100%!important;
	background-color: rgba(0,0,0, .7)!important;
	color: rgb(255, 255, 255)!important;
}
/*
 * [20. END GOOGLE MAPS]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
 * [21. STICKY]
 */
.z-index-5 > div.sticky {
	z-index: 5;
}
.ui.sticky.bound.top, .ui.sticky.fixed.top {
	top: 0;
	bottom: auto;
}
.ui.sticky.fixed {
	position: fixed;
	left: auto;
	right: auto;
}
.ui.sticky {
	position: static;
	-webkit-transition: none;
	transition: none;
	z-index: 800;
}
.is-sticky {
	position: fixed;
	top: 0;
	z-index: 999;
	animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
/*
 * [21. END STICKY]
 * --------------------------------------------------------------- */
/* ---------------------------------------------------------------
 * [22. PROPERTIES]
 */
.Properties__body{
	padding: 1rem 0.575rem !important;
}
.Properties__gallery{
	height: 285px;
	width: auto;
	background-color: #e6e6e6;
}
.InfoWindow-Properties__Thumb,
.Properties,
.Properties__gallery,
.Properties__gallery > div{
	/*border-radius: 12px;*/
	/*-moz-border-radius: 12px;*/
	/*-webkit-border-radius: 12px;*/
}
/* ---------- INFO WINDOW -------- */
.InfoWindow-Properties.Properties-Border{
	border: 0!important;
}
.InfoWindow-Properties.Properties-Shadow{
	box-shadow: none!important;
	-moz-box-shadow: none!important;
	-webkit-box-shadow: none!important;
}
.InfoWindow-Properties__Thumb > .Properties__gallery {
	height: 210px;
	width: 324px;
}
.InfoWindow-Properties__Thumb > .Properties__body{
	padding: 1rem!important;
}
.InfoWindow-Properties__Thumb > .Properties__body > *{
	color: initial!important;
}
.InfoWindow-Properties__Thumb > .Properties__gallery,
.InfoWindow-Properties__Thumb > .Properties__gallery > div {
	border-bottom-left-radius: 0!important;
	border-bottom-right-radius: 0!important;
	-moz-border-bottom-left-radius: 0!important;
	-moz-border-bottom-right-radius: 0!important;
	-webkit-border-bottom-left-radius: 0!important;
	-webkit-border-bottom-right-radius: 0!important;
}
/* ---------- INFO WINDOW -------- */

/*
 * [22. END PROPERTIES]
 * --------------------------------------------------------------- */

.bg-images {
  background-size: cover;
}

.image-bg-responsive{
	height: 100%;
	width: 100%;
	background-size: cover;
}
.b-l-0{
	border-left: 0;
}
.b-r-0{
	border-right: 0;
}

.b-transparent-white{
	border-color: rgba(255, 255, 255, 1) !important;
	background-color: transparent!important;
}
.b-transparent-white.focused{
	border-color: rgba(255, 255, 255, .5)!important;
	background-color: transparent!important;
}
.b-transparent-black{
	border-color: rgba(0, 0, 0, 1) !important;
	background-color: transparent!important;
}
.b-transparent-black.focused{
	border-color: rgba(0, 0, 0, .5)!important;
	background-color: transparent!important;
}
.b-transparent-master{
	border-color: rgba(0, 0, 0, .1) !important;
	background-color: transparent;
}
.b-transparent-master.focused{
	border-color: rgba(0, 0, 0, .1)!important;
	background-color: transparent;
}
[data-pages-bg-image]{
	background-size: cover;
	position: relative;
}
[data-pages-bg-image] > *{
	z-index: 0;
	position: relative;
}
[data-pages-bg-image] > .bg-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	opacity: .4;
}
.btn-no-outline:focus{
	outline: 0;
	box-shadow: none;
}
.cursor-pointer:hover{
	cursor: pointer;
}
.cursor-pointer > *:hover{
	cursor: pointer;
}
.h-0{
	height: 0;
}
.w-0{
	width: 0;
}
.letter-sp-normal{
	letter-spacing: initial!important
}
.btn-link{
	padding: 0;
	border: 0;
	background: transparent;
	text-shadow: none !important;
	color: #006BB3;
	transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
	font-weight: inherit;
	font-size: inherit;
}
.btn-link:focus,
.btn-link:hover,
.btn-link:active{
	background: transparent !important;
	color: #3a8fc0 !important;
}

/*.hover-zoom{*/
/*	filter: brightness(100%);*/
/*	transition: all 0.3s ease;*/
/*}*/
/*.hover-zoom:hover {*/
/*	filter: brightness(80%);*/
/*}*/
.brightness-filter-80{
	-webkit-filter: brightness(80%);
	filter: brightness(80%);
}
.ar-2-1:before{
	padding-top: calc(50% - 0px);
}
.fancybox__container{
	z-index: 1555!important;
}
/*.modal-open .fancybox__backdrop{*/
/*	background: rgba(0,0,0, .98);*/
/*}*/
.p-elem {
	display: block;
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0.01em;
	line-height: 25px;
	margin: 0px 0px 10px 0px;
	font-style: normal;
	white-space: normal;
}
.btn-bordered {
	background-color: transparent;
	border-width: 2px;
	border-color: #ffffff;
	color: #ffffff;
}
.btn-bordered:hover {
	background-color: #ffffff;
	border: 2px solid #ffffff;
	color: #000000;
}
.btn-bordered.btn-black{
	background-color: transparent;
	border-color: #000000;
	color: #000000;
}
.btn-bordered.btn-black:hover{
	background-color: #000000;
	color: #ffffff;
}
.b-radius-1{
	border-radius: 1px;
	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
}
.b-radius-2{
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
}
.b-radius-3{
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.b-radius-4{
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
}
.b-radius-5{
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
}
.b-radius-6{
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}
.b-radius-7{
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
}
.b-radius-8{
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
}
.b-radius-9{
	border-radius: 9px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
}
.b-radius-10{
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
}
.b-radius-11{
	border-radius: 11px;
	-webkit-border-radius: 11px;
	-moz-border-radius: 11px;
}
.b-radius-12{
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
}
.b-radius-13{
	border-radius: 13px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
}
.b-radius-14{
	border-radius: 14px;
	-webkit-border-radius: 14px;
	-moz-border-radius: 14px;
}
.b-radius-15{
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
}
.b-radius-16{
	border-radius: 16px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
}
.b-radius-17{
	border-radius: 17px;
	-webkit-border-radius: 17px;
	-moz-border-radius: 17px;
}
.b-radius-18{
	border-radius: 18px;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
}
.b-radius-19{
	border-radius: 19px;
	-webkit-border-radius: 19px;
	-moz-border-radius: 19px;
}
.b-radius-20{
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
}
.box-shadow{
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.card img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover!important;
}

.card > div > span{
	width: 100% !important;
	height: inherit !important;
	max-width: 100% !important;
	max-height: 100% !important;
}
.zig-photo img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover!important;
}

.zig-photo > div > span{
	width: 100% !important;
	height: 100% !important;
	max-width: 100% !important;
	max-height: 100% !important;
}
.col-photo img,
.col-photo-list img,
.col-photo-tile img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover!important;
}
.col-photo > span,
.col-photo-list > span,
.col-photo-tile > span{
	width: 100% !important;
	height: 100% !important;
	max-width: 100% !important;
	max-height: 100% !important;
}
.bottom-unset{
	bottom: unset!important;
}
.item-even {
	width: calc(20%);
}
.item-odd {
	width: calc(50%);
}
.infinite-scroll-component{
	overflow: unset!important;
}
.filters ul li a{
	font-weight: normal;
}
.filters ul li a.active{
	font-weight: bold;
}
.animate-child-height > span{
	transition: height 1000ms ease-out;
}
.animate-child-height.animation-active > span{
	transition: height 1000ms ease-in;
}
#btn-back-to-top{
	position: fixed;
	bottom: 20px;
	right: 20px;
	display: none;
}
.z-index-2030,
.z-index-2030 > *{
	z-index: 2030!important;
}
.text-color-initial{
	color: initial;
}
.sticky-top-important{
	position: -webkit-sticky!important;
	position: sticky!important;
	top: 0!important;
	z-index: 1022!important;
}

.nav-tabs > li > a:hover > span {
	font-weight: 600 !important;
}

#quick_search {
	display: flex;
	/*padding: 10px 20px 10px 10px;*/
}

.navbar .navbar-brand {
	margin-right: 0px;
	flex-grow: 1;
}

.map-search-activator {
	background: #ffffff;
	bottom: 20px;
	padding: 10px 20px;
	border-radius: 8px;
	left: 50%;
	transform: translate(-50%, 0);
}

.text-light-color {
	color: #ffffff !important;
}

.text-light-color * {
	color: #ffffff !important;
}

/*.design-box-service {*/
/*	border-radius: 8px;*/
/*	box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);*/
/*	-webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);*/
/*	-moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);*/
/*	border: 1px solid #eeeeee;*/
/*}*/

body, html, #__next {
	/*overflow-x: hidden;*/
}

.row:before, .row:after {display: none !important;}

.search-xs-p-b-70 {
	padding-bottom: 70px;
}